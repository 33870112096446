import moment from 'moment';
import DeepDiff from 'deep-diff';
import { ItemBankCtrl } from "../../item-set-editor/controllers/item-bank";

interface IContext {
    itemBankCtrl: ItemBankCtrl, // todo: highly stateful
}

type IQuestionConfigJSON = string;

interface IData {
    [test_design_id:number]: {
        [qId:number]: IQuestionConfigJSON
    }
}

export const itemContentDiff = (data:IData, inculdeCurrent: boolean, ctx:IContext) => {
    
    const formatTime = (time) => {
      return moment(time).format('MMMM Do YYYY, h:mm:ss a')
    }

    const parsedData:any[] = [];
    const questions:any = {}

    const globalIgnores = [
      'isShowAdvancedOptions',
      '_isCollapsed',
      '_changeCounter',
      '__changeCounter'
    ]

    const ignoreProps = (obj:any, ignoreParams=true) => {
      delete obj['quadrantFreq'];
      delete obj['versionId']
      delete obj['updated_on']
      delete obj['question_set_id'] // normally wouldnt be needed because it would never change, but we just started tracking this prop
      if (!obj['notes']){
        delete obj['notes']
      }
      if (ignoreParams){
        delete obj['meta'];
        delete obj.langLink['meta'];
      }
      return obj
    }

    const sanitizeDiff = (diffs:any[]) => {
      if (!diffs){
        return null;
      }
      const diffsSanitized:any[] = [];
      for (let i=0; i<diffs.length; i++){
        let isInclude = true;
        const diff = diffs[i]
        const path = diff?.path;
        if (path && path.length){
          const lastProp = path[path.length-1]
          if (globalIgnores.includes(lastProp)){
            isInclude = false
          }
        }
        if (isInclude){
          diffsSanitized.push(diff)
        }
      }
      if (diffsSanitized.length){
        return diffsSanitized
      }
      return null;
    }

    Object.keys(data).forEach(test_design_id => {
      const question_records = data[test_design_id]
      Object.keys(question_records).forEach(qId => {
        let record = question_records[qId];

        if(qId in questions){

          const config = ignoreProps(JSON.parse(record.config));
          // compare configs
          const diffs = DeepDiff(JSON.parse(questions[qId]["question_configs"][0]["config"] || {}), config)
          
          questions[qId]['diff'] = sanitizeDiff(diffs)
          
          questions[qId]["question_configs"].push({
            tqv_id : record.tqv_id,
              last_updated_on: formatTime(record.date),
              config: JSON.stringify(config),
              name: test_design_id,
          })

        } 
        else {

          const config = ignoreProps(JSON.parse(record.config));

          questions[qId] = {
            test_question_id : qId,
            question_label: record.question_label,
            question_configs : [{
              tqv_id : record.tqv_id,
              last_updated_on: formatTime(record.date),
              config: JSON.stringify(config),
              name: test_design_id,
            }]
          }

          if (inculdeCurrent) {
            // deep_clone 
            let current_config =  JSON.parse(JSON.stringify(ctx.itemBankCtrl.getQuestionById(+qId)));
            current_config = ignoreProps(current_config);
            questions[qId]["question_configs"].push({
              config: JSON.stringify(current_config),
              name: "current"
            });

            // compare configs
            const diffs = DeepDiff(JSON.parse(questions[qId]["question_configs"][0]["config"] || {}), current_config)
            questions[qId]['diff'] = sanitizeDiff(diffs)
            // questions[qId]['hasDiff'] = diff ? true : false
          }
        }
          
      })
    })

    parsedData.push(...Object.values(questions))

    // remodel data for expansion panel
    const reModeledData = parsedData.map(qDiff => {
      const content = [{type:'JsonView', label: 'Json-Diff', data: qDiff.diff}]
      let description = ''
      qDiff.question_configs.forEach(qc => {
        content.push({type:'TextArea', label: qc.name, data: qc.config});
        if(qc.name != 'current'){
          description += `${qc.name} : ${qc.last_updated_on}  `;
        }
      })

      const hasDiff = (qDiff.diff || content.length < 3) ? true : false  
      
      return {
        title: qDiff.test_question_id,
        subTitle: qDiff.question_label,
        description,
        content,
        hasDiff,
        style: { 'border': `solid 0.1em ${hasDiff ? "orange" : "green"}`}
      }
    });

    return reModeledData;

    
  }