// app services

import { PrintModeService } from '../../print-mode.service';

import { IQuestionConfig } from "../../../ui-testrunner/models";
import { FormControl } from '@angular/forms';
import { ItemSetFrameworkCtrl } from './framework';
import { ItemBankUtilCtrl } from './util';
import { ItemFilterCtrl } from './item-filter';
import { ItemBankCtrl } from './item-bank';
import { ItemSetPrintViewCtrl } from './print-view';
import { TestletCtrl } from './testlets';
import { IQuadrantConstraint } from '../models/assessment-framework';
import { Destroyable } from './destroyable';

export class FrameworkQuadrantCtrl implements Destroyable {

    showQuadrantConfigDetail = new FormControl();
    activeQuadrant: {id: string | number, questions: IQuestionConfig[]};
    quadrantsCrossLinks: any[] = [];
    public util = new ItemBankUtilCtrl();
  
    constructor(
      public frameworkCtrl:ItemSetFrameworkCtrl,
      public printMode: PrintModeService,
      public itemFilterCtrl:ItemFilterCtrl,
      public itemBankCtrl: ItemBankCtrl,
      public printViewCtrl: ItemSetPrintViewCtrl,
      public testletCtrl: TestletCtrl,
    ){
      
    }

    destroy() {

    }
  
    createQuadrantSection() {
      const section = parseInt(prompt('What section is this quadrant connected to? (enter a number)'));
      if(Number.isNaN(section)){return;}
      const description = prompt('How would you describe the new quadrant?', 'new quadrant');
      if(description === null){return;}
      this.frameworkCtrl.asmtFmrk.quadrants.push({
        id: this.util.nextId(this.frameworkCtrl.asmtFmrk.quadrants),
        section,
        description,
        constraints: [],
        config: {
          numItems: 1,
          discardThreshold: 100,
          triesBeforeFail: 100,
          triesBeforeFailWhole: 100,
          constraints: [],
        },
      });
    }
    activateQuadrantQuestions(quadrantId: string | number) {
      this.activeQuadrant = {
        id: quadrantId,
        questions: this.getQuadrantQuestions(quadrantId),
      };
      this.itemBankCtrl.currentItemListPage = 1;
      this.itemFilterCtrl.updateItemFilter();
      this.frameworkCtrl.scrollToQuestionListing();
    }
    printQuadrantQuestions(quadrantId: string | number) {
      this.printViewCtrl.printModeQuestions = [];
      const quadrantConfig = this.getQuadrantConfigById(quadrantId);
      this.printViewCtrl.printTitle = 'Quadrant ' + quadrantId + ': ' + quadrantConfig.description;
      this.getQuadrantQuestions(quadrantId).forEach(_question => {
        const question = this.itemBankCtrl.getQuestionContent(_question);
        this.printViewCtrl.printModeQuestions.push({
          props: [
            {caption: 'Quadrant ID', val: quadrantId},
            {caption: 'Quadrant Name', val: quadrantConfig.description},
            {caption: 'Question Label', val: question.label},
          ],
          question,
        });
      });
      console.log('printQuadrantQuestions', this.printViewCtrl.printModeQuestions);
      this.printMode.isActive = true;
    }

    computePerItemsUsedQuad(quadrant: any) {
      const deno = quadrant.questions.length;
      const nume = quadrant.numItemsUsed;
      let perc = 0;
      if (deno) {
        perc = Math.round(100 * 100 * nume / deno) / 100;
      }
      return perc + '%';
    }
  
    getQuadrantQuestions(quadrantId: string | number) {
      const questions: IQuestionConfig[] = [];
      const questionIdList:number[] = [];
      this.frameworkCtrl.asmtFmrk.quadrantItems.forEach(quadrantItemSet => {
        if (quadrantItemSet.id === quadrantId) {
          for (let qDef of quadrantItemSet.questions){
            questionIdList.push(+qDef.id)
          }
        }
      });
      this.itemBankCtrl.getItems().forEach(question => {
        if (questionIdList.includes(+question.id)) {
          questions.push(question);
        }
      });
      return questions;
    }
  
    getTestletReplacementSetting() {
      return !!this.frameworkCtrl.asmtFmrk.isTestletItemsReplaceDisabled;
    }

    getQuadrantConfigById(quadrantId: string | number) {
      let quadrant;
      this.frameworkCtrl.asmtFmrk.quadrants.forEach(_quadrant => {
        if (_quadrant.id === quadrantId) {
          quadrant = _quadrant;
        }
      });
      return quadrant;
    }
  
     
    refreshQuadrantItems(config?:{watchQuadrantId?:number, watchItemIds?:number[]}) {
      const {watchQuadrantId, watchItemIds} = (config || {})
      this.frameworkCtrl.asmtFmrk.quadrantItems = [];
      const failedCases = [];
      this.frameworkCtrl.asmtFmrk.quadrants.forEach((quadrant, quadrantIndex) => {
        const assignedItems = new Map();
        const quadrantItemsDef = {
          id: quadrant.id,
          description: quadrant.description,
          minQRequired: quadrant.config.numItems,
          questions: [],
          testletQuestions: [],
          crossLinkedItems: [],
          section: quadrant.section
        };
        if (quadrant.config.numItems > 0) {
          this.itemBankCtrl.getItems().forEach((question: any, questionIndex) => {
            const id = question.id;
            const label = question.label;
            const meta = question.meta;
            let isAllConstraintsSatisfied = true;
            const itemFailedConstraints = [];
            quadrant.constraints.forEach(constraint => {
              const vals = ('' + constraint.val).split(',');
              const targetVals = vals.map(this.util.numStrToBool);
              let configuredVal;
              try {
                configuredVal = this.util.numStrToBool(meta[constraint.param]);
              } catch (e) {
                // console.warn('Missing meta', label);
                configuredVal = false;
              }
              if (targetVals.indexOf(configuredVal) === -1 ) {
                isAllConstraintsSatisfied = false;
                itemFailedConstraints.push({
                  param: constraint.param, 
                  val_target: targetVals, 
                  val_item: configuredVal, 
                  val_item_raw: meta[constraint.param]
                })
              }
            });

            if (isAllConstraintsSatisfied) {
              if (assignedItems.has(id)) {
                const quadrantsCrossLinks = assignedItems.get(id);
                if (quadrantsCrossLinks.length = 1) {
                  quadrantItemsDef.crossLinkedItems.push({ id, label, quadrantsCrossLinks });
                }
                quadrantsCrossLinks.push(quadrant.id);
              } else {
                assignedItems.set(id, [quadrant.id]);
              }
              quadrantItemsDef.questions.push({id, label});
            }
            else {
              if (quadrant.id == watchQuadrantId && watchItemIds && watchItemIds.includes(+question.id)){
                failedCases.push({
                  quadrant_id: quadrant.id,
                  item_id: question.id,
                  gaps: itemFailedConstraints,
                })
              }
            }
          });
          this.frameworkCtrl.asmtFmrk.quadrantItems.push(quadrantItemsDef);
        }
      });
      this.testletCtrl.updateTestletSummary();
      return failedCases;
    }
    
    createQuadrantConstraint(constraints: IQuadrantConstraint[]) {
      constraints.push({
        param: prompt('Parameter'),
        val: prompt('Value'),
      });
    }
  }
  