import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DropdownService {

  constructor() { }

  public isNewItemDropdownActive: boolean = false;

  hideAllDropdowns() {
    this.isNewItemDropdownActive = false;
  }

  toggleNewItemDropdown(event: any) {
    const isShowing = !!this.isNewItemDropdownActive;
    this.hideAllDropdowns();
    if(!isShowing) {
      this.isNewItemDropdownActive = true;
    }
    event.stopPropagation();
  }
}
