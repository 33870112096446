import * as moment from 'moment';
import * as _ from 'lodash';

// app services
import { PrintModeService } from '../../print-mode.service';
import { IFilterSetting, FilterSettingMode, IFilterSettingConfigValue, IFilterSettingConfigRange } from '../../../ui-partial/capture-filter-range/capture-filter-range.component';
import { downloadStr } from '../../../core/download-string';
import { TAB, NEWLINE, stripTabs, LangId, possibleLanguages } from '../models/constants'
import { IPaginatorCtrl } from '../../../ui-partial/paginator/paginator.component';
import { FormControl } from '@angular/forms';
import { IQuadrantTestlet, IQuadrant, ITestletConstraintCommon, TestletConstraintFunction, ITestletConstraint, ITestletConstrain_VARIETY, ITestletConstrain_AVG, ITestletConstraint_MATCH, DimensionType, QuadrantConstraintErrorTypes, INPUT_ERRORS, QuadrantSettingErrors, QUADRANT_CONFIG_ERR_MESSAGES } from '../models/assessment-framework';
import { IQuestionConfig } from '../../../ui-testrunner/models';
import { ItemBankCtrl } from './item-bank';
import { compareMeasure, generateTestlets, IItem, measureAverage, measureMatch, measureVarietyCount, getBlockItemsByConstraint } from '../models/loft';
import { FrameworkQuadrantCtrl } from './quadrants';
import { ItemSetFrameworkCtrl } from './framework';
import { ItemFilterCtrl } from './item-filter';
import { ItemSetPrintViewCtrl } from './print-view';
import { Destroyable } from './destroyable';
export class TestletCtrl implements Destroyable {

  activeTestlet: {testlet: any, questions: IQuestionConfig[], questionsMissing?:{id:number, label:string}[]};
  // A map of the flagged testlet that maps each parameter to the testlet, and the different configurations of that param to the paramater
  flaggedTestlets: Map<number, Map<string, any[]>> = new Map();
  currentTestletViewing: string = '';
  currentParamViewing: string = '';
  isTestletEditOpen: boolean;
  isAllTestletsSelected: boolean;
  testletSelection: Map<IQuadrantTestlet, boolean> = new Map();
  testletFilters: {[key: string]: IFilterSetting} = {};
  testletsFiltered = [];
  updateTestletFilterThrottled = _.throttle(() => {
    this.updateTestletFilter();
  }, 1000);
  testletsFilteredDisplay = [];
  testletsPaginator:IPaginatorCtrl = {
    pageSize: 5,
    loadNewData: (skip:number) => new Promise((resolve, reject)=> {
      this.refreshTestletDisplayData();      
      resolve(null);
    })
  }
  isTestletFiltersActive = new FormControl(false);

  public printMode: PrintModeService;
  public itemBankCtrl: ItemBankCtrl;
  public quadrantCtrl: FrameworkQuadrantCtrl;

  constructor(
    public frameworkCtrl:ItemSetFrameworkCtrl,
    public itemFilterCtrl:ItemFilterCtrl,
    public printViewCtrl: ItemSetPrintViewCtrl
  ){
    this.initTestletFilter();
  }

  destroy() {

  }

  // oldId:number
  replaceTestletItem(testlet: IQuadrantTestlet, oldLabel: string, oldId?:number) {
    if (!oldLabel && !oldId) { return; }
    const newLabel = prompt('New question:');
    if (!newLabel) { return; }
    let isDuplicate = false;
    console.log('replaceTestletItem', {oldLabel, oldId},  testlet.questions);
    testlet.questions.forEach(question => {
      if (question.label === newLabel) {
        isDuplicate = true;
      }
    });
    if (isDuplicate) {
      alert('This question is already used in this testlet');
      return;
    }
    let questionIndexToSwap = -1;
    testlet.questions.forEach((question, i) => {
      // console.log(oldLabel, question.label)
      if (oldId){
        if (oldId===question.id){
          questionIndexToSwap = i;
        }
      }
      else if(oldLabel){
        if (oldLabel && question.label){
          questionIndexToSwap = i;
        }
      }
    });
    if (questionIndexToSwap === -1){
      alert('Cannot locate item to swap in testlet');
      return;
    }
    const newQuestion = this.itemBankCtrl.getQuestionByLabel(newLabel);
    console.log('replace newQuestion', newQuestion)
    testlet.questions[questionIndexToSwap] = {
      id: newQuestion.id,
      label: newQuestion.label,
      meta: newQuestion.meta,
    };
    testlet.isModified = true;
    this.activateTestletQuestions(testlet);
    this.updateTestletSummary();
  }


  addTestlet(payload:{quadrantId:number | string, questions:any[], stats?:any, quality?:any,}){
    const {
      stats,
      quality,
      questions,
      quadrantId,
    } = payload;
    const asmtFmrk = this.frameworkCtrl.asmtFmrk;
    if (!asmtFmrk.testlets) {
      asmtFmrk.testlets = [];
    }
    if (!asmtFmrk.testletCounter) {
      asmtFmrk.testletCounter = 1;
    }
    const quadrantConfig: IQuadrant = this.quadrantCtrl.getQuadrantConfigById(quadrantId);
    if (+quadrantConfig.config.numItems !== questions.length){
      alert(`Wrong number of items, cannot create testlet. Need ${quadrantConfig.config.numItems}, got ${questions.length}.`)
      return;
    }
    asmtFmrk.testletCounter++;
    const newTestlet = {
      id: this.findMaxId(asmtFmrk.testlets)+1,
      section: quadrantConfig.section,
      quadrant: quadrantConfig.id,
      statsMap: stats || {},
      stats: this.frameworkCtrl.parseStats(stats || {}),
      quality,
      questions,
    }
    asmtFmrk.testlets.push(newTestlet);
    return newTestlet;
  }

  findMaxId(arr: IQuadrantTestlet[]) {
    // Ensure the array is not empty
    if (arr.length === 0) {
      return 0; // or any other appropriate value for an empty array
    }
  
    // Use the reduce function to find the maximum ID
    const highestId = arr.reduce((maxId, currentObj) => {
      return Math.max(maxId, currentObj.id);
    }, arr[0].id);
  
    return highestId;
  }

  computePrevTestletItems(quadrantId: number | string){
    const asmtFmrk = this.frameworkCtrl.asmtFmrk;
    if (!asmtFmrk.testlets) {
      asmtFmrk.testlets = [];
    }
    let prevTestletItems:string[] = []
    for (let testlet of asmtFmrk.testlets){
      if (''+testlet.quadrant === ''+quadrantId){
        const itemIDs = testlet.questions.map(q => +q.id).sort().join()
        prevTestletItems.push(itemIDs)
      }
    }
    return prevTestletItems;
  }

  checkConfigPropertyIsPositiveNumber(value: any, propertyName: string, minValue:number = 0){
    if (value === undefined) {
      return `${propertyName} ${INPUT_ERRORS.UNDEFINED}`;
    }
    if (isNaN(value)) {
      return `${propertyName} ${INPUT_ERRORS.NOT_A_NUMBER}`;
    }
    if (Number(value) < minValue) {
      return `${propertyName} (is less than ${minValue})`;
    }
    return false;
  }

  generateConstraintErrorMessage(minVal, maxVal, type: 'MAX'|'MIN'|'RANGE', constraintID, target) {
    let errorMessage = '';
    
    switch (type) {
      case 'MAX':
        errorMessage = `Max possible ${constraintID} ${maxVal} is less than allowed ${target}`;
        break;
      case 'MIN':
        errorMessage = `Min possible ${constraintID} ${minVal} is more than allowed ${target}`;
        break;
      case 'RANGE':
        errorMessage = `Required ${constraintID} ${target} is outside possible Range ${minVal} - ${maxVal}`;
        break;
      default:
        errorMessage = `Invalid constraint type: ${type}`;
    }
    return errorMessage;
  }

  checkQuadrantConstraints(quadrant: IQuadrant):boolean{
    const config = quadrant.config
    const questions = <IItem[]>this.quadrantCtrl.getQuadrantQuestions(quadrant.id);
    let canGenerateTestlets = true;
    // First check that there are no missing configs
    const numExistingTestlets = this.frameworkCtrl.asmtFmrk.quadrantItems.find(q => q.id === quadrant.id).numTestlets;
    const {triesBeforeFail, triesBeforeFailWhole, numItems, discardThreshold} = config;
    // Map for constraint errors contain USER_INPUT and IMPOSSIBLE_CONSTRAINT
    const constraintError = new Map();
    let missingConfigs: any[] = [];

    // SO this is a soft check as  it's only checking that if there are no constraints how many testlet there'd be
    if(numExistingTestlets >= this.getTheMaxNumberOfTestlet(+questions.length, +numItems)){
      alert(QUADRANT_CONFIG_ERR_MESSAGES.NO_MORE_TESTLETS_POSSIBLE);
      return false
    }
    // Check that each destructured property exists and is defined
    [this.checkConfigPropertyIsPositiveNumber(triesBeforeFail, QuadrantSettingErrors.TRIES_BEFORE_FAIL),
    this.checkConfigPropertyIsPositiveNumber(triesBeforeFailWhole, QuadrantSettingErrors.TRIES_BEFORE_FAIL_WHOLE),
    this.checkConfigPropertyIsPositiveNumber(numItems, QuadrantSettingErrors.NUM_ITEMS),
    this.checkConfigPropertyIsPositiveNumber(discardThreshold, QuadrantSettingErrors.DISCARD_THRESHOLD)].forEach((err)=>{
      if(err){
        missingConfigs.push(err)
      }
    })

    

    const quadrantParams = this.frameworkCtrl.getCurrentParameters();
    const possibleParamCodes = quadrantParams.map(param=>param.code)
    // Check that none of the constraints have missing params
    let constraintsWithoutParam = 0;
    // Check that params exist in quadrants
    const nonExistingParams = [];
    let unknownComparisons = 0;
    config.constraints?.forEach((constraint)=>{
      if(constraint.config.isHidden){
        return;
      }
      if(!constraint.config.param){
        constraintsWithoutParam++;
        return;
      }
      if(!possibleParamCodes.includes(constraint.config.param)){
        nonExistingParams.push(constraint.config.param);
      };
      let isCount, isVal;
      const constraintID = `${constraint.config.param}(${constraint.func})`
      switch (constraint.func) {
        case TestletConstraintFunction.VARIETY:
          const constraintVariety = constraint.config as ITestletConstrain_VARIETY;
          const regVarietyCount = Number(constraintVariety.count)
          isCount = this.checkConfigPropertyIsPositiveNumber(regVarietyCount, 'Count', 0);
          if(isCount){
          this.pushOrCreateTestletParamMap(constraintError, QuadrantConstraintErrorTypes.USER_INPUT, constraintID, isCount);
          } else {
            if(constraintVariety.isMin && regVarietyCount > numItems){
              this.pushOrCreateTestletParamMap(constraintError, QuadrantConstraintErrorTypes.USER_INPUT, constraintID, QUADRANT_CONFIG_ERR_MESSAGES.MIN_COUNT_GREATER_THAN_ITEMS);
            }
            const { minVariety, maxVariety } = this.getMinMaxVariety(questions, constraintVariety.param, numItems, constraintVariety.isEmptyWildcard);
            if (constraintVariety.isMin && maxVariety < regVarietyCount) {
              this.pushOrCreateTestletParamMap(constraintError, QuadrantConstraintErrorTypes.IMPOSSIBLE_CONSTRAINT, constraintID, this.generateConstraintErrorMessage(minVariety, maxVariety, "MAX", 'variety', regVarietyCount));
            }
            if (constraintVariety.isMax && minVariety > regVarietyCount) {
              this.pushOrCreateTestletParamMap(constraintError, QuadrantConstraintErrorTypes.IMPOSSIBLE_CONSTRAINT, constraintID, this.generateConstraintErrorMessage(minVariety, maxVariety, "MIN", 'variety', regVarietyCount));
            }
            if (constraintVariety.isEqual && (minVariety > regVarietyCount || maxVariety < regVarietyCount)) {
              this.pushOrCreateTestletParamMap(constraintError, QuadrantConstraintErrorTypes.IMPOSSIBLE_CONSTRAINT, constraintID, this.generateConstraintErrorMessage(minVariety, maxVariety, "RANGE", 'variety', regVarietyCount));

            }
          }

          break;
        case TestletConstraintFunction.AVG:
          const constraintAVG =  constraint.config as ITestletConstrain_AVG;
          isVal = isNaN(constraintAVG.val);
          if(isVal){
            let message = constraintAVG.val? constraintAVG.val + INPUT_ERRORS.NOT_A_NUMBER : INPUT_ERRORS.UNDEFINED;
            this.pushOrCreateTestletParamMap(constraintError, QuadrantConstraintErrorTypes.USER_INPUT, constraintID, "Val "+ message);
          } else {
            const {minAverage, maxAverage} =  this.getMinMaxNAverages(questions, constraintAVG.param, numItems);
            if(constraintAVG.isMax && minAverage > constraintAVG.val){
              this.pushOrCreateTestletParamMap(constraintError, QuadrantConstraintErrorTypes.IMPOSSIBLE_CONSTRAINT, constraintID, this.generateConstraintErrorMessage(minAverage, maxAverage, "MAX", 'average', constraintAVG.val));
            } 
            if(constraintAVG.isMin && maxAverage < constraintAVG.val){
              this.pushOrCreateTestletParamMap(constraintError, QuadrantConstraintErrorTypes.IMPOSSIBLE_CONSTRAINT, constraintID, this.generateConstraintErrorMessage(minAverage, maxAverage, "MIN", 'average', constraintAVG.val));

            } 
            if (constraintAVG.isEqual && (minAverage > constraintAVG.val || maxAverage < constraintAVG.val)) {
              this.pushOrCreateTestletParamMap(constraintError, QuadrantConstraintErrorTypes.IMPOSSIBLE_CONSTRAINT, constraintID, this.generateConstraintErrorMessage(minAverage, maxAverage, "RANGE", 'average', constraintAVG.val));
            }
          }
          break;

        case TestletConstraintFunction.MATCH:
          let constraintMatch =  constraint.config as ITestletConstraint_MATCH;
          let constraintIDMatch = `${constraint.config.param}(${constraint.func} = ${constraintMatch.val})`;
          const reqMatchCount = Number(constraintMatch)
          isCount = this.checkConfigPropertyIsPositiveNumber(constraintMatch.count, 'Count', 0);
          isVal = constraintMatch.val === undefined || constraintMatch.val === null;
          if(isCount){
            this.pushOrCreateTestletParamMap(constraintError, QuadrantConstraintErrorTypes.USER_INPUT, constraintIDMatch, isCount);
          }
          if(isVal){
            this.pushOrCreateTestletParamMap(constraintError, QuadrantConstraintErrorTypes.USER_INPUT, constraintIDMatch, 'Val ' + INPUT_ERRORS.UNDEFINED);
          }
          if(!isCount && !isVal){
            if(constraintMatch.isMin && reqMatchCount > numItems){
              this.pushOrCreateTestletParamMap(constraintError, QuadrantConstraintErrorTypes.USER_INPUT, constraintIDMatch, QUADRANT_CONFIG_ERR_MESSAGES.MIN_COUNT_GREATER_THAN_ITEMS);
            }
            const matchCount = measureMatch(questions, constraintMatch);
            const nonMatchCount = numItems - matchCount;
            if (constraintMatch.isMin && reqMatchCount > matchCount) {
              this.pushOrCreateTestletParamMap(constraintError, QuadrantConstraintErrorTypes.IMPOSSIBLE_CONSTRAINT, constraintID, this.generateConstraintErrorMessage(reqMatchCount- nonMatchCount, matchCount, "MAX", 'match', reqMatchCount));
              this.pushOrCreateTestletParamMap(constraintError, QuadrantConstraintErrorTypes.IMPOSSIBLE_CONSTRAINT, constraintIDMatch, `Max possible matches ${matchCount} is less than minimum allowed ${reqMatchCount}`);
            } 
            if (constraintMatch.isMax && (numItems - reqMatchCount) > nonMatchCount) {
              this.pushOrCreateTestletParamMap(constraintError, QuadrantConstraintErrorTypes.IMPOSSIBLE_CONSTRAINT, constraintID, this.generateConstraintErrorMessage(reqMatchCount- nonMatchCount, matchCount, "MIN", 'match', reqMatchCount));
            }
            if (constraintMatch.isEqual && ((numItems - reqMatchCount) > nonMatchCount || reqMatchCount > matchCount) ) {
              this.pushOrCreateTestletParamMap(constraintError, QuadrantConstraintErrorTypes.IMPOSSIBLE_CONSTRAINT, constraintID, this.generateConstraintErrorMessage(Math.max(0 ,numItems - nonMatchCount), Math.min(reqMatchCount, matchCount), "MIN", 'match', reqMatchCount));
            }
          }
          break;

        default:
          unknownComparisons++
          break;
      }

    })


    // These are errors mostly related to user inputs
    if(missingConfigs.length > 0) {
      canGenerateTestlets = false;
      alert(QUADRANT_CONFIG_ERR_MESSAGES.PROBLEMATIC_QUADRANT_CONFIG + missingConfigs.join('\n- '));
    }
    if(constraintsWithoutParam){
      canGenerateTestlets = false; // I'm not fully sure this prevents testlet gen but still shouldn't be blank
      alert(`There ${constraintsWithoutParam > 1 ? "are": 'is'} ${constraintsWithoutParam} constraints without target param`);
    }
    if(unknownComparisons){
      canGenerateTestlets = false;
      alert(`There ${unknownComparisons > 1 ? "are": 'is'} ${unknownComparisons} constraints with unkown comparison functions`);
    }

    // These are errors directly related to the Quadrants constraints
    if(constraintError.has(QuadrantConstraintErrorTypes.USER_INPUT)){
      canGenerateTestlets = false;
      const INPUT_ERRORS = constraintError.get(QuadrantConstraintErrorTypes.USER_INPUT);
      let ERROR_MESSAGE = <string> QUADRANT_CONFIG_ERR_MESSAGES.INVALID_INPUT_FOR_CONSTRAINT;
      INPUT_ERRORS.keys().forEach(err=>{
        ERROR_MESSAGE += `\n- ${err}: ${INPUT_ERRORS.get(err).join(', ')}`
      })
      alert(ERROR_MESSAGE);
    }
    if(constraintError.has(QuadrantConstraintErrorTypes.IMPOSSIBLE_CONSTRAINT)){
      canGenerateTestlets = false;
      const INPUT_ERRORS = constraintError.get(QuadrantConstraintErrorTypes.IMPOSSIBLE_CONSTRAINT);
      let ERROR_MESSAGE = <string> QUADRANT_CONFIG_ERR_MESSAGES.IMPOSSIBLE_CONSTRAINT_MESSAGE;
      INPUT_ERRORS.keys().forEach(err=>{
        ERROR_MESSAGE += `\n- ${err}: ${INPUT_ERRORS.get(err).join(', ')}`
      })
      alert(ERROR_MESSAGE);
    }
    
    // These are just warnings they don't halt the process
    if(nonExistingParams.length){
      alert(QUADRANT_CONFIG_ERR_MESSAGES.MISSING_PARAM_ON_CONSTRAINT + nonExistingParams.join('\n- '));
    }
    return canGenerateTestlets;
  }
  
  getMinMaxVariety(questions: IItem[], param: string, n: number, isEmptyWildcard: boolean) {
    // Extract values from questions.meta[param]
    const values = questions.map(question => question.meta[param]);
  
    // Measure variety count as per measureVarietyCount function
    let varietyCount = 0;
    const varietyRef: { [key: string]: number } = {};
    values.forEach(value => {
      const val = value ? '' + value : '';
      if (!varietyRef[val] || (!val && isEmptyWildcard)) {
        varietyCount++;
      }
      if (isEmptyWildcard || (val !== null && val !== undefined && val !== '')) {
        varietyRef[val] = (varietyRef[val] || 0) + 1;
      }
    });
  
    // Calculate maximum variety (min(n, unique values))
    const maxVariety = Math.min(n, varietyCount);
  
    // Calculate minimum variety (how many of the most frequent values are needed to reach n)
    const valueCounts = Object.values(varietyRef).sort((a, b) => b - a);
    let countToReachN = 0;
    let currentN = 0;
    for (const count of valueCounts) {
      currentN += count;
      countToReachN++;
      if (currentN >= n) {
        break;
      }
    }
    const minVariety = countToReachN;
  
    return { minVariety, maxVariety };
  }

  getMinMaxNAverages(questions: IItem[], param: string, n: number) {
    // Convert values to numbers and filter out non-numeric values
    const numericValues = questions
      .map(question => {
        const value = question.meta[param];
        if (typeof value === 'number') {
          return value;
        }
        if (typeof value === 'boolean') {
          return value ? 1 : 0; // Convert boolean to number
        }
        const parsedValue = parseFloat(value as string);
        return isNaN(parsedValue) ? NaN : parsedValue;
      })
      .filter(value => !isNaN(value));
  
    // Sort the numeric values in ascending order
    numericValues.sort((a, b) => a - b);
  
    // Get the min n values
    const minNValues = numericValues.slice(0, n);
    const minSum = minNValues.reduce((acc, val) => acc + val, 0);
    const minAverage = minSum / n;
  
    // Get the max n values
    const maxNValues = numericValues.slice(-n);
    const maxSum = maxNValues.reduce((acc, val) => acc + val, 0);
    const maxAverage = maxSum / n;
  
    return { minAverage, maxAverage };
  }
  
  /**
   * 
   * @param n Number of items mapped to quadrant
   * @param x Number of items per testlet
   * @returns Number of testlet that can be created if no constraints applied
   */
  getTheMaxNumberOfTestlet(n: number, k: number): number {
    // Calculate the binomial coefficient using an iterative approach
    if (k < 0 || k > n) {
      return 0; // Invalid input according to the definition of combination
    }
    
    let result = 1;
    
    // Since C(n, k) == C(n, n-k)
    if (k > n - k) {
        k = n - k;
    }
    
    // Calculate C(n, k) = n! / (k! * (n - k)!)
    for (let i = 0; i < k; i++) {
        result *= (n - i);
        result /= (i + 1);
    }
    
  return result;
  }
  generateTestlets(quadrantId: number | string) {
    const numTestlets = parseInt(prompt('How many?', '1'));
    const isTestletItemsReplaceDisabled = this.quadrantCtrl.getTestletReplacementSetting()
    const quadrantConfig: IQuadrant = this.quadrantCtrl.getQuadrantConfigById(quadrantId);
    const questions = this.quadrantCtrl.getQuadrantQuestions(quadrantId).map(question => {
      return {
        id: question.id,
        label: question.label,
        meta: question.meta,
      };
    });

    try {
      if(!this.checkQuadrantConstraints(quadrantConfig)){
        return;
      }
      const teslets = generateTestlets(questions, quadrantConfig, numTestlets, this.computePrevTestletItems(quadrantId), isTestletItemsReplaceDisabled);
      if(!teslets?.length){
        alert(`Could not generate ${numTestlets} testlets. Try again after adjusting the quadrant configuration details and/or the quadrant questions.`);
        return;
      }
      else if(teslets.length !== numTestlets){
        if(!confirm(`Could only generate ${teslets.length}/${numTestlets}, would you like to proceed to adding them?`)){
          return
        }
      } else {
        alert(teslets.length + ' generated based on framework constraints. You can try again if you wish to generate more.');
      }
      teslets.map(testlet => {
        this.addTestlet({
          quadrantId, 
          questions: testlet.items,
          stats: testlet.stats,
          quality: testlet.quality,
        })
      });
      this.updateTestletSummary();
    } catch (err) {
        alert(`Unknown Error: Could not generate Testlets.`);
    }
  }

  toggleTestletColumnsEdit() {
    this.gatherActiveTestletColumns();
    this.isTestletEditOpen = !this.isTestletEditOpen;
  }

  /**
   * Function that takes a constraints comparison action and returns a string
   * @param constraint 
   * @returns String to symbolize what t
   */
  getConstraintcomparison(constraint: ITestletConstraintCommon):string{
    if(constraint.isEqual){
      return 'EQUAL'
    }
    if(constraint.isMax){
      return 'MAX'
    }
    if(constraint.isMin){
      return 'MIN'
    }
    else return 'COMPARE_NOT_SET'
  }

  clearFlaggedTestlets(){
    this.flaggedTestlets = new Map();
  }

  /**
   * Function that maps each param to a testlet, and the constraints of those testlets to those params
   * @param setFailedParamsOnly set true to only map the failed constraints/params
   * @param retrieveHiddenConstraints set true if you'd like to retrieve the hidden constraints as well
   * @param checkQuadrantParams this flag is used to check if testlets has items that intersection doesn't allow or the required number of items 
   * @returns 
   */
  retrieveTestletParams(setFailedParamsOnly: boolean = false, retrieveHiddenConstraints: boolean = false, checkQuadrantParams: boolean = false) {
    
    this.flaggedTestlets = new Map();
    const quadrantConstraintMap = new Map();
    const quadrantItemCountMap = new Map();
  
    // Creating a map for each quadrant and populating it with its constraints
    this.frameworkCtrl.asmtFmrk.quadrants.forEach(quadrant => {
      // We have to clone as map passes the references and since we're adding modifying what's being passed to the map
      quadrantConstraintMap.set(quadrant.id, JSON.parse(JSON.stringify(quadrant.config.constraints)));
      quadrantItemCountMap.set(quadrant.id, quadrant.config.numItems);
  
      // If we're checking intersection constraints, add constraints here
      if (checkQuadrantParams) {
        quadrant.constraints.forEach(intersection => {
          quadrantConstraintMap.get(quadrant.id).push({
            func: TestletConstraintFunction.MATCH,
            config: {
              val: (""+intersection.val).trim(),
              count: +quadrant.config.numItems,
              param: ""+intersection.param,
              isMin: true,
              isIntersection: true
            }
          });

        });
      }
    });
  
    this.frameworkCtrl.asmtFmrk.testlets.forEach(testlet => {
      const constraints = quadrantConstraintMap.get(testlet.quadrant);
      const blockItems = <IItem[]>this.getTestletQuestions(testlet).questions;
  
      // Checking if the number of items is correct, if not adding to the array of issues
      if (checkQuadrantParams && setFailedParamsOnly) {
        const quadNumItems = quadrantItemCountMap.get(testlet.quadrant);
        if (+blockItems.length !== +quadNumItems) {
          this.pushOrCreateTestletParamMap(this.flaggedTestlets, testlet.id, '# of Items', { required: +quadNumItems, current: +blockItems.length });
        }
      }
  
      constraints?.forEach(constraint => {
        if(constraint.config.isHidden && !retrieveHiddenConstraints){
          return;
        }
        let paramObject = {};
        let constraintConfig = constraint.config, count, measureConfig;
        const allowedBlockItems = getBlockItemsByConstraint(constraint, blockItems);
        switch (constraint.func) {
          case TestletConstraintFunction.VARIETY:
            count = measureVarietyCount(allowedBlockItems, constraintConfig);
            measureConfig = constraintConfig.count;
            paramObject = {
              func: <string>constraint.func,
              count: +count,
              comparison: this.getConstraintcomparison(constraintConfig),
              required: +constraintConfig.count,
            };
            break;
  
          case TestletConstraintFunction.AVG:
            if (blockItems.length) {
              count = measureAverage(allowedBlockItems, constraintConfig);
              measureConfig = constraintConfig.val;
              paramObject = {
                func: <string>constraint.func,
                testletAVG: +count,
                comparison: this.getConstraintcomparison(constraintConfig),
                required: +constraintConfig.val,
              };
            }
            break;
  
          case TestletConstraintFunction.MATCH:
            count = measureMatch(allowedBlockItems, constraintConfig);
            measureConfig = constraintConfig.count;
            paramObject = constraintConfig.isIntersection ?
              {
                items_fail_intersection: true,
                required_val: constraintConfig.val,
                outdated_items: +constraintConfig.count - count,
              } :
              {
                func: <string>constraint.func,
                targetValue: constraintConfig.val,
                count: count,
                comparison: this.getConstraintcomparison(constraintConfig),
                required: +constraintConfig.count,
              };
            break;
  
          default:
            paramObject = {
              error: 'Unknown Comparison Function'
            };
            break;
        }
  
        // If retrieving only failed params, then ensure that the measure fails
        if (!setFailedParamsOnly || !compareMeasure(count, measureConfig, constraintConfig)) {
          this.pushOrCreateTestletParamMap(
            this.flaggedTestlets,
            testlet.id,
            constraintConfig.param,
            paramObject
          );
        }
        if(allowedBlockItems.length !== blockItems.length){
          const allowedItemID = allowedBlockItems.map(item => item.id)
          const removedItems = blockItems.filter(item => !allowedItemID.includes(item.id)).map(item => `${item.label} (${item.id})`);
          const missingParamObj = {
            items_missing_params: removedItems
          }
          this.pushOrCreateTestletParamMap(
            this.flaggedTestlets,
            testlet.id,
            constraintConfig.param,
            missingParamObj
          )
        }
      });
    });
  }
  
  /**
   *  Function used to toggle the params to see the details of that params constraint in the widget table
   *  @param param parameter being toggled
   *  @param testlet testlet ID being viewed
   */
  setView(param, testlet){
    if(this.currentTestletViewing === testlet && this.currentParamViewing === param){
      this.currentTestletViewing = '';
      this.currentParamViewing = '';
      return;
      }
    this.currentTestletViewing = testlet;
    this.currentParamViewing = param;
  }

  /**
   * A function that creates a map for all testlets and their param stats, ensuring that the passed keys are created if they don't exists
   * @param testletParamMap a map of testlets to params
   * @param testledID The testlet we are adding a param stat to
   * @param paramCode The key for the the param in the testlet 
   * @param value param stat being pushed to the array held by param in testlet
   */
  pushOrCreateTestletParamMap(testletParamMap: Map<any, Map<any, any[]>>, testledID: number|string, paramCode: string, value: any) {
    // Check if the parent map exists
    if (!testletParamMap.has(testledID)) {
      testletParamMap.set(testledID, new Map());
    }
    const innerMap = testletParamMap.get(testledID);
    // Check if child map exists
    if (!innerMap.has(paramCode)) {
      innerMap.set(paramCode, [value]);
    }
    else {
      innerMap.get(paramCode).push(value);
    }
  }

  gatherActiveTestletColumns() {
    const asmtFmrk = this.frameworkCtrl.asmtFmrk;
    const existingColRef = new Map();
    if (!asmtFmrk.testletStatCol) {
      asmtFmrk.testletStatCol = [];
    }
    asmtFmrk.testletStatCol.forEach(col => {
      existingColRef.set(col.key, col);
    });
    asmtFmrk.testlets.forEach(testlet => {
      if (!testlet.statsMap) {
        testlet.statsMap = {};
      }
      // <temp >
      testlet?.stats?.forEach(({ key, val }) => {
        testlet.statsMap[key] = val;
      });      
      // </temp>
      Object.keys(testlet.statsMap).forEach(key => {
        if (!existingColRef.has(key)) {
          const col = { key, isShown: true, };
          asmtFmrk.testletStatCol.push(col);
          existingColRef.set(key, col);
        }
      });
    });
  }

  recomputeTestletStats(){
    const testlets = this.frameworkCtrl.asmtFmrk.testlets;
    const asmtFmrk = this.frameworkCtrl.asmtFmrk;
    const question = new Map();
    this.itemBankCtrl.questions.forEach(q =>{
      question.set(q.id, {label: q?.label, meta: q?.meta})
    })
    testlets.forEach(testlet =>{
      testlet.questions.forEach(q =>{
        if(question.has(q.id)){
          const {label, meta} = question.get(q.id);
          q.label = label;
          q.meta = meta;
        }
      })
    })
    const existingColRef = new Map();
    const constraints:ITestletConstraint[] = [];
    this.frameworkCtrl.asmtFmrk.quadrants.forEach(quadrant => quadrant.config.constraints.forEach(c => {if(!c.config.isHidden){constraints.push(c)}}));
    const uniqueConstraints = [];
    // Setting the columns
    const testletStatCol = []
    constraints.sort((a,b) =>{ // Sort it by param so it's a bit mor organized
      const paramA = a.config.param.toLowerCase(); 
      const paramB = b.config.param.toLowerCase(); 
      
      if (paramA < paramB) {
        return -1;
      }
      if (paramA > paramB) {
        return 1;
      }
      return 0; 
    })
    .forEach(constraint =>{
      let colName;
      switch (constraint.func) {
        
        case TestletConstraintFunction.MATCH:
          const matchConstraint = <ITestletConstraint_MATCH> constraint.config;
          colName = `${constraint.config.param}/${constraint.func}(${matchConstraint.val})`;
          break;
        case TestletConstraintFunction.VARIETY:
          const varietyConstraint = <ITestletConstrain_VARIETY> constraint.config;
          colName = `${constraint.config.param}/${constraint.func}${varietyConstraint?.isEmptyWildcard?"(Wildcard)":""}`;
          break;
        case TestletConstraintFunction.AVG:
        default:
          colName = `${constraint.config.param}/${constraint.func}`;
          break;
      }
      if(!existingColRef.has(colName)){
        existingColRef.set(colName, false);
        testletStatCol.push({key: colName, isShown: asmtFmrk.testletStatCol.find(t => t.key === colName)?.isShown?? false});
        uniqueConstraints.push(constraint);
      }
    });
    
    asmtFmrk.testletStatCol = testletStatCol;

    // Setting value in columns
    testlets.forEach(testlet=>{
      const {questions} = this.getTestletQuestions(testlet, false);
      testlet.stats = [];
      testlet.statsMap = {};
      
      uniqueConstraints.forEach(constraint =>{
        let stat;
        let colName;
        switch (constraint.func) {
          case TestletConstraintFunction.MATCH:
            const matchConstraint = <ITestletConstraint_MATCH> constraint.config;
            stat = measureMatch(questions, constraint.config);
            colName = `${constraint.config.param}/${constraint.func}(${matchConstraint.val})`;
            break;
          case TestletConstraintFunction.VARIETY:
            const varietyConstraint = <ITestletConstrain_VARIETY> constraint.config;
            stat = measureVarietyCount(questions, constraint.config);
            colName = `${constraint.config.param}/${constraint.func}${varietyConstraint.isEmptyWildcard?"(Wildcard)":""}`;
            break;
          case TestletConstraintFunction.AVG:
            stat = measureAverage(questions, constraint.config);
            colName = `${constraint.config.param}/${constraint.func}`;
            break;
          default:
            stat = 'FUNC Error';
            break;
        }

        testlet.stats.push({key: colName, val: stat});
        testlet.statsMap[colName] = stat;
      });
    });
  }

  getTestletStat(testlet: IQuadrantTestlet, key: string) {
    if (testlet.statsMap) {
      let val = testlet.statsMap[key];
      if (typeof val === 'number') {
        val = parseFloat(val.toFixed(2));
      }
      return val;
    }
  }

  toggleSelectAllTestlets() {
    const asmtFmrk = this.frameworkCtrl.asmtFmrk;
    if (!this.isAllTestletsSelected) {
      this.isAllTestletsSelected = true;
      asmtFmrk.testlets.forEach(testlet => {
        this.testletSelection.set(testlet, true);
      });
    } else {
      this.isAllTestletsSelected = false;
      asmtFmrk.testlets.forEach(testlet => {
        this.testletSelection.set(testlet, false);
      });
    }
  }
  checkTestletSelected(testlet: IQuadrantTestlet) {
    return !!this.testletSelection.get(testlet);
  }
  toggleTestletSelection(testlet: IQuadrantTestlet) {
    if (this.testletSelection.get(testlet)) {
      this.testletSelection.set(testlet, false);
    } else {
      this.testletSelection.set(testlet, true);
    }
  }

  createTestletConstraint(constraints: ITestletConstraint[]) {
    const testletConstraint = {
      weight: 1,
      config: <any> {
        param: prompt('Parameter'),
        isHidden: false,

      },
      func: <TestletConstraintFunction> prompt('Function (MATCH, VARIETY, AVG)').toUpperCase(), // , or STDEV
    };
    const minMax = prompt('MIN/MAX').toUpperCase();
    const config = <ITestletConstraintCommon> testletConstraint.config;
    if (minMax === 'MIN') {
      config.isMin = true;
    } else {
      config.isMax = true;
    }
    constraints.push(testletConstraint);
  }
  getTestletQuestions(testlet, warnMissing:boolean = true) {
    let questionsMissing:{label:string, id:number}[] = [];
    const questions = [];
    testlet.questions.forEach(questionRef => {
      const question = this.itemBankCtrl.getQuestionById(questionRef.id);
      if (question){
        questions.push(question)
      }
      else{
        questionsMissing.push(questionRef);
      }
    });;
    if (questionsMissing.length && warnMissing){
      alert('Some questions no longer appear to be available in this item bank: ' + questionsMissing.map(q => q.label).join(', '));
    }
    return {questions, questionsMissing};
  }
  removeAllTestletFromQuadrant(quadrantId: number) {
    if (confirm('Are you sure that you want to delete all of the testlets generated for quadrant ' + quadrantId)) {
      const asmtFmrk = this.frameworkCtrl.asmtFmrk;
      asmtFmrk.testlets = asmtFmrk.testlets.filter(testlet => {
        return (testlet.quadrant !== quadrantId);
      });
      this.updateTestletSummary();
    }
  }

  updateTestletSummary() {
    // quadrant
    const quadrantsMap = new Map();
    this.frameworkCtrl.asmtFmrk.quadrantItems.forEach(quadrantItemSetInfo => {
      quadrantItemSetInfo.numTestlets = 0;
      quadrantItemSetInfo.numTestletsUsed = 0;
      quadrantItemSetInfo.numItemsUsed = 0;
      quadrantItemSetInfo.testletQuestions = [];
      quadrantsMap.set(1 * quadrantItemSetInfo.id, quadrantItemSetInfo);
    });
    // todo: should be splitting by quadrant first before computing on testlet (otherwise if an item is in more than one quadrant... which is normally not allowed, then it could have some strange double counting)
    const questionMap = new Map();
    this.itemBankCtrl.getItems().forEach(question => {
      question.quadrantFreq = 0;
      questionMap.set(+question.id, question);
    });
    this.frameworkCtrl.asmtFmrk.testlets.forEach(testlet => {
      const quadrant = quadrantsMap.get(1 * testlet.quadrant);
      if (quadrant) {
        quadrant.numTestlets ++;
        const trackedQuestionIds:number[] = quadrant.testletQuestions.map(q => +q.id); // todo:assumption that testlets do not contain an item more than once (within any given testlet)
        if(!testlet.isDisabled) {
          testlet.questions.forEach(questionInfo => {
            const question = questionMap.get(+questionInfo.id);
            if (question) {
              if (!question.quadrantFreq) {
                question.quadrantFreq = 0;
              }
              question.quadrantFreq ++;
            }
            if (! trackedQuestionIds.includes(+questionInfo.id)){
              quadrant.numItemsUsed ++
              quadrant.testletQuestions.push(questionInfo)
            }
          })
          quadrant.numTestletsUsed ++;
        }
      } else {
        console.warn('no quadrant', );
      }
    });
    const numTestTakers = this.frameworkCtrl.asmtFmrk.estimatedTestTakers || 100;
    this.frameworkCtrl.asmtFmrk.quadrantItems.forEach(quadrantItemSetInfo => {
      let exposureMin;
      let exposureMax = 0;
      quadrantItemSetInfo.testletQuestions.forEach(questionInfo => {
        const question = questionMap.get(+questionInfo.id);
        if (question) {
          if (!quadrantItemSetInfo.numTestlets) {
            question.estimatedExposure = 0;
          } else {
            question.estimatedExposure = Math.round((question.quadrantFreq / quadrantItemSetInfo.numTestlets) * numTestTakers);
          }
          const exposure = question.estimatedExposure || 0;
          if (exposure > 0) {
            if (exposureMin === undefined) {
              exposureMin = exposure;
            }
            exposureMin = Math.min(exposureMin, exposure);
          }
          exposureMax = Math.max(exposureMax, exposure);
        }
      });
      quadrantItemSetInfo.exposureMin = exposureMin || 0;
      quadrantItemSetInfo.exposureMax = exposureMax;
    });
    this.frameworkCtrl.asmtFmrk.isExposureComputed = true;
  }
  refreshTestletDisplayData(){
    this.testletsPaginator.totalRecords = this.testletsFiltered.length;
    const pageSize = this.testletsPaginator.pageSize;
    this.testletsPaginator.totalPages = Math.ceil(this.testletsPaginator.totalRecords/pageSize);
    this.testletsPaginator.currentPage = Math.min(this.testletsPaginator.currentPage, this.testletsPaginator.totalPages);
    let i_a = pageSize * Math.max(0, this.testletsPaginator.currentPage-1);
    let i_b = Math.min(i_a + pageSize, this.testletsFiltered.length);
    const i_1 = Math.min(i_a, i_b);
    const i_2 = Math.max(i_a, i_b);

    this.testletsFilteredDisplay = this.testletsFiltered.slice(i_1, i_2);
  }
  initTestletFilter(){
    this.isTestletFiltersActive.valueChanges.subscribe(e => this.updateTestletFilter());
  }
  updateTestletFilter() {
    if(!this.frameworkCtrl.asmtFmrk || !this.frameworkCtrl.asmtFmrk.testlets) {
      return;
    }
    this.testletsFiltered = [];
    this.frameworkCtrl.asmtFmrk.testlets.forEach(testlet => {
      let isFilterViolated = false;
      if (this.isTestletFiltersActive.value) {
        Object.keys(this.testletFilters).forEach(key => {
          let val: string | number;
          let isString = false;
          switch (key) {
            case 'id': val = testlet.id; break;
            case 'quadrant': val = testlet.quadrant; break;
            case 'section': val = testlet.section; break;
            case 'similarity': val = testlet.similaritySlug; isString = true; break;
            case 'comment': val = testlet?.comments?.map(comment=> comment.caption).reduce((accumulator, currentValue) => accumulator +" "+currentValue); ; isString = true; break;
            default: /* stats */
            val = testlet.statsMap[key]; break;
          }
          let isValUnset = false;
          const areSet = (arr: Array<number | string>) => {
            let isAnyNotSet = false;
            arr.forEach(val => {
              if (!val && val !== 0) {
                isAnyNotSet = true;
              }
            });
            return !isAnyNotSet;
          };
          if (!areSet([val])) {
            isValUnset = true;
            val = 0;
          } else {
            if (!isString){
              val = parseFloat('' + val);
            }
          }
          const filterSetting = this.testletFilters[key];
          if (filterSetting.mode === FilterSettingMode.VALUE) {
            const config = <IFilterSettingConfigValue> filterSetting.config;
            if (areSet([config.value])) {
              // console.log('FilterSettingMode.VALUE', config.value, val, key)
              if(isString && !val.toString().includes(config.value)){
                isFilterViolated = true;
              }
              if (!isString && +config.value !== val) {
                isFilterViolated = true;
              }
            }
          } else if (filterSetting.mode === FilterSettingMode.RANGE) {
            const config = <IFilterSettingConfigRange> filterSetting.config;
            if (areSet([config.from, config.to])) {
              if ( (+val < 1 * config.from) || (+val > 1 * config.to) ) {
                isFilterViolated = true;
              }
            }
          }
        });
      }
      if (!isFilterViolated) {
        this.testletsFiltered.push(testlet);
      }
    });
    this.refreshTestletDisplayData();
  }
   // backwards compatibility
   ensureTestletQuestionIds(){
    if(!this.frameworkCtrl?.asmtFmrk?.testlets) {
      return;
    }
    this.frameworkCtrl.asmtFmrk.testlets.forEach(testlet => {
      testlet.questions.forEach(questionRef => {
        if (!questionRef.id){
          const question = this.itemBankCtrl.getQuestionByLabel(questionRef.label)
          if(question){
            questionRef.id = question.id;
          }
          else{
            console.warn('Lost track of item with the label of '+ questionRef.label);
          }
        }
      })
    });
  }

  checkActiveTestlet(testlet) {
    if (this.activeTestlet && this.activeTestlet.testlet === testlet) {
      return true;
    }
  }

  activateTestletQuestions(testlet) {
    const {questions, questionsMissing} = this.getTestletQuestions(testlet)
    this.activeTestlet = {
      testlet,
      questions, 
      questionsMissing,
    };
    this.itemBankCtrl.currentItemListPage = 1;
    this.itemFilterCtrl.updateItemFilter();
    this.frameworkCtrl.scrollToQuestionListing();
  }

  printSelectedTestlets() {
    this.printViewCtrl.printTitle = prompt('Print Title:', 'Testlet Export');
    this.printViewCtrl.printModeQuestions = [];
    this.testletsFiltered.forEach(testlet => {
      if (this.testletSelection.get(testlet)) {
        testlet.questions.forEach(questionInfo => {
          const question = this.itemBankCtrl.getQuestionByLabel(questionInfo.label);
          if (!question) {
            console.warn('No question under this label.');
          } else {
            this.printViewCtrl.printModeQuestions.push({
              props: [
                {caption: 'Testlet ID', val: testlet.id},
                {caption: 'Section', val: testlet.section},
                {caption: 'Quadrant', val: testlet.quadrant},
                {caption: 'Question Label', val: question.label},
              ],
              question,
            });
          }
        });
      }
    });
    // console.log('printTestletQuestions', this.printModeQuestions)
    this.printMode.isActive = true;
  }


  addTestletComment(testlet: any) {
    const comment = prompt('Comment on testlet ' + testlet.id);
    if (comment) {
      if (!testlet.comments) {
        testlet.comments = [];
      }
      testlet.comments.push({
        caption: comment,
        timestamp: 0,
        user: '',
      });
    }
  }

  exportTestletTable() {
    const rows = [];
    const header = [
      'id',
      'section_id',
      'quadrant_id',
      'similarity',
      'is_reviewed',
      'items',
      'comments'
      
    ];
    const statCols = [ ];
    this.gatherActiveTestletColumns();
    this.frameworkCtrl.asmtFmrk.testletStatCol.forEach(col => {
      if (col.isShown) {
        header.push(col.key);
        statCols.push(col.key);
      }
    });
    const formatItems = (questions, key) => {
      let itemLabels = [];
      questions?.forEach((question) => {
        itemLabels.push(`'${question[key]}'`);
      });
      return '"' + itemLabels.join('\n') + '"';
    };
    
    rows.push(header);
    this.testletsFiltered.forEach(testlet => {
      const row = [
        testlet.id, // 'testlet_id',
        testlet.section, // 'section_id',
        testlet.quadrant, // 'quadrant_id',
        testlet.similaritySlug,
        testlet.isReviewed? true : false, // 'is_reviewed',
        formatItems(testlet.questions, 'label'),
        formatItems(testlet.comments, 'caption')
      ];
      statCols.forEach(key => {
        let val: any = '';
        if (testlet.statsMap) {
          val = testlet.statsMap[key];
        }
        row.push(val);
      });
      rows.push(row);
    });
    const filename = `testlets-${this.itemBankCtrl.currentSetName.value}-${moment().format('YYYY-MM-DD[T]HH_mm_ss')}.csv`;
    downloadStr(rows.map(row => row.join(',')).join(NEWLINE), filename);
  }

}