
import { MIN_PASSWORD_LEN, MAX_PASSWORD_LEN, PassValConstraint } from '../../api/constants/password-validation';

interface IValidationResult {
  isValid: boolean,
  message: string
}

/**
 * Check the validity of an email address
 * @param email 
 * @returns {boolean} true if the email is valid
 */
export const isEmailValid = (email) => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return email !== undefined && email !== null && re.test(String(email).trim().toLowerCase())
}

/**
 * Check if url has an image file extension
 * Accepted image extension: jpg, jpeg, png, gif
 * @param url 
 * @returns {boolean} true - if file contains image extension
 */
export const isUrlImage = (url:string):boolean => {
  let allowedImgExt = new RegExp(/\.(jpg|jpeg|png|gif)/i)
  return allowedImgExt.test(url)
}

/**
 * Check inputted password against Password Policy
 * @param {string} inputPassword - User inputted password
 * @returns {IValidationResult} { isValid: boolean, message: string }
 */

export const isPasswordValid = (inputPassword:string): IValidationResult => {
  const SYMBOLS = '!@#$%^&*.';

  // Check password length
  if (inputPassword.length < MIN_PASSWORD_LEN) {
    return { isValid: false, message: PassValConstraint.PASS_VALC_MIN_CHARS };
  }

  if (inputPassword.length > MAX_PASSWORD_LEN) {
    return { isValid: false, message: PassValConstraint.PASS_VALC_MAX_CHARS };
  }

  // Check for uppercase letter
  if (!/[A-Z]/.test(inputPassword)) {
    return { isValid: false, message: PassValConstraint.PASS_VALC_UPPERCASE };
  }

  // Check for lowercase letter
  if (!/[a-z]/.test(inputPassword)) {
    return { isValid: false, message: PassValConstraint.PASS_VALC_LOWERCASE };
  }

  // Check for number
  if (!/\d/.test(inputPassword)) {
    return { isValid: false, message: PassValConstraint.PASS_VALC_NUMBER };
  }

  // Check for special symbol
  const symbolPattern = new RegExp(`[${SYMBOLS}]`);
  if (!symbolPattern.test(inputPassword)) {
    return { isValid: false, message: PassValConstraint.PASS_VALC_SYMBOL };
  }

  // If all checks pass
  return { isValid: true, message: 'VALID_PASSWORD' };
};
