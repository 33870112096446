import { element } from "angular";
import { IContentElementDnd, IElementPos } from "src/app/ui-testrunner/element-render-dnd/model";
import { ElementRenderGroupingComponent } from "src/app/ui-testrunner/element-render-grouping/element-render-grouping.component";
import { IContentElementGroup } from "src/app/ui-testrunner/element-render-grouping/model";
import { ElementRenderInsertionComponent } from "src/app/ui-testrunner/element-render-insertion/element-render-insertion.component";
import { IContentElementInsertion } from "src/app/ui-testrunner/element-render-insertion/model";
import { ElementRenderMoveableDndComponent } from "src/app/ui-testrunner/element-render-moveable-dnd/element-render-moveable-dnd.component";
import { IContentElementMoveableDragDrop } from "src/app/ui-testrunner/element-render-moveable-dnd/model";
import { ElementRenderOrderComponent } from "src/app/ui-testrunner/element-render-order/element-render-order.component";
import { IContentElementOrder, OrderMode } from "src/app/ui-testrunner/element-render-order/model";
import { ElementType, IContentElement } from "src/app/ui-testrunner/models";
//! If updating this please make sure updates are done in API @src/services/public/student/session-question-audit/matrix-validation-helpers/generate-combinations.ts

type Combination = (string[] | null)[];
const MAX_NUMBER_ELEMENTS = 10;

export const generatePossibleElementCombinations = (element:any) : any[] => {
    let possibleCombinations: any[] = [];
    switch (element.elementType) {
        case ElementType.GROUPING:
          possibleCombinations = generatePossibleElementCombinationsGrouping(element);
          break;
        case ElementType.MOVEABLE_DND:
          possibleCombinations = generatePossibleElementCombinationsDND(element);
          break;
        case ElementType.INSERTION:
          possibleCombinations = generatePossibleElementCombinationsInsertion(element);
          break;
        case ElementType.ORDER:
          possibleCombinations = generatePossibleElementCombinationsOrder(element);
          break;
        default:
          console.error("Unable to generate combinations for element type " + element.elementType);
      }
    return possibleCombinations;
}

const generatePossibleElementCombinationsDND = (element:IContentElementMoveableDragDrop) =>{
    const options = element.scoreMatrix.rows;
    const isOptionsReusable = element.isOptionsReusable;
    const slots = element.targets.length;
    const component = new ElementRenderMoveableDndComponent(null, null, null, null);
    const getState = component.updateState.bind(component);
    const formatted_combinations: any[] = [];
    const initTargert = options.map(draggable => ({ 
        contents: [{
            isTarget: false,
            ref: draggable.option
        }], 
    originalPosition: true, 
    targetContext: draggable }));
    const combinations: any[][] = generatePermutationsInsertion(slots, options, true, isOptionsReusable); 
    for(let combination of combinations){
        const elementCopy = JSON.parse(JSON.stringify(element));
        const targets = JSON.parse(JSON.stringify(initTargert));
        for(let target of element.targets){
            let ref = combination.pop();
            if(ref.length === 0){
                targets.push({
                    contents:[],
                    originalPosition: false,
                    targetContext: target
                })
            } else {
                targets.push({ 
                    contents: [{
                        isTarget: false,
                        ref: ref.option
                    }], 
                    originalPosition: false, 
                    targetContext: target });
            }

        }
        const homeTargetContents = new Map();
        for (let draggable_idx = 0; draggable_idx < initTargert.length; draggable_idx++) {
            if(targets[draggable_idx].contents.length === 0 ){continue;}
            for(let target_idx = initTargert.length; target_idx < targets.length; target_idx++){
                if(JSON.stringify(targets[draggable_idx].contents)===JSON.stringify(targets[target_idx].contents)){
                    targets[draggable_idx].contents = []
                }
            }
            homeTargetContents.set(targets[draggable_idx].contents, true);
            
        }
        component.element = elementCopy;
        component.homeTargetContents = homeTargetContents;
        component.targets = targets;
        component.questionState = new Map();
        formatted_combinations.push({1: getState()});

    }
    return formatted_combinations

}

const generatePossibleElementCombinationsGrouping = (element: IContentElementGroup) => {
    const options = element.scoreMatrix.rows.map((row: any) => ({ ref: row.option }));
    const isOptionsReusable = element.isOptionsReusable;
    // If max group size is set by element use it if it's less than the default max else use max
    const max_group_size = element.isGroupSizeLimited ? (element.groupSize < MAX_NUMBER_ELEMENTS? element.groupSize: MAX_NUMBER_ELEMENTS) : MAX_NUMBER_ELEMENTS
    const slots = element.targets.length;
    const combinations = generateCombinations(slots, options, isOptionsReusable, max_group_size);
    const formatted_combinations: any[] = [];
    const component = new ElementRenderGroupingComponent(null, null)
    const getState = component.updateState.bind(component);

    for(let combination of combinations){
        const elementCopy = JSON.parse(JSON.stringify(element));
        const targets = []
        for( let target of elementCopy.targets){
            targets.push(
                {
                    targetContext: target,
                    contents: combination.pop()
                }
            )
        }
        component.element = elementCopy;
        component.targets = targets;
        component.draggables = <IElementPos[]> options;
        component.questionState = new Map();

        formatted_combinations.push({1:getState()});
    }
    // for(let combination of combinations){
    //       formatted_combinations.push({1:updateStateOrder(element,combination)})
    // }
    return formatted_combinations;
  }
  
const generatePossibleElementCombinationsOrder = (element: IContentElementOrder) => {
    const orderModeTarget = element.orderMode === OrderMode.TARGET;
    const options = element.scoreMatrix.rows.map((row:any)=> [row.option]);
    const staticAnswers:Set<number> = new Set();
    const component =  new ElementRenderOrderComponent(null, null, null, null);
    const getState = component.updateState.bind(component);
    for (let [index, option] of element.options.entries()){
    if(orderModeTarget && option.isReadOnly){
        options.splice(index, 0,[option]);
        staticAnswers.add(index);
    }

    }
    const combinations = generatePermutationsOrder(options, staticAnswers, orderModeTarget);
    const formatted_combinations = [];
    for(let combination of combinations){
        component.element = element;
        component.options = element.options;
        component.questionState = new Map();
        component.getAnswers = () => {return combination}
        formatted_combinations.push({1:getState()});
    }
    return formatted_combinations;
}

const generatePossibleElementCombinationsInsertion = (element: IContentElementInsertion) => {
const options = element.scoreMatrix.rows.map((row:any)=> row.option);
const isRepeatableOptions = element?.isRepeatableOptions;
const slots = element.textBlocks.filter((textblock:any)=> textblock.element.elementType === ElementType.BLANK || textblock.element.elementType === ElementType.BLANK_DEPRECIATED).length
const combinations: any[][] = generatePermutationsInsertion(slots, options, true, isRepeatableOptions); 
const formatted_combinations: any[] = [];
const blocks: any[][] = [];
const component = new ElementRenderInsertionComponent(null, null, null, null);
const getState = component.refreshState.bind(component);
for(let combination of combinations){
    const block: any[] = [];
    for (let [index, textblock] of element.textBlocks.entries()){
    if(textblock.element.elementType === ElementType.BLANK || textblock.element.elementType === ElementType.BLANK_DEPRECIATED){
        let ctx = combination.pop(); // Pop the last element from the combination array
        const contents = Array.isArray(ctx) ? null : [{ref:ctx}]; // so that blanks ge treated properly
        block.push({ isTarget: true, ctx:textblock, contents});
    } else {
        block.push({isTarget:false, ctx: {element:textblock.element}});
    }
    }
    blocks.push(block)
}
for (let block of blocks){
    component.questionState = new Map();
    component.element = element;
    component.draggables = [];
    component.blocks = block;
    formatted_combinations.push({1:getState()});
}
return formatted_combinations;
}

function generatePermutationsInsertion(
length: number,
options: any[],
allowEmpty: boolean = false,
allowReuse: boolean = false
): any[][] {
const results: any[][] = [];

// Helper function to create permutations
function permute(currentArray: any[]) {
    if (currentArray.length === length) {
    results.push(currentArray.slice());
    return;
    }

    // Iterating through each option and adding it to the current permutation
    for (const option of options) {
    // Check if the current option can be reused
    if (allowReuse || !currentArray.includes(option)) {
        permute([...currentArray, option]);
    }
    }

    // If allowing empty values, add an empty value and continue
    if (allowEmpty && currentArray.length < length) {
    permute([...currentArray, []]); // Using 'null' to represent empty values
    }
}

// Initialize the permutation generation
permute([]);

return results;
}
function generatePermutationsOrder(array: any[], reservedIndices: Set<number>, allowEmpty = false, areOptionsReusable = false) {
const results: any[] = [];
const blank: any[] = [];  // Using an empty array as the placeholder for blank

function swap(arr: { [x: string]: any; }, i: string | number, j: string | number) {
    const temp = arr[i];
    arr[i] = arr[j];
    arr[j] = temp;
}

function permute(arr: any[], start: number) {
    if (start >= arr.length) {
    results.push(arr.slice());  // Clone and store the current permutation
    return;
    }

    if (reservedIndices.has(start)) {
    permute(arr, start + 1);  // Skip permutation for reserved indices
    } else {
    const seen = new Set();  // Track elements that have been used at this position

    for (let i = start; i < arr.length; i++) {
        if (!reservedIndices.has(i) && (!seen.has(arr[i]) || areOptionsReusable)) {
        seen.add(arr[i]);
        swap(arr, start, i);
        permute(arr, start + 1);
        swap(arr, start, i);  // Backtrack
        }
    }
    if (allowEmpty) {
        const original = arr[start];
        if (!seen.has(blank) || areOptionsReusable) {  // Add blank if it's not considered as seen or if reusable
        arr[start] = blank.slice();  // Set current index as blank and permute
        permute(arr, start + 1);
        arr[start] = original;  // Restore original element after the recursive call
        }
    }
    }
}

permute(array, 0);
return results;
}
function generateCombinations(
    numSlots: number,
    options: any[],
    areOptionsReusable: boolean,
    maxItemsPerSlot: number
): Combination[] {
    const results: Combination[] = [];
    
    // Helper function to generate all possible subsets of options up to maxItemsPerSlot
    function generateSubsets(availableOptions: string[]): string[][] {
        const subsets: string[][] = [];
        const totalOptions = availableOptions.length;
        // Calculate 2^totalOptions combinations, representing each possible subset
        const maxCombinations = 1 << totalOptions;

        for (let i = 0; i < maxCombinations; i++) {
            let subset: string[] = [];
            for (let j = 0; j < totalOptions; j++) {
                if (i & (1 << j)) {
                    subset.push(availableOptions[j]);
                    if (subset.length > maxItemsPerSlot) break; // Stop if the subset exceeds the max items per slot
                }
            }
            if (subset.length <= maxItemsPerSlot) {
                subsets.push(subset);
            }
        }
        return subsets;
    }

    // Recursive function to generate all combinations
    function permute(currentCombination: Combination, availableOptions: string[]) {
        if (currentCombination.length === numSlots) {
            results.push(currentCombination.slice());
            return;
        }

        const subsets = generateSubsets(availableOptions);

        // Iterate over each subset and add it to the current combination
        subsets.forEach(subset => {
            // If options are reusable, we pass the same available options array
            // Otherwise, we remove the used options from the available pool for the next calls
            const nextAvailableOptions = areOptionsReusable ? availableOptions : availableOptions.filter(opt => !subset.includes(opt));

            permute([...currentCombination, subset], nextAvailableOptions);
        });

        // Consider the slot as empty if allowed (empty array represents an empty slot)
        permute([...currentCombination, []], availableOptions);
    }

    // Initialize the recursive function
    permute([], options);

    return results;
}
  