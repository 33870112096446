import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from '../api/auth.service';
import { BehaviorSubject, Subject, fromEvent, Observable, Subscription } from 'rxjs';
import Sockette from 'sockette';
import { WhitelabelService } from '../domain/whitelabel.service';
import { RoutesService } from '../api/routes.service';

interface selectedItemMap {
  [key: number]: string[]
}

interface authorNameMap {
  [key: number]: string
}

@Injectable({
  providedIn: 'root'
})

export class WidgetAuthoringConnectionService implements OnInit, OnDestroy {
  private ws: Sockette;
  public userConnectionId: string // connection id to communicate through websocket of online user

  private wsURL: string;
  private apiHost: string;

  public socketConnecting: boolean = true;
  private socketReady: boolean = false;
  private communicationTimeout;

  public networkOfflineSub: Subscription = null;
  private offlineEvent: Observable<Event>;

  private pingIntervalTime = 2000;
  private pingInterval;

  public prevSelectedItemId: number;
  public selectedItems: selectedItemMap = {};
  public authorNames: authorNameMap = {};

  public updatedItemSub = new BehaviorSubject<{}>({});  
  public userTimeoutSub = new Subject<any>();
  public websocketDisconnectedSub = new Subject<boolean>();

  constructor (
    private auth: AuthService,
    private whitelabel: WhitelabelService,
    private routes: RoutesService
  ) {
    this.apiHost = this.whitelabel.getApiAddress();
    window['closeWSPermanent'] = () => {
      this.cleanupOnDestroy();
    }
  }

  /**
   * @important - ngOnInit is NOT implemented for services and therefore is not called.
   * If you want to init something, use the constructor.
   */
  ngOnInit(): void {
    this.offlineEvent = fromEvent(window, 'offline');
    this.networkOfflineSub = this.offlineEvent.subscribe(e => {
      if (this.auth.getUid() && this.userConnectionId) {
        this.logOffUser();
      }
    })
  }

  ngOnDestroy(): void {
    this.disconnect();
  }

  resetCommunicationTimeout(timeoutDuration: number = 5000) {
    if (this.communicationTimeout) {
      clearTimeout(this.communicationTimeout);
    }

    // communication is considered lost if we do not receive a message from WS server in 5 seconds
    this.communicationTimeout = setTimeout(this.didLoseCommunication, timeoutDuration);
  }

  didLoseCommunication = () => {
    clearInterval(this.pingInterval);

    // make sure the connection is properly closed before we try connecting again
    if (this.ws) {
      console.log(" ---- lost communication, restarting connection ---- ");
      this.disconnect();
    } else {
      console.log(" ---- attemping to reconnect ---- ");
      this.connect();
    }

    this.resetCommunicationTimeout(1000);
  }
  
  reconnectInactiveUser = () => {
    if (this.prevSelectedItemId) {
      this.prevSelectedItemId = null;
      this.userConnectionId = null;
    }

    this.userTimeoutSub.next();
    this.reconnectUser();
    this.socketReady = false;
  }

  async connect() {
    this.socketConnecting = true;
    const { websocketUrl } = await this.auth.apiFind(this.routes.TEST_AUTH_INIT_WEBSOCKETS)
    this.wsURL = 'wss://' + websocketUrl;
    this.ws = new Sockette(this.wsURL, {
      timeout: 10e3,
      maxAttempts: 60,
      onopen: async (ev:Event) => {
        console.log(" ---- connection established ---- ");
        this.socketReady = true;
        this.connectUser();
      },
      onmessage: (ev: MessageEvent) => { 
        this.socketReady = true;
        this.resetCommunicationTimeout();
        this.onUserMessage(ev);
      },
      onmaximum: (ev: CloseEvent) => {
        console.debug('websocket max connection attempts reached, giving up', ev);
        this.socketReady = false;
      },
      onreconnect: (ev: Event | CloseEvent) => {
        this.socketReady = false;
        console.debug('websocket trying to reconnect...', ev);
      },
      onclose: (ev: CloseEvent) => {
        console.debug('websocket closed!', ev);
        this.disconnect();
        this.socketReady = false;
      },
      onerror: (ev: Event) => {
        console.debug('websocket error:', ev)
        this.socketReady = false;
      }
    });
    this.pingInterval = setInterval(() => {
      this.wsSend('ping');
    }, this.pingIntervalTime);
  }

  cleanupOnDestroy() {
    this.disconnect();
    this.prevSelectedItemId = undefined;
    clearTimeout(this.communicationTimeout);
  }

  disconnect() {
    if (this.ws) {
      this.logOffUser();
      this.ws.close();
      this.ws = undefined;
    }
    this.socketConnecting = false;
    this.socketReady = false;
    this.selectedItems = {};
    this.authorNames = {};
    this.userConnectionId = null;
    this.websocketDisconnectedSub.next(true);
  }

  private wsSend(action: string, dataObject = {}, apiHost = this.apiHost) {
    if (this.socketReady) {
      this.ws.json({
        action,
        data: {
          ...dataObject,
          apiHost
        },
      })
    }
  }

  private reconnectUser() {
    this.wsSend('reconnect', {
      firstName: this.auth.getFirstName(),
      lastName: this.auth.getLastName()
    });
  }

  private connectUser() {
    this.wsSend('connect', {
      firstName: this.auth.getFirstName(),
      lastName: this.auth.getLastName()
    });
  }

  private logOffUser() {
    this.wsSend('disconnect');
  }

  private deselectPrevItem() {
    if (this.selectedItems[this.prevSelectedItemId] === undefined) return;

    const newSelectedItems = this.selectedItems[this.prevSelectedItemId].filter(userConnectionId => {
      return userConnectionId && userConnectionId !== this.userConnectionId;
    });

    this.selectedItems[this.prevSelectedItemId] = newSelectedItems;
  }

  private selectNewItem(itemId: number) {
    if (this.selectedItems[itemId] !== undefined) {
      this.selectedItems[itemId].push(this.userConnectionId);
    } else {
      this.selectedItems[itemId] = [this.userConnectionId];
    }
  }

  selectItem(itemId: number) {
    if (this.userConnectionId) {
      this.deselectPrevItem();
      this.selectNewItem(itemId);
  
      this.wsSend('selectItem', {
        selectedItems: this.selectedItems[itemId],
        prevSelectedItems: this.selectedItems[this.prevSelectedItemId],
        itemId: itemId,
        prevItemId: this.prevSelectedItemId
      });
    }

    this.prevSelectedItemId = itemId;
  }

  updateItem(itemId) {    
    this.wsSend('updateItem', {
      itemId: itemId
    });
  }

  private onUserMessage(e) {
    if (!this.socketReady) return;

    let eObj;

    try {
      eObj = JSON.parse(e.data);
    } catch(e) {
      // Don't set eObj
    }

    if(!eObj) {
      return;
    }

    switch (eObj.action) {
      case "connect": case "disconnect": {
        const newAuthorNames = {};
        const newSelectedItems = {};
    
        eObj.authors.map(user => {
          newAuthorNames[user.connectionId] = user.firstName + " " + user.lastName;
        });

        eObj.selectedItems.map(item => {
          newSelectedItems[item.itemId] = item.selectedItems;
        });

        this.authorNames = newAuthorNames;
        this.selectedItems = newSelectedItems;
        break;
      }

      case "setConnectionId": {
        this.userConnectionId = eObj.connectionId;     
        this.websocketDisconnectedSub.next(false);  
        this.socketConnecting = false; 
        if (this.prevSelectedItemId) {
          this.selectItem(this.prevSelectedItemId);
        }
        break;
      }

      // just used to reset communicationTimeout
      case "pong": {
        break;
      }

      case "selectItem": {
        const newSelectedItems = {};

        eObj.selectedItems.map(item => {
          newSelectedItems[item.itemId] = item.selectedItems;
        });

        this.selectedItems = newSelectedItems;
        break;
      }

      case "updateItem": {
        this.updatedItemSub.next({
          "itemId": eObj['itemId'], 
          "connectionId": eObj['connectionId']
        });
      }
    }
  }
}