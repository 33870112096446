<div class="report-version-1" [class.report-version-1-non-page1]="i!=0" [ngClass]="{'report-details-fr-alt' : isLinearFailReport(report) && lang.c() === 'fr', 'firefox': isFireFox()}">
    <!-- Header -->
    <div class="image-container">
        <img  [src]="lang.tra(whitelabel.getSiteText('sa_report_logo_link_2022'))" />
    </div>
    <div class="header-container">
        <h2 class="report-header report-header-1">
            <tra style="font: 700 18pt Arial;" slug="lbl_osslt_reports_2_title_1"></tra>
        </h2>
        <h2 class="report-header report-header-2">
            <tra style="font: 700 18pt Arial;" slug="lbl_osslt_reports_2_title_2"></tra>
        </h2>
        <div style="font: 400 1.2em Arial; margin-top: 0.25em;" >{{getReportYearVersion1(report)}}</div>
    </div>
    <!-- Student Name, OEN, School Info -->
    <div class="report-details" [class.report-details-en] = "lang.c() === 'en'" [class.report-details-fr] = "lang.c() === 'fr'">
        <div class="student-full-name">
            {{report.FirstName}} {{report.LastName}}
        </div>
        <div>
            <table>
                <tr>
                    <th><tra slug="student_report_oen_2"></tra></th>
                    <td>{{maskOENWithFiveStars(report.StudentOEN)}}</td>
                </tr>
                <tr>
                    <th><tra slug="student_report_school_2"></tra></th>
                    <td>{{report.SchName}} ({{report.SchMident}})</td>
                </tr>
                <tr>
                    <th><tra slug="student_report_school_board_2"></tra></th>
                    <td>{{report.school_board_name}}</td>
                </tr>
            </table>
        </div>
    </div>
    <!-- Student Result -->
    <div class="report-student-result">
        <!-- RESULTS -->
        <div class="result-title" [class.result-title-npo]="report.Result > 2">
            <tra style="font: 400 1.2em Arial;" slug="student_report_result_osslt"></tra>
        </div>

        <ng-container [ngSwitch]="report.Result">
            <!-- successful -->
            <div *ngSwitchCase="1" >
                <p *ngIf="!showReportNumQ(report)" style = "font-weight: 700; padding-bottom: 2em;">{{lang.tra("sa_report_state_1_successful")}}</p>
                <div *ngIf="showReportNumQ(report)">
                    <tra style = "margin-bottom: 0.25em; font-weight: 700; padding-bottom: 2em;" slug="sa_report_state_1_successful"></tra>
                    <table class="report-unsuccessful-details" [class.report-unsuccessful-details-en] = "lang.c() === 'en'" [class.report-unsuccessful-details-fr] = "lang.c() === 'fr'" [ngClass]="{'report-unsuccessful-details-fr-alt' : isLinearFailReport(report) && lang.c() === 'fr'}">
                        <tr>
                            <th style = "font-weight: 700;">{{lang.tra("sa_report_unsuccessful_score")}}</th>
                            <td style = "font-weight: 700;">{{getScaleScore(report)}}</td>
                        </tr>
                        <tr>
                            <th style = "font-weight: 700;">{{lang.tra("sa_report_unsuccessful_attempts")}}</th>
                            <td style = "font-weight: 700;">{{report.questions_answered}}{{lang.tra("sa_report_unsuccessful_attempts_of")}}&nbsp;{{report.total_questions}}</td>
                        </tr>
                    </table>
                </div>
            </div>
            <!-- not yet successful -->
            <div *ngSwitchCase="2">
                <tra style = "margin-bottom: 0.25em;" slug="sa_report_state_2_unsuccess"></tra>
                <table class="report-unsuccessful-details" [class.report-unsuccessful-details-en] = "lang.c() === 'en'" [class.report-unsuccessful-details-fr] = "lang.c() === 'fr'" [ngClass]="{'report-unsuccessful-details-fr-alt' : isLinearFailReport(report) && lang.c() === 'fr'}">
                    <tr>
                        <th style = "font-weight: 700;">{{lang.tra("sa_report_unsuccessful_score")}}</th>
                        <td style = "font-weight: 700;">{{getScaleScore(report)}}</td>
                    </tr>
                    <tr *ngIf="showReportNumQ(report)">
                        <th style = "font-weight: 700;">{{lang.tra("sa_report_unsuccessful_attempts")}}</th>
                        <td style = "font-weight: 700;">{{report.questions_answered}}{{lang.tra("sa_report_unsuccessful_attempts_of")}}&nbsp;{{report.total_questions}}</td>
                    </tr>
                </table>
                
            </div>
            <!-- All other outcome 3 - 11 -->
            <div *ngSwitchDefault>
                <p class="result-detail-other">
                    <tra style = "margin-bottom: 0.25em;" slug="{{getNonFullyParticipatingOutcomeSlug(report.Result)}}"></tra>
                </p>

                <table class="report-unsuccessful-details" [class.report-unsuccessful-details-en-v2] = "lang.c() === 'en'" [class.report-unsuccessful-details-fr] = "lang.c() === 'fr'" [ngClass]="{'report-unsuccessful-details-fr-alt' : isLinearFailReport(report) && lang.c() === 'fr'}">
                    <tr>
                        <th style = "font-weight: 700;">{{lang.tra("sa_report_unsuccessful_attempts")}}</th>
                        <td *ngIf="report.Result != 11" style = "font-weight: 700;">{{lang.tra("lbl_not_applicable")}}</td>
                        <td *ngIf="report.Result == 11" style = "font-weight: 700;">{{report.questions_answered}}{{lang.tra("sa_report_unsuccessful_attempts_of")}}&nbsp;{{report.total_questions}}</td>
                    </tr>
                </table>
            </div>
        </ng-container>

    </div>
    <div *ngIf = "showALtAsScaleScore(report)" style = "font: 400 8pt Arial; margin-top: 0.5rem;">{{lang.tra("sa_student_report_result_osslt_footer")}}</div>
    <!-- Result Explaination -->
    <div class="result-explaination" 
        [class.result-explaination-1-fr] = "report.Result == '2' && lang.c()=='fr'"
        [class.result-explaination-1-en] = "report.Result == '2' && lang.c()=='en'"    
        [class.result-explaination-2] = "report.Result != '2'" 
        [ngSwitch]="report.Result">
        <div  *ngSwitchCase="1">
            <div class="result-explaination-caption">
                <tra-md style ='font: 400 1.2em Arial; margin-bottom: 0em;' slug="student_report_result_explaination_caption"></tra-md>
            </div>
            <div class="result-explaination-caption">
                <tra-md style="font: 400 0.9em Arial; margin-bottom: 0em; margin-top: 0em; word-spacing: 0.05em; line-height: 1.2em;" slug="student_report_result_explaination_subcaption"></tra-md>
            </div>
            <div class="result-1-explaination-body">
                <!-- Reading -->
                <div class="reading-container">
                    <div class="container-header"><tra style="font: 700 14px/20px Arial;" slug="student_report_result_content_title_reading"></tra></div>
                    <div class="container-body"><tra-md style="font: 400 9.3pt Arial; line-height: 1.3em;" slug="student_report_result_content_reading"></tra-md></div>
                </div>
                <!-- Writing -->
                <div class="writing-container">
                    <div class="container-header"><tra style="font: 700 14px/20px Arial;" slug="student_report_result_content_title_writing"></tra></div>
                    <div class="container-body"><tra-md style="font: 400 9.3pt Arial; line-height: 1.3em;" slug="student_report_result_content_writing"></tra-md></div>
                </div>
            </div>
        </div>
        <div  *ngSwitchCase="2">
            <div class="result-explaination-caption">
                <tra-md style ='font: 400 1.2em Arial; margin-bottom: 0em; margin-top: 0.5em;' slug="student_report_result_explaination_caption_unsuccessful_v2"></tra-md>
            </div>
            <div class="result-explaination-caption">
                <tra-md *ngIf = "!isLinearFailReport(report)" style="font: 400 0.9em Arial; margin-bottom: 0em; margin-top: 0em; word-spacing: 0.05em; line-height: 1.2em;" slug="student_report_result_explaination_subcaption_unsuccessful"></tra-md>
                <tra-md *ngIf = "isLinearFailReport(report)" style="font: 400 0.9em Arial; margin-bottom: 0em; margin-top: 0em; word-spacing: 0.05em; line-height: 1.2em;" slug="student_report_result_explaination_subcaption_unsuccessful_linear"></tra-md>
            </div>
            <div class="result-2-explaination-body">
                <!-- Reading -->
                <div class="reading-container">
                    <div><tra style="font: 700 14px/20px Arial;" slug="student_report_result_content_title_reading"></tra></div>
                    <div class="container-body"><tra-md style="font: 400 9.3pt Arial; line-height: 1.3em;" slug="student_report_result_content_reading_unsuccessful_v2"></tra-md></div>
                </div>
                <!-- Writing -->
                <div class="writing-container">
                    <div><tra style="font: 700 14px/20px Arial;" slug="student_report_result_content_title_writing"></tra></div>
                    <div class="container-body"><tra-md style="font: 400 9.3pt Arial; line-height: 1.3em;" slug="student_report_result_content_writing_unsuccessful_v2"></tra-md></div>
                </div>
            </div>
        </div>
        <div *ngSwitchDefault>
            <div class="result-explaination-caption">
                <tra-md style ='font: 400 1.2em Arial; margin-bottom: 0em; margin-top: 0.5em;' slug="student_report_result_explaination_caption_npo"></tra-md>
            </div>
            <div class="result-explaination-caption">
                <tra-md style="font: 400 0.9em Arial; margin-bottom: 0em; margin-top: 0em; word-spacing: 0.05em; line-height: 1.2em;" slug="student_report_result_explaination_subcaption_npo"></tra-md>
            </div>
        </div>
    </div>
    <!-- Report Footer -->
    <div class="report-footer">
        <tra-md style = "font: 400 8pt Arial;" slug="student_report_result_footer"></tra-md>
        <tra-md style = "font: 400 8pt Arial;" slug="txt_privacy"></tra-md>
    </div>
</div>