import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { WhitelabelService } from './domain/whitelabel.service';
import { Router, NavigationError, ActivatedRoute, NavigationStart, NavigationEnd } from '@angular/router';
import { IConfirmationReq, IConfirmationReqBtnConfig, LoginGuardService } from './api/login-guard.service';
import { LangService } from './core/lang.service';
import { Subscription } from 'rxjs';
import { SidepanelService } from './core/sidepanel.service';
import { ChatpanelService } from './core/chatpanel.service';
import { DataGuardService } from './core/data-guard.service';
import { ImageLoadService } from './ui-testrunner/image-load.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnDestroy {
  subscription = new Subscription();
  isEducator:boolean = false;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public loginGuard: LoginGuardService,
    public lang: LangService,
    private sidePanel: SidepanelService,
    private chatPanel: ChatpanelService,
    public whiteLabel: WhitelabelService,
    private dataGuard: DataGuardService,
    private meta: Meta,
    public imageLoad: ImageLoadService
  ) {
    this.setupRouterDefault();
    this.setupConnectionOverlaySubs();
    this.meta.addTag({name: 'robots', content: 'noindex'});
  }
  ngOnInit(){
    // this.router.events.pipe(
    //   filter(event => event instanceof NavigationEnd)
    // )
  }
  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  ngAfterViewInit() {
    this.loginGuard.checkFontErrorsInterval();
  }

  title = 'app';
  isShowingDevNetFailOverlay: boolean;
  isShowingApiNetFailOverlay: boolean;
  confirmationProceedCaptions: string[];
  confirmationCancelCaptions: string[];
  currentConfirmation: IConfirmationReq;//  | {caption: string, confirm: () => any, hideCancel?: boolean };
  isShowingInfoOverlay: boolean;
  confirmationInput: string;
  confirmationCheckbox: boolean;
  modalChainCount: number = 0;
  categorySelection:string;
  extraTextInput:string;

  setupConnectionOverlaySubs() {
    this.subscription.add(this.loginGuard.getDevNetFail().subscribe(v => this.isShowingDevNetFailOverlay = v ));
    this.subscription.add(this.loginGuard.getApiNetFail().subscribe(v => this.isShowingApiNetFailOverlay = v ));
    this.subscription.add(this.loginGuard.getInfoOverlay().subscribe(v => this.isShowingInfoOverlay = v));
    this.subscription.add(
      this.loginGuard.getConfirmationReq().subscribe(v => {
        this.currentConfirmation = <any> v;
        this.categorySelection = undefined;
        this.extraTextInput = undefined;
        if (v) {
          this.confirmationProceedCaptions = v.btnProceedConfig?.captions || ['btn_ok'];
          this.confirmationCancelCaptions = v.btnCancelConfig?.captions || ['btn_cancel'];
          
          if (this.router.url.indexOf('/osslt-tool-exploration') > -1 || this.router.url.indexOf('/tools') > -1) {
            this.currentConfirmation.caption = 'msg_finish_tool_exploration';
            this.confirmationCancelCaptions = ['osslt_tools_message'];
          } 
        }
      })
    );
  }
  isAuthActive() {
    return this.loginGuard.isAuthActive();
  }
  closeModal() {
    this.currentConfirmation.close();
    this.loginGuard.confirmationReqDeactivate();
    this.confirmationInput = null;
    this.confirmationCheckbox = false;
  }
  blockConfirm() {
    if (!this.currentConfirmation || (!this.currentConfirmation.requireTextInput && !this.currentConfirmation.requireCheckboxInput)) {
      return false;
    }
    if (this.currentConfirmation.requireTextInput && !this.confirmationInput) {
      return true;
    }
    if (this.currentConfirmation.requireCheckboxInput && !this.confirmationCheckbox) {
      return true;
    }
    return false;
  }
  confirmModal() {
    if(this.currentConfirmation.requireCheckboxInput) {
      if(!this.confirmationCheckbox) {
        alert(this.lang.tra('alert_submit_student_assessment_checkbox'));
        return;
      }
      this.confirmationCheckbox = false;
    }
    if (this.currentConfirmation.requireTextInput) {
      if (this.confirmationInput) {
        if (this.currentConfirmation.requireTextInput.indexOf(this.confirmationInput.toLowerCase()) > -1) {
          if (!this.currentConfirmation.catagoriesList){
            this.currentConfirmation.confirm();
            this.loginGuard.confirmationReqDeactivate();
          } else if (this.currentConfirmation.catagoriesList && this.currentConfirmation.catagoriesList.indexOf(this.categorySelection) > -1) {
            this.currentConfirmation.confirm();
            this.loginGuard.confirmationReqDeactivate();
           }else {
            alert(this.lang.tra('alert_submit_student_assessment2_empty2'));
           } 
        } else {
          alert(this.lang.tra('alert_submit_student_assessment2_wrong'));
        }
        this.confirmationInput = null;
      } else {
        alert(this.lang.tra('alert_submit_student_assessment2_empty'));
      }
    } else {
      this.currentConfirmation.confirm();
      if (!this.currentConfirmation.isModalChain || this.modalChainCount >= this.currentConfirmation.modalChainLimit) {
        this.modalChainCount = 0;
        this.loginGuard.confirmationReqDeactivate();
      } else {
        this.modalChainCount++;
      }
    }
  }
  getCurrentConfirmationCaption() {
    if (this.currentConfirmation) {
      return this.lang.tra(this.currentConfirmation.caption);
    }
  }
  getCurrentConfirmationSubCaption() {
    if (this.currentConfirmation) {
      return this.lang.tra(this.currentConfirmation.subCaption);
    }
  }
  showCurrentConfirmationSubCaption() {
    if (this.currentConfirmation) {
      return this.currentConfirmation.subCaption;
    }
  }
  haveDescription(){
    if (this.currentConfirmation && this.currentConfirmation.description) {
      return true;
    }
    return false;
  }
  getCurrentConfirmationDescription(){
    if (this.currentConfirmation) {
      return this.lang.tra(this.currentConfirmation.description);
    }
  }
  showDropDownCategories(){
    if (this.currentConfirmation && this.currentConfirmation.catagoriesList && this.currentConfirmation.catagoriesList.length> 0) {
      return true;
    }
    return false;
  }
  getCurrentConfirmationCategoriesLabel(){
    if (this.currentConfirmation) {
      return this.lang.tra(this.currentConfirmation.categoriesLabel);
    }
  }
  categorySelectionChange(newValue){
    if (this.currentConfirmation) {
      this.currentConfirmation.selectedCategory = newValue
      this.currentConfirmation.categorySelectionChange(newValue)
    }
  }
  getCatagoryText(catagory){
    return this.lang.tra(catagory);
  }
  showExtraText(){
    if (this.currentConfirmation && this.currentConfirmation.showExtraText) {
      return true;
    }
    return false;
  }

  extraTextAreaChange(newValue){
    if (this.currentConfirmation) {
      this.currentConfirmation.extraTextInput = this.extraTextInput
    }  
  }
  showCurrentConfirmationInputBox() {
    if (this.currentConfirmation) {
      return this.currentConfirmation.requireTextInput;
    }
  }
  showCurrentConfirmationCheckBox() {
    if (this.currentConfirmation) {
      return this.currentConfirmation.requireCheckboxInput;
    }
  }
  getCurrentConfirmationCheckBox() {
    if (this.currentConfirmation) {
      return this.currentConfirmation.requireCheckboxInput.checkboxCaption;
    }
  }
  hideCancel() {
    if (this.currentConfirmation) {
      return this.currentConfirmation.btnCancelConfig?.hide;
    }
  }
  hideConfirm() {
    if(this.currentConfirmation) {
      return this.currentConfirmation.btnProceedConfig?.hide;
    }
  }

  setCustomWidth(){
    const defaultWidth = '30em';
    if(this.currentConfirmation && this.currentConfirmation.width){
      return this.currentConfirmation.width
    }
    return defaultWidth;
  }

  dismissSupportPopup() {
    this.loginGuard.supportReqDeactivate();
  }

  gotoHomeScreen() {
    this.router.navigate([
      this.whiteLabel.getHomepageRoute()
    ]);
  }

  closeInfoOverlay() {
    this.loginGuard.toggleInfoOverlay();
  }

  setupRouterDefault() {
    // this.route.params.subscribe(params => {
    //   console.log(params, params)
    // });
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart && event.url) {
        this.lang.updateCurrentUrl(event.url);
        const routePath = event.url.split('/');
        this.lang.setCurrentLanguage(routePath[1]);
      }
      if (event instanceof NavigationError) {
        console.warn('NavigationError', event);
        setTimeout(() => {
          this.gotoHomeScreen();
        }, 100);
      }
    });
  }
  isSidePanelExpanded(){
    return this.sidePanel.getExpandedStatus();
  }
  isSidePanelVisible(){
    return this.sidePanel.getVisibilityStatus() && this.sidePanel.isAccountLoaded;
  }
  isMobileLeftPanel(){
    return this.sidePanel.getMobileVisibilityStatus();
  }
  openMobileLeftPanel(){
    this.sidePanel.activateMobile();
  }
  closeMobileLeftPanel(){
    this.sidePanel.deactivateMobile();
  }

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: BeforeUnloadEvent) {
    if (this.dataGuard.isActive()) {
      $event.returnValue = true;
    }
  }

  getNextPicUrl() {
    if (this.lang.getCurrentLanguage() == 'en') {
      return "https://eqao.vretta.com/authoring/user_uploads/96360/authoring/next/1621020375829/next.svg"
    } else {
      return "https://eqao.vretta.com/authoring/user_uploads/96360/authoring/Fr-next/1624046675741/Fr-next.svg"
    }
  }

  getBackPicUrl() {
    if (this.lang.getCurrentLanguage() =='en') {
      return "https://eqao.vretta.com/authoring/user_uploads/96360/authoring/prev/1621021529272/prev.svg"
    } else {
      return "https://eqao.vretta.com/authoring/user_uploads/96360/authoring/Fr-prev/1624046906166/Fr-prev.svg"
    }
  }

  getTextPicUrl() {
    if (this.lang.getCurrentLanguage() =='en') {
      return "https://eqao.vretta.com/authoring/user_uploads/96360/authoring/Text/1621021741680/Text.svg"
    } else {
      return "https://eqao.vretta.com/authoring/user_uploads/96360/authoring/Fr-Text/1624047097061/Fr-Text.svg"
    }
  }

  getConfirmationBtnStyle(config: IConfirmationReqBtnConfig) {
    return {
      'background-color': config?.bgColor,
      'color': config?.fgColor
    };
  }

}
