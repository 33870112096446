import * as moment from 'moment';
import * as _ from 'lodash';

// app services
import { LangService } from '../../../core/lang.service';
import { IQuestionConfig } from "../../../ui-testrunner/models"
import { IAssessmentFrameworkDetail, ISectionItems, IAssessmentPartition, IPanelModulesConfig, IQuadrantTestlet, IPanelModuleConfig, IAssembledPanel } from "../models/assessment-framework"
import { ITestDesignPayload, ISectionDef } from "../../../ui-testtaker/view-tt-test-runner/view-tt-test-runner.component";
import { extractSampleQuestionsStructure } from '../../../ui-testrunner/sample-questions/data/extract';
import { downloadStr } from '../../../core/download-string';
import { ISampleTestForm } from "../models/types";
import { ItemBankCtrl } from './item-bank';
import { ItemSetFrameworkCtrl } from './framework';
import { generateMsCatTestForm } from './mscat';
import { ItemBankSaveLoadCtrl } from './save-load';
import { TestletCtrl } from './testlets';
import { WhitelabelService } from '../../../domain/whitelabel.service';
import { Destroyable } from './destroyable';

export type PartitionToSectionItems = (asmtFmrk:IAssessmentFrameworkDetail, partition:IAssessmentPartition) => ISectionItems;

export class TestFormGen implements Destroyable {

  documentMap:Map<number, IQuestionConfig> = new Map();

  constructor(
    public lang: LangService,
    public whitelabel: WhitelabelService,
    public frameworkCtrl: ItemSetFrameworkCtrl,
    public itemBankCtrl: ItemBankCtrl,
    public saveLoadCtrl:ItemBankSaveLoadCtrl,
    public testletCtrl: TestletCtrl,
  ){

  }

  destroy() {

  }

  async generateLinearTestForm() {
    this.documentMap = new Map();
    const testFormStore = await this.initTestFormStore();
    await testFormStore.initLinearSections( this.frameworkCtrl.getSectionQuestionsLinear );
    return testFormStore.getTestForm();
  }
  /*
  getVisibleReadingSelections: (q :IQuestionConfig) => string[],
  langCode:string,
  isEnglish: boolean,
  asmtFmrk: IAssessmentFrameworkDetail,
  */

  async generateMsCatTestForm(targetPanel?:IPanelModulesConfig){
    const baseTestForm = generateMsCatTestForm({
      targetPanel,
      getQuestionByLabel: this.itemBankCtrl.getQuestionByLabel,
      getQuestionById: this.itemBankCtrl.getQuestionById,
      getVisibleReadingSelections: this.itemBankCtrl.getVisibleReadingSelections,
      isEnglish: this.itemBankCtrl.isEnglish(),
      asmtFmrk: this.frameworkCtrl.asmtFmrk,
      langCode: this.lang.c()
    });
    const testFormStore = await this.initTestFormStore(baseTestForm);
    await testFormStore.initMsCat();
    return testFormStore.testForm;
  }

  async clearSampleTestForms() {
    this.frameworkCtrl.asmtFmrk.testForms = [];
  }

  async generateSampleTestForm(skipStorage: boolean = false, testletIds?:number[]): Promise<ISampleTestForm> {
    this.testletCtrl.ensureTestletQuestionIds();
    const testFormStore = await this.initTestFormStore();
    await testFormStore.preinitLoft(testletIds);
    // await testFormStore.initLoft();
    const testForm = testFormStore.getTestForm();
    console.log('new test form', testForm);
    if (!skipStorage) {
      this.storeTestDesign(testForm.currentTestDesign)
    }
    // console.log('testForm', testForm)
    return testForm;
  }

  exportSampleTestForm = (testForm: ITestDesignPayload) => {
    const questionDb = [];
    // get preambles
    const missingPreambles = [];
    testForm.sections.forEach(section => {
      if (section.preamble) {
        const question = this.itemBankCtrl.getQuestionById(section.preamble);
        if (question) {
          questionDb.push(question);
        } else {
          section.preamble = null;
          missingPreambles.push(`${section.caption} (${section.preamble})`);
        }
      }
    });
    // get question content
    testForm.sections.forEach(section => {
      section.questions.forEach(questionId => {
        const question = this.itemBankCtrl.getQuestionById(questionId);
        if (question) {
          questionDb.push(question);
        }
      });
    });
    const payload = extractSampleQuestionsStructure(testForm, questionDb);
    // console.log('exportSampleTestForm', payload);
    if (missingPreambles.length > 0) {
      alert('The following preambles could not be found: ' + missingPreambles.join(', '));
    }
    downloadStr(JSON.stringify(payload), 'test-form-export.json');
  }

  async convertLOFTFormDefToPreview(testDesign: ITestDesignPayload, testletIds?:number[]) {
    const testFormStore = await this.initTestFormStore(testDesign);
    await testFormStore.initLOFTSections(testletIds);
    return testFormStore.getTestForm();
  }

  convertPreviewFormToStoredForm(sampleTestForm: any) {
    let questionDb = {};
    sampleTestForm.questionSrcDb.forEach((a, b) => questionDb[b] = a );
    return {
      ... sampleTestForm.currentTestDesign,
      lang: sampleTestForm.testLang,
      questionDb,
    };
  }

  storeTestDesign(testDesign:ITestDesignPayload){
    const asmtFmrk = this.frameworkCtrl.asmtFmrk;
    if (!asmtFmrk.testForms) {
      asmtFmrk.testForms = [];
    }
    asmtFmrk.testForms.push(testDesign);
  }

  private async initTestFormStore(baseTestForm?:Partial<ITestDesignPayload>){
    return new TestFormStore(
      baseTestForm || {},
      this.lang.c(),
      this.frameworkCtrl.asmtFmrk,
      this.saveLoadCtrl.loadDocument,
      (flag:string) => this.whitelabel.getSiteFlag(flag),
      this.itemBankCtrl.getQuestionById,
      this.itemBankCtrl.getQuestionByLabel,
      this.itemBankCtrl.getVisibleReadingSelections,
      this.itemBankCtrl.isEnglish(),
      this.documentMap
    );
  }

  generateLOFTTestFormsForTestDesign = async (usePreAssembled?:boolean, skipStorage:boolean = false):Promise<string[]> => {
    const forms:string[] = [];
    const batchSize = this.frameworkCtrl.asmtFmrk.numOfPublishingIterations || 1;
    this.documentMap = new Map()
    this.testletCtrl.ensureTestletQuestionIds();

    let testformIterator:any[] = Array.from('X'.repeat(batchSize)).map(()=>new Object({}));
    const assembledPanels = this.frameworkCtrl.asmtFmrk.assembledPanels
    if (usePreAssembled && assembledPanels && assembledPanels.length){ // if no panels skip this
      // prioritize any assembled panels 
      const step = prompt('Where do you want to start the publishing?\nEnter the index:');
      const rangeFrom = +step
      if(Number.isNaN(rangeFrom) || rangeFrom < 0 || rangeFrom > assembledPanels.length - 1){
        alert(this.lang.tra('publish_panel_index_error'));
        throw new Error("")
      }
      const panelSlice = assembledPanels.filter(p => !p.isExcluded).slice(rangeFrom);
      const confirmation = confirm([`Publishing panel indexes: ${rangeFrom}-${panelSlice.length - 1}`, 'This includes the following panels: ', panelSlice.map(p => p.id).join(', ')].join('\n'))
      if (!confirmation){
        throw new Error('MANUAL');
      }
      testformIterator = panelSlice;
    }

    if(this.determineLotfFtEmbedPresence()) {
      const ftQuadrants =  this.getLotfFtEmbedPresence();

      // Keep synchronous to avoid duplicated API calls
      for (let sourceFormDef of testformIterator) {
        await this.generateLotfFormFtEmbbeded(sourceFormDef, forms, ftQuadrants, skipStorage)
      }
    } else {
      for (let sourceFormDef of testformIterator) {
        await this.generateLotfForm(sourceFormDef, forms, '', skipStorage)
      }
    }
    return forms;
  }

  /**
   * Generates a form given a panel and pushes it to the forms array
   * @param sourceFormDef panel to generate form from
   * @param forms array of JSON strings to store the generated forms
   */
  async generateLotfForm(sourceFormDef: IAssembledPanel, forms: string[], iterationSuffix: string = '', skipStorage:boolean = false) {
    const sampleForm = await this.generateTloftSampleFormFromPanel(sourceFormDef, skipStorage);
    const storedForm = await this.convertPreviewFormToStoredForm(sampleForm);
    storedForm.sourceFormId = sourceFormDef.id + iterationSuffix
    const form = JSON.stringify(storedForm)
    // console.log('storedForm', JSON.parse(form))
    forms.push(form);
  }

  /**
   * Generates a form variant for each FT testlet and pushes them to the forms array
   * @param sourceFormDef panel to generate form from
   * @param forms array of JSON strings to store the generated forms
   * @param ftQuadrants an array of quadrants listed for post-assembly injection
   */
  async generateLotfFormFtEmbbeded(sourceFormDef: IAssembledPanel, forms: string[], ftQuadrants: {quadrantId: number, testlets: IQuadrantTestlet[]}[], skipStorage:boolean = false) {
    // Keep synchronous to avoid duplicated API calls
    // There is a chance that multiple quadrants are listed for FT, loop through all quadrants.
    for (let ftQuad of ftQuadrants){
      // Inject each testlet into the sourceFormDef with the iteration and ID as a label suffix.
      for (let i = 0; i < ftQuad.testlets.length; i++) {
        const testlet = ftQuad.testlets[i];
        const iterationSuffix = `%${i+1}_${testlet.id}${testlet.similaritySlug ? `_${testlet.similaritySlug}` : ''}`;
        const sfdClone = JSON.parse(JSON.stringify(sourceFormDef));
        sfdClone.testletIds.push(testlet.id);
        await this.generateLotfForm(sfdClone, forms, iterationSuffix, skipStorage);
      }
    }
  }

  /**
   * Generates a testlet preview 
   * @param testlets testlets id to generate preview from
   */
  async generateTestletForm(testlets: number[]){
    const sampleFormDef = await this.generateSampleTestForm(true , testlets);
    return await this.convertLOFTFormDefToPreview(sampleFormDef.currentTestDesign);
  }

  /**
   * Checks if the Tloft test design requires post assembly injection
   * @returns if isPostAssemblyTestletsAppliedToAllPanels and at least one quadrant uses post assembly insertion
   */
  determineLotfFtEmbedPresence() {
    const asmtFmrk = this.frameworkCtrl.asmtFmrk;
    const postAssemblyInsertionQuadrant = asmtFmrk.quadrants.find((quadrant) => quadrant.is_post_assembly_insertion)
    return asmtFmrk.isPostAssemblyTestletsAppliedToAllPanels && postAssemblyInsertionQuadrant;
  }

  /**
   * Gets all quadrants and testlets that require post assembly insertion
   * @returns an array of objects containing the quadrant ID and the testlet IDs associated with it
   */
  getLotfFtEmbedPresence() {
    const quadrants = this.frameworkCtrl.asmtFmrk.quadrants.filter((quadrant) => quadrant.is_post_assembly_insertion);
    return quadrants.map((quadrant) => {
      const testlets = this.frameworkCtrl.asmtFmrk.testlets
        .filter((testlet) => testlet.quadrant == quadrant.id && !testlet.isDisabled);
      return {quadrantId: quadrant.id, testlets}
    });
  }

  async generateTloftSampleFormFromPanel(sourceFormDef:any, skipStorage:boolean=false){
    const testletIds = sourceFormDef.testletIds;
    const sampleFormDef = await this.generateSampleTestForm(skipStorage, testletIds)
    // console.log('sampleFormDef', {fixed:JSON.parse(JSON.stringify(sampleFormDef)), dyn:sampleFormDef})
    const sampleForm = await this.convertLOFTFormDefToPreview(sampleFormDef.currentTestDesign);
    return sampleForm;
  }

  generateLinearTestFormsForTestDesign = async (): Promise<string[]> => {
    const sampleForm = await this.generateLinearTestForm()
    const storedForm = this.convertPreviewFormToStoredForm(sampleForm);
    return [JSON.stringify(storedForm)];
  }

  generateMscatTestFormsForTestDesign = async (iterationCount=0):Promise<string[]> => {
    const forms: string[] = [];
    this.documentMap = new Map();
    const isFtEmbed = this.determineMscatFtEmbedPresence()
    const iterationLabelSuffix = '%'+(iterationCount+1)
    const panels = this.frameworkCtrl.asmtFmrk.panels
    if(panels){
      for (let i=0; i < panels.length; i++){
        const stepCount = (panels.length*iterationCount) + i
        let panel =  panels[i];
        if(panel.isDisabled) continue;
        panel = JSON.parse(JSON.stringify(panel)); // clone the panel data model so that it can be modified to support embeddings
        if (isFtEmbed){
          const isEmbedsAvail = this.injectMscatPanelFtEmbeddings(panel, stepCount)
          if (!isEmbedsAvail){
            continue;
          }
        }
        panel.id = panel.id + iterationLabelSuffix
        const sampleFormDef = await this.generateMsCatTestForm(panel);
        const storedForm = this.convertPreviewFormToStoredForm(sampleFormDef);
        storedForm.testletItems = panel.testletItems
        storedForm.sourceFormId = panel.id // deprecated: 'mscat-'+panel.id
        forms.push( JSON.stringify(storedForm) );
      }
    }
    return forms;
  }

  determineMscatFtEmbedPresence(){
    for (let module of this.frameworkCtrl.asmtFmrk.panelAssembly.allModules){
      if (module.has_ft){
        return true
      }
    }
    return false;
  }

  injectMscatPanelFtEmbeddings(panel:IPanelModulesConfig, stepCount:number){
    const testlets = this.frameworkCtrl.asmtFmrk.testlets
    panel.testletItems = [];
    // todo: generalize based on the panel assembly
    // temp: pick next 2 testlets (Refactoring urgently needed)
    const targetLen = 2 
    const testlet_module1 = testlets[targetLen*stepCount]
    const testlet_module4 = testlets[targetLen*stepCount+1]

    if (!testlet_module4){
      return false;
    }

    const associateTestletItems = (module:IPanelModuleConfig, testlet:IQuadrantTestlet) => {
      for (let item of testlet.questions){
        module.itemLabels.push(item.label);
        module.__cached_itemIds.push(item.id);
        if (!module.routingExclusions){
          module.routingExclusions = {};
        }
        module.routingExclusions[item.id] = true;
        panel.testletItems.push({
          testlet_id: testlet.id,
          quadrant_id: testlet.quadrant,
          item_id: item.id,
          item_label: item.label,
        });
      }
    }
    
    for (let module of panel.modules){
      if (module.moduleId == 1){
        associateTestletItems(module, testlet_module1);
      }
      if (module.moduleId == 4){
        associateTestletItems(module, testlet_module4);
      }
    }
    
    console.log('Consuming testlets', testlet_module1.id, testlet_module4.id);

    return true;
  }

}

export class TestFormStore {
  
  public questionSrcDb:Map<number, IQuestionConfig> = new Map();

  constructor (
    public  testForm: Partial<ITestDesignPayload>,
    private langCode:string, 
    private asmtFmrk:IAssessmentFrameworkDetail, 
    public  loadDocument: (documentMap:Map<number, IQuestionConfig>, itemId:number, isEnglish?:boolean) => Promise<IQuestionConfig>,
    private getSiteFlag: (flag:string) => boolean,
    private getQuestionById: (id:number) => IQuestionConfig,
    private getQuestionByLabel: (label:string) => IQuestionConfig,
    private getVisibleReadingSelections: (q :IQuestionConfig) => string[],
    private isEnglish:boolean = false,
    private documentMap:Map<number, IQuestionConfig> = new Map()
  ){
    if(this.testForm.questionSrcDb) {
      this.questionSrcDb = this.testForm.questionSrcDb;
    }
    this.loadTestFormMetas();
    this.loadDocumentMapIntoTestForm();
  }

  loadTestFormMetas(){
    loadTestFormMetas(this.testForm, this.asmtFmrk);
  }

  storeQuestionById(id) {
    return storeQuestionById(this.questionSrcDb, this.asmtFmrk, id, this.getQuestionById, this.getVisibleReadingSelections, this.isEnglish);
  }
  
  storeQuestionContent(questionId:number, question:IQuestionConfig, isLangSelected:boolean, forceNewCreation:boolean = false) {
    return storeQuestionContent(this.questionSrcDb, this.asmtFmrk, questionId, question, this.getVisibleReadingSelections, this.isEnglish, isLangSelected, forceNewCreation);
  }

  extractQuestionReadingSelections(question:IQuestionConfig){
    let readSelections;
    if (!this.isEnglish && question.langLink){
      readSelections = this.getVisibleReadingSelections(question.langLink)
    }
    else {
      readSelections = this.getVisibleReadingSelections(question);
    }
    // console.log('store reading selection' , question)
    if (readSelections){
      readSelections.forEach(readSelQLabel => {
        const readSel = this.getQuestionByLabel(readSelQLabel);
        if (readSel) {
          this.storeQuestionById(readSel.id);
        }
        else{
          console.error('Missing read selection: ', readSelQLabel)
        }
      })
    }
  }

  async preinitLoft(preAsmblTestletIds?:number[]){
    console.log('preAsmblTestletIds', preAsmblTestletIds)
    this.testForm.testletIds = [];
    const testForm = this.testForm;
    testForm.helpPageId__cache = this.asmtFmrk.helpPageId;
    const quadrantTestletMap: Map<number, IQuadrantTestlet[]> = new Map();
    const sectionQuadrantItems: Map<number, {items: number[], testlets: number[]}> = new Map();
    const isTestletAllowed = (testlet:{id:number, isDisabled?:boolean}) => {
      if (testlet.isDisabled){ return false; }
      if (preAsmblTestletIds){
        if (preAsmblTestletIds.indexOf(+testlet.id) == -1){
          return false
        }
      }
      return true;
    }
    this.asmtFmrk.testlets.forEach(testlet => {
      const quadrantId = 1 * testlet.quadrant;
      let testletPool = quadrantTestletMap.get(quadrantId);
      if (!testletPool) {
        testletPool = [];
        quadrantTestletMap.set(quadrantId, testletPool);
      }
      if (isTestletAllowed(testlet)){ // CLOSER_LOOK_20210807_r this was possibly removed (I think we're okay)
        testletPool.push(testlet);
      }
    });
    this.asmtFmrk.quadrants.forEach(quadrant => {
      const quadrantId = 1 * quadrant.id;
      const sectionId = 1 * quadrant.section;
      let sectionContent = sectionQuadrantItems.get(sectionId);
      if (!sectionContent) {
        sectionContent = {
          items: [],
          testlets: [],
        };
        sectionQuadrantItems.set(sectionId, sectionContent);
      }
      const testletPool = quadrantTestletMap.get(quadrantId);
      if (testletPool && testletPool.length) {
        const i = Math.floor(testletPool.length * Math.random());
        const testlet = testletPool[i];
        const testletId = 1 * testlet.id;
        sectionContent.testlets.push(testletId);
        testlet.questions.forEach(question => {
          sectionContent.items.push(question.id);
        });
      }
    });
    if (!testForm.sections){
      testForm.sections = [];
    }
    for (let i=0; i < this.asmtFmrk.partitions.length; i++){
      const section = this.asmtFmrk.partitions[i];
      await this.captureSectionMetaItemIds(section);
      const sectionId = 1 * section.id;
      const sectionContent = sectionQuadrantItems.get(sectionId);
      let questions;
      if (sectionContent) {
        testForm.testletIds = testForm.testletIds.concat(sectionContent.testlets);
        questions = sectionContent.items;
        // console.log('pre form shuffle')
        if (section.orderByParam){ // this was the old OSSLT flag, but should be updated...
          const orderByParam = (''+section.orderByParam).trim()
          let sortedQuestions = [];
          let hasOrder
          questions.forEach(id => {
            const question = this.getQuestionById(id);
            const order = +(question.meta[orderByParam]) || 0;
            sortedQuestions.push({id, order});
          });
          // console.log('form has order ', section.orderByParam)
          sortedQuestions = _.sortBy(sortedQuestions, 'order')
          questions = sortedQuestions.map(q => q.id);
        }
        if (section.isShuffled) {
          questions = _.shuffle(questions);
        }
      } else {
        questions = [];
        console.error ('Error. No questions defined for section ' + sectionId + ' ' + section.description);
      }
      let preamble;
      if (section.preambleQuestionLabel) {
        const preambleQuestion = this.getQuestionByLabel(section.preambleQuestionLabel);
        if (preambleQuestion) {
          preamble = preambleQuestion.id;
        }
      }

      let preambleList: number[] = []; //list of ids
      if(section.preambleLabelList) {
        for( const label of section.preambleLabelList) {
          const preambleQuestion = this.getQuestionByLabel(label);
          if (preambleQuestion) {
            preambleList.push(preambleQuestion.id);
          }
        }
      }
      let postambleList: number[] = [];
      if(section.postambleLabelList) {
        for(const label of section.postambleLabelList) {
          const postambleQuestion = this.getQuestionByLabel(label);
          if (postambleQuestion) {
            postambleList.push(postambleQuestion.id);
          }        
        }
      }
      testForm.sections.push({
        ... extractSectionParams(section),
        preamble,
        preambleList,
        postambleList,
        questions,
      });
    };
    await this.loadDocumentMapIntoTestForm();
  }

  async initLOFTSections(testletIds?:number[]){
    if(this.testForm?.sections) {
      this.testForm.sections.forEach(section => {
        if (section.preamble){
          this.storeQuestionById(section.preamble);
        }
        const ambleLists = ['preambleList', 'postambleList']
        for(const ambleListProp of ambleLists) {
          if (section[ambleListProp]?.length) {
            for(const qId of section[ambleListProp]) {
              this.storeQuestionById(qId);
            }
          }
        }
        section.questions.forEach(questionId => {
          this.storeQuestionById(questionId)
        });
      });
    }
    for (let i=0; i < this.asmtFmrk.partitions.length; i++){
      const section = this.asmtFmrk.partitions[i];
      await this.captureSectionMetaItemIds(section);
    }
    await this.loadDocumentMapIntoTestForm();
  }

  async initMsCat(){
    await this.loadDocumentMapIntoTestForm();
  }

  async captureSectionMetaItemIds(partition: IAssessmentPartition){
    if (partition.mapMetaItemId){
      const id = +partition.mapMetaItemId;
      const mapMetaItem = await this.loadDocument(this.documentMap, id, true); // need the outermost structure, so forcing english here 
      mapMetaItem.id = id;
      this.storeQuestionContent(id, mapMetaItem, false, true);
    }
  }

  async initLinearSections( getSectionItemsByPartition:PartitionToSectionItems ){
    this.testForm.sections = []
    for (let i=0; i<this.asmtFmrk.partitions.length; i++){
      const partition = this.asmtFmrk.partitions[i];

      let preamble: number; // id
      if (partition.preambleQuestionLabel) {
        const preambleQuestion = this.getQuestionByLabel(partition.preambleQuestionLabel);
        if (preambleQuestion) {
          preamble = preambleQuestion.id;
          this.storeQuestionById(preamble);
        }
      }
      
      let preambleList: number[] = []; //list of ids
      if(partition.preambleLabelList) {
        for( const label of partition.preambleLabelList) {
          const preambleQuestion = this.getQuestionByLabel(label);
          if (preambleQuestion) {
            preambleList.push(preambleQuestion.id);
            this.storeQuestionById(preambleQuestion.id);
          }
        }
      }

      let postambleList: number[] = [];
      if(partition.postambleLabelList) {
        for(const label of partition.postambleLabelList) {
          const postambleQuestion = this.getQuestionByLabel(label);
          if (postambleQuestion) {
            postambleList.push(postambleQuestion.id);
            this.storeQuestionById(postambleQuestion.id);
          }        
        }
      }

      await this.captureSectionMetaItemIds(partition);

      const sectionItems = getSectionItemsByPartition(this.asmtFmrk, partition);
      const questions = [];

      sectionItems.questions.forEach(questionRef => {
        const questionId = questionRef.id;
        const question = this.storeQuestionById(questionId);
        if (question) {
          questions.push(questionId);
          this.extractQuestionReadingSelections(question);
        } 
        else {
          console.error('An item has been removed from the associated item banks since this test form was created'), questionRef;
        }
      });

      const section: ISectionDef = {
        ... extractSectionParams(partition),
        questions,
        preamble,
        preambleList,
        postambleList
      };
      if (partition.isTimeLimit) {
        section.isTimeLimit = true;
        section.timeLimitMinutes = partition.timeLimitMinutes;
      }
      this.testForm.sections.push(section);
    };
    await this.loadDocumentMapIntoTestForm();
  }

  async loadDocumentMapIntoTestForm() {
    let refDocPages = this.asmtFmrk.referenceDocumentPages || [];
    const documentItemIds:number[] = [];
    // console.log('loadDocumentMapIntoTestForm', this.asmtFmrk.helpPageId)
    if (this.asmtFmrk.helpPageId){
      documentItemIds.push(+this.asmtFmrk.helpPageId);
    }
    refDocPages.forEach(rdp => documentItemIds.push(rdp.itemId) );

    // Keep synchronous to avoid duplicated API calls
    for (let itemId of documentItemIds) {
      await this.loadDocument(this.documentMap, itemId, this.isEnglish)
    }
    // console.log('documentMap', this.documentMap)
    if(!this.questionSrcDb) {
      this.questionSrcDb = new Map();
    }
    this.documentMap.forEach((item, itemId) => {
      // console.log('saving doc', itemId, item)
      if (this.questionSrcDb==null) return;
      // Patch when the helpPageId is not from the question bank
      if(!this.getQuestionById(itemId) && !this.questionSrcDb.has(itemId)){
        this.questionSrcDb.set(itemId, item);
      } else {
        this.storeQuestionById(itemId)
      }
    })
  }

  getTestForm(base?:any) : ISampleTestForm {
    return {
      ... (base || {}),
      currentTestDesign: this.testForm,
      questionSrcDb: this.questionSrcDb,
      questionStates: {},
      testLang: this.langCode,
    }
  }

}

const extractSectionParams = (partition:IAssessmentPartition) : Partial<ISectionDef> => {
  return {
    caption: partition.description,
    hasCalculator: partition.isCalculatorEnabled,
    hasNotepad: !partition.isNotepadDisabled,
    hasFormulas: partition.isFormulaSheetEnabled,
    areQuestionsSkippable: partition.areQuestionsSkippable,
    isShuffleDisabled: !partition.isShuffled,
    disableScoring: partition.disableScoring,
    disableFlagging: partition.disableFlagging,
    msgReqFill: partition.msgReqFill,
    disableLeftBar: partition.disableLeftBar,
    submissionText: partition.submissionText,
    notepadText: partition.notepadText,
    preambleThumbnail: partition.preambleThumbnail,
    preambleThumbnailSelected: partition.preambleThumbnailSelected,
    preambleThumbnailText: partition.preambleThumbnailText,
    isConditional: partition.isConditional,
    conditionOnItem: partition.conditionOnItem,
    conditionOnOption: partition.conditionOnOption,
    sidebarThumbnailEn: partition.sidebarThumbnailEn,
    sidebarThumbnailFr: partition.sidebarThumbnailFr,
    sectionId: partition.id
  }
}


export const storeQuestionById = (questionSrcDb, asmtFmrk, questionId:number, getQuestionById, getVisibleReadingSelections, isEnglish, forceNewCreation:boolean = false) => {
  if (questionSrcDb.has(questionId) && !forceNewCreation){ 
    return questionSrcDb.get(questionId); 
  }
  const question = getQuestionById(questionId);
  if (!question) { return; }
  return storeQuestionContent(questionSrcDb, asmtFmrk, questionId, question, getVisibleReadingSelections, isEnglish, false, forceNewCreation);
}

export const loadTestFormMetas = (testForm, asmtFmrk) => {
  testForm.label = moment().format();
  testForm.useQuestionLabel = asmtFmrk.useQuestionLabel;
  testForm.questionWordSlug = asmtFmrk.questionWordSlug;
  testForm.useSectionCaptions = asmtFmrk.useSectionCaptions;
}

const storeQuestionContent = (questionSrcDb, asmtFmrk, questionId:number, question:IQuestionConfig, getVisibleReadingSelections, isEnglish: boolean, isLangSelected:boolean, forceNewCreation:boolean = false, ) => {
  if (questionSrcDb.has(questionId) && !forceNewCreation){ 
    return questionSrcDb.get(questionId); 
  }
  let questionContent = question;
  let label = questionContent.label
  if (!isLangSelected && !isEnglish) {
    questionContent = question.langLink;
  }
  let questionContentList = questionContent.content;
  if (asmtFmrk.isUnreadyExcluded && !question.isReady){
    questionContentList = <any> [
      {"elementType":"text","caption":"\n\n*This page/question was excluded from\nthe last publishing of the assessment.*","simpleList":[],"advancedList":[],"paragraphStyle":"regular","entryId":1,"link":{"elementType":"text_link","caption":""},"font":"Arial","alignment":"center","rotation":0,"isShowAdvancedOptions":true}
    ]
  }
  questionSrcDb.set(questionId, 
    {
    content: questionContentList,
    entryOrder: questionContent.entryOrder,
    voiceover: questionContent.voiceover,
    meta: captureRelevantQuestionMeta(asmtFmrk, question),
    ///////
    label: label,
    isReadingSelectionPage: questionContent.isReadingSelectionPage,
    readingSelectionCaption: questionContent.readingSelectionCaption,
    isReqFill: questionContent.isReqFill,
    reqFillEntries: questionContent.reqFillEntries,
    reqFillMsg: questionContent.reqFillMsg,
    readSel: questionContent.readSel,
    testLabel: questionContent.testLabel,
    caption: questionContent.caption,
    // captionVoiceover: questionContent.captionVoiceover,
    points: questionContent.points,
    ishidePoints: questionContent.ishidePoints || false,
    readSelections: getVisibleReadingSelections(questionContent),
    isReadingSelectionsAlwaysShown: questionContent.isReadingSelectionsAlwaysShown,
    isStartHalf: questionContent.isStartHalf,
    backgroundImage: questionContent.backgroundImage,
    bannerImage: questionContent.bannerImage,
    bannerSubtitle: questionContent.bannerSubtitle,
    bannerSubtitleMarginBottom: questionContent.bannerSubtitleMarginBottom,
    bannerTitle: questionContent.bannerTitle,
    showBannerHr: questionContent.showBannerHr,
    bannerHrColor: questionContent.bannerHrColor,
    bannerOverlay: questionContent.bannerOverlay,
    useCustomPrev: questionContent.useCustomPrev,
    useCustomNext: questionContent.useCustomNext,
    customPrevBgColor: questionContent.customPrevBgColor,
    customNextBgColor: questionContent.customNextBgColor,
    customPrevFgColor: questionContent.customPrevFgColor,
    customNextFgColor: questionContent.customNextFgColor,
    customPrevText: questionContent.customPrevText,
    customNextText: questionContent.customNextText,
    customButtonPos: questionContent.customButtonPos,
    customPrevBold: questionContent.customPrevBold,
    customNextBold: questionContent.customNextBold,
    customButtonIndent: questionContent.customButtonIndent,
    isQuestionnaire: questionContent.isQuestionnaire
  });
  return question;
}

  const captureRelevantQuestionMeta = (asmtFmrk:IAssessmentFrameworkDetail, question:IQuestionConfig) => {
    // console.log('captureRelevantQuestionMeta', question.id, asmtFmrk.preservedMetaParams)
    if (asmtFmrk.preservedMetaParams){
      const meta:{[key:string]: any} = {};
      asmtFmrk.preservedMetaParams.forEach(param => {
        meta[param] = question.meta[param];
      });
      return meta;
    }
    return undefined;
  }