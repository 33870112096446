// app services

import { LangService } from '../../../core/lang.service';
import { PrintAltTextService } from '../../print-alt-text.service';
import { PrintModeService } from '../../print-mode.service';
import { ItemBankCtrl } from './item-bank';
import { ItemSetFrameworkCtrl } from './framework';
import { TestFormGen } from './testform-gen';
import { ISampleTestForm } from '../models/types';
import { IQuestionConfig } from '../../../ui-testrunner/models';
import { Destroyable } from './destroyable';


export class ItemSetPrintViewCtrl implements Destroyable {
  
  printModeQuestions = [];
  printTitle: string;
  isResultsPrint: boolean = false;
  
  constructor(
    public printMode: PrintModeService,
    public printAltText: PrintAltTextService,
    public lang: LangService,
    public itemBankCtrl:ItemBankCtrl,
    public testFormGen: TestFormGen,
    public frameworkCtrl:ItemSetFrameworkCtrl,
  ){

  }

  destroy() {

  }

  isPrintModeActive = () => this.printMode.isActive;


  print() {
    window.print();
  }

  toggleAltTextVisible() {
    this.printAltText.toggleAltTextVisible();
  }

  toggleVoiceScript() {
    this.printMode.toggleShowOnlyScript();
  }

  showOnlyVoiceScript() {
    return this.printMode.showOnlyScript;
  }

  private async getQuestionSrcDb(): Promise<Map<string, any>> {
    return (await this.testFormGen.generateLinearTestForm()).questionSrcDb;
  }

  private async getQuestionSrcDbMsCat(panel): Promise<Map<string, any>> {
    return (await this.testFormGen.generateMsCatTestForm(panel)).questionSrcDb;
  }

  private async getQuestionSrcDbTloft(panel): Promise<any> {
    return (await this.testFormGen.generateTloftSampleFormFromPanel(panel)).questionSrcDb;
  }

  private getQuestionsByLabel(questionSrcDb: Map<string, any>): Map<string, IQuestionConfig> {
    const questionMap:Map<string, IQuestionConfig> = new Map()
    questionSrcDb.forEach((question: IQuestionConfig)=>{
      questionMap.set(question.label, question);
    });
    return questionMap
  }

  getQuestionContent(questions, questionSrcDb) {
    const questionsByLabel = this.getQuestionsByLabel(questionSrcDb);
    const questionsLangSpecific = questions.map(question => {
      let content;
      let readingSelections;
      if ((!this.itemBankCtrl.isLangEnabled('en')) || (this.lang.c() !== 'en')){
        content = question.langLink.content;
        readingSelections = this.itemBankCtrl.getVisibleReadingSelections(question.langLink);
      }
      else {
        content = question.content;
        readingSelections = this.itemBankCtrl.getVisibleReadingSelections(question);
      }

      const captions = [];
      if (readingSelections) {
        for (const readSel of readingSelections) {
          const readSelQuestion = questionsByLabel.get(readSel);
          if (!readSelQuestion) continue;
          readSelQuestion.content.filter(c => c.elementType === 'canvas').map(c => captions.push(c['caption']));
        }
      }
      return {
        ... question,
        content,
        points: questionSrcDb.get(question.id).points,
        readSelCaption: captions.join(', ')
      }
    });
    this.printModeQuestions = questionsLangSpecific.map(question => {
      return {
        question,
      }
    })
  }

  async gotoTloftPrintView(panel) {
    this.printModeQuestions = [];
    this.printMode.isActive = true;
    const clone = (o) => JSON.parse(JSON.stringify(o));
    const questionSrcDb = await this.getQuestionSrcDbTloft(panel);
    const questions = clone(this.frameworkCtrl.getTloftFormQuestions(questionSrcDb));    
    this.getQuestionContent(questions, questionSrcDb);
  }

  async gotoMsCatPrintView(panel) {
    this.printModeQuestions = [];
    this.printMode.isActive = true;
    const clone = (o) => JSON.parse(JSON.stringify(o));
    const questions = clone(this.frameworkCtrl.getMsCatFormQuestions(panel));      
    const questionSrcDb = await this.getQuestionSrcDbMsCat(panel);
    this.getQuestionContent(questions, questionSrcDb);
  }

  async gotoLinearPrintView(){
    this.printModeQuestions = [];
    this.printMode.isActive = true;
    const clone = (o) => JSON.parse(JSON.stringify(o));
    const questions = clone(this.frameworkCtrl.getLinearFormQuestions());
    const questionSrcDb = await this.getQuestionSrcDb();
    this.getQuestionContent(questions, questionSrcDb);
  }

  activatePrintMode(){
    const numQuestions = parseInt(prompt('How many questions do you want to display?'));
    const skipQuestions = parseInt(prompt('How many questions (from the beginning) do you want to skip?'));
    this.itemBankCtrl.questions = this.itemBankCtrl.getItems().slice(skipQuestions, skipQuestions + numQuestions );
    this.printMode.isActive = true;
  }
}