// app services
import { AdvancedSettings } from '../item-set-editor/models/advanced-settings';
import { AssetLibraryCtrl } from '../item-set-editor/controllers/asset-library';
import { AuthScopeSetting, AuthScopeSettingsService } from '../auth-scope-settings.service';
import { AuthService } from '../../api/auth.service';
import { CdkDrag } from '@angular/cdk/drag-drop';
import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DropdownService } from '../dropdown.service';
import { EditingDisabledService } from '../editing-disabled.service';
import { EditViewMode } from '../item-set-editor/models/types';
import { Subscription, Subject } from 'rxjs';
import { elementIconById, IQuestionConfig } from '../item-set-editor/models';
import { elementTypes } from "../../ui-testrunner/models/ElementTypeDefs";
import { FrameworkQuadrantCtrl } from '../item-set-editor/controllers/quadrants';
import { IContentElement, IElementTypeDef, ISequenceConfig } from '../../ui-testrunner/models/index';
import { LoginGuardService } from '../../api/login-guard.service';
import { IItemTag } from '../item-tag/item-tag.component';
import { IItemTypeDef, ItemType, ItemTypeDefs, itemTypes, CustomButtonPos } from '../models';
import { installPatch } from '../../patches/nested-drag-drop-patch';
import { ItemBankAuditor } from '../item-set-editor/controllers/audits';
import { ItemBankCtrl } from '../item-set-editor/controllers/item-bank';
import { ItemBankSaveLoadCtrl } from '../item-set-editor/controllers/save-load';
import { ItemBankUtilCtrl } from '../item-set-editor/controllers/util';
import { ItemComponentEditService } from '../item-component-edit.service';
import { ItemEditCtrl } from '../item-set-editor/controllers/item-edit';
import { ItemFilterCtrl } from '../item-set-editor/controllers/item-filter';
import { ItemSetFrameworkCtrl } from '../item-set-editor/controllers/framework';
import { ItemSetPreviewCtrl } from '../item-set-editor/controllers/preview';
import { ItemSetPrintViewCtrl } from '../item-set-editor/controllers/print-view';
import { ItemSetPublishingCtrl } from '../item-set-editor/controllers/publishing';
import { LangService } from '../../core/lang.service';
import { MemberAssignmentCtrl } from '../item-set-editor/controllers/member-assignment';
import { PanelCtrl } from '../item-set-editor/controllers/mscat';
import { PARAM_SPECIAL_FLAGS } from '../framework-dimension-editor/model';
import { StyleprofileService } from '../../core/styleprofile.service';
import { TestFormGen } from '../item-set-editor/controllers/testform-gen';
import { TestletCtrl } from '../item-set-editor/controllers/testlets';
import { TextToSpeechService } from '../../ui-testrunner/text-to-speech.service';
import { TwiddleState } from '../../ui-partial/twiddle/twiddle.component';
import { getElVoice, getVoiceChange } from 'src/app/io-audio/capture-voice/capture-voice.component';
import { WhitelabelService } from 'src/app/domain/whitelabel.service';
import { mtz } from '../../core/util/moment';
import { OnlineOrPaperService } from 'src/app/ui-testrunner/online-or-paper.service';
import { WidgetAuthoringConnectionService } from '../widget-authoring-connection.service';

enum LanguagesAvailable {
  en = 'en',
  fr = 'fr'
}

export const IS_TRACK_CHANGES_DISABLED = true;

@Component({
  selector: 'widget-authoring-main',
  templateUrl: './widget-authoring-main.component.html',
  styleUrls: ['./widget-authoring-main.component.scss']
})
export class WidgetAuthoringMainComponent implements OnInit, OnDestroy {

  @Input() assetLibraryCtrl: AssetLibraryCtrl
  @Input() frameworkCtrl: ItemSetFrameworkCtrl
  @Input() auditCtrl: ItemBankAuditor
  @Input() itemBankCtrl: ItemBankCtrl
  @Input() itemEditCtrl: ItemEditCtrl
  @Input() itemFilterCtrl: ItemFilterCtrl
  @Input() memberAssignmentCtrl: MemberAssignmentCtrl
  @Input() panelCtrl: PanelCtrl
  @Input() previewCtrl: ItemSetPreviewCtrl
  @Input() printViewCtrl: ItemSetPrintViewCtrl
  @Input() publishingCtrl: ItemSetPublishingCtrl
  @Input() quadrantCtrl: FrameworkQuadrantCtrl
  @Input() saveLoadCtrl: ItemBankSaveLoadCtrl
  @Input() testFormGen: TestFormGen
  @Input() testletCtrl: TestletCtrl
  @ViewChild('secureDataUpload', { static: false }) secureDataUpload: ElementRef;
  @ViewChild('tagInput') tagInput: ElementRef;

  EditViewMode = EditViewMode;

  IS_TRACK_CHANGES_DISABLED = IS_TRACK_CHANGES_DISABLED;
  
  ItemType = ItemType;
  ItemTypeDefs = ItemTypeDefs;
  getElVoice = getElVoice;
  getVoiceChange = getVoiceChange;
  isViewingArchived = false;
  showAdvancedSettings = false;
  isEditingSetName = false;
  itemPropsTwiddle = new TwiddleState(false);
  advancedItemPropsTwiddle = new TwiddleState(false);
  scoringItemPropsTwiddle = new TwiddleState(false);
  sequenceCommentsTwiddle = new TwiddleState(false);
  commentsTwiddle = new TwiddleState(true);
  pinnedCommentsTwiddle = new TwiddleState(false);
  rubricGrid: any[] = [];
  advancedSettings = AdvancedSettings;
  personalEditorSettings = {};
  rawResponseTableDataSource;
  dataSource;
  displayedColumns = ['response_ID', 'response'];
  isHighContrast: boolean = false;
  isWebsocketDisconnected: boolean = false;
  inactiveSubject = new Subject<any>();
  inactiveSub: Subscription;
  websocketDisconnectedSub: Subscription;
  inactiveTimeout;
  inactivityTimerDuration = 1800000; // 30 minutes
  createNewQuestionSub: Subscription;
  selectQuestionSub: Subscription;
  updatedItemSub: Subscription;
  userTimeoutSub: Subscription;
  autoSaveSub: Subscription;
  isLangSplitView: boolean = false;
  splitScreenZoom = {left: 100, right:100};
    
  public util = new ItemBankUtilCtrl();
  selectedRevisionFilterLanguages: string[] = [];
  archivedQuestions: any[] = [];
  isLoadingArchived: boolean;
  LanguagesAvailable = LanguagesAvailable;

  constructor(
    private textToSpeech: TextToSpeechService,
    private onlineOrPaper: OnlineOrPaperService,
    private editingDisabled: EditingDisabledService,
    public profile: StyleprofileService,
    public dropdown: DropdownService,
    public lang: LangService,
    private auth: AuthService,
    private whiteLabel: WhitelabelService,
    private loginGuard: LoginGuardService,
    public itemComponentEdit: ItemComponentEditService,
    private authScopeSettings: AuthScopeSettingsService,
    public ws: WidgetAuthoringConnectionService
  ) { }

  CustomButtonPos = CustomButtonPos;

  ngOnInit(): void {
    try { this.previewCtrl.updateDragZoomCorrection(); } catch(e){}
    //Required to fix cdk drag drop with nested drop lists (From https://github.com/angular/components/issues/16671)
    installPatch();
    this.ws.connect();
    this.initializeUserInactivityChecker();
    this.updatedItemSub = this.ws.updatedItemSub.subscribe((item) => {
      if (!this.itemBankCtrl.currentQuestion) return;

      const selectedItems = this.ws.selectedItems[item["itemId"]];
      if (!selectedItems) return;

      const userHasItemSelected = this.ws.prevSelectedItemId === item["itemId"];
      const userSentSaveRequest = (this.ws.userConnectionId === item["connectionId"]);
      if (userHasItemSelected && !userSentSaveRequest) {
        this.showItemHasUpdatedAlert(item["connectionId"]);
        this.itemBankCtrl.reloadCurrentQuestion();
      }
    });
    this.inactiveSub = this.inactiveSubject.subscribe(_ => {
      this.ws.reconnectInactiveUser();
    });
    this.autoSaveSub = this.saveLoadCtrl.autoSaveSub.subscribe(_ => {
      if (!this.itemBankCtrl.isEditActivationPending()) {
        this.wsSaveItem();
      }
    });
    this.websocketDisconnectedSub = this.ws.websocketDisconnectedSub.subscribe((isDisconnected: boolean) => {
      this.isWebsocketDisconnected = isDisconnected;
    });
    this.selectQuestionSub = this.itemBankCtrl.selectQuestionSub.subscribe((question: IQuestionConfig) => {
      if (question && this.ws.prevSelectedItemId !== question.id) {
        this.ws.selectItem(question.id);
      }
    });
    this.userTimeoutSub = this.ws.userTimeoutSub.subscribe(_ => {
      if (!this.isEditActivationPending()) {
        this.saveLoadCtrl.autoSaveQuestionData();
        this.editingDisabled.isManualEditingEnabled = false;
      }
      this.loginGuard.quickPopup("You have been disconnected for inactivity. Start editing again to reconnect.");
      this.itemBankCtrl.deselectQuestions();
    });
    this.createNewQuestionSub = this.itemBankCtrl.createNewQuestionSub.subscribe(_ => {
      this.selectQuestion(this.itemBankCtrl.currentQuestion);
    });
    if (this.itemBankCtrl.editModeItemId) {
      const questions = [];
      this.itemFilterCtrl.getSideItemList().forEach(i => {
        if (i.type === 'item') {
          questions.push(i)
        } else if (i.type === 'sequence') {
          (i as ISequenceConfig).children.forEach(q => questions.push(q))
        }
      })
      const question = questions.filter(q => q.id === this.itemBankCtrl.editModeItemId)[0];
      setTimeout(() => {
        this.itemBankCtrl.selectQuestion(question);
      }, 0)
    }
  }

  ngOnDestroy(): void {
    this.itemComponentEdit.reset();
    this.ws.cleanupOnDestroy();
    this.cleanupUserInactivityChecker();
  }


  isRightPanelExpanded() {
    return this.authScopeSettings.isRightPanelExpanded
  }
  toggleRightPanelExpanded() {
    this.authScopeSettings.isRightPanelExpanded = !this.authScopeSettings.isRightPanelExpanded
  }

  getQuestionLastEdit() {
    this.itemBankCtrl
  }

  toggleTextToSpeech = () => this.textToSpeech.toggle();
  toggleOnlineOrPaper = () => this.onlineOrPaper.toggle();
  isTextSpeechActive = () => this.textToSpeech.isActive;
  isOnlineOrPaperActive = () => this.onlineOrPaper.isPaper;
  isSidePanelVisible = () => false;
  isSidePanelExpanded = () => false;
  createNewConversation() { }
  resolveComment(comment) { }
  isReadOnly = (ignoreCurrQLock: boolean = false, ignoreEditingDisabled: boolean = false) => {
    // if (this.isEditActivationPending()){
    //   return true;
    // }
    return this.editingDisabled.isReadOnly(ignoreCurrQLock, ignoreEditingDisabled) || this.itemBankCtrl.getCurrQTrackChanges();
  }
  elementTypes: IElementTypeDef[] = elementTypes;
  itemTypes: IItemTypeDef[] = itemTypes;

  refreshPinnedTrigger: number = 0;

  ITEM_RERENDER_TIME_BUDGET = 600;

  assignButtonHandler($event) {
    this.memberAssignmentCtrl.openAssignUser({note: $event});
  }

  isEditActivationPending = () => this.itemBankCtrl.isEditActivationPending();
  activateManualEditing = async () => this.itemBankCtrl.activateManualEditing();
  finishManualEditing = async () => this.itemBankCtrl.finishManualEditing();
  isManualEditApplicable = () => this.itemBankCtrl.isManualEditApplicable();
  toggleManualEdit = async () => {
    if (this.isWebsocketDisconnected) {
      this.showWebsocketDisconnectedAlert();
    }
    if (this.isEditActivationPending() && this.isQuestionSelectedByOtherUser()) {
      this.showItemIsBeingEditedAlert(this.ws.prevSelectedItemId);
    }

    this.itemBankCtrl.toggleManualEdit();
    this.wsSaveItem();
  }
  isHeldForEditing = () => {
    return this.isManualEditApplicable() && !this.isEditActivationPending();
  }

  getIconByElementTypeId(elementTypeId: string) {
    return elementIconById.get(elementTypeId);
  }

  isNewItemDropdownActive() {
    return this.dropdown.isNewItemDropdownActive;
  }

  toggleNewItemDropdown(event) {
    return this.dropdown.toggleNewItemDropdown(event);
  }

  allDropListIds() {
    return this.itemBankCtrl.dropListIds;
  }

  allowedToDropIntoSeq = (drag: CdkDrag, drop) => {
    return drag.data.type !== ItemType.SEQUENCE
  }

  collapseSequence(seq: ISequenceConfig) {
    this.itemBankCtrl.setSequenceCollapsed(seq, !seq.isCollapsed);
  }

  currentQuestionIsSequence() {
    return this.itemBankCtrl.currentQuestionIsSequence();
  }

  getCurrentQuestionType() {
    return ItemTypeDefs[this.itemBankCtrl.currentQuestion.type].caption;
  }

  isViewable(param) {
    return this.authScopeSettings.isItemParamViewable(param)
  }
  isLeftPanelOpen(){
    return !this.itemBankCtrl.isFocusView && (!this.isLangSplitView);
  }
  getCurrentQuestionLabelSlug(): string {
    const id = ItemTypeDefs[this.itemBankCtrl.currentQuestion.type].id;
    let slug = '';
    if (id === ItemType.ITEM) {
      slug = 'auth_item_label';
    } else if (id === ItemType.SEQUENCE) {
      slug = 'auth_sequence_label';
    }
    return slug;
  }

  getCurrentQuestionUpdatedOn() {
    const question = this.itemBankCtrl.currentQuestion;
    if (question && question.updated_on) {
      return mtz(question.updated_on).fromNow();
    }
    return '---'
  }

  getCurrentQuestionIDSlug(): string {
    const id = ItemTypeDefs[this.itemBankCtrl.currentQuestion.type].id;
    let slug = '';
    if (id === ItemType.ITEM) {
      slug = 'ie_item_id';
    } else if (id === ItemType.SEQUENCE) {
      slug = 'auth_sequence_id';
    }
    return slug;
  }

  getRemoveItemLabelSlug(): string {
    const id = ItemTypeDefs[this.itemBankCtrl.currentQuestion.type].id;
    let slug = '';
    if (id === ItemType.ITEM) {
      slug = 'auth_remove_item';
    } else if (id === ItemType.SEQUENCE) {
      slug = 'auth_remove_sequence';
    }
    return slug;
  }

  deselectQuestions(event) {
    // if(event.target.classList.contains("allow-deselect-question")) {
    //   this.itemBankCtrl.deselectQuestions();
    // }
  }

  isNewItemDisabled() {
    const isLockedSequenceQuestion = (this.currentQuestionIsSequence() || this.itemBankCtrl.getParent(this.itemBankCtrl.currentQuestion)) && this.itemBankCtrl.isLocked(this.itemBankCtrl.currentQuestion)
    return (
      this.isReadOnly(true) || 
      this.saveLoadCtrl.isLoadingQuestion || 
      isLockedSequenceQuestion || 
      (this.isManualEditApplicable() && this.editingDisabled.isManualEditingEnabled)
    )
  }

  canDeleteReadSel(i: number) {
    return !this.itemBankCtrl.isContentEditingDisabled() && this.itemBankCtrl.isOwnReadSel(this.itemBankCtrl.getCurrentQuestionContent(), i)
  }

  closeEditMode() {
    this.itemBankCtrl.isPsychometricViewEnabled = true;
    this.itemBankCtrl.editModeItemId = null;
  }

  refreshPinned() {
    this.refreshPinnedTrigger++;
  }

  isOwnRevision(revision: any) {
    const uid = this.auth.getUid();
    if (!uid || uid == -1) {
      return false;
    }
    return revision && uid == revision.created_by_uid;
  }

  writeRevisionMessage(revision: any) {
    const message = prompt("Message");
    this.saveLoadCtrl.saveRevisionMessage(revision, message);
  }

  getParamDisplay(param) {
    return param.isHidden || !this.isViewable(param) ? 'none' : ''
  }

  addTag(tag: IItemTag) {
    this.itemBankCtrl.addTag(tag);
    this.tagInput.nativeElement.blur();
  }

  isCurrentQuestionNull():boolean{
    return this.itemBankCtrl.currentQuestion === null;
  }

  gotoPrevQuestion() {
    if (this.isHeldForEditing()) return alert('Save your edits before moving on.');
    const questions = this.itemFilterCtrl.getSideItemList();
    let activeQuestionIndex = questions.findIndex(q => q.id === this.itemBankCtrl.currentQuestion.id);
    if (activeQuestionIndex < 0) {
      let previousChildQuestion;
      questions.forEach((question, index) => {
        if (question.children && question.children.length > 0) {
          let childIndex = question.children.findIndex(q => q.id === this.itemBankCtrl.currentQuestion.id)
          if (childIndex > -1 && childIndex - 1 >= 0) {  // If not the first child in sequence, proceed to the previous child
            previousChildQuestion = question.children[childIndex - 1]
          } else if (childIndex > -1) {
            activeQuestionIndex = index; // If first child in sequence, update activeQuestionIndex
          }
        }
      })
      if (previousChildQuestion) {
        this.itemBankCtrl.selectQuestion(previousChildQuestion);
        return;
      }
    }
    const prevQ = questions[activeQuestionIndex - 1];
    if (!prevQ) return;
    if (prevQ.children && prevQ.children.length > 0) {
      this.itemBankCtrl.selectQuestion(prevQ.children[prevQ.children.length - 1]);
      return;
    }
    this.itemBankCtrl.selectQuestion(prevQ);
  }

  gotoNextQuestion() {
    if (this.isHeldForEditing()) return alert('Save your edits before moving on.');
    const questions = this.itemFilterCtrl.getSideItemList();
    let activeQuestionIndex = questions.findIndex(q => q.id === this.itemBankCtrl.currentQuestion.id);
    // Find if question is inside of a sequence
    if (activeQuestionIndex < 0) {
      let nextChildQuestion;
      questions.forEach((question, index) => {
        if (question.children && question.children.length > 0) {
          let childIndex = question.children.findIndex(q => q.id === this.itemBankCtrl.currentQuestion.id)
          if (childIndex > -1 && question.children.length > childIndex + 1) {  // If not the last child in sequence, proceed to the next child
            nextChildQuestion = question.children[childIndex + 1]
          } else if (childIndex > -1) {
            activeQuestionIndex = index; // If last child in sequence, update activeQuestionIndex
          }
        }
      })
      if (nextChildQuestion) {
        this.itemBankCtrl.selectQuestion(nextChildQuestion);
        return;
      }
    }
    const nextQ = questions[activeQuestionIndex + 1];
    if (!nextQ) return;
    if (nextQ.children && nextQ.children.length > 0) {
      this.itemBankCtrl.selectQuestion(nextQ.children[0]);
      return;
    }
    this.itemBankCtrl.selectQuestion(nextQ);
  }

  toolbarEditingColumns = ["Format", "Code", "Example Code", "Example View"]
  twiddleTable = new TwiddleState(false);

  getToolbarCommands() {
    const rows = [
      ["Bold", "**", "**Example**", "<strong>Example<strong>"],
      ["Italic", "*", "*Example*", "<i>Example</i>"],
      ["Underline", "<u> </u>", "<u>Example</u>", "<u>Example</u>"],
      ["Strikethrough", "<strike> </strike>", "<strike>\nExample\n</strike>", "<strike>Example</strike>"],
      ["Superscript", "^", "Example^2", "Example<sup>2</sup>"],
      ["Subscript", "-", "Example_2", "Example<sub>2</sub>"],
      ["Invisible character", "&zwnj;", "url_&zwnj;website", "url_website"],
      ["Non-breakable space", "&nbsp;", "Example&nbsp;2", "Example&nbsp;2"],
      ["Em Dash", "&mdash;", "Example&mdash;2", "Example&mdash;2"],
      ["En Dash", "&ndash;", "Example&ndash;2", "Example&ndash;2"],
      ["Degree", "&deg;", "25&deg;C", "25&deg;C"],
      ["Triangle", "\\triangle (math block)", "\\triangle ABC", "△ ABC"],
      ["Angle", "\\angle (math block)", "\\angle ABC", "∠ ABC"]
    ]
    return rows
  }

  suggestionsEnabled() {
    return this.authScopeSettings.getSetting(AuthScopeSetting.ENABLE_SUGGESTIONS);
  }

  containsSpecial(param, spec: PARAM_SPECIAL_FLAGS) {
    if (param.config.special && param.config.special[spec]) {
      return true
    }
    return false
  }

  isWordCount(param) {
    return this.containsSpecial(param, PARAM_SPECIAL_FLAGS.WORD_COUNT)
  }

  getFilteredDims(dims: any[]) {
    const filtered = []
    dims.forEach((dim) => {
      if (this.itemBankCtrl.currentQuestion?.isReadingSelectionPage || !this.isWordCount(dim)) {
        filtered.push(dim)
      }
    })
    return filtered
  }

  isTrackedChangesBtnDisabled() {
    return this.authScopeSettings.getSetting(AuthScopeSetting.DISABLE_TRACKED_CHANGES_CTRL);
  }

  getSaveSlug() {
    if (this.itemBankCtrl.isSuggestionMode()) {
      return 'Save Suggestions';
    }
    return 'ie_auto_save';
  }

  getSavingSlug() {
    return 'ie_saving';
  }

  getRightPanelSuggestionState() {
    if (this.itemComponentEdit.usingEditingMode()) {
      return this.itemComponentEdit.suggestion?.state; //in editing mode, we need to provide a direct link to the suggestion state's element for editing the value.
    }

    return this.itemComponentEdit.suggestionStateCopy; //in review mode, we provide a copy since we're modifying it by adding deleted elements.
  }

  getCurrQStateContent() {
    return this.itemBankCtrl.getCurrQStateContent();
  }

  getCurrentQuestionMeta() {
    const dimensions = [...this.frameworkCtrl.asmtFmrk.primaryDimensions, ...this.frameworkCtrl.asmtFmrk.secondaryDimensions];

    if(this.lang.c() === 'en') {
      return this.getCurrQStateContent().meta;
    }

    const meta = {}
    for(const param of dimensions) {
      const langIndependent = param.config.special && param.config.special[PARAM_SPECIAL_FLAGS.LANG_INDEPENDENT];

      let value
      if(langIndependent) {
        value = this.itemBankCtrl.currentQuestion.langLink?.meta[param.code];
      } else {
        value = this.itemBankCtrl.currentQuestion.meta[param.code];
      }

      if(value !== undefined) {
        meta[param.code] = value;
      }
    }

    return meta
  }
  getCurrQEnglish(){
    return this.itemBankCtrl.getCurrQStateContent('en');
  }
  getCurrQFrench(){
    return this.itemBankCtrl.getCurrQStateContent('fr');
  }

  isQuestionSelectedByCurrentUser(questionId: number) {
    if (this.ws.prevSelectedItemId === questionId) {
      return true;
    }

    return false;
  }

  isQuestionSelected(questionId: number) {
    const selectedItems = this.ws.selectedItems;
    if (!selectedItems[questionId]) return false;

    return selectedItems[questionId].length > 0;
  }

  isQuestionSelectedByOtherUser() {
    const selectedItems = this.ws.selectedItems;
    if (!selectedItems[this.ws.prevSelectedItemId]) return false;

    const otherUsersOnQuestion = selectedItems[this.ws.prevSelectedItemId].filter(connectionId => {
      return connectionId !== this.ws.userConnectionId
    });

    return otherUsersOnQuestion.length > 0;
  }

  getNumberOfUsersOnItem() {
    if (this.isWebsocketDisconnected && !this.ws.socketConnecting) return "WEBSOCKET DISCONNECTED";

    const selectedItems = this.ws.selectedItems;
    if (!selectedItems[this.ws.prevSelectedItemId]) return "User Tracking";

    const otherUsersOnQuestion = selectedItems[this.ws.prevSelectedItemId].filter(connectionId => {
      return connectionId !== this.ws.userConnectionId
    });

    if (otherUsersOnQuestion.length === 0) {
      return "User Tracking";
    }

    if (otherUsersOnQuestion.length === 1) {
      return otherUsersOnQuestion.length + " other user in item";
    }

    return otherUsersOnQuestion.length + " other users in item";
  }

  showItemSelectedAlert(questionId: number) {
    const selectedAuthorName = this.ws.authorNames[this.ws.selectedItems[questionId][0]];
    this.loginGuard.quickPopup(selectedAuthorName + " is already editing this question. Please choose another question to edit.");
  }

  showItemIsBeingEditedAlert(questionId: number) {
    const selectedAuthorName = this.ws.authorNames[this.ws.selectedItems[questionId][0]];
    this.loginGuard.quickPopup(selectedAuthorName + " is already editing this question."
      + " Please note that your work for this question might not be saved until they are done.");
  }

  showItemHasUpdatedAlert(userConnectionId: string) {
    const selectedAuthorName = this.ws.authorNames[userConnectionId];
    this.loginGuard.quickPopup(selectedAuthorName + " has updated this item. Click 'OK' to see the updated version."
      + " Please note that if you have made changes during this time, your work may not have saved.");
  }

  showWebsocketDisconnectedAlert() {
    this.loginGuard.quickPopup("Your websocket is not connected to the server. Until your websocket connects, you and"
      + " other people in this item bank will not be able to see each other's changes or selected items in real-time.");
  }

  async selectQuestion(question) {
    if (this.isHeldForEditing() || !question) return;
    if (this.isQuestionSelectedByCurrentUser(question.id)) return;
    if(this.saveLoadCtrl.isRevisionsOpen) this.saveLoadCtrl.isRevisionsOpen = false;
    if (this.isWebsocketDisconnected) {
      this.showWebsocketDisconnectedAlert();
    }

    await this.itemBankCtrl.selectQuestion(question);    
    if (this.isQuestionSelected(question.id)) {
      this.showItemSelectedAlert(question.id);
    }

    this.ws.selectItem(question.id);
  }

  private wsSaveItem() {
    if (this.saveLoadCtrl.isSaving) {
      this.ws.updateItem(this.itemBankCtrl.currentQuestion.id);
    }
  }

  isSaving() {
    if (this.itemBankCtrl.isSuggestionMode()) {
      return this.saveLoadCtrl.isSuggesting;
    }
    return this.saveLoadCtrl.isSaving;
  }

  autoGenQuestionScript = () => {
    return this.itemEditCtrl.autoGenQuestionScript(this.itemBankCtrl.getCurrentQuestionContent())
  }

  autoGenElScriptFn = (element: IContentElement) => {
    return () => { this.itemEditCtrl.autoGenElScript(element) };
  }

  checkIsSplitData() {
    let content = this.itemBankCtrl.currentQuestion
    let isSplitData = false;
    const processContent = function (el: any) {
      if (Array.isArray(el.content)) {
        el.content.forEach(processContent);
      }
      if (Array.isArray(el.advancedList)) {
        el.advancedList.forEach(processContent);
      }
      if (el.elementType == 'select_table') {
        if (el.splitData) {
          isSplitData = true;
        }
      }
    }
    content.content.forEach(processContent)
    if (isSplitData) {
      this.createRawResponseTableDataSource();
    }
    return isSplitData;
  }

  responseInLetter = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
  createRawResponseTableDataSource() {
    let tableData = []
    let formattedResponse = this.itemEditCtrl.simulateSubmissionRecord.formatted_response.split(';')
    let formattedResponseInLetter = []
    formattedResponse.forEach(response => {
      formattedResponseInLetter.push(this.getResponseInLetter(response))
    })

    this.itemEditCtrl.simulateSubmissionRecord.entries.forEach((entry, index) => {
      tableData.push({ response_id: entry.response_id, response: formattedResponseInLetter[index] })
    })
    if (JSON.stringify(this.dataSource) !== JSON.stringify(tableData)) {
      this.dataSource = tableData
    }
  }

  // In split data the count is for the appearance of each choice/response in one question,
  // e.g. how many time A is choosed for the first questionnaire question.
  getExpectedAnswersTableSplitData() {
    let expectedAnswers = this.itemEditCtrl.expectedAnswers;
    let expectedAnswersSplit = [];
    //A map that counts the number of time a choice of a question appears
    const responseCount = new Map();
    //Iterate through each choice (R#C#) in each formatted response (formatted_response: 'R1C2;R2C2;R3C3;R4C3')
    expectedAnswers.forEach((answer) => {
      let responses = answer.formatted_response.split(';')
      responses.forEach((response) => {
        let count = 0;
        responseCount.has(response) ? count = responseCount.get(response) + answer.total : count = answer.total;
        responseCount.set(response, count);
      })
    })
    const availableChoices = Array.from(responseCount.keys())
    const count = Array.from(responseCount.values())

    availableChoices.forEach((choice, index) => {
      if (choice == '') { return }

      const responseID = this.dataSource[parseInt(choice.charAt(1)) - 1].response_id;
      const formattedResponse = this.getResponseInLetter(choice)

      expectedAnswersSplit.push({
        response_id: responseID,
        formatted_response: formattedResponse,
        lang: expectedAnswers[0].lang,
        score: expectedAnswers[0].score,
        total: count[index],
        weight: expectedAnswers[0].weight,
      })
    })

    expectedAnswersSplit.sort(function (a, b) {
      if (a.response_id < b.response_id) return -1;
      if (a.response_id < b.response_id) return 1;
      if (a.response_id == b.response_id) {
        if (a.formatted_response < b.formatted_response) return -1
        if (a.formatted_response > b.formatted_response) return 1
      }
      return 0;
    });

    return expectedAnswersSplit;
  }

  getResponseInLetter(response) {
    var lastChar = response[response.length - 1];
    if (response == '') {
      return ''
    } else {
      return this.responseInLetter.charAt(parseInt(lastChar) - 1)
    }
  }

  toggleHiContrast() {
    this.isHighContrast = !this.isHighContrast;
    this.textToSpeech.hi_contrast_toggle();
  }

  initializeUserInactivityChecker() {
    window.onload = this.resetInactiveTimeout;
    window.onmousedown = this.resetInactiveTimeout;
    window.onkeydown = this.resetInactiveTimeout;
    window.onclick = this.resetInactiveTimeout;
  }

  resetInactiveTimeout = () => {
    if (this.inactiveTimeout) {
      clearTimeout(this.inactiveTimeout);
    }

    // disconnect and reconnect the user if they are inactive for 30 minutes or more
    this.inactiveTimeout = setTimeout(this.reconnectInactiveUser, this.inactivityTimerDuration);
  }

  reconnectInactiveUser = () => {
    this.inactiveSubject.next();
  }

  cleanupUserInactivityChecker() {
    window.onload = () => { };
    window.onmousedown = () => { };
    window.onkeydown = () => { };
    window.onclick = () => { };
    clearTimeout(this.inactiveTimeout);
  }
  setLangSplitView(val = true){
    this.isLangSplitView = val;
  }
  getZoomFactor(zoom:number):string{
    return `${zoom}%`
  }
  parseRevisionAffectedLang(langs:any){
    let affected_langs = [];
    try{
      affected_langs = JSON.parse(langs).map(lang => lang.toLocaleUpperCase());
    } catch{

    }
    if(affected_langs.length === 0){
      return ""
    }
    return affected_langs.map((l:string) => l.toLocaleUpperCase()).join('/');
  }
  handleCheckboxRevisionFilterChange(event: Event, lang: string) {
    if ((event.target as HTMLInputElement).checked) {
      // If checkbox is checked, add language to selectedRevisionFilterLanguages array
      this.selectedRevisionFilterLanguages.push(lang);
    } else {
      // If checkbox is unchecked, remove language from selectedRevisionFilterLanguages array
      const index = this.selectedRevisionFilterLanguages.indexOf(lang);
      if (index !== -1) {
        this.selectedRevisionFilterLanguages.splice(index, 1);
      }
    }
  }
  checkIfRevisionIsFiltered(lang:string, date:string){
    if(this.selectedRevisionFilterLanguages.length && !date){
      return this.selectedRevisionFilterLanguages.some(item => lang?.includes(item));;
    } else return true;
  }


  async retrieveArchivedItems() {
    this.isLoadingArchived = true;
    this.isViewingArchived = !this.isViewingArchived;
    if (this.isViewingArchived) {
      this.archivedQuestions = await this.saveLoadCtrl.getArchivedQuestions();
      if (this.archivedQuestions[0]) {
        this.itemBankCtrl.selectQuestion(this.archivedQuestions[0]);
      }
    } else {
      this.archivedQuestions = []
      if (this.itemFilterCtrl.getSideItemList()[0]) {
        this.itemBankCtrl.selectQuestion(this.itemFilterCtrl.getSideItemList()[0]);
      }
    }
    this.isLoadingArchived = false;
  }
  async recoverQuestion() {
    await this.itemBankCtrl.recoverQuestion()
    const archivedQuestions = await this.saveLoadCtrl.getArchivedQuestions();
    this.archivedQuestions = archivedQuestions.filter((q) => {
      return !this.itemBankCtrl.getParentId(q) &&
        q.type !== ItemType.SEQUENCE //Assumes children have already been filtered
        ;
    });
  }
}
