export default async function getAssetSize(url: string): Promise<number> {
    const response: Response = await fetch(url, { method: 'HEAD' });
    const contentLength: string | null = response.headers.get('Content-Length');
    if (contentLength !== null) {
        const sizeInBytes: number = parseInt(contentLength);
        const sizeInKB: number = sizeInBytes / 1024;
        return sizeInKB;
    }
    return 0;
}
