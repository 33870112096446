// app services
import { AssetLibraryService, IAssetLibraryConfig} from '../../services/asset-library.service';
import { AssignedUsersService } from '../../assigned-users.service';
import { AuthScopeSettingsService, AuthScopeSetting } from "../../auth-scope-settings.service";
import { AuthService } from '../../../api/auth.service';
import { DataGuardService } from '../../../core/data-guard.service';
import { EditingDisabledService } from '../../editing-disabled.service';
import { ItemComponentEditService } from '../../item-component-edit.service';
import { ItemMakerService } from '../../item-maker.service';
import { LangService } from '../../../core/lang.service';
import { LoginGuardService } from '../../../api/login-guard.service';
import { PrintAltTextService } from '../../print-alt-text.service';
import { PrintModeService } from '../../print-mode.service';
import { RoutesService } from '../../../api/routes.service';
import { ScriptGenService } from '../../script-gen.service';
import { StyleprofileService } from '../../../core/styleprofile.service';
import { WhitelabelService } from '../../../domain/whitelabel.service';

import { IItemAuthNote } from '../../element-notes/element-notes.component';
import {IGraphicsRequest} from "./../../graphics-workflow-section/types"
import { FormControl } from '@angular/forms';
import { AuthRolesService } from '../../auth-roles.service';
import { ItemBankCtrl } from './item-bank';
import { ENoteItemType } from '../../element-notes/types';
import { Destroyable } from './destroyable';


export enum EComAssignType {
  USER = 'USER',
  ACCESS_LEVEL = 'ACCESS_LEVEL'
}

export enum AssignTargetType {
  NOTE = "NOTE",
  GRAPHIC_REQUEST = "GRAPHIC_REQUEST"
}

export class MemberAssignmentCtrl implements Destroyable {

  isAssigningUser:boolean = false;
  groupMembers:any[] = []; // possible loss during merge issue
  openedAssignWindowNote: IItemAuthNote;
  openedAssignWindowRequest: IGraphicsRequest | any;
  targetType: AssignTargetType;

  assignType: EComAssignType = EComAssignType.USER;
  assignTypes;
  accessLevels;
  accessLevelFc = new FormControl();

  constructor(
    private auth: AuthService,
    private assignedUsersService: AssignedUsersService,
    private authRoles: AuthRolesService,
    private routes: RoutesService,
  ){
    this.accessLevels = this.authRoles.authRoleTypes.concat(this.authRoles.bcAuthRoleTypes);
  }

  destroy() {

  }

  genParams() {
      let query:any =   {
        item_id: this.openedAssignWindowNote.item_id,
        note_item_type: this.openedAssignWindowNote.item_type
      }
      return {query};
  }
    
  setGroupMembers(groupMembers) {

    const namedGroupMembers = groupMembers.map( m => {
      let name = "";
      if(m.first_name) {
        name += m.first_name;
        if(m.last_name) {
          name += " " + m.last_name;
        }
      }
      m.name = name;
      return m;
    })
    this.groupMembers = namedGroupMembers;
    this.assignedUsersService.groupMembers = namedGroupMembers;
  }


  /**
   * Assignt the user to the note or graphic request for which the modal was opened
   * @param member The user chosen for assignment
   */
  assignUser(member){

    this.isAssigningUser = true;
    const assigned_uid = member.id;
    const assigned_name =  this.assignedUsersService.findUserName(assigned_uid);
    this.assignedUsersService.resetSelectedUsers(+member.id);

    switch(this.targetType){
      case AssignTargetType.NOTE:
        const noteId = this.openedAssignWindowNote.id;
        this.assignedUsersService.setAssignedUser(noteId, assigned_uid, assigned_name.name, assigned_name.shortName);
        this.auth.apiPatch(this.routes.TEST_AUTH_NOTES, noteId, {assigned_uid}, this.genParams())
        .then(()=>{
          this.isAssigningUser = false;
          this.openedAssignWindowNote = null;
        })
        break;

      case AssignTargetType.GRAPHIC_REQUEST:
        const requestId = this.openedAssignWindowRequest.id
        this.assignedUsersService.setAssignedUser(requestId, assigned_uid, assigned_name.name, assigned_name.shortName, AssignTargetType.GRAPHIC_REQUEST);
        this.auth.apiPatch(this.routes.TEST_AUTH_QUESTION_GRAPHIC_REQUESTS, requestId, {assigned_uid})
        .then(()=>{
          this.isAssigningUser = false;
          this.openedAssignWindowRequest = null;
        })
        break;
    }


  }

  assignAccessLevelToComment(accessLevel) {
    const noteId = this.openedAssignWindowNote.id;
    this.assignedUsersService.setAssignedAccessLevel(noteId, accessLevel);
    this.isAssigningUser = true;
    this.auth.apiPatch(this.routes.TEST_AUTH_NOTES, noteId, {assigned_access_level: accessLevel}, this.genParams()).then(() => {
      this.isAssigningUser = false;
      this.openedAssignWindowNote = null;
    })

  }

  /**
   * Open te widget to assign users, either to a note or a graphic request
   * @param config - pass either the note or graphic request object
   */
  openAssignUser(target: {note?: IItemAuthNote, request?: IGraphicsRequest}) {
    const {note, request} = target

    const userAssignType = {id: EComAssignType.USER, caption: 'Specific User', icon: 'fa-user'}
    const accessLeveLassignType = {id: EComAssignType.ACCESS_LEVEL, caption: 'Access Level', icon: 'fa-users'}

    if (note) {
      this.openedAssignWindowNote = note
      this.targetType = AssignTargetType.NOTE
      this.assignTypes = [userAssignType, accessLeveLassignType];
    }
    else if (request) {
      this.openedAssignWindowRequest = request
      this.targetType = AssignTargetType.GRAPHIC_REQUEST
      this.assignTypes = this.assignTypes = [userAssignType];

    }
  }

  cancelAssign(){
    this.openedAssignWindowNote = null;
  }


  

}