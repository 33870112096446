import { Injectable, OnDestroy} from '@angular/core';
import { Location } from '@angular/common';
import { LangService } from '../core/lang.service';
import { Router } from '@angular/router';
import { StudentDashboardView } from './view-student-g9-dashboard/view-student-g9-dashboard.component';
import { AuthService } from '../api/auth.service';
import { RoutesService } from '../api/routes.service';
import { StudentG9ConnectionService } from './student-g9-connection.service';
import { OnlineOrPaperService } from '../ui-testrunner/online-or-paper.service';
import { G9DemoDataService } from '../ui-schooladmin/g9-demo-data.service';
import { LoginGuardService } from '../api/login-guard.service';
import { StyleprofileService } from '../core/styleprofile.service';

@Injectable({
  providedIn: 'root'
})
export class StudentDashboardService implements OnDestroy {

  constructor(
    private lang: LangService,
    private router: Router,
    private auth: AuthService,
    private routes: RoutesService,
    private studentG9Connection: StudentG9ConnectionService,
    private onlineOrPaper: OnlineOrPaperService,
    private g9DemoData: G9DemoDataService,
    private loginGuard: LoginGuardService,
    private styleprofile: StyleprofileService,
    private location: Location
  ) {
  }

  userSub;
  private schoolSoftLockEnable: boolean = false
  private schoolSoftLockPjEnable: boolean = false
  private schoolSoftLockG9Enable: boolean = false
  private schoolSoftLockG10Enable: boolean = false

  init() {
    if(!this.userSub) {
      this.userSub = this.auth.user().subscribe(async (info) => {
        await this.studentG9Connection.onUserInfoChange(info);
      });
    }
  }


  ngOnDestroy() {
    if(this.userSub) {
      this.userSub.unsubscribe();
    }
  }

  logAction(logActionSlug: string, body: {session_id?: string, state?: object, info?: object}, slugPrefix? : string): void {
    if(!this.auth.userIsStudent()) {
      return;
    }
    let slug = logActionSlug;
    if (slugPrefix !== null && slugPrefix !== undefined && slugPrefix.trim() !== '') {
      slug = `${slugPrefix}_${logActionSlug}`;
    }
    this.auth.apiCreate( this.routes.LOG,
      {
        slug: slug,
        data: {
          uid: this.auth.getUid(),
          ...body
        }
      }
    )
  }

  goToDashboardView = (view: StudentDashboardView, isOsslt?: boolean) => {
    if(isOsslt){
      const baseURL = `${this.lang.c()}/student/dashboard`;
      this.router.navigate([`${baseURL}/${view}`]);
    } else {
      const baseURL = `${this.lang.c()}/student/dashboard`;
      this.router.navigate([`${baseURL}/${view}`]);
    }
  }

  configureQueryParams(allow_inactive_subsession?: boolean) {
    if (this.auth.u()) {
      return {
        query: {
          schl_class_group_id: this.auth.u().sch_class_group_id,
          schl_class_id: this.auth.u().sch_class_id,
          allow_inactive_subsession: allow_inactive_subsession
        }
      }
    }
    return null;
  }

  async loadAssessments(allow_inactive_subsession?: boolean){
    return this.auth
      .apiFind(this.routes.STUDENT_SESSION, this.configureQueryParams(allow_inactive_subsession))
  }

  /**
   * Checks whether the Redis session cache is initialized for the given school class id
   * If not, shows a popup and throws an error to prevent the student from accessing the test
   * @throws ERR_SESSION_CACHE_NOT_INITIALIZED - if the session cache is not initialized
   */
  async checkRedisCacheInitialized() {
    const res = await this.auth.apiGet(this.routes.STUDENT_SESSION_CACHE_INIT_CHECK, this.auth.u().sch_class_id, {
      query: {
        schl_class_group_id: this.auth.u().sch_class_group_id
      }
    });

    if(!res?.isSessionInitialized) {
      this.loginGuard.quickPopup('err_session_cache_not_initialized')
      throw new Error('ERR_SESSION_CACHE_NOT_INITIALIZED');
    }
  }

  async loadAttempt(test_session_id, route:string = this.routes.STUDENT_SESSION){
    const sch_class_group_id = this.auth.u()?.sch_class_group_id;
    if(!sch_class_group_id) {
      return Promise.resolve();
    }

    return this.auth
      .apiGet(route, test_session_id, {query:{KIOSK_PASSWORD: this.auth.activeKioskPassword, schl_class_group_id: sch_class_group_id}}).then(async(res) => {
        this.schoolSoftLockEnable = !!res[0].is_softlock_enabled;
        this.schoolSoftLockPjEnable = !!res[0].is_softlock_enabled_pj;
        this.schoolSoftLockG9Enable = !!res[0].is_softlock_enabled_g9;
        this.schoolSoftLockG10Enable = !!res[0].is_softlock_enabled_g10;
        this.onlineOrPaper.setIsPaper(res[0].IsCrScanDefault, this.g9DemoData.asmtSlugToCurricShort(res[0].asmt_slug));
        if(res[0].styleProfileId){
          this.styleprofile.setAttemptStyleProfile(res[0].styleProfileId).catch(err =>{
            console.error(err);
            this.blockAttempt('attempt_error_loading_test_design_style_profile');
          })
        } else {
          this.blockAttempt('attempt_error_test_design_missing_style_profile');
          this.styleprofile.checkStyleProfileLoaded({componentSource: 'STUDENT_DASHBOARD_SERVICE'}, true)
        }
        return res;
      })
  }

  blockAttempt(message:string){
    this.loginGuard.confirmationReqActivate({
      caption: message,
      btnCancelConfig: {
        hide: true
      },
      btnProceedConfig: {
        hide: true
      }
    })
  }

  getSchoolSoftLockEnablePj() {
    return this.schoolSoftLockPjEnable;
  }

  getSchoolSoftLockEnableG9() {
    return this.schoolSoftLockG9Enable;
  }

  getSchoolSoftLockEnableG10() {
    return this.schoolSoftLockG10Enable;
  }

  getSchoolSoftLockEnable(){
    return this.schoolSoftLockEnable
  }
}
