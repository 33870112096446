// app services
import { AssetLibraryService, IAssetLibraryConfig, AssetTypeFilter} from '../../services/asset-library.service';
import { AssignedUsersService } from '../../assigned-users.service';
import { AuthScopeSettingsService, AuthScopeSetting } from "../../auth-scope-settings.service";
import { AuthService } from '../../../api/auth.service';
import { DataGuardService } from '../../../core/data-guard.service';
import { EditingDisabledService } from '../../editing-disabled.service';
import { ItemComponentEditService } from '../../item-component-edit.service';
import { ItemMakerService } from '../../item-maker.service';
import { LangService } from '../../../core/lang.service';
import { LoginGuardService } from '../../../api/login-guard.service';
import { PrintAltTextService } from '../../print-alt-text.service';
import { PrintModeService } from '../../print-mode.service';
import { RoutesService } from '../../../api/routes.service';
import { ScriptGenService } from '../../script-gen.service';
import { StyleprofileService } from '../../../core/styleprofile.service';
import { WhitelabelService } from '../../../domain/whitelabel.service';
import { ItemBankSaveLoadCtrl } from './save-load';
import { IContentElement, IQuestionConfig } from '../../../ui-testrunner/models';
import { Subscription } from 'rxjs';
import { Destroyable } from './destroyable';

export class AssetLibraryCtrl implements Destroyable {
  
  isAssetLibraryOpen = false;
  currentAsset: IContentElement;
  itemElement: IQuestionConfig;
  initAssetEditing: boolean;
  assetId: number;
  focusedAssetEditField: string;
  alSub:Subscription
  typeFilter: AssetTypeFilter

  constructor(
    private assetLibraryService: AssetLibraryService,
    private saveLoadCtrl: ItemBankSaveLoadCtrl
  ){
    this.alSub = this.assetLibraryService.observer.subscribe( (config:IAssetLibraryConfig) => {
      if (!config || JSON.stringify(config)=="{}") {
        return;
      }
      this.openAssetLibrary(config.element, config.initEnabled, config.focusedField, config.itemElement, config.assetId);
    })
  }

  destroy(){
    this.alSub.unsubscribe();
  }

  openAssetLibrary(
    contentElement: IContentElement, 
    initAssetEditing: boolean, 
    focusedAssetEditField: string, 
    itemElement: IQuestionConfig,
    assetId: number) 
  {
    this.currentAsset = contentElement;
    this.initAssetEditing = initAssetEditing;
    this.focusedAssetEditField = focusedAssetEditField;
    this.itemElement = itemElement;
    this.assetId = assetId;
    this.isAssetLibraryOpen = true;
  }

  openAssetLibraryToIds(assetIds : number[]) {
    this.assetLibraryService.assetIdFilter = assetIds;
    this.isAssetLibraryOpen = true;
  }
  
  closeAssetLibrary() {
    this.isAssetLibraryOpen = false;
    if (!this.itemElement) {
      this.saveLoadCtrl.saveCurrentQuestionData(); //Save any relevant changes e.g. alt-text updates
    }
  }

}