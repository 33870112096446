<div 
class="panels-container"
[class.is-focus-view]="itemBankCtrl.isFocusView"
[class.is-left-panel-visible]="isSidePanelVisible()"
[class.is-left-panel-expanded]="isSidePanelExpanded()"
[class.single-item-mode]="itemBankCtrl.editModeItemId"
>
  <div 
    class="panel-questions"
    *ngIf="isLeftPanelOpen()"
    [class.allow-deselect-question]="!itemBankCtrl.editModeItemId"
    (click)="deselectQuestions($event)"
  >
    <div>
      <button
        *ngIf="itemBankCtrl.editModeItemId"
        class="button is-small is-fullwidth"
        (click)="closeEditMode()"
        ><span>Back to preview</span>
      </button>
      <button 
        *ngIf="itemFilterCtrl.isQuestionFiltersActive.value && itemFilterCtrl.filteredQuestions && !itemBankCtrl.editModeItemId" 
        class="button is-small is-fullwidth has-icon"
        (click)="itemFilterCtrl.clearItemsFilter()"
      >
        <span class="icon"><i class="fa fa-times"></i></span>
        <span>Clear Filters ({{itemFilterCtrl.filteredQuestions.length}} items)</span>
      </button>
  
    <div class="item-selected"
    [class.is-selected-by-other-user]="isQuestionSelectedByOtherUser()"
    [class.is-websocket-disconnected]="isWebsocketDisconnected && !this.ws.socketConnecting"
    >
      {{getNumberOfUsersOnItem()}}
    </div>

    <div *ngIf="!saveLoadCtrl.isInspecting"
    class="save-indicator"
    (click)="saveLoadCtrl.saveCurrentQuestionData()"
    style="cursor:pointer;"
    [class.no-pointer-events]="saveLoadCtrl.isSavingDisabled() && !itemBankCtrl.isSuggestionMode()"
    [class.is-green]="saveLoadCtrl.isSaving"
    [class.is-suggestion]="itemBankCtrl.isSuggestionMode()"
    >
    <div *ngIf="!isSaving()"><tra [slug]="getSaveSlug()"></tra></div>
    <div *ngIf="isSaving()"><tra [slug]="getSavingSlug()"></tra></div>
  </div>

  <div *ngIf="saveLoadCtrl.isInspecting">
    <div class="save-indicator is-green"
    (click)="itemEditCtrl.openElementRestore();"
    style="cursor:pointer;"
    [class.no-pointer-events]="isReadOnly(false, true)"
    >
      <div *ngIf="!saveLoadCtrl.isSaving"><tra slug="ie_restore"></tra></div>
      <div *ngIf="saveLoadCtrl.isSaving"><tra slug="ie_restoring"></tra></div>
    </div>
    <div *ngIf="!saveLoadCtrl.isSaving" class="save-indicator is-cancel"
    (click)="saveLoadCtrl.cancelInspect()"
    style="cursor:pointer;"
    >
      <div><tra slug="ie_cancel"></tra></div>
    </div>
  </div>
<div class="lang-btn-container">
  <button *ngIf="itemBankCtrl.isLangEnabled('en')" class="button is-small " (click)="previewCtrl.setLang('en')" [class.is-info]="itemBankCtrl.isLang('en')">
    <span>EN</span>
  </button>
  <button *ngIf="itemBankCtrl.isLangEnabled('fr')"  class="button is-small " (click)="previewCtrl.setLang('fr')" [class.is-info]="itemBankCtrl.isLang('fr')">
    <span>FR</span>
  </button>
  <button *ngIf="itemBankCtrl.activeItemSetLanguages.size > 1"  class="button is-small " [disabled]="isCurrentQuestionNull()" (click)="setLangSplitView(true)">
    <span>EN | FR</span>
  </button>
</div>
</div>

<div 
  id="item-list" 
  style="padding-top:2em;padding-bottom:2em; flex-grow:1;" 
  class="question-list-container" 
  [class.allow-deselect-question]="!itemBankCtrl.editModeItemId"
  cdkDropList [cdkDropListData]="itemBankCtrl.topLevelQuestions" [cdkDropListConnectedTo]="allDropListIds()" [cdkDropListDisabled]="isReadOnly(true) || saveLoadCtrl.isLoadingQuestion"
  (cdkDropListDropped)="itemBankCtrl.questionDrop($event)">
  
  <!-- && !isHiddenQuestionsShown -->
  <!-- This show the item list on the left -->
  <ng-container *ngIf="!isViewingArchived">
    <ng-container *ngFor="let question of itemFilterCtrl.getSideItemList(); let questionIndex = index">
      <ng-container *ngIf="question.type === ItemType.SEQUENCE">
        <div *ngIf="!itemBankCtrl.editModeItemId || question.id === itemBankCtrl.editModeItemId"
         cdkDrag  [cdkDragData]="question"
         class="disable-cdk-animation sequence-block" 
         [class.is-active]="question === itemBankCtrl.currentQuestion"
        >
          <div style="font-size: 0.5em" [class.no-pointer-events]="isReadOnly(true)" class="drag-header" cdkDragHandle (click)="selectQuestion(question)">
            <!-- <i class="fas fa-folder" aria-hidden="true"></i> -->
            <div style="display: flex; align-items: center">
              {{question.label}}
              <i *ngIf="question.isLocked" class="fas fa-lock" style="font-size:0.6em; margin-left: 0.5em; margin-top:0.3em"></i>
            </div>
            <div style="width: 0.75em; display: flex; justify-content: center" (click)="collapseSequence(question); $event.stopPropagation();">
              <i [ngClass]="question.isCollapsed ? 'fas fa-caret-right' : 'fas fa-caret-down'" aria-hidden="true"></i>
            </div>
          </div>
          <div *ngIf="!question.isCollapsed" class="question-list-container" style="padding:0.5em; min-height: 4em" [id]="itemBankCtrl.getDropListId(question)" cdkDropList [cdkDropListData]="question.children" [cdkDropListDisabled]="isReadOnly(true) || saveLoadCtrl.isLoadingQuestion || itemBankCtrl.isLocked(question)" [cdkDropListConnectedTo]="allDropListIds()" [cdkDropListEnterPredicate]="allowedToDropIntoSeq" (cdkDropListDropped)="itemBankCtrl.questionDrop($event)">
            <div *ngFor="let child of question.children" cdkDrag [cdkDragData]="child"
              class="disable-cdk-animation question-block" 
              [class.is-hidden]="(child.isHidden) || saveLoadCtrl.isInspecting" 
              [class.is-info-slide]="child.isInfoSlide" 
              [class.is-active]="child === itemBankCtrl.currentQuestion" 
              [class.is-manual-edit]="isHeldForEditing()" 
              (click)="selectQuestion(child)" 
              style="position:relative;"
            >
              <div class="question-block-label">
                <!-- <i *ngIf="question.isInfoSlide" class="fa fa-info-circle" aria-hidden="true" style="margin-right:0.3em;"></i> -->
                {{child.label}}
              </div>
              <i *ngIf="child.isLocked" class="question-block-lock fas fa-lock"></i>
              <i *ngIf="!child.isLocked && itemBankCtrl.getQuestionContent(child).isTrackingChanges"
              [class.track-changes-color]="!itemBankCtrl.getQuestionContent(child).isEditing" 
              [class.editing-color]="itemBankCtrl.getQuestionContent(child).isEditing" 
              class="question-block-lock fas fa-user-edit"></i>
              <div *ngIf="!child.isInfoSlide" class="question-block-indicator">
                <i *ngIf="child.isReady" class="fa fa-check is-confirmed" ></i>
                <i *ngIf="!child.isReady" class="fa fa-circle is-unconfirmed" ></i>
              </div>
              <div *ngIf="isQuestionSelected(child.id)" class="question-block-selected-indicator">
                <i class="fas fa-user"></i>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="question.type !== ItemType.SEQUENCE">
        <div 
          cdkDrag  
          [cdkDragData]="question"
          class="disable-cdk-animation question-block double-font-size" 
          [class.is-hidden]="(question.isHidden) || saveLoadCtrl.isInspecting" 
          [class.is-info-slide]="question.isInfoSlide" 
          [class.is-active]="question === itemBankCtrl.currentQuestion" 
          [class.is-manual-edit]="isHeldForEditing()" 
          (click)="selectQuestion(question)" 
          style="position:relative;"
          *ngIf="!itemBankCtrl.editModeItemId || question.id === itemBankCtrl.editModeItemId"
        >
          <div class="question-block-label">
            <!-- <i *ngIf="question.isInfoSlide" class="fa fa-info-circle" aria-hidden="true" style="margin-right:0.3em;"></i> -->
            {{question.label}}
          </div>
          <i *ngIf="itemBankCtrl.getQuestionContent(question).isLocked" class="question-block-lock fas fa-lock"></i>
          <i
            *ngIf="!itemBankCtrl.getQuestionContent(question).isLocked && itemBankCtrl.getQuestionContent(question).isTrackingChanges"
            class="question-block-lock fas fa-user-edit"
          ></i>
          <div *ngIf="!question.isInfoSlide" class="question-block-indicator">
            <i *ngIf="question.isReady" class="fa fa-check is-confirmed" ></i>
            <i *ngIf="!question.isReady" class="fa fa-circle is-unconfirmed" ></i>
          </div>
          <div *ngIf="isQuestionSelected(question.id)" class="question-block-selected-indicator">
            <i class="fas fa-user"></i>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>
  <!-- This shows the archived questions on the left -->
  <ng-container *ngIf="isViewingArchived">
    <p><strong>Archived Items</strong></p>
    <p *ngIf="!archivedQuestions.length">Nothing Archived</p>
    <ng-container *ngFor="let question of archivedQuestions">
      <ng-container *ngIf="question.type !== ItemType.SEQUENCE">
        <div 
          cdkDrag  
          [cdkDragData]="question"
          class="disable-cdk-animation question-block double-font-size" 
          [class.is-hidden]="(question.isHidden) || saveLoadCtrl.isInspecting" 
          [class.is-info-slide]="question.isInfoSlide" 
          [class.is-active]="question === itemBankCtrl.currentQuestion" 
          [class.is-manual-edit]="isHeldForEditing()" 
          (click)="selectQuestion(question)" 
          style="position:relative; opacity: 0.6;"
          *ngIf="!itemBankCtrl.editModeItemId || question.id === itemBankCtrl.editModeItemId"
        >
          <div class="question-block-label">
            <!-- <i *ngIf="question.isInfoSlide" class="fa fa-info-circle" aria-hidden="true" style="margin-right:0.3em;"></i> -->
            {{question.label}}
          </div>
          <i *ngIf="question.isLocked" class="question-block-lock fas fa-lock"></i>
          <i *ngIf="!question.isLocked && itemBankCtrl.getQuestionContent(question).isTrackingChanges" [class.track-changes-color]="!itemBankCtrl.getQuestionContent(question).isEditing" [class.editing-color]="itemBankCtrl.getQuestionContent(question).isEditing" class="question-block-lock fas fa-user-edit"></i>
          <div *ngIf="!question.isInfoSlide" class="question-block-indicator">
            <i *ngIf="question.isReady" class="fa fa-check is-confirmed" ></i>
            <i *ngIf="!question.isReady" class="fa fa-circle is-unconfirmed" ></i>
          </div>
          <div *ngIf="isQuestionSelected(question.id)" class="question-block-selected-indicator">
            <i class="fas fa-user"></i>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>
</div>
<div *ngIf="!saveLoadCtrl.isLoading && !itemBankCtrl.isContentEditingDisabled()">
  <div *ngIf="itemBankCtrl.personalEditorSettings['isMetaScreensEnabled']"> <button class="button is-small is-fullwidth has-icon" style="background-color: #ececec;" (click)="itemBankCtrl.createNewInfoSlide()">
    <span class="icon"><i class="fa fa-plus" aria-hidden="true"></i></span>
    <span>New Info Slide</span>
  </button> </div>
  <div *ngIf="!frameworkCtrl.isFrameworkView && !itemBankCtrl.editModeItemId"> 
    <div style="display: flex; flex-direction: row">
      <button [disabled]="isNewItemDisabled()" class="button is-small is-fullwidth has-icon" (click)="itemBankCtrl.createNewQuestion(ItemType.ITEM)">
        <span class="icon"><i class="fa fa-plus" aria-hidden="true"></i></span>
        <span><tra slug="ie_new_item"></tra></span>
      </button> 
      <div class="dropdown is-up" [class.is-active]="isNewItemDropdownActive()">
        <div class="dropdown-trigger">
          <button [disabled]="isNewItemDisabled()" (click)="toggleNewItemDropdown($event)" class="button is-small" aria-haspopup="true" aria-controls="dropdown-menu" style="margin-right: 0">
            <!-- <span class="icon"><i class="fa fa-plus" aria-hidden="true"></i></span> -->
            <!-- <span style="position:absolute; right:1.5em;" class="icon is-small"> -->
              <i class="fas fa-angle-up" aria-hidden="true"></i>
            <!-- </span> -->
          </button>
        </div>
        <div style="margin-left: -11.59em; width: 13.95em" class="dropdown-menu" id="dropdown-menu" role="menu">
          <div class="dropdown-content">
            <ng-container *ngFor="let type of itemTypes">
              <a  *ngIf="!itemBankCtrl.isCreateItemTypeDisabled(type.id)" class="dropdown-item" (click)="itemBankCtrl.createNewQuestion(type.id); dropdown.hideAllDropdowns();">
                <div class="icon"><i class="fa" [ngClass]="type.icon" aria-hidden="true"></i></div>
                New {{type.caption}} 
              </a> 
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div style="position:relative;" *ngIf="itemEditCtrl.isProtected.value">
    <button  class="button is-small is-fullwidth"><tra slug="ie_load_secure_data"></tra></button>
    <input type="file" #secureDataUpload style="opacity:0;position:absolute; top:0px; bottom:0px; left:0px; right:0px;">
  </div>
</div>
</div>
<div
class="panel-preview" 
cdkScrollable
[class.panel-preview-split-view]="isLangSplitView"
[class.is-condensed]="isRightPanelExpanded()"
>
<div *ngIf="itemBankCtrl.getCurrQTrackChanges() && suggestionsEnabled()" class="edit-view-mode-selector">
  <button (click)="itemBankCtrl.setEditViewMode(EditViewMode.BEFORE)" class="button is-small has-icon" [class.is-info]="itemBankCtrl.editViewMode === EditViewMode.BEFORE"><i style="margin-top: 0.1em;" class="fas fa-caret-square-left"></i>&nbsp;Before</button>
  <button (click)="itemBankCtrl.setEditViewMode(EditViewMode.AFTER)" class="button is-small has-icon" [class.is-info]="itemBankCtrl.editViewMode === EditViewMode.AFTER" style="margin-right: 0">After&nbsp;<i style="margin-top: 0.1em;" class="fas fa-caret-square-right"></i></button>
</div>
<div class="panel-preview-container" *ngIf="saveLoadCtrl.isLoadingQuestionVersion">
  <div class="revision-load-message">
    <p><tra slug="ie_loading_revision"></tra> ({{saveLoadCtrl.isLoadingVersionId}})...</p>
  </div>
</div>
<div *ngIf="saveLoadCtrl.isInspecting && !saveLoadCtrl.isLoadingQuestionVersion " class="revision-view-message">
  <tra slug="ie_inspecting_version"></tra> {{saveLoadCtrl.currentQuestionRevisions?.restoredToId}}
</div>
<div class="panel-preview-container" *ngIf="itemBankCtrl.currentQuestion && !saveLoadCtrl.isLoadingQuestionVersion" [class.is-hi-contrast]="isHighContrast">
  <div class="question-container" [ngStyle]="{'font-size': previewCtrl.questionTextSize.value+'em'}">
    <question-runner
      *ngIf="!isLangSplitView" 
      [currentQuestion]="getCurrQStateContent()" 
      [currentQuestionMeta]="getCurrentQuestionMeta()"
      [questionState]="itemBankCtrl.getAuthQuestionState()"
      [isSubmitted]="itemEditCtrl.isLocked"
      [isPrintMode]="printViewCtrl.isResultsPrint"
      [selectedEditEntry]="itemComponentEdit.selectedEntry"
    ></question-runner>
    <div *ngIf="isLangSplitView" class="splitLangView">

      <div class="lang-btn-container-split-view">
        <div>
          <button *ngIf="itemBankCtrl.isLangEnabled('fr')"  class="button is-small " (click)="setLangSplitView(false)" >
            <i class="fa fa-times"></i>
          </button>
          <button *ngIf="itemBankCtrl.isLangEnabled('en')" class="button is-small " (click)="previewCtrl.setLang('en')" [class.is-info]="itemBankCtrl.isLang('en')">
            <span>EN</span>
          </button>
        </div>
        <div>
         <button *ngIf="itemBankCtrl.isLangEnabled('fr')"  class="button is-small " (click)="previewCtrl.setLang('fr')" [class.is-info]="itemBankCtrl.isLang('fr')">
            <span>FR</span>
          </button>
        </div>
      </div>
      <div class="divider-container">
        <div class="divider">
          <div class="label">English</div>
          <div class="input-container">
            <input type="range" min="10" max="200" [(ngModel)]="splitScreenZoom.left">
            <i class="fas fa-search"></i>
          </div>
          
        </div>
        <div class="divider">
          <div class="label">French</div>
          <div class="input-container">
            <input type="range" min = '10' max = '200' [(ngModel)]="splitScreenZoom.right">
            <i class="fas fa-search"></i>
          </div>
          
        </div>
      </div>
      <div class="question-container-split-view">
        <div class="question-runner-left"  >
          <span [style.zoom]="getZoomFactor(splitScreenZoom.left)">
            <question-runner-lang-locked
            langCode="en"
            [currentQuestion]="getCurrQEnglish()" 
            [questionState]="lang.c() === LanguagesAvailable.en? itemBankCtrl.getAuthQuestionState(): {}"
            [isSubmitted]="itemEditCtrl.isLocked"
            [isPrintMode]="printViewCtrl.isResultsPrint"
            [selectedEditEntry]="itemComponentEdit.selectedEntry"
            ></question-runner-lang-locked>
          </span>
        </div>
      <div class="question-runner-right">
        <span [style.zoom]="getZoomFactor(splitScreenZoom.right)">
          <question-runner-lang-locked
            langCode="fr"
            [currentQuestion]="getCurrQFrench()" 
            [questionState]="lang.c() === 'fr'? itemBankCtrl.getAuthQuestionState(): {}"
            [isSubmitted]="itemEditCtrl.isLocked"
            [isPrintMode]="printViewCtrl.isResultsPrint"
            [selectedEditEntry]="itemComponentEdit.selectedEntry"
          ></question-runner-lang-locked>
        </span>
      </div>
      </div>
    </div>
  </div>
  <div class="question-config">
    <ng-container *ngIf="!currentQuestionIsSequence()">
      <div class="zoom-settings">
        <div>
          <button class="button is-small is-info  has-icon" (click)="itemEditCtrl.toggleQuestionLock()" [class.is-outlined]="!itemEditCtrl.isLocked" style="margin-right:0.5em;">
            <span class="icon"><i class="fa" [class.fa-lock]="!itemEditCtrl.isLocked" [class.fa-unlock]="itemEditCtrl.isLocked"></i></span>
            <span>{{!itemEditCtrl.isLocked ? lang.tra('auth_simulate_submission') : lang.tra('auth_undo_submission')}}</span>
          </button>
          <button class="button is-small is-info has-icon" [class.is-outlined]="!isTextSpeechActive()" (click)="toggleTextToSpeech()" style="margin-right:0.5em;">
            <span class="icon"><i class="fa" [class.fa-circle]="!isTextSpeechActive()" [class.fa-check-circle]="isTextSpeechActive()"></i></span>
            <span><tra slug="ie_text_to_speech"></tra></span>
          </button>
          <button class="button is-small is-info has-icon" [class.is-outlined]="!isOnlineOrPaperActive()" (click)="toggleOnlineOrPaper()" style="margin-right:0.5em;">
            <span class="icon"><i class="fa" [class.fa-circle]="!isOnlineOrPaperActive()" [class.fa-check-circle]="isOnlineOrPaperActive()"></i></span>
            <span><tra slug="ie_online_or_paper"></tra></span>
          </button>
          <button class="button is-small is-info has-icon" [class.is-outlined]="!printViewCtrl.isResultsPrint" (click)="printViewCtrl.isResultsPrint = !printViewCtrl.isResultsPrint;" style="margin-right:1em">
            <span class="icon"><i class="fa" [class.fa-print]="!printViewCtrl.isResultsPrint" [class.fa-arrow-left]="printViewCtrl.isResultsPrint"></i></span>
            <span>{{!printViewCtrl.isResultsPrint ? lang.tra('auth_results_print_mode') : lang.tra('auth_leave_results_print_mode')}}</span>
          </button>
          <button class="button is-small is-info has-icon" [class.is-outlined]="!isHighContrast" (click)="toggleHiContrast()" style="margin-right:1em">
            <span><tra slug="btn_hi_contrast"></tra></span>
          </button>
        </div>
        <div>
          <tra slug="ie_zoom"></tra>
          <!-- <div [class.is-disabled]="isReadOnly()" class="select is-small"> -->
          <div class="select is-small">
            <select [formControl]="previewCtrl.questionTextSize" (change)="previewCtrl.updateDragZoomCorrection()">
              <option value="0.5">50%</option>
              <option value="0.75">75%</option>
              <option value="1.0">100%</option>
              <option value="1.25">125%</option>
              <option value="1.5">150%</option>
              <option value="1.75">175%</option>
              <option value="2.0">200%</option>
            </select>
          </div>
        </div>
      </div>

      <div class="submission-state-preview" *ngIf="itemEditCtrl.isLocked">
        <div *ngIf="itemEditCtrl.simulateSubmissionRecord">
          <div *ngIf="checkIsSplitData()">
            <div><strong><tra slug="tr_results_score"></tra></strong> {{itemEditCtrl.simulateSubmissionRecord.score}} / {{itemEditCtrl.simulateSubmissionRecord.weight}}</div>
            <div><strong>Formatted Response:</strong></div>

            <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 raw-response-table">
              <ng-container matColumnDef="response_ID">
                <th mat-header-cell *matHeaderCellDef class="raw-response-table-header">response ID</th>
                <td mat-cell *matCellDef="let data"> {{data.response_id}} </td>
              </ng-container>
              <ng-container matColumnDef="response">
                <th mat-header-cell *matHeaderCellDef class="raw-response-table-header">response</th>
                <td mat-cell *matCellDef="let data"> {{data.response}} </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let rows; columns: displayedColumns;"></tr>
            </table>
            
          </div>
          <div *ngIf="!checkIsSplitData()">
            <div><strong><tra slug="tr_results_score"></tra></strong> {{itemEditCtrl.simulateSubmissionRecord.score}} / {{itemEditCtrl.simulateSubmissionRecord.weight}}</div>
            <div><strong>Formatted Response:</strong> {{itemEditCtrl.simulateSubmissionRecord.formatted_response}}</div>
          </div>
        </div>
        <div *ngIf="itemEditCtrl.expectedAnswers">
          <div *ngIf="checkIsSplitData()">
            <widget-expected-answers 
              [expectedAnswers]="getExpectedAnswersTableSplitData()" 
              [itemId]="itemBankCtrl.currentQuestion.id"
              [isSplitData]="checkIsSplitData()">
            </widget-expected-answers>
          </div>
          <div *ngIf="!checkIsSplitData()">
            <widget-expected-answers 
              [expectedAnswers]="itemEditCtrl.expectedAnswers" 
              [itemId]="itemBankCtrl.currentQuestion.id"
              [itemBankCtrl]="itemBankCtrl"
              (expectedAnsValidated)="itemEditCtrl.getAllExpAnswer()"
              >
            </widget-expected-answers>
          </div>
        </div>
        <h3>Response State</h3> 
        <!-- <ngx-json-viewer [json]="itemBankCtrl.getCurrentQuestionContent()" ></ngx-json-viewer> -->
        <ngx-json-viewer [json]="itemBankCtrl.activeQuestionState" ></ngx-json-viewer>
      </div>
      <div *ngIf="frameworkCtrl.asmtFmrk">
        <div (click)="frameworkCtrl.isParamDetailExpanded = !frameworkCtrl.isParamDetailExpanded" style="cursor: pointer;display: flex; flex-direction: row; justify-content: space-between;">
        <h3><tra slug="ie_item_parameters"></tra></h3>
        <span>
          <i *ngIf="!frameworkCtrl.isParamDetailExpanded" class="fa fa-chevron-right" aria-hidden="true"></i>
          <i *ngIf="frameworkCtrl.isParamDetailExpanded" class="fa fa-chevron-down" aria-hidden="true"></i>
        </span>
      </div>
      <table *ngIf="frameworkCtrl.isParamDetailExpanded" class="table is-narrow">
        <tr *ngFor="let param of frameworkCtrl.asmtFmrk.primaryDimensions" [style.display]="getParamDisplay(param)" >
          <th [ngStyle]="{'background-color':frameworkCtrl.parseParamColor(param.color)}">{{param.code}}</th>
          <td [ngStyle]="{'background-color':frameworkCtrl.parseParamColor(param.color)}">{{param.name}}</td>
          <td [ngStyle]="{'background-color':frameworkCtrl.parseParamColor(param.color)}">
            <framework-dimension-input 
            [frameworkCtrl]="frameworkCtrl"
            [questionScoringInfo]="itemBankCtrl.getQuestionScoringInfo(itemBankCtrl.currentQuestion.id)"
            [param]="param" 
            [question]="itemBankCtrl.currentQuestion" 
            [questionImpressions]="itemBankCtrl.questionImpressions"
            [changeCounter]="itemBankCtrl.currentQuestion.__changeCounter"
            [isFullwidth]="true" 
            [enabled]="true"
            ></framework-dimension-input>
          </td>
        </tr>
        <tr *ngFor="let param of frameworkCtrl.asmtFmrk.secondaryDimensions" [style.display]="getParamDisplay(param)">
          <th [ngStyle]="{'background-color':frameworkCtrl.parseParamColor(param.color)}">{{param.code}}</th>
          <td [ngStyle]="{'background-color':frameworkCtrl.parseParamColor(param.color)}">{{param.name}}</td>
          <td [ngStyle]="{'background-color':frameworkCtrl.parseParamColor(param.color)}">
            <framework-dimension-input 
            [frameworkCtrl]="frameworkCtrl"
            [questionScoringInfo]="itemBankCtrl.getQuestionScoringInfo(itemBankCtrl.currentQuestion.id)"
            [param]="param" 
            [question]="itemBankCtrl.currentQuestion" 
            [questionImpressions]="itemBankCtrl.questionImpressions"
            [changeCounter]="itemBankCtrl.currentQuestion.__changeCounter"
            [isFullwidth]="true" 
            [enabled]="true"
            ></framework-dimension-input></td>
          </tr>
      </table>
    </div>
  </ng-container>
    </div>
  </div>
</div>
<div 
  class="panel-editor" 
  cdkScrollable
  [class.is-expanded]="isRightPanelExpanded()"
>
<div *ngIf="itemBankCtrl.isFocusView">
  <div style="position:absolute; top:0px; left:0px; padding:1em; cursor: pointer;" (click)="itemBankCtrl.toggleFocusView(false)">
    <i *ngIf="!isRightPanelExpanded()" class="fa fa-chevron-left"></i>
    <i *ngIf="isRightPanelExpanded()" class="fa fa-chevron-right"></i>
  </div>
</div>
<div class="navigation-panel-container" [class.is-wider]="lang.c() === 'fr'" >
  <div class="navigation-panel" *ngIf="itemBankCtrl.isFocusView">
    <button class="toolbar-icon navigation-icon left-nav-button" (click)="gotoPrevQuestion()">
        <div class="nav-button-div">
            <i class="fas fa-arrow-left"></i> 
            <tra slug="btn_prev_bc"></tra>
        </div>
    </button>
    <button class="toolbar-icon navigation-icon" (click)="gotoNextQuestion()">
        <div class="nav-button-div">
            <i class="fas fa-arrow-right"></i> 
            <tra slug="btn_next_bc"></tra>
        </div>
    </button>
  </div>
</div>
<div *ngIf="!itemBankCtrl.isFocusView">
<div style="position:absolute; top:0px; left:0px; padding:1em; cursor: pointer;" (click)="toggleRightPanelExpanded()">
  <i *ngIf="!isRightPanelExpanded()" class="fa fa-chevron-left"></i>
  <i *ngIf="isRightPanelExpanded()" class="fa fa-chevron-right"></i>
</div>
<mode-toggle [itemBankCtrl]="itemBankCtrl" [saveLoadCtrl]="saveLoadCtrl" [frameworkCtrl]="frameworkCtrl"></mode-toggle>

<div class="mode-toggle"  *ngIf="itemBankCtrl.personalEditorSettings['isPsychometricsEnabled'] && saveLoadCtrl.isInspecting">
  <span class="mode-toggle-option is-active">
    <tra slug="ie_inspecting"></tra>
  </span>
</div>

<div *ngIf="saveLoadCtrl.isLoading">
  <tra slug="ie_loading"></tra>
</div>

<div *ngIf="!saveLoadCtrl.isLoading">
  
  <!-- *ngIf="false" -->
  <div style="text-align:right;" *ngIf="false" >
    <button class="button is-white" (click)="showAdvancedSettings=!showAdvancedSettings">
      <i class="fa fa-cog" aria-hidden="true"></i>
    </button>
  </div>

  <div class="notification" *ngIf="showAdvancedSettings" style="margin-bottom:1em;">
    <div>
      <span class="tag">
        <strong><tra slug="ie_question_task_id"></tra>: </strong>
        <span>{{itemBankCtrl.currentQuestion.taskId}}</span>
      </span>
    </div>
    <h4><tra slug="ie_advanced_settings"></tra></h4>
    <div *ngFor="let setting of advancedSettings" class="advanced-setting-option">
      <checkbox [state]="itemBankCtrl.personalEditorSettings[setting.prop]" [caption]="setting.caption" ></checkbox>
      <!-- (toggle)="setPersonalEditorSetting(setting.prop, $event)" -->
    </div>
  </div>
</div>

<div 
  id="info-auth-item-set"
  *ngIf="!saveLoadCtrl.isLoading && !itemBankCtrl.isEditing" 
  style="color:#888; border-bottom: 1px solid #ccc; padding-bottom: 0.5em; margin-bottom: 0.5em;"
>
  <div class="simple-field" >
    <div class="simple-field-label"> <tra slug="lbl_assessment"></tra>: </div>
    <div  *ngIf="!isEditingSetName" style="margin-right:1em;"> {{itemBankCtrl.currentSetName.value}}</div>
    <button [disabled]="isReadOnly(true)" class="button is-small" (click)="itemBankCtrl.itemBankInfoEditStart()">
      <i class="fa fa-cog" aria-hidden="true"></i>
    </button>
    <div  *ngIf="false && !isEditingSetName && !itemBankCtrl.isContentEditingDisabled()"> <button (click)="isEditingSetName = true"  class="button is-small">Edit</button> </div>
    <input  *ngIf="isEditingSetName"  class="input" type="text" [formControl]="itemBankCtrl.currentSetName">
    <button *ngIf="isEditingSetName" (click)="isEditingSetName = false" class="button is-small">Save</button>
  </div>
  <div *ngIf="itemBankCtrl.itemBankEditForm && !isReadOnly(true)">
    <table>
      <tr>
        <td><tra slug="ie_name"></tra></td>
        <td>
          <input class="input" type="text" [formControl]="itemBankCtrl.itemBankEditForm.name" />
        </td>
      </tr>
      <tr>
        <td><tra slug="test_auth_title_description"></tra></td>
        <td>
          <textarea class="textarea" [formControl]="itemBankCtrl.itemBankEditForm.description"></textarea>
        </td>
      </tr>
      <tr>
        <td><tra slug="ie_item_bank_languages"></tra></td>
        <td>
          <div *ngFor="let language of itemBankCtrl.itemBankEditForm.languages"> 
            <input type="checkbox" [formControl]="language.fc" style="margin-right:0.5em;">
            <span>{{language.caption}}</span>
          </div>
        </td>
      </tr>
      <tr>
        <td>Archived</td> <!--TODO ADD LANGUAGE SLUG-->
        <td>
          <label style="display: flex">
            <input type="checkbox" style="justify-self:center; margin-right: 0.3em" [disabled]="isLoadingArchived" [(ngModel)]="isViewingArchived" (click)="retrieveArchivedItems()">
            view?
          </label>
        </td>
      </tr>
      <tr>
        <td><tra slug="ie_item_bank_style_profile"></tra></td>
        <td>
          <fieldset [disabled]="isReadOnly(true)">
            <div class="control is-expanded">
              <div [class.no-pointer-events]="isReadOnly(true)" class="select is-fullwidth">
                <select [formControl]="itemBankCtrl.styleProfileSelector" >
                  <option *ngFor="let option of profile.getProfileOptions()" [value]="option.slug">
                    <tra-md [slug]="option.caption"></tra-md>
                  </option>
                </select>
              </div>
            </div>
          </fieldset>
        </td>
      </tr>
    </table>
    <div class="buttons">
      <button [disabled]="saveLoadCtrl.isSavingItemSet" (click)="itemBankCtrl.itemBankInfoEditSave()" class="button"><tra slug="btn_save"></tra></button>
      <button [disabled]="saveLoadCtrl.isSavingItemSet" (click)="itemBankCtrl.itemBankInfoEditClose()" class="button is-danger"><tra slug="ie_cancel"></tra></button>
    </div>
  </div>
</div>
<div id="panel-auth-item-config"
  *ngIf="itemBankCtrl.currentQuestion && !itemBankCtrl.isEditing"
>

<div class="item-status">
  <div *ngIf="itemBankCtrl.getNumPendingGraphicReqs()" class="item-status-label">
    <i class="fa fa-images fa-light pending-grpahics-icon" aria-hidden="true"></i>
    <div class="status-text">
      <tra slug="auth_graphic_req_pending"></tra>
    </div>
  </div>
</div>

  <div 
    style="display: flex; flex-direction: row; justify-content: space-between;"
  >
    <div class="simple-field" style="align-items: center;">
      <div class="simple-field-label" style="width: auto; margin-right:0.5em;">
        <tra [slug]="getCurrentQuestionIDSlug()"></tra>:
      </div>
      <code>{{itemBankCtrl.currentQuestion.id}}</code>
    </div>
    <div *ngIf="!isViewingArchived">
      <button class="button is-small" (click)="gotoPrevQuestion()">Prev</button>
      <button class="button is-small" (click)="gotoNextQuestion()">Next</button>
    </div>
  </div>
  <div class="simple-field" style="align-items: center;">
    <div class="simple-field-label">
      <tra [slug]="getCurrentQuestionLabelSlug()"></tra>
    </div>
    <div class="control" *ngIf="itemBankCtrl.isContentEditingDisabled()">
      {{itemBankCtrl.currentQuestionLabel.value}}
    </div>
    <div *ngIf="!itemBankCtrl.isContentEditingDisabled()">
      <fieldset [disabled]="isReadOnly() || itemBankCtrl.getCurrQTrackChanges()">
        <input *ngIf="!itemBankCtrl.currentQuestion.isInfoSlide" class="input" type="text" maxlength="20" style="width:15em; text-align:center" [formControl]="itemBankCtrl.currentQuestionLabel" [disabled]="true">
        <input *ngIf="itemBankCtrl.currentQuestion.isInfoSlide" class="input" type="text" maxlength="20" style="width:15em;" [formControl]="itemBankCtrl.currentQuestionLabel" [disabled]="true">
      </fieldset>
    </div>
    <ng-container *ngIf="!currentQuestionIsSequence()">
      <button class="button" (click)="saveLoadCtrl.loadCurrentQuestionRevisions()" [disabled]="saveLoadCtrl.isLoadingRevisions || itemBankCtrl.getCurrQTrackChanges()">
        <i class="fa fa-history" aria-hidden="true"></i>
      </button>
    </ng-container>
  </div>
  <ng-container *ngIf="!currentQuestionIsSequence()">
    <div *ngIf="saveLoadCtrl.isRevisionsOpen && saveLoadCtrl.currentQuestionRevisions?.displayList" style="max-height:20em; overflow:auto;">
      <strong><tra slug="ie_revision_history"></tra></strong>
      <div>
        <label class="revision-lang-filter">
          <input type="checkbox" (change)="handleCheckboxRevisionFilterChange($event, 'en')">
          EN
        </label>
        <label class="revision-lang-filter">
          <input type="checkbox" (change)="handleCheckboxRevisionFilterChange($event, 'fr')">
          FR
        </label>
      </div>
      <table class="table is-hoverable is-narrow is-bordered" *ngIf="saveLoadCtrl.currentQuestionRevisions && saveLoadCtrl.currentQuestionRevisions.displayList">
        <ng-container *ngFor="let revision of saveLoadCtrl.currentQuestionRevisions.displayList">
          <tr *ngIf="checkIfRevisionIsFiltered(revision.affected_langs, revision.createdOnDate)">
            <td> {{revision.createdOnDate }} </td>
            <td title="{{revision.question_label}}"> 
              <div>
                {{revision.createdOnTime }}
                <p *ngIf="revision.affected_langs && revision.affected_langs?.length > 0" style="color:#888">{{parseRevisionAffectedLang(revision.affected_langs)}}</p>
              </div>
            </td>
            <td> by <a href="mailto:{{revision.contact_email}}">{{revision.first_name }}</a>  
              <ng-container *ngIf="revision.message">
                <i style="margin-left:0.35em; cursor: pointer;" [tooltip]="revision.message" class="fas fa-comment-dots"></i>
              </ng-container>
              <ng-container *ngIf="!revision.message && isOwnRevision(revision)">
                <i style="margin-left:0.35em; cursor: pointer; color: rgb(92, 199, 92)" class="fas fa-comment-medical" (click)="writeRevisionMessage(revision)"></i>
              </ng-container><br>
              <p>id: {{revision.id}}</p> 
            </td>
            <td>
              <button 
                (click)="saveLoadCtrl.loadQuestionRevision(revision.id, true)" 
                class="button is-small"
                [class.is-warning]="saveLoadCtrl.currentQuestionRevisions.restoringToId == revision.id"
                [class.is-info]="saveLoadCtrl.currentQuestionRevisions.restoredToId == revision.id"
              >
                <tra slug="ie_inspect"></tra>
              </button>
            </td>
          </tr>
          <tr *ngIf="revision.message" >
            <td colspan=4 style="background-color: #f1f1f1; font-size: 0.9em;">
              <tra-md [slug]="revision.message"></tra-md>
            </td>
          </tr>
        </ng-container>
      </table>
    </div>
  </ng-container>
  
  
  
  <div 
  id="btns-auth-item-locks"
  style="display:flex; justify-content: flex-end; border-bottom: 1px dotted #ccc; padding-top: 0.5em; padding-bottom: 0.5em; margin-bottom: 0.5em;"
  >
  <button
  id="btn-auth-manual-edit-toggle"
  *ngIf="isManualEditApplicable() && !isViewingArchived"
  class= "button is-small" 
  [disabled]="isSaving() || isReadOnly(false, true)"
  [class.is-info]="isEditActivationPending()"
  [class.is-success]="!isEditActivationPending()"
  style="flex-grow: 1;"
  (click)="toggleManualEdit()"
  >
  <span *ngIf="isEditActivationPending()">Edit Item</span>
  <span *ngIf="!isEditActivationPending()">Save Item</span>
</button>
<button
*ngIf="isViewingArchived"
class = 'button is-small is-info'
style = 'flex-grow: 1;  pointer-events: auto;'
[style.background-color]="'rgba(41, 124, 179, 1)'"
(click)="recoverQuestion()"
>
<span>Recover Item</span>

</button>
    <button 
      id="btn-auth-item-lock-toggle"
      *ngIf="itemBankCtrl.isQLockAvail()" 
      [disabled]="itemBankCtrl.getCurrQTrackChanges()" 
      class="button is-small" 
      style="width: 3em" 
      (click)="itemBankCtrl.toggleCurrentQuestionLock()"
    >
      <i 
        class="fas" 
        [class.fa-lock-open]="!itemBankCtrl.getCurrentQuestionContent().isLocked" 
        [class.fa-lock]="itemBankCtrl.getCurrentQuestionContent().isLocked"
      ></i>
    </button>
  </div>
  <div *ngIf="itemBankCtrl.getCurrQTrackChanges()" class="notification is-warning"><tra slug="ie_item_tracking_changes_warning"></tra></div>
  <div 
    [class.has-text-info]="isEditActivationPending()"
    [class.has-text-danger]="!isEditActivationPending()"
  >
    <small>Last save: {{getCurrentQuestionUpdatedOn()}}</small>
  </div>
  
  <ng-container *ngIf="!isViewingArchived">
    <comment-section [memberAssignmentCtrl]="memberAssignmentCtrl" [itemBankCtrl]="itemBankCtrl"></comment-section>
    <graphics-workflow-section [memberAssignmentCtrl]="memberAssignmentCtrl" *ngIf="!currentQuestionIsSequence()" [itemBankCtrl]="itemBankCtrl"></graphics-workflow-section>
    <ng-container *ngIf="!currentQuestionIsSequence()">
      <twiddle [state]="itemPropsTwiddle" caption="auth_item_settings"></twiddle>
      <div *ngIf="itemPropsTwiddle.value">
    
        <fieldset [disabled]="isReadOnly() || itemBankCtrl.getCurrQTrackChanges()">
          <div class="simple-field read-sels">
            <div class="simple-field-label">
              <tra slug="auth_associated_texts"></tra>
            </div>
            <div>
              <div style="display: flex; align-items: center; margin-bottom: 0.25em" *ngFor="let readSel of itemBankCtrl.getCurrentReadingSelections(); let i = index">
                {{readSel}}
                <button [disabled]="!canDeleteReadSel(i)" class="button is-small" style="margin-left: 0.5em" (click)="itemEditCtrl.deleteReadSelection(i)">
                  <tra slug="ie_delete_param"></tra>
                </button>
      
                <button class="button is-small" style="width: 2em; height: 2em; font-size: 0.75em;" (click)="itemEditCtrl.toggleReadSelVisible(readSel)">
                  <i [ngClass]="itemBankCtrl.isCurrentReadSelVisible(readSel) ? 'fas fa-eye' : 'fas fa-eye-slash'"></i>
                </button>
              </div>
              <div style="margin-top:0.5em" class="field has-addons" *ngIf="!itemBankCtrl.isContentEditingDisabled()">
                <div class="control">
                  <input 
                    class="input is-small" 
                    type="text" 
                    maxlength="20" 
                    style="max-width:10em; text-align:center" 
                    [formControl]="itemBankCtrl.currentQuestionReadSel"
                  >
                </div>
                <div class="control">
                  <button (click)="itemEditCtrl.addSelection()" class="button is-small ">
                    <tra slug="auth_add_reading_passage"></tra>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </fieldset>
  
        <fieldset [disabled]="isReadOnly() || itemBankCtrl.getCurrQTrackChanges()">
          <div class="simple-field" style="margin-bottom:1em;">
            <div class="simple-field-label">
              <tra slug="auth_caption"></tra>
            </div>
            <div class="control">
              <input type="text" [disabled]="itemBankCtrl.isContentEditingDisabled()" [(ngModel)]="itemBankCtrl.getCurrentQuestionContent().caption">
            </div>
          </div>
          <div class="simple-field" style="margin-bottom:1em;">
            <div class="simple-field-label">
              <tra slug="auth_points"></tra>
            </div>
            <div class="control">
              <input type="text" [disabled]="itemBankCtrl.isContentEditingDisabled()" [(ngModel)]="itemBankCtrl.getCurrentQuestionContent().points">
            </div>
          </div>
          <div class="simple-field" style="margin-bottom:1em;">
            <div class="simple-field-label">
              <tra slug="auth_hide_points"></tra>
            </div>
            <div class="control">
              <input type="checkbox" [disabled]="itemBankCtrl.isContentEditingDisabled()" [(ngModel)]="itemBankCtrl.getCurrentQuestionContent().ishidePoints">
            </div>
          </div>
          <div class="simple-field" style="margin-bottom:1em;">
            <div class="simple-field-label">
              <tra slug="auth_require_completion"></tra>
            </div>
            <div class="control">
              <input type="checkbox" [disabled]="itemBankCtrl.isContentEditingDisabled()" [(ngModel)]="itemBankCtrl.getCurrentQuestionContent().isReqFill">
              <input 
                *ngIf="itemBankCtrl.getCurrentQuestionContent().isReqFill"
                type="text" [disabled]="itemBankCtrl.isContentEditingDisabled()" 
                [(ngModel)]="itemBankCtrl.getCurrentQuestionContent().reqFillMsg"
              >
              <div tooltip="comma separated">
                <small><tra slug="auth_specific_entry_ids"></tra> </small>
                <input type="text" placeholder="commas sep" style="width:6em;" [disabled]="itemBankCtrl.isContentEditingDisabled()" [(ngModel)]="itemBankCtrl.getCurrentQuestionContent().reqFillEntries">
              </div>
            </div>
          </div>
          <div class="simple-field" style="margin-bottom:1em;">
            <div class="simple-field-label">
              <tra slug="auth_reading_selection"></tra>
            </div>
            <div class="control">
              <input type="checkbox" [disabled]="itemBankCtrl.isContentEditingDisabled()" [(ngModel)]="itemBankCtrl.getCurrentQuestionContent().isReadingSelectionPage">
            </div>
          </div>
          <div class="simple-field" style="margin-bottom:1em;">
            <div class="simple-field-label">
              <tra slug="auth_reading_selection_caption"></tra>
            </div>
            <div class="control">
              <input type="text" [disabled]="!itemBankCtrl.getCurrentQuestionContent().isReadingSelectionPage" [(ngModel)]="itemBankCtrl.getCurrentQuestionContent().readingSelectionCaption">
            </div>
          </div>
          <div class="simple-field" style="margin-bottom:1em;">
            <div class="simple-field-label">
              <tra slug="auth_use_reading_selection_list"></tra>
            </div>
            <div class="control">
              <input type="checkbox" [disabled]="itemBankCtrl.isContentEditingDisabled()" [(ngModel)]="itemBankCtrl.getCurrentQuestionContent().isReadingSelectionsAlwaysShown">
            </div>
          </div>
          <div class="simple-field" style="margin-bottom:1em;">
            <div class="simple-field-label">
              <tra slug="start_split_screen"></tra>
            </div>
            <div class="control">
              <input type="checkbox" [disabled]="itemBankCtrl.isContentEditingDisabled()" [(ngModel)]="itemBankCtrl.getCurrentQuestionContent().isStartHalf">
            </div>
          </div>
          <div class="simple-field" style="margin-bottom:1em;">
            <div class="simple-field-label">
              <tra slug="is Questionnaire?"></tra>
            </div>
            <div class="control">
              <input type="checkbox" [disabled]="itemBankCtrl.isContentEditingDisabled()" [(ngModel)]="itemBankCtrl.getCurrentQuestionContent().isQuestionnaire">
            </div>
          </div>
          <div style="margin-left: 1.5em">
            <twiddle [state]="scoringItemPropsTwiddle" caption="Scoring"></twiddle>
            <ng-container *ngIf="scoringItemPropsTwiddle.value">
              <widget-item-scoring-info [itemBankCtrl]="itemBankCtrl" [frameworkCtrl]="frameworkCtrl"></widget-item-scoring-info>
            </ng-container>
          </div>
          <div style="margin-left: 1.5em">
            <twiddle [state]="advancedItemPropsTwiddle" caption="auth_adv_item_settings"></twiddle>
            <ng-container *ngIf="advancedItemPropsTwiddle.value">
              <div class="simple-field">
                <div class="simple-field-label">
                  Background image
                </div>
                <capture-image [element]="itemBankCtrl.getCurrentQuestionContent().backgroundImage"></capture-image>
              </div>
              <div class="simple-field">
                <div class="simple-field-label">
                  Banner image
                </div>
                <capture-image [element]="itemBankCtrl.getCurrentQuestionContent().bannerImage"></capture-image>
              </div>
              <div class="simple-field">
                <div class="simple-field-label">
                  Banner overlay
                </div>
                <capture-image [element]="itemBankCtrl.getCurrentQuestionContent().bannerOverlay"></capture-image>
              </div>
              <div class="simple-field">
                <div class="simple-field-label">
                  Banner subtitle
                </div>
                <div style="display: flex; flex-direction: column">
                  <element-config-text [element]="itemBankCtrl.getCurrentQuestionContent().bannerSubtitle"></element-config-text>
                  <capture-voice 
                    [element]="getElVoice(itemBankCtrl.getCurrentQuestionContent().bannerSubtitle)" 
                    [scriptChanges]="getVoiceChange(itemBankCtrl.getCurrentQuestionContent().bannerSubtitle)"
                    title="Banner Subtitle Voice-over"
                    [isDisabled]="isReadOnly()"
                    [triggerMagic]="autoGenElScriptFn(itemBankCtrl.getCurrentQuestionContent().bannerSubtitle)"
                  ></capture-voice>
                </div>
              </div>
              <div *ngIf="itemBankCtrl.getCurrentQuestionContent().bannerSubtitle" class="simple-field">
                <div class="simple-field-label">
                  Subtitle y-offset
                </div>
                <input type="number" [(ngModel)]="itemBankCtrl.getCurrentQuestionContent().bannerSubtitleMarginBottom">
              </div>
              <div class="simple-field">
                <div class="simple-field-label">
                  Banner title
                </div>
                <div style="display: flex; flex-direction: column">
                  <element-config-text [element]="itemBankCtrl.getCurrentQuestionContent().bannerTitle"></element-config-text>
                  <capture-voice 
                    [element]="getElVoice(itemBankCtrl.getCurrentQuestionContent().bannerTitle)" 
                    [scriptChanges]="getVoiceChange(itemBankCtrl.getCurrentQuestionContent().bannerTitle)"
                    title="Banner Title Voice-over"
                    [triggerMagic]="autoGenElScriptFn(itemBankCtrl.getCurrentQuestionContent().bannerTitle)"
                  ></capture-voice>
                </div>
              </div>
              <div class="simple-field">
                <div class="simple-field-label">
                  Banner HR
                </div>
                <div class="control checkbox">
                  <input type="checkbox" [(ngModel)]="itemBankCtrl.getCurrentQuestionContent().showBannerHr">
                </div>
              </div>
              <div *ngIf="itemBankCtrl.getCurrentQuestionContent().showBannerHr" class="simple-field">
                <div class="simple-field-label">
                  Banner HR Color
                </div>
                <input type="color" [(ngModel)]="itemBankCtrl.getCurrentQuestionContent().bannerHrColor">
              </div>
      
              <div class="simple-field">
                <div class="simple-field-label">
                  Use Custom Prev Button
                </div>
                <div class="control checkbox">
                  <input type="checkbox" [(ngModel)]="itemBankCtrl.getCurrentQuestionContent().useCustomPrev">
                </div>
              </div>
      
              <ng-container *ngIf="itemBankCtrl.getCurrentQuestionContent().useCustomPrev">
                <div class="simple-field">
                  <div class="simple-field-label">
                    Prev Button Background Colour
                  </div>
                  <input type="color" [(ngModel)]="itemBankCtrl.getCurrentQuestionContent().customPrevBgColor">
                </div>
                <div class="simple-field">
                  <div class="simple-field-label">
                    Prev Button Foreground Colour
                  </div>
                  <input type="color" [(ngModel)]="itemBankCtrl.getCurrentQuestionContent().customPrevFgColor">
                </div>
                <div class="simple-field">
                  <div class="simple-field-label">
                    Prev Button Text
                  </div>
                  <input type="text" [(ngModel)]="itemBankCtrl.getCurrentQuestionContent().customPrevText">
                </div>
                <div class="simple-field">
                  <div class="simple-field-label">
                    Bold prev button text?
                  </div>
                  <div class="control checkbox">
                    <input type="checkbox" [(ngModel)]="itemBankCtrl.getCurrentQuestionContent().customPrevBold"> 
                  </div>
                </div>
              </ng-container>
      
              <div class="simple-field">
                <div class="simple-field-label">
                  Use Custom Next Button
                </div>
                <div class="control checkbox">
                  <input type="checkbox" [(ngModel)]="itemBankCtrl.getCurrentQuestionContent().useCustomNext">
                </div>
              </div>
      
              <ng-container *ngIf="itemBankCtrl.getCurrentQuestionContent().useCustomNext">
                <div class="simple-field">
                  <div class="simple-field-label">
                    Next Button Background Colour
                  </div>
                  <input type="color" [(ngModel)]="itemBankCtrl.getCurrentQuestionContent().customNextBgColor">
                </div>
                <div class="simple-field">
                  <div class="simple-field-label">
                    Next Button Foreground Colour
                  </div>
                  <input type="color" [(ngModel)]="itemBankCtrl.getCurrentQuestionContent().customNextFgColor">
                </div>
                <div class="simple-field">
                  <div class="simple-field-label">
                    Next Button Text
                  </div>
                  <input type="text" [(ngModel)]="itemBankCtrl.getCurrentQuestionContent().customNextText">
                </div>
                <div class="simple-field">
                  <div class="simple-field-label">
                    Bold next button text?
                  </div>
                  <div class="control checkbox">
                    <input type="checkbox" [(ngModel)]="itemBankCtrl.getCurrentQuestionContent().customNextBold"> 
                  </div>
                </div>
              </ng-container>
      
              <ng-container *ngIf="itemBankCtrl.getCurrentQuestionContent().useCustomNext || itemBankCtrl.getCurrentQuestionContent().useCustomPrev">
                <div class="simple-field">
                  <div class="simple-field-label">
                    Custom Buttons Position
                  </div>
                  <div class="select">
                    <select [(ngModel)]="itemBankCtrl.getCurrentQuestionContent().customButtonPos">
                      <option [ngValue]="CustomButtonPos.AFTER_CONTENT">After Content</option>
                      <option [ngValue]="CustomButtonPos.BOT_RIGHT">Bottom Right</option>
                    </select>
                  </div>
                </div>
                <ng-container *ngIf="itemBankCtrl.getCurrentQuestionContent().customButtonPos === CustomButtonPos.AFTER_CONTENT">
                  <div class="simple-field">
                    <div class="simple-field-label">
                      Custom Buttons Indent
                    </div>
                    <input type="number" [(ngModel)]="itemBankCtrl.getCurrentQuestionContent().customButtonIndent">
                  </div>
                </ng-container>
              </ng-container>
            </ng-container>
          </div>
        </fieldset>
      </div>
    </ng-container>
    <ng-container *ngIf="!currentQuestionIsSequence()">  
      <div style="position: relative;">
        <capture-voice 
          *ngIf="!itemEditCtrl.isVoiceUpload"
          [element]="itemBankCtrl.getCurrentQuestionContent().voiceover" 
          [title]="lang.tra('ie_item_voice_over')"
          [isDisabled]="isReadOnly()"
          [triggerMagic]="autoGenQuestionScript"
          [fromItemVoiceOver]="true"
        ></capture-voice>
        <element-config-audio *ngIf="itemEditCtrl.isVoiceUpload" [element]="itemBankCtrl.getCurrentQuestionContent().voiceover"> </element-config-audio>
      
        <fieldset [disabled]="isReadOnly()">
          <button *ngIf="itemEditCtrl.isVoiceUpload" (click)="itemBankCtrl.getCurrentQuestionContent().voiceover.url = undefined">Clear</button>
          <button style="position:absolute; right:0.3em; top:0.3em;" class="button is-small white" (click)="itemEditCtrl.toggleIsVoiceUpload()">
            {{!itemEditCtrl.isVoiceUpload ? lang.tra('auth_upload') : 'Capture'}}
          </button>
        </fieldset>
      </div>
      <div  *ngIf="itemBankCtrl.currentQuestion.isInfoSlide" class="notification is-small is-danger" style="margin-bottom:1em;">
        <tra slug="ie_info_slide_disclaimer"></tra>
      </div>
      <div *ngIf="!itemBankCtrl.isContentEditingDisabled()">
        <div *ngIf="getCurrQStateContent().content.length === 0 && !isReadOnly()">
          <div class="notification is-small is-warning" style="margin-bottom:3em;">
            <tra slug="ie_empty_content_instruction"></tra>
          </div>
        </div>
        <div *ngIf="getCurrQStateContent().content.length > 0">
          <div 
            class="stack-edit-container" 
            cdkDropList
            [cdkDropListDisabled]="isReadOnly()"
            (cdkDropListDropped)="util.drop(getCurrQStateContent().content, $event)"
          >    
            <div 
              *ngFor="let contentElement of getCurrQStateContent().content" 
              cdkDrag 
              class="stack-edit-element"
            >
              <div [class.no-pointer-events]="isReadOnly()" class="stack-edit-element-header" cdkDragHandle>
                  <button [disabled]="isReadOnly()" class="button icon" (click)="contentElement._isCollapsed = !contentElement._isCollapsed">
                    <i class="fa" [ngClass]="getIconByElementTypeId(contentElement.elementType)" aria-hidden="true"></i>
                  </button>
                  <div style="align-items: center; display: flex; flex-wrap: wrap; justify-content: flex-start;">
                    <button [disabled]="isReadOnly()" (click)="itemEditCtrl.frameElement(getCurrQStateContent().content, contentElement)" class="button is-small" style="font-size:0.6em"><i class="fas fa-window-restore" aria-hidden="true"></i></button>
                    <button [disabled]="isReadOnly()" (click)="itemEditCtrl.openElementImportExport(contentElement)" class="button is-small" style="font-size:0.6em"><i class="fa fa-upload" aria-hidden="true"></i></button>
                    <button [disabled]="isReadOnly()" [class.no-pointer-events]="isReadOnly()" aria-label="close" class="delete" (click)="util.removeElement(getCurrQStateContent().content, contentElement)"></button>
                  </div>
              </div>
              <div class="stack-edit-element-config" *ngIf="!contentElement._isCollapsed && !(itemEditCtrl.isElementImportExportOpen && contentElement===itemEditCtrl.currentElement)">
                <element-config [contentElement] = "contentElement" ></element-config>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="!itemBankCtrl.isContentEditingDisabled()">
        <div class="section-header" *ngIf="getCurrQStateContent().content.length">
          <tra slug="auth_new_block"></tra> 
        </div>
        <div class="element-selector-container">
          <div 
          *ngFor="let elementSelector of elementTypes" 
          class="element-selector" 
          [class.is-disabled]="elementSelector.isDisabled || isReadOnly()"
          [class.is-hidden]="elementSelector.isInteractive && getCurrQStateContent().isInfoSlide" 
          (click)="itemEditCtrl.createNewStackElement(elementSelector.id, elementSelector.isDisabled || isReadOnly())"
          >
            <div class="icon"><i class="fa" [ngClass]="elementSelector.icon" aria-hidden="true"></i></div>
            <div style="text-align:center">{{lang.tra(elementSelector.caption)}}</div>
          </div>
        </div>
      </div>
      <twiddle [state]="twiddleTable" caption="Formatting Guide"></twiddle>
      <div *ngIf="twiddleTable.value">
        <table style="z-index:15">
          <tr>
            <td *ngFor="let col of toolbarEditingColumns" style="font-weight:bold;">
              {{col}}
            </td>
          </tr>
          <tr style="font-size:0.75em" *ngFor="let row of getToolbarCommands()">
            <td *ngFor="let col of [0,1,2]">
              {{row[col]}}
            </td>
            <td>
              <div [innerHtml]="row[3]"></div>
            </td>
          </tr>
        </table>
      </div>
    </ng-container>
    <ng-container *ngIf="!currentQuestionIsSequence()">
      <div class="section-header">
        <tra slug="ie_tags_header"></tra>
      </div>
      <div>
        <input
          #tagInput 
          type="text"
          [placeholder]="lang.tra('ie_search')"
          class="input"
          matInput
          [formControl]="itemBankCtrl.tagFc"
          [matAutocomplete]="auto">
  
        <mat-autocomplete #auto="matAutocomplete">
          <mat-option (click)="addTag(tag)" *ngFor="let tag of itemBankCtrl.filteredTags | async" [value]="tag.caption">
            {{tag.caption}}
          </mat-option>
        </mat-autocomplete>
      </div>
      <div style="display: flex; flex-wrap: wrap">
        <ng-container *ngFor="let tag of itemBankCtrl.getCurrLinkedTags()"> 
            <item-tag (delete)="itemBankCtrl.deleteTag(tag)" [tag]="tag"></item-tag>
        </ng-container>
      </div>
  
      <div *ngIf="false">
        <div class="section-header" >
          Scoring Rubric
        </div>
        <div>
          <strong><tra slug="ie_marking_approach"></tra>: </strong>
          <div [class.is-disabled]="isReadOnly()" class="select">
            <select>
              <option><tra slug="ie_holistic_option"></tra></option>
              <option><tra slug="ie_weighted_option"></tra></option>
              <option><tra slug="ie_qualitative_descriptions"></tra></option>
            </select>
          </div>
        </div>
        <div>
          <strong><tra slug="ie_rubric"></tra>:</strong>
        </div>
        <div>
          <!-- Component: marking-rubric -->
          <!-- Component: marking-rubric-code -->
          <!-- https://stackoverflow.com/questions/34556277/angular2-table-rows-as-component -->
          <table>
            <tr>
              <th><tra slug="ie_code"></tra></th>
              <th><tra slug="ie_description"></tra></th>
              <th><tra slug="ie_exemplars"></tra></th>
            </tr>
            <tr >
              <td>
                <input type="text" class="input">
              </td>
              <td *ngFor="let scoringCode of rubricGrid;">
                <!-- need a form control here -->
                <textarea class="textarea"></textarea>
              </td>
              <td>
                <capture-image></capture-image>
                <div>
                  <button class="button is-fullwidth"><tra slug="ie_include_another_exemplar"></tra></button>
                </div>
              </td>
            </tr>
          </table>
          
        </div>
      </div>
    </ng-container>
    <div class="section-header" >
      <tra slug="ie_notes"></tra>
    </div>
    <div *ngIf="itemBankCtrl.currentQuestion">
      <div style="margin-bottom:0.5em;">
        <fieldset [disabled]="isReadOnly()">
          <capture-upload-files [element]="itemBankCtrl.currentQuestion" prop="uploads" [displayImageControls]="false"></capture-upload-files>
        </fieldset>
        <!-- <a [class.is-disabled]="isReadOnly() || itemBankCtrl.getCurrQTrackChanges()" (click)="itemEditCtrl.insertUpload(itemBankCtrl.currentQuestion)">
          <i class="fa fa-upload" aria-hidden="true"></i>
        <tra slug="ie_upload_files"></tra>
        </a> -->
      </div>
    </div>
    <div>
      <strong>Scoring Approach: </strong>
      <div *ngIf="false" class="select">
        <select>
          <option>Holistic (one rubric for the item)</option>
          <option>Weighted (one rubric per response entry)</option>
          <option>Qualitative Descriptions</option>
        </select>
      </div>
      <a [class.is-disabled]="isReadOnly()" (click)="itemEditCtrl.insertUpload(itemBankCtrl.currentQuestion)">
        <i class="fa fa-upload" aria-hidden="true"></i>
        <tra slug="ie_upload_files"></tra>
      </a>
    </div>
    <ng-container *ngIf="!currentQuestionIsSequence()">
      <div>
        <fieldset [disabled]="isReadOnly() || itemBankCtrl.getCurrQTrackChanges()">
          <textarea 
          [formControl]="itemBankCtrl.currentQuestionNotes"
          cdkTextareaAutosize 
          [cdkTextareaAutosize]="true" 
          [cdkAutosizeMinRows]="2"
          class="textarea is-fullwidth is-small" 
          ></textarea>
          <div style="margin-top:0.3em;" *ngIf="!itemBankCtrl.currentQuestion.isInfoSlide">
            <checkbox [state]="itemBankCtrl.currentQuestion.isReady" [caption]="lang.tra('ie_item_is_ready')" (toggle)="itemBankCtrl.currentQuestion.isReady = $event"></checkbox>
          </div>
        </fieldset>
      </div>
    </ng-container>
    <div class="buttons" style="margin-top:3em; text-align:center;" *ngIf="!itemBankCtrl.isContentEditingDisabled()">
      <button [disabled]="isReadOnly()" (click)="itemBankCtrl.removeCurrentQuestion()" *ngIf="!frameworkCtrl.isFrameworkView" class="button has-icon is-small is-danger">
        <span class="icon">
          <i class="fa fa-trash" aria-hidden="true"></i>
        </span>
        <span><tra [slug]="getRemoveItemLabelSlug()"></tra></span>
      </button>
      <button [disabled]="isReadOnly() || saveLoadCtrl.isLoadingQuestion" (click)="itemBankCtrl.duplicateCurrentSelection()" *ngIf="!frameworkCtrl.isFrameworkView" class="button has-icon is-small">
        <span class="icon">
          <i class="fa fa-clone" aria-hidden="true"></i>
        </span>
        <span><tra slug="test_auth_duplicate"></tra> {{getCurrentQuestionType()}}</span>
      </button>
      <button (click)="itemEditCtrl.startImportExportQuestion()" class="button has-icon is-small">
        <span class="icon">
          <i class="fa fa-upload" aria-hidden="true"></i>
        </span>
        <span><tra slug="ie_import_export"></tra></span>
      </button>
      <ng-container *ngIf="!currentQuestionIsSequence()">
        <button [disabled]="isReadOnly() || itemBankCtrl.getCurrQTrackChanges()" (click)="itemEditCtrl.transferFromLang()" class="button has-icon is-small" *ngIf="itemBankCtrl.isMultiLingual()">
          <span class="icon">
            <i class="fa fa-language" aria-hidden="true"></i>
          </span>
          <span><tra slug="ie_transfer_from_lang"></tra></span>
        </button>
        <asset-library-link [disabled]="itemBankCtrl.getCurrQTrackChanges()" [itemElement]="itemBankCtrl.currentQuestion"></asset-library-link>
      </ng-container>
    </div>
    <div class="navigation-panel-container" [class.is-wider]="lang.c() === 'fr'" >
      <div class="navigation-panel" *ngIf="itemBankCtrl.isFocusView">
        <button class="toolbar-icon navigation-icon left-nav-button" (click)="gotoPrevQuestion()">
            <div class="nav-button-div">
                <i class="fas fa-arrow-left"></i> 
                <tra slug="btn_prev_bc"></tra>
            </div>
        </button>
        <button class="toolbar-icon navigation-icon" (click)="gotoNextQuestion()">
            <div class="nav-button-div">
                <i class="fas fa-arrow-right"></i> 
                <tra slug="btn_next_bc"></tra>
            </div>
        </button>
      </div>
    </div>
    
  </ng-container>
  

</div>
<div *ngIf="itemBankCtrl.isEditing && !isViewingArchived">

  <button *ngIf="itemComponentEdit.usingEditingMode()" [disabled]="IS_TRACK_CHANGES_DISABLED" class="button is-small" (click)="itemBankCtrl.toggleCurrentQuestionIsEditing()"><div style="display: flex; align-items: center"><i [class.editing-color]="itemBankCtrl.getCurrQIsEditing()" [class.track-changes-color]="!itemBankCtrl.getCurrQIsEditing()" class="fas fa-user-edit"></i> <i class="fas fa-arrow-right" [class.editing-color]="!itemBankCtrl.getCurrQIsEditing()" [class.track-changes-color]="itemBankCtrl.getCurrQIsEditing()"></i> <i [class.editing-color]="!itemBankCtrl.getCurrQIsEditing()" [class.track-changes-color]="itemBankCtrl.getCurrQIsEditing()" class="fas fa-user-edit"></i></div></button>

  <div style="margin-bottom:2em">
    <comment-section [memberAssignmentCtrl]="memberAssignmentCtrl" [itemBankCtrl]="itemBankCtrl"></comment-section>
  </div>
  <button *ngIf="!itemComponentEdit.usingEditingMode()" (click)="itemBankCtrl.onClickAcceptAllChanges()" class="accept-all-changes-button"
  >Accept All Changes</button>
  <div *ngIf="getRightPanelSuggestionState()?.content?.length">
    <div 
    class="stack-editing-container" 
    >
      <!-- Uses the suggestedQuestionState to display suggestions -->
      <div *ngFor="let el of getRightPanelSuggestionState()?.content">
        <element-config [contentElement]="el" [isEditing]="itemBankCtrl.isEditing"></element-config>
      </div>
    </div>
  </div>
</div>
<div class="navigation-panel-container" [class.is-wider]="lang.c() === 'fr'" >
  <div class="navigation-panel" *ngIf="itemBankCtrl.isFocusView">
    <button class="toolbar-icon navigation-icon left-nav-button" (click)="gotoPrevQuestion()">
        <div class="nav-button-div">
            <i class="fas fa-arrow-left"></i> 
            <tra slug="btn_prev_bc"></tra>
        </div>
    </button>
    <button class="toolbar-icon navigation-icon" (click)="gotoNextQuestion()">
        <div class="nav-button-div">
            <i class="fas fa-arrow-right"></i> 
            <tra slug="btn_next_bc"></tra>
        </div>
    </button>
  </div>
</div>
</div>
<ng-container *ngIf="isViewingArchived">
  <button (click)="itemEditCtrl.startImportExportQuestion()" class="button has-icon is-small">
    <span class="icon">
      <i class="fa fa-upload" aria-hidden="true"></i>
    </span>
    <span><tra slug="ie_import_export"></tra></span>
  </button>
</ng-container>
<special-character-keyboard *ngIf="!isReadOnly()" style="position:fixed; right:0.5em;bottom:0.5em; z-index: 10; "></special-character-keyboard>
</div>
