import { IAssessmentFrameworkDetail, DimensionType } from "./assessment-framework";

export const FRAMEWORK_CONFIG:IAssessmentFrameworkDetail = {
  "caption": "sample",
  "projectId": "sample",
  "notes": "",
  "partitions": [
    {
      "description": "Section 1",
      "id": 1
    }
  ],
  "primaryDimensions": [
    {
      "code": "FT",
      "config": {},
      "name": "Field Test",
      "type": DimensionType.BINARY,
    }
  ],
  "secondaryDimensions": [
    {
      "code": "p",
      "config": {},
      "name": "CTT Difficulty",
      "type": DimensionType.NUMERIC,
    },
    {
      "code": "rpb",
      "config": {},
      "name": "Item-Test Point-Biserial Correlation",
      "type": DimensionType.NUMERIC,
    },
    {
      "code": "N",
      "config": {},
      "name": "Number of Test-Takers",
      "type": DimensionType.NUMERIC,
    },
    {
      "code": "QS",
      "config": {},
      "name": "Question Similarity",
      "type": DimensionType.NUMERIC,
    }
  ],
  "subcaption": "v2",
  "__id": "JwdVaPLZkRy1wBgKszeV",
  "quadrantIdAI": 0,
  "parititionIdAI": 0,
  "quadrants": [],
  useLangSpecificSectionProps: undefined // brough over from abed rest is the same
}