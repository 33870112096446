// app services
import { FormControl } from '@angular/forms';
import { ZoomService } from '../../../ui-testrunner/zoom.service';
import { LangService } from '../../../core/lang.service';
import { WhitelabelService } from '../../../domain/whitelabel.service';
import { ITestDesignPayload } from '../../../ui-testtaker/view-tt-test-runner/view-tt-test-runner.component';
import { IPanelModulesConfig } from '../models/assessment-framework';
import { updateQChangeCounter } from '../models/expected-answer';
import { ISampleTestForm } from '../models/types';
import { ItemBankCtrl } from './item-bank';
import { ItemDiffCtrl } from './item-diff';
import { ItemFilterCtrl } from './item-filter';
import { ItemBankSaveLoadCtrl } from './save-load';
import { TestFormGen } from './testform-gen';
import { Destroyable } from './destroyable';
import { ItemSetPublishingCtrl } from './publishing';

export class ItemSetPreviewCtrl implements Destroyable {

  questionTextSize = new FormControl('1.5');
  sampleTestForm: ISampleTestForm;
  isTestFormPrintView:boolean;
  isShowingResults: boolean = false;
  currentTestFormId:string;
  isTesletPreview = false;

  constructor(
    public whitelabel: WhitelabelService,
    public lang: LangService,
    public testFormGen: TestFormGen,
    public itemBankCtrl: ItemBankCtrl,
    public saveLoadCtrl: ItemBankSaveLoadCtrl,
    public zoom: ZoomService,
    public itemDiffCtrl: ItemDiffCtrl,
    public itemFilterCtrl: ItemFilterCtrl,
    public publishingCtrl: ItemSetPublishingCtrl
  ){
    this.questionTextSize.valueChanges.subscribe((val)=> {this.zoom.update(val, true);})
    this.zoom.update(this.questionTextSize.value, true);
  }

  destroy() {

  }

  submitTest = (skipPost?: boolean) => {
    if(!skipPost) {
      this.showResults();
    }
  }
  
  showResults = () => {
    if (this.whitelabel.getSiteFlag('IS_BCED')){
      this.isShowingResults = true;
    }
    else{
      this.exitSampleTestForm();
    }
    return Promise.resolve();
  }

  exitSampleTestForm = () => {
    this.sampleTestForm = null;
    this.isShowingResults = false;
    this.zoom.update(this.questionTextSize.value, true);
  }

  async previewTestForm(testFormId:number, testForm: ITestDesignPayload, isTestFormPrintView:boolean=false) {
    this.isTestFormPrintView = isTestFormPrintView;
    this.sampleTestForm = await this.testFormGen.convertLOFTFormDefToPreview(testForm);
    this.currentTestFormId = 'SAMPLE'+testFormId;
    // console.log('this.sampleTestForm', this.sampleTestForm)
    const questionSrcDb = [];
    this.sampleTestForm.questionSrcDb.forEach((val, key)=>{
      questionSrcDb.push({val, key});
    })
    const sampleTestFormExport = {
      ... this.sampleTestForm,
      questionSrcDb,
    }
    // downloadStr(JSON.stringify(sampleTestFormExport), 'test.json');
  }

  async previewTloft(targetPanel?:any){
    this.testFormGen.documentMap = new Map();
    const sampleTestForm:ISampleTestForm = <any> await this.testFormGen.generateTloftSampleFormFromPanel(targetPanel)
    this.sampleTestForm = <any> sampleTestForm;
  }

  async previewTestlet(testlets:number[]){ 
    this.isTesletPreview = false;
    this.testFormGen.documentMap = new Map();
    const sampleTestForm:ISampleTestForm = <any> await this.testFormGen.generateTestletForm(testlets);
    sampleTestForm.isTestletPreview = true;
    const questions = sampleTestForm.currentTestDesign.sections.filter(section => section.questions.length)[0].questions;
    sampleTestForm.currentTestDesign.sections.forEach( section => section.questions = questions);
    this.sampleTestForm = <any> sampleTestForm;
  }

  async previewMsCat(targetPanel?:IPanelModulesConfig){
    this.testFormGen.documentMap = new Map();
    const sampleTestForm:ISampleTestForm = <any> await this.testFormGen.generateMsCatTestForm(targetPanel)
    this.sampleTestForm = <any> sampleTestForm;
  }

  async previewLinearTestForm() {
    // const res = await Promise.all([this.publishingCtrl.updateCaptionVoiceovers(this.lang.c()), this.testFormGen.generateLinearTestForm()]);
    // this.sampleTestForm = res[1];
    const res = await this.testFormGen.generateLinearTestForm();
    this.sampleTestForm = res;
    // downloadStr(JSON.stringify(this.sampleTestForm), 'test.json');
  }

  setLang(langCode: string) {
    if(this.lang.c() === langCode) {
      return;
    }

    this.lang.setCurrentLanguage(langCode);
    const memQuestion = this.itemBankCtrl.currentQuestion;
    this.itemBankCtrl.currentQuestion = null;
    setTimeout(() => {
      this.itemBankCtrl.resetQuestionState();
      this.itemBankCtrl.currentQuestion = memQuestion;
      if(this.itemBankCtrl.isPsychometricViewEnabled) {
        for(const item of this.itemBankCtrl.itemList) {
          updateQChangeCounter(item); //refreshes the parameter fields for language independent parameters in framework view
        }
        this.itemFilterCtrl.updateItemFilter(); //refreshes filtering for language independent params
      } else {
        this.itemBankCtrl.refreshEditingDisabledCurrQ();
        this.itemDiffCtrl.refreshSuggestions();
      }
      // console.log('this.currentQuestion', this.currentQuestion, this.getCurrentQuestionContent());
    }, 300);
  }
  getTestTakerName = () =>  this.lang.tra('ie_student_tt_name');

  saveQuestionResponse = (data) => Promise.resolve();
  
  updateDragZoomCorrection(){
    const cdkStyle = document.getElementById('cdk-font-size-override');
    if (cdkStyle){
      cdkStyle.innerText = `.cdk-drag-preview { font-size: ${this.questionTextSize.value}em; }`
    }
  }

}