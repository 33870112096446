import { Component, Input, OnInit } from '@angular/core';
import { QuestionRunnerComponent } from '../question-runner/question-runner.component';
import { LangService } from 'src/app/core/lang.service';
import { WhitelabelService } from 'src/app/domain/whitelabel.service';
import { AuthScopeSettingsService } from 'src/app/ui-item-maker/auth-scope-settings.service';
import { ItemComponentEditService } from 'src/app/ui-item-maker/item-component-edit.service';
import { DrawingLogService } from '../drawing-log.service';
import { QuestionRunnerLayoutService } from '../question-runner-layout.service';
import { TextToSpeechService } from '../text-to-speech.service';

@Component({
  selector: 'question-runner-lang-locked',
  templateUrl: '../question-runner/question-runner.component.html',
  styleUrls: ['../question-runner/question-runner.component.scss'],
  providers: [LangService]
})
export class QuestionRunnerLangLockedComponent extends QuestionRunnerComponent {
  @Input() langCode: 'en' | 'fr';

  constructor(
    textToSpeech: TextToSpeechService,
    authScope: AuthScopeSettingsService,
    whitelabel: WhitelabelService,
    bufferedLog: DrawingLogService,
    itemComponentEdit: ItemComponentEditService,
    questionRunnerLayout: QuestionRunnerLayoutService,
    private lang: LangService
  ) {
    super(
      textToSpeech,
      authScope,
      whitelabel,
      bufferedLog,
      itemComponentEdit,
      questionRunnerLayout
    );
  }
  ngOnInit(): void {
    if(this.langCode){
      this.lang.setCurrentLangCode(this.langCode);
    }
  }
}
