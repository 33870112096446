import { Component, OnInit, Input } from '@angular/core';
import { LangService } from 'src/app/core/lang.service';
import { WhitelabelService } from 'src/app/domain/whitelabel.service';

@Component({
  selector: 'osslt-isr-v2',
  templateUrl: './osslt-isr-v2.component.html',
  styleUrls: ['./osslt-isr-v2.component.scss']
})
export class OssltIsrV2Component implements OnInit {
  @Input() report: any;
  @Input() i: any;

  @Input() isFireFox: () => boolean;
  @Input() getScaleScore: (report: any) => string;
  @Input() showALtAsScaleScore: (report: any) => boolean;
  @Input() getReportYearVersion1: (report: any) => string;
  @Input() maskOENWithFiveStars: (oen: any) => string;
  @Input() showReportNumQ: (report: any) => boolean;
  @Input() isLinearFailReport: (report: any) => boolean;

  constructor(
    public lang: LangService,
    public whitelabel: WhitelabelService
  ) { }

  ngOnInit(): void {
  }

  /**
   * Get appripriate slug according to report.Result value
   * For OSSLT outcome 3 - 11
   * @param result_state 
   * @returns {string} translation slug
   */
  getNonFullyParticipatingOutcomeSlug(result_state:number):string{
    let traSlug:string
    switch(+result_state){
      case 3:
        traSlug = "sa_report_npo_3"
        break;
      case 4:
        traSlug = "sa_report_npo_4"
        break;
      case 5:
        traSlug = "sa_report_npo_5"
        break;
      case 6:
        traSlug = "sa_report_npo_6"
        break;
      case 10:
        traSlug = "sa_report_npo_10"
        break;
      case 11:
        traSlug = "sa_report_npo_11"
        break;
      default:
        traSlug = "sa_report_npo_3" 
    }
    
    return traSlug
  }
}