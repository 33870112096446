import moment from "moment";
import { ColDef, GridOptions } from 'ag-grid-community';
import { LangService } from "src/app/core/lang.service";
import { mtz } from "./moment";

// assumes that the date is in the string ISO format that is returned by the API by default
// comparator only compares the date part, not the time part
export const dateColDef = function(lang: LangService, formatSlug = 'datefmt_day_month_year'): Partial<ColDef> {
  return {
    valueGetter: (params) => {
      const value = params.data[params.colDef.field];
      if(!value) return null;
      return new Date(value)
    },
    cellRenderer: (params) => {
      if (!params.value) return null;
      return mtz(params.value).format(lang.tra(formatSlug));
    },
    filter: 'agDateColumnFilter',
    filterParams: {
      browserDatePicker: true,
      comparator: (filterLocalDateAtMidnight: Date, cellValue: Date) => {
        if (!cellValue) return 0;
        const cellDate = moment(cellValue).startOf('day').toDate().getTime();
        const localTimeAtMidnight = filterLocalDateAtMidnight.getTime();

        return cellDate == localTimeAtMidnight ? 0 : cellDate < localTimeAtMidnight ? -1 : 1;
      }
    }
  }
}