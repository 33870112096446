export const AdvancedSettings = [
  {prop: 'isBoosterPackMappingsEnabled',   caption: 'Booster Pack Mappings'},
  {prop: 'isEditorHidden',   caption: 'Disable Content Editing'},
  {prop: 'isMetaScreensEnabled',   caption: 'Meta Info Screens'},
  {prop: 'isSolutionBoxesEnabled', caption: 'Solution Boxes'}, // (coming soon)
  {prop: 'isLogbookEnabled',       caption: 'Logbook'}, // (coming soon)
  {prop: 'isTableBlocksEnabled',   caption: 'Table Blocks'}, // (coming soon)
  {prop: 'isTagsEnabled',          caption: 'Question Tags'},
  {prop: 'isCurriculumEnabled',    caption: 'Curriculum Linkage'}, // (coming soon)
  {prop: 'isCommentsEnabled',      caption: 'Comments'}, // (coming soon)
  {prop: 'isQuestionStatusEnabled',   caption: 'Question Ready'},
  {prop: 'isPsychometricsEnabled',    caption: 'Psychometrics Mode'},
  {prop: 'isDistractorsEnabled',      caption: 'Insert Distractor Info'},
]