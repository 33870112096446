import { IContentElementDndDraggable, IContentElementDndTarget } from "../element-render-dnd/model";
import { IContentElementImage } from "../element-render-image/model";
import { IContentElement, IEntryStateScored, IScoredResponse } from "../models";

export interface IContentElementMoveableDragDrop extends IContentElement, IScoredResponse {
    width?: number;
    height?: number;
    draggables: IContentElementDndDraggable[];
    targets: IContentElementDndTarget[];
    isTargetsInvisible?: boolean;
    isDraggablesInvisible?: boolean;
    draggableCounter?: number;
    isDragsTransparent?: number;
    isTargetColourSame?: boolean;
    isAllTargetsToPlace?: boolean;
    isDraggableColourSame?: boolean;
    hasFrame?: boolean;
    moveableImages?: any[];
    targetColour?: string;
    draggableColour?: string;
    backgroundImg?: IContentElementImage;
    isOptionsReusable?: boolean;
    isCustomValidataion?: boolean;
    isMultipleOptionsRight?: boolean;
    pairMapping?: IDPairing[];
    isDraggableNotResizable?: boolean;
    isParaFullWidth?: boolean;
    isHideDraggableDropShadow?: boolean;
    isAcceptMultipleCombinations?: boolean;
    multipleCombinations?: any[]
    gradingMode: GradingType;
    howManyToFill?: number; 
    isNoInvertDragPreview: boolean
    isOverflownY?: boolean;
  }

  export interface IEntryStateMoveableDragAndDrop extends IEntryStateScored {
    targets: any[];
    isKeyIdUsed?: boolean,
    simplifiedStateTargets?: {key: string, contents: number[]}[]
  }

  export interface IContentElementLocAndDims extends IContentElement {
    x: number;
    y: number;
    width: number;
    height: number;
    padding: number;
  }

  export enum GradingType {
    PLUS_MINUS = "plus_minus",
    REDUCTION = "reduction",
    NORMAL = "normal"
  }

  export interface IDPairing {
    optionID?: string;
    targetID?: string;
  }

  export const moveableDndEditInfo = {
    editExcludeFields: ['draggables', 'targets', 'backgroundImg']
  }