// app services
import { AuthService } from '../../../api/auth.service';
import { LangService } from '../../../core/lang.service';
import { RoutesService } from '../../../api/routes.service';
import { ItemSetFrameworkCtrl } from './framework';
import { ItemBankCtrl } from './item-bank';
import { TestFormConstructionMethod } from '../models/assessment-framework';
import { TestFormGen } from './testform-gen';
import { IQuestionConfig } from '../../../ui-testrunner/models';
import { Destroyable } from './destroyable';
import { ItemEditCtrl } from './item-edit';
import { ScriptGenService } from '../../script-gen.service';
import * as _ from 'lodash';

export class ItemSetPublishingCtrl implements Destroyable {

  testDesignReleaseHistory;
  private testDesignReleaseHistoryMap = {};
  isShowingTestDesignReleaseHistory:boolean;
  isPublishingTestDesign: boolean;
  publishingProgress: {count: number, total:number, isUploading: boolean} = {count: 0, total: 0, isUploading: false};

  constructor(
    public auth: AuthService,
    public lang: LangService,
    public routes: RoutesService,
    public frameworkCtrl: ItemSetFrameworkCtrl,
    public itemBankCtrl: ItemBankCtrl,
    public testFormGen: TestFormGen,
    public itemEditCtrl: ItemEditCtrl,
    private scriptGen: ScriptGenService
  ){

  }
  destroy() {

  }

  lockQuestions(){
    const questions = this.frameworkCtrl.getLinearFormQuestions()
    if (questions) {
      questions.forEach((quest)=>{
        const itemBankQuest:IQuestionConfig = this.itemBankCtrl.getQuestionById(quest.id)
        if (itemBankQuest["isLocked"]==true) {
          return;
        }
        this.itemBankCtrl.toggleQuestionLock(itemBankQuest)
        this.frameworkCtrl.saveLoadCtrl.saveTargetQuestionData(itemBankQuest, true)
      })
    }
  }

  async updateTestDesigns(id:number){
    await this.auth.apiPatch(this.routes.TEST_AUTH_TEST_DESIGNS, id, this.testDesignReleaseHistoryMap[id]);
  }
  
  async publishTestDesign(test_design_id?:number, ranOnce:boolean = false) {
    let lang = this.lang.c();
    const availableLangs = this.lang.getSupportedLanguages();

    if (!this.itemBankCtrl.isLangEnabled('en')){
      lang = 'fr'
    }

    // if (this.itemBankCtrl.isQLockAvail()) {
    //   const lock = confirm("Do you want to lock the test and all items in it from being edited?")
    //   if (lock) {
    //      this.lockQuestions();
    //   }
    // }
    this.isPublishingTestDesign = true;

    try {
      if (test_design_id){
        let numCopies = 0;
        if(!ranOnce){
          numCopies = +prompt('How many forms?')
        } else {
          numCopies = 1;
        }
        if (numCopies){
          const targetIterations = Math.ceil(numCopies/10);
          this.publishingProgress = {
            count: 0,
            total: targetIterations,
            isUploading: false
          }
          await this._publishTestDesign(null, lang, test_design_id, !ranOnce);
        }
      }
      else{
        const name = prompt('Enter a name for this test design');
        if (!name) {
          this.isPublishingTestDesign = false;
          return;
        }
        this.publishingProgress = {
          count: 0,
          total: this.determineTargetIterations(),
          isUploading: false
        }
        let alertMessage = '';
        switch (this.frameworkCtrl.asmtFmrk.testFormType) {
          case TestFormConstructionMethod.TLOFT:
            if (this.frameworkCtrl.testFormConstructionMethod.value === TestFormConstructionMethod.TLOFT && !this.frameworkCtrl.asmtFmrk.assembledPanels?.length) {
              alertMessage = `Publishing ${this.frameworkCtrl.asmtFmrk.numOfPublishingIterations || 1} iteration(s)`;
            } else {
              alertMessage = `Can publish up to ${this.frameworkCtrl.asmtFmrk.assembledPanels.length} iteration(s)`;
              ranOnce = true;
            }
            break;

          case TestFormConstructionMethod.MSCAT:
          case TestFormConstructionMethod.LINEAR:
            alertMessage = `Publishing ${this.publishingProgress.total} iteration(s)`;
            ranOnce = true;
            break;
        }
        alert(alertMessage);

        await this._publishTestDesign(name, lang);
        await this.loadTestDesignReleaseHistory();
        if(!ranOnce){
          for (const language of availableLangs) {
            if (language !== lang) { // Publish in each language except the initial one
              this.lang.setCurrentLanguage(language);
              await this.publishTestDesign(this.testDesignReleaseHistory.find(td => td.name === name).id, true);
            }
          }
          alert("Published in all available languages");
          this.lang.setCurrentLanguage(lang); // Restore the initial language
        }
      }
      this.isPublishingTestDesign = false;
    }
    catch(e){
      console.error('Publishing failed', e)
      this.isPublishingTestDesign = false;
    }
  }

  displayProgressPercentage(){
    if (this.publishingProgress.total == 0){
      return 'N/A'
    }
    if (this.publishingProgress.isUploading){
      return 'uploading files'
    }
    else if (this.publishingProgress.count == this.publishingProgress.total) {
      return 'pending'
    }
    return Math.round(100*(this.publishingProgress.count)/this.publishingProgress.total) + '% forms generated'
  }

  /**
   * Determines whether a test design is currently being published
   * @returns true if the latest test design is still publishing
   */
  isPublishingOngoing() {
    if(!this.testDesignReleaseHistory || this.testDesignReleaseHistory.length == 0) {
      return false;
    }
    const process = this.testDesignReleaseHistory[0];
    return process.is_publishing_in_progress == 1 && process.is_error == 0
  }

  determineTargetIterations(){
    switch (this.frameworkCtrl.asmtFmrk.testFormType) {
      case TestFormConstructionMethod.TLOFT:  
        return 1;
      case TestFormConstructionMethod.MSCAT:  
        return this.determineMscatIterations();
      case TestFormConstructionMethod.LINEAR: 
      default: 
        return 1;
    }
  }

  determineMscatIterations(){
    return +this.frameworkCtrl.asmtFmrk.panelAssembly.numFtVariants || 1;
  }

  isMultiForm(){
    switch (this.frameworkCtrl.asmtFmrk.testFormType) {
      case TestFormConstructionMethod.TLOFT:  
      case TestFormConstructionMethod.MSCAT:  
        return true;
      case TestFormConstructionMethod.LINEAR: 
      default: 
        return false;
    }
  }

  // async updateCaptionVoiceovers(lang?: string) {
  //   //Generate and upload any missing or outdated question title voiceovers
  //   //Ensures that changes in question captions due to positioning or framework settings
  //   //are taken into account on publish without using the magic wand icon.
  //   const questionTitleMap = await this.itemEditCtrl.genQuestionTitleMap();
  //   const tuples = Array.from(questionTitleMap.keys());
  //   await Promise.all(tuples.map((tupleKey)=> {
  //     const tuple = JSON.parse(tupleKey);
  //     const sId = tuple[0];
  //     const qId: number = tuple[1];
  //     let question = this.itemBankCtrl.getQuestionById(qId);
  //     if(lang === 'fr') {
  //       question = question.langLink;
  //     }
  //     return this.scriptGen.autoGenQuestionCaptionVoiceover(sId, question, questionTitleMap.get(tupleKey), lang).then(()=> {
  //       this.frameworkCtrl.saveLoadCtrl.saveTargetQuestionData(question, true)
  //     });
  //   }))
  // }
  async _publishTestDesign(name?:string, lang?:string, test_design_id?:number, alertUser:boolean = true){
    
    // Do required iterations to prepare the test forms
    let forms: string[] = [];
    while (this.publishingProgress.count < this.publishingProgress.total){
      const iterationForms:string[] = await this.getTestForms(this.publishingProgress.count);
      iterationForms.forEach(iterationForm => forms.push(iterationForm))
      this.publishingProgress.count++;
    }
    // check if form has removed questions if so alert user
    const removedQuestionsInForms = this.getRemovedQuestionsInForms(forms)
    if (Object.keys(removedQuestionsInForms).length){
      alert(this.lang.tra('ie_publishing_test_design_includes_archived'));
      console.error(removedQuestionsInForms);
      return;
    }

    const source_item_set_id = this.itemBankCtrl.customTaskSetId;
    const framework = JSON.stringify(this.frameworkCtrl.asmtFmrk);
    
    this.publishingProgress.isUploading = true;
    // Upload the info about the forms
    const formCacheInfoData = {
      source_item_set_id,
      framework,
      name,
      lang,
      test_design_id,
      source_tf_id: this.frameworkCtrl.asmtFmrk.panelName || null,
    }
    const formCacheInfoDataBlob = this.createBlobFromJsonObject(formCacheInfoData);
    const formCacheInfoUpload =  await this.auth.uploadFile(formCacheInfoDataBlob, `publishing-form-info.json`, 'authoring', true);
    const form_cache_info = formCacheInfoUpload.url;

    // Upload JSONSs with forms in sets of 50 forms, to prevent overloading API during publishing process
    const form_cache_list = [];

    const uploadPromises = _.chunk(forms, 50).map(async (formsChunk, i) => {
      const data = {
        forms: formsChunk,
        forms_fraction_index: i
      }
      const dataBlob = this.createBlobFromJsonObject(data);
      const upload = await this.auth.uploadFile(dataBlob, `publishing-form-cache-${i}.json`, 'authoring', true);
      form_cache_list.push(upload.url)
    })

    await Promise.all(uploadPromises)

    this.publishingProgress.isUploading = false;

    await this.auth.apiCreate(this.routes.TEST_AUTH_TEST_DESIGNS, {
      source_item_set_id,
      framework,
      name,
      lang,
      test_design_id,
      source_tf_id: this.frameworkCtrl.asmtFmrk.panelName || null,
      form_cache_list,
      form_cache_info
    });
    if(alertUser){
      alert('Test design is now publishing. Please refresh the Publishing History to view its progress.');
    }
    return test_design_id
    
  }


  getRemovedQuestionsInForms(forms:string[]){
    const formArr = forms.map(f => JSON.parse(f));
    const removedQuestions = {};
    switch(this.frameworkCtrl.testFormConstructionMethod.value as TestFormConstructionMethod){
      default:
      case TestFormConstructionMethod.LINEAR:
        this.frameworkCtrl.getRemovedQuestionFromLinearForm(removedQuestions);
        break;
      case TestFormConstructionMethod.TLOFT:
        this.getUsedTestletContainingArchived(removedQuestions, formArr);
        break;
      case TestFormConstructionMethod.MSCAT:
        this.getArchivedInMSCATForm(removedQuestions, formArr);
        break;
    }
    return removedQuestions
  }

  /**
   * Function that adds archived questions to a map by checking the panel in the form and the testlets being used
   * @param removedQuestions Map used to keep track of removed questions
   * @param formArr An array of the forms that are being checked for archived questions
   */
  private getArchivedInMSCATForm(removedQuestions: {}, formArr: any[]) {
    this.frameworkCtrl.getRemovedQuestionFromMSCATPanel(removedQuestions);
    formArr.forEach(form => {
      form.testletItems.forEach(testletItem => {
        const testletID = testletItem.testlet_id;
        const questions = form.testletItems.map(item => { return { label: item.item_label, id: item.item_id }; });
        const removedQ = this.frameworkCtrl.getRemovedQuestions(questions);

        if (removedQ.length) {
          const key = `Testlet ${this.lang.tra('ie_id')} ${testletID}`;
          if (!removedQuestions[key]) {
            removedQuestions[key] = [];
          }

          const uniqueRemovedQ = new Set(removedQuestions[key]);
          removedQ.forEach(q => uniqueRemovedQ.add(q));

          removedQuestions[key] = Array.from(uniqueRemovedQ);
        }
      });
    });
  }

  /**
   * Function that adds archived questions to a map by checking the testlets the forms are using
   * @param removedQuestions Map used to keep track of removed questions
   * @param formArr An array of the forms that are being checked for archived questions
   */
  private getUsedTestletContainingArchived(removedQuestions: {}, formArr: any[]) {
    const testletMissingQuestionMap = {};
    this.frameworkCtrl.getRemovedQuestionFromTestlets(testletMissingQuestionMap);
    formArr.forEach(form => {
      const { testletIds } = form;
      testletIds.forEach(id => {
        const testletKey = `Testlet ${this.lang.tra('ie_id')} ${id}`;
        if (testletMissingQuestionMap[testletKey]) {
          removedQuestions[testletKey] = testletMissingQuestionMap[testletKey];
        }
      });

    });
  }

  /**
   * Creates a blob from a JSON object that can be uploaded.
   * @param jsonObject JSON object
   * @param filename apply a file name to the blob
   * @returns a new Blob
   */
  createBlobFromJsonObject(jsonObject: {}, filename?: string): Blob {
    const jsonString = JSON.stringify(jsonObject);
    const blob = new Blob([jsonString], { type: 'application/json' });
  
    // Set a default filename if not provided
    const effectiveFilename = filename || 'data.json';
  
    // Attach the filename to the Blob
    blob['name'] = effectiveFilename;
  
    return blob;
  }

  async getTestForms(iterationCount=0) {
    switch (this.frameworkCtrl.asmtFmrk.testFormType) {
      case TestFormConstructionMethod.TLOFT:  
        return await this.testFormGen.generateLOFTTestFormsForTestDesign(true, true);
      case TestFormConstructionMethod.MSCAT:  
        return await this.testFormGen.generateMscatTestFormsForTestDesign(iterationCount); 
        // const formsFirstQSummary = forms.map(s => JSON.parse(s).panelModules[0].questions.sort(function(a, b) {
        //   return a - b;
        // }))
        // console.log('formsFirstQSummary', formsFirstQSummary);
        // const firstCol = formsFirstQSummary[0] 
        // const csv = []; // to check questions in first module
        // for (let j=0; j<firstCol.length; j++){
        //   const row = []
        //   for (let i=0; i<formsFirstQSummary.length; i++){
        //     row.push(formsFirstQSummary[i][j]);
        //   }
        //   csv.push(row.join('\t'))
        // }
        // console.log(csv.join('\n'));
      case TestFormConstructionMethod.LINEAR: 
      default: 
        return await this.testFormGen.generateLinearTestFormsForTestDesign();
    }
  }

  async loadFormsForPublishedTestDesign(test_design_id:number){
    return this.auth
      .apiFind(
        this.routes.TEST_AUTH_TEST_DESIGN_FORMS, 
        { 
          query: {
            test_design_id
          } 
        }
      )
  }
  async revokeForm(test_form_id:number){
    await this.auth
      .apiRemove(
        this.routes.TEST_AUTH_TEST_DESIGN_FORMS, 
        test_form_id,
      )
  }
  async unrevokeForm(test_form_id:number){
    await this.auth
      .apiPatch(
        this.routes.TEST_AUTH_TEST_DESIGN_FORMS, 
        test_form_id, 
        { },
      )
  }
  async toggleTestDesignIsPublic(testDesignRecord:{id:number, is_public:number}){
    if ( (testDesignRecord.is_public === 0) || !testDesignRecord.is_public){
      await this.auth.apiPatch(this.routes.TEST_AUTH_TEST_DESIGNS_IS_PUBLIC, testDesignRecord.id, { })
      testDesignRecord.is_public = 1;
    }
    else {
      this.auth.apiRemove(this.routes.TEST_AUTH_TEST_DESIGNS_IS_PUBLIC, testDesignRecord.id);
      testDesignRecord.is_public = 0;
    }    
  }

  async getPublishedTestFormsByUrl(testformID: number, total:number, limit:number = 300) {
    let offset = 0;
    let allResults = {};
    let results;

    do {
        results = await this.auth.apiFind(this.routes.TEST_AUTH_TEST_DESIGN_FORMS, {
            query: {
                test_design_id: testformID,
                retrieveJSON: 1,
                limit,
                offset
            }
        });

        allResults = {...allResults, ...results};
        offset += limit;
    } while (Object.keys(allResults).length < total);

    return allResults;
  }
  async getPublishedTestFormsTFMI(testformID: number) {
    return await this.auth.apiFind(this.routes.TEST_AUTH_TEST_DESIGN_FORMS, {
      query: {
          test_design_id: testformID,
          retrieveTFMI: 1,
      }
  });
  }


  async loadTestDesignReleaseHistory() {
    const source_item_set_id = this.itemBankCtrl.customTaskSetId;
    // console.log('source_item_set_id', source_item_set_id);
    this.testDesignReleaseHistory = await this.auth
      .apiFind(
        this.routes.TEST_AUTH_TEST_DESIGNS, 
        {query: {source_item_set_id}}
      )
    this.testDesignReleaseHistory.forEach(design => this.testDesignReleaseHistoryMap[design.id] = design);
  }

  togglePwdProtected(val: boolean) {
    const newVal = !val;
    this.auth.apiPatch(this.routes.TEST_AUTH_PUB_PWD_PROTECTED, this.itemBankCtrl.customTaskSetId, {public_pwd_protected: newVal}).then( (res) => {
        this.itemBankCtrl.publicPwdProtected = newVal;
      }
    );
  }

}
