import * as _ from 'lodash';
import { IContentElementMcq } from "../../element-render-mcq/model";
import { IQuestionConfig } from "../../models";
import { ITestDef } from "./sections";


// // ANSWERs

export const extractSampleQuestionsStructure = (testDef:ITestDef, questions:IQuestionConfig[]) => {
  const payload:{[key:string]:any} = {
    SECTIONS_EN: null,
    SECTIONS_FR: null,
    ANS_EN: [],
    ANS_FR: [],
    SQ_EN: [],
    SQ_FR: [],
  };
  const EN = 'EN';
  const FR = 'FR';
  const langs = [EN,FR];
  const sepLangKeys = (key:string, lang:string) => {
    if (lang === FR) { return FR+':'+key; }
    return key
  }
  questions = JSON.parse(JSON.stringify(questions));
  testDef = JSON.parse(JSON.stringify(testDef)); // might not need this
  const questionRef = new Map();
  const questionLabelRef:Map<number, string> = new Map();
  questions.forEach(question => {
    const key = question.label;
    question.taskId = key;
    questionLabelRef.set(question.id, question.label);
    questionRef.set(question.taskId, question);
    question.langLink.label = key;
    question.langLink.taskId = sepLangKeys(key, FR);
    questionRef.set(question.langLink.taskId, question.langLink);
  });
  langs.forEach(lang => {
    const testDefLang:ITestDef = {
      sections: [],
    };
    const preambles = [];
    let questionKeys = [];
    testDef.sections.forEach(section => {
      console.log('preamble check', section)
      if (section.preamble){
        preambles.push(
          questionRef.get(
            sepLangKeys(questionLabelRef.get(section.preamble), lang)
          )
        );
      }
      else{
        preambles.push(null);
      }
      const isShuffleEnabled = section.isShuffleDisabled; // bad variable name ...
      let sectionQuestionKeys:string[] = section.questions.map(questionId => {
        const questionLabel = questionLabelRef.get(questionId);
        return sepLangKeys(questionLabel, lang)
      });
      if (isShuffleEnabled){
        sectionQuestionKeys = _.shuffle(sectionQuestionKeys);
      }
      else{
        // to do: should get rid of the shuffling here and take care of the sorting when the form is being initially generated
        sectionQuestionKeys = _.sortBy(sectionQuestionKeys, str => str.trim() )
      }
      testDefLang.sections.push({
        ... section,
        preamble: <any> questionLabelRef.get(section.preamble),
        questions: <any>sectionQuestionKeys,
      });
      questionKeys = questionKeys.concat(sectionQuestionKeys);
    })
    const questionDb:IQuestionConfig[] = questionKeys.map(key => questionRef.get(key));
    const answers = extractQuestionAnswers(questionDb);

    payload['SECTIONS_'+lang] = testDefLang;
    payload['SQ_'+lang] = questionDb;
    payload['ANS_'+lang] = answers;
    payload['PREAMBLES_'+lang] = preambles;
  })
  return payload;
}

export const extractQuestionAnswers = (questions:IQuestionConfig[]) => {
  interface IAnswerPayload {
    [key:string]:  {
      [key:string]:  {optionIndex: number }
    }
  }
  const answerPayload:IAnswerPayload = {};
  const  couldNotScore:string[] = [];
  questions.forEach(question => { // traverse each question
    var responseMap = {};
    try {
      question.entryOrder.forEach(entryId => { // for each defined "entry" in the question
        // TO DO : this actually needs to be recursive... this only works for simple multiple choice at the moment
        question.content.forEach(el => { // find the element that is that entry
          if (el.entryId === entryId){ // ...
            const mcqEl = <IContentElementMcq> el;
            // TO DO: this is too specific to multiple choice
            mcqEl.options.forEach((option, i) => { // go through the options and pull out the correct answer
              if (option.isCorrect){
                responseMap[entryId] = {optionIndex: i}
              }
            })
          }
        })
      });
    }
    catch(e){
      question.isNotScored = true;
      /// quick hack ?
      responseMap = {1: {optionIndex: 1}}
      ///
      couldNotScore.push(question.taskId);
    }
    answerPayload[question.taskId] = responseMap;
  });
  console.log('couldNotScore', couldNotScore);
  return answerPayload;
}