
type ItematicLessonId = string;
type ItematicSceneId = string;

export interface ITask {
    __id?: string;
    type?: string;
    name?: string;
    timeCreated?: number;
    itematicLessonId?: ItematicLessonId;
    itematicScreenId?: ItematicSceneId;
    itematicSceneId?: ItematicSceneId;
    itematicItemVersionId?: string,
    itematicEngineVersion?: string,
    engineOverride?: string; // deprecated
    questions?: string[];
    isUserGeneratedTask?: boolean;
    customTaskSetId?: string;
    isOpenResponse?: boolean;
    isHeadsUp?: boolean;
    isPublic?: boolean;
    numThumbsDown?: number;
    numThumbsUp?: number;
    thumbnailUrl?: string;
    isMarksUsed?: boolean;
    totalMarks?: number;
    parentTask?:string;
    screenshot?: {
        url: string,
        bg: {
            color?: string,
            colorCoverImg?: boolean,
            colorCoverOpacity?: number,
            img?: string
        }
    }
    marksAlloc?: Array<{
        part: string,
        marks: number
    }>;

    itemOptions?: {[key: string]: any};
}

    // , 


export const generateDefaultTask = (name: string, overrides:ITask = null):ITask => {
    const payload = {
        type: 'assessment',
        isPublic: true,
        isArchived: false,
        numThumbsDown: 0,
        numThumbsUp: 0,
        questions: [],
    }
    if (overrides){
        Object.keys(overrides).forEach(param => {
            payload[param] = overrides[param];
        })
    }
    return payload;
}

export const generateDefaultQuestionTask = (parentTask:string, overrides:ITask = null):ITask => {
    const payload =  {
        type: 'question_screen',
        isPublic: true,
        numThumbsDown: 0,
        numThumbsUp: 0,
        parentTask,
        timeCreated: serverTimestamp(),
    }
    if (overrides){
        Object.keys(overrides).forEach(param => {
            payload[param] = overrides[param];
        })
    }
    return payload;
}

export const serverTimestamp = () => {
    return (new Date()).valueOf();
}
  