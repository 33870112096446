import { Pipe, PipeTransform } from '@angular/core';

export const sortBy = (array: any, field: string, isDescending:boolean=false): any[] => {
  if (!Array.isArray(array)) {
    return;
  }
  array.sort((a: any, b: any) => {
    if (a[field] < b[field]) {
      return isDescending?1:-1;
    } else if (a[field] > b[field]) {
      return isDescending?-1:1;
    } else {
      return 0;
    }
  });
  return array;
}

@Pipe({
  name: 'sort'
})
export class SortPipe  implements PipeTransform {
  transform(array: any, field: string, isDescending:boolean=false): any[] {
    return sortBy(array, field, isDescending);
  }
}