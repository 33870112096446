import * as _ from 'lodash';
// app services
import { AuthService } from '../../../api/auth.service';
import { LangService } from '../../../core/lang.service';
import { RoutesService } from '../../../api/routes.service';
import { ScriptGenService } from '../../script-gen.service';
import { ItemFilterCtrl } from './item-filter';
import { ItemBankCtrl } from './item-bank';
import {
  collectAndEnsureEntryIds,
  getQuestionConfiguredEA,
  identifyQuestionResponseEntries
} from '../models/expected-answer';
import { ItemSetFrameworkCtrl } from './framework';
import { LangId, possibleLanguages} from '../models/constants';
import { ElementType, getElementWeight, IContentElement,  IQuestionConfig, ScoringTypes, } from '../../../ui-testrunner/models';
import { PARAM_SPECIAL_FLAGS } from '../../framework-dimension-editor/model';
import { FrameworkQuadrantCtrl } from './quadrants';
import { getElementChildren, ExpectedAnswer} from '../models';
import { EStatus, ILibraryAsset} from '../../asset-details/types'
import { TestFormConstructionMethod } from '../models/assessment-framework';
import { IContentElementMcq, IEntryStateMcq, McqDisplay } from '../../../ui-testrunner/element-render-mcq/model';
import { IContentElementInput, InputFormat } from '../../../ui-testrunner/element-render-input/model';
import { ElementTypeDefs } from '../../../ui-testrunner/models/ElementTypeDefs';
import { TextParagraphStyle } from 'src/app/ui-testrunner/element-render-text/model';
import { Destroyable } from './destroyable';
import { ItemEditCtrl } from './item-edit';

import { IContentElementSelectionTable } from '../../../ui-testrunner/element-render-selection-table/model';
import { IContentElementMoveableDragDrop } from '../../../ui-testrunner/element-render-moveable-dnd/model';
import { IContentElementDndDraggable } from '../../../ui-testrunner/element-render-dnd/model';
import { IContentElementGroup } from '../../../ui-testrunner/element-render-grouping/model';
import { IContentElementInsertion } from '../../../ui-testrunner/element-render-insertion/model';
import { IContentElementVideo } from '../../../ui-testrunner/element-render-video/model';
import { IContentElementImage } from '../../../ui-testrunner/element-render-image/model';
import { IContentElementAudio } from '../../../ui-testrunner/element-render-audio/model';
import { IContentElementMcqOption } from 'src/app/ui-testrunner/element-render-mcq/model';
import { AuditConfigs, IAuditConfig, EAuditElementType, IAuditFilterScopedQuestions, AuditQuestionScope, IAuditPatch, ItemTypesParam, ITEM_MAP_MODULE_META_AUDIT_CONST_LABELS, } from 'src/app/ui-item-maker/widget-audits/data/audits'
import { processCheck, IAuditResult, AuditResultCore, AuditResultMeta } from 'src/app/ui-item-maker/widget-audits/util/checks';
import { extractTestDesignScopedquestion, getQuestionsByScope, getScreens, IContext } from 'src/app/ui-item-maker/widget-audits/util/item-scopes';
import { itemContentDiff } from 'src/app/ui-item-maker/widget-audits/util/content-diff';
import { countKeyValues, getQuestionContent, getQuestionDeepElements, getQuestionPoints, getQuestionRespondableDeep, isNullOrEmptyOrUndefined } from 'src/app/ui-item-maker/widget-audits/util/content-analysis';
import { processPatch } from '../../widget-audits/util/patches';
import { IExpansionPanelContent,ExpansionPanelContentType } from 'src/app/ui-partial/expansion-panel/expansion-panel.component';
import { IContentElementTemplate } from 'src/app/ui-testrunner/element-render-template/model';
import getAssetSize from 'src/app/ui-item-maker/widget-audits/util/get-asset-size';
import { scoreMatrixElementTypes } from '../../config-score-matrix/models';

export interface IAuditFormattedResult {
  key: string
  description: string;
  isAutoFixable?: boolean;
  items: any[];
  num_issues: number;
}
export interface IAssetResult {
  size: number;
  url?: string;
  entryId?: number; 
  type?: ElementType | string;
}
interface IQuestionResult {
  qid: number;
  results: IAssetResult[];
  totalSize: number;
  imageSize: number;
  audioSize: number;
}

export class ItemBankAuditor implements Destroyable {
  
  voiceoverAuditResults;
  
  auditsRan: {[key: string]: boolean} = {};
  auditsFixProgress: {[key: string]: {count: number, total: number}} = {};
  auditQuestionMem: {[memId: string]: any} = {};
  activeAuditQuestionMemId: string;
  auditResultHeader: string;

  auditFilterScopedQuestions: IAuditFilterScopedQuestions = {
    ITEMS_SCORED: false,
    ITEMS_SCORED_MINUS_FT: false,
    ITEMS_HUMAN_SCORED: false,
    ITEMS_SURVEY:false,
    SCREENS: false,
    SCREENS_NONSCORED: false,
    SCREENS_PASSAGES: false,
  };

  customIterators:{[auditSlug:string] : (auditResultsModel:AuditResultCore, skipDisabled?:boolean) => Promise<AuditResultMeta>} = {};
  customAutoFixes:{[patchSlug:string] : (auditSlug:string, patchSlug:string) => void} = {};
  auditLogs: any[];
  auditsRunning: {[key: string]: boolean} = {};
  auditsWithError: {[key: string]: boolean} = {};
  
  constructor(
    public scriptGen: ScriptGenService,
    public itemFilterCtrl:ItemFilterCtrl,
    public itemBankCtrl:ItemBankCtrl,
    public frameworkCtrl:ItemSetFrameworkCtrl,
    public quadrantCtrl: FrameworkQuadrantCtrl,
    public auth: AuthService,
    public routes: RoutesService,
    private lang: LangService,
    public itemEditCtrl: ItemEditCtrl
  ){
    this.initAudits()
    this.initAuditAutoFixes()
  }

  destroy() {

  }

  async runCustomCleaningPatch(audit:IAuditConfig, patchSlug:string){
    const auditSlug = audit.slug;
    if (audit.isCompleteFix){
      const patch = audit.autoFixes.find(el => el.slug == patchSlug)
      // const {questions} = await this.renderAuditResultsModel(auditSlug); // todo: sub optimal. should be getting the list of questions from the parent check
      this.runQuestionAutoFixes( patchSlug, [patch.checkCompositeSlug], async (questionFixing: IQuestionConfig) => {
        await processPatch(audit, patch, questionFixing, {
          itemBankCtrl: this.itemBankCtrl,
          frameworkCtrl: this.frameworkCtrl,
        })
      })
    }
    else {
      if (typeof this.customAutoFixes[patchSlug] === 'function') {
        await this.customAutoFixes[patchSlug](auditSlug, patchSlug);
      }
      else {
        alert('Cleaning fix is not configured for '+patchSlug)
      }
    }
  }
  
    
  async runCustomAudit(auditSlug:string, scope:AuditQuestionScope, isLangSensitive:boolean = true){
    this.markAuditRunning(auditSlug, true);
    try{
      const auditResultsModel = await this.renderAuditResultsModel(auditSlug, scope, isLangSensitive);
      let skipResultsRollUp = false
      // console.log(auditSlug, typeof this.customIterators[auditSlug])
      if (typeof this.customIterators[auditSlug] === 'function') {
        const {isCustomResults} = await this.customIterators[auditSlug](auditResultsModel);
        skipResultsRollUp = isCustomResults
      }
      else if (!auditResultsModel.audit.isCompleteCheck) { // skip 
        alert('Audit is not configured for ' + auditSlug)
      }

      const commonCheckProps = auditResultsModel.audit.commonCheckProps || {};
      const checks = auditResultsModel.audit?.checks?.map(check => _.merge({}, commonCheckProps, check)) || []; // side effect, breaks symbolic link
      for (let check of checks){
        if (check.checkType){
          await processCheck(check, auditResultsModel.auditResultsMap, auditResultsModel.questions, {
            itemBankCtrl: this.itemBankCtrl,
            frameworkCtrl: this.frameworkCtrl,
          })
        }
      }
      if (!skipResultsRollUp){
        this.populateResultsInAuditQuestionMem(auditResultsModel.checkIds, auditSlug, auditResultsModel.auditResults, auditResultsModel.auditResultsMap);
        const results = this.getFormattedResults(auditResultsModel.checkIds, auditResultsModel.auditResultsMap);  
        const isTestletOnly = false;
        const isRan = true;
        this.markAuditAsRan(auditSlug, isTestletOnly, isRan, results); 
      }
    } catch(e){
      console.error(e);
      this.auditsWithError[auditSlug] = true
    }
    this.markAuditRunning(auditSlug, false);
  }

  
  private getAuditBySlug(auditSlug:string) : IAuditConfig | null {
    for (let audit of AuditConfigs){ // todo: this should be coming from the DB
      if (audit.slug === auditSlug){
        return audit;
      }
    }
    return null;
  }

  private async renderAuditResultsModel(auditSlug:string, scope:AuditQuestionScope, isLangSensitive:boolean = true) : Promise<AuditResultCore> {
    const audit = this.getAuditBySlug(auditSlug);
    let checkIds = [];
    const auditResults:IAuditResult[] = [];
    if (audit && audit.checks){
      checkIds = audit.checks.map(check => check.id);
      for (let check of audit.checks){
        auditResults.push({
          id: check.id, 
          caption: check.caption,
          typeCaption: check.typeCaption,
          type: check.checkElType || audit?.commonCheckProps?.checkElType || 'QUESTION',
          items: [], 
        })
      }
    }
    const questions = await this.getQuestionsByScope(scope, isLangSensitive);
    const auditResultsMap: Map<string, IAuditResult> = new Map();
    auditResults.forEach(result => auditResultsMap.set(result.id, result))
    return {
      audit, // should be read only, please
      auditSlug,
      checkIds,
      questions,
      auditResults, 
      auditResultsMap,
    }
  }

  private populateResultsInAuditQuestionMem(checkIds:string[], auditSlug, auditResults, auditResultsMap) {
    this.auditQuestionMem[auditSlug+"_RESULTS"] = auditResults;
    for (let checkId of checkIds){
      // .filter(key => !isNaN(Number(CheckId[key])))
      this.auditQuestionMem[auditSlug+"_"+checkId] = auditResultsMap.get(checkId).items.map(i => this.itemBankCtrl.getQuestionById(i.id));
    }
  }
  
  private getFormattedResults(checkIds:string[], auditResultsMap) {
    const results : IAuditFormattedResult[] =  []
    for (let checkId of checkIds){
      // .filter(key => !isNaN(Number(CheckId[key])))
      const result = auditResultsMap.get(checkId);
      results.push({
        key: checkId,
        description: result.caption,
        isAutoFixable: result.isAutoFixable || false,
        items: result.items?.map(i => this.itemBankCtrl.getQuestionById(i.id)),
        num_issues: result.items?.length ?? 0
      })
    };
    return results;
  }
  
  private async markAuditAsRan(auditSlug: string, isTestletsOnly: boolean, isRan: boolean= true, results?: any) {
    this.auditsRan[auditSlug] = isRan;
    if (isTestletsOnly) {
      this.auditsRan[auditSlug + '/TESTLETS'] = isRan;
    }
    let num_issues = 0
    results.forEach(result =>{
      num_issues += result.num_issues ?? result.items?.length ?? 0;
    })
    const formattedResults:any = {
      item_set_id: this.itemBankCtrl.customTaskSetId, 
      audit_slug: auditSlug, 
      audit_results: JSON.stringify(results),
      lang: this.lang.c(),
      num_issues
    }
    // log
    await this.auth.apiCreate(this.routes.TEST_AUTH_ITEM_SET_AUDITS, formattedResults);
    formattedResults.audited_on = new Date();
    formattedResults.audited_by_uid = this.auth.getUid();
    this.updateAudit(auditSlug, formattedResults);
  }

  updateAudit(auditSlug:string, formattedResults:any){
    const auditIndex = this.auditLogs.findIndex(log => log.audit_slug == auditSlug);
    this.auditLogs[auditIndex] = formattedResults;
  }

  // resets the state to all listed audits so that they look "ready to run" as opposed to "already run"
  refreshAudit(auditSlugs:string[]) {
    auditSlugs.forEach(slug => {
      if(this.auditQuestionMem.hasOwnProperty(slug)){
        delete this.auditQuestionMem[slug];
      }
      if(this.auditsRan.hasOwnProperty(slug)){
        delete this.auditsRan[slug];
      }
    });
    this.activateAuditQuestions(null); // todo: clarify why this is needed
  }
  
  // generalized autofix algorithm that runs in series for any process that requires it (this cannot function without a process fix)
  runQuestionAutoFixes(fixFlag:string, questionMems:string[], processFix:(question:IQuestionConfig)=>Promise<any> ) {
    return new Promise( (resolve, reject) => {
      this.auditQuestionMem[fixFlag] = {i:0, n:1};
      let questions:IQuestionConfig[] = [];
      questionMems.forEach(questionMemKey => {
        questions = questions.concat(this.auditQuestionMem[questionMemKey]);
      })
      this.auditQuestionMem[fixFlag].n = questions.length - 1 
      const questionLabels = [];
      questions.forEach(question => {
        if (questionLabels.indexOf(question?.label) === -1){
          questionLabels.push(question?.label);
        }
      })
      let currentQuestionIndex = 0;
      let currentQuestion;
      const fixNextQuestion = () => {
        this.auditQuestionMem[fixFlag].i = currentQuestionIndex
        if (currentQuestionIndex >= questionLabels.length){
          this.itemBankCtrl.selectQuestion(currentQuestion, true)
          this.auditQuestionMem[fixFlag] = false;
          alert('All questions have been fixed where possible. Please refresh the page in order to run another audit.');
          return;
        }
        const questionLabel = questionLabels[currentQuestionIndex];
        currentQuestion = this.itemBankCtrl.getQuestionByLabel(questionLabel);
        this.itemBankCtrl.selectQuestion(currentQuestion).then(()=>{
          currentQuestion = this.itemBankCtrl.getQuestionByLabel(questionLabel);
          processFix(currentQuestion).then(() => {
            currentQuestionIndex++;
            fixNextQuestion();
          })
        })
      }
      if (questionLabels.length > 0){
        fixNextQuestion()
      }
      else{
        this.auditQuestionMem[fixFlag] = false;
        resolve(undefined);
      }
    })
  }
      
  afterQuestionAutoFixes(){
    alert('There are no questions that can be auto-fixed.');
  }
          
  checkActiveQuestionMem(auditMemId: string) {
    if (this.activeAuditQuestionMemId === auditMemId) {
      return true;
    }
  }

  activateAuditQuestions(auditMemId: string) {
    this.activeAuditQuestionMemId = auditMemId;
    this.itemBankCtrl.currentItemListPage = 1;
    this.itemFilterCtrl.updateItemFilter();
    this.frameworkCtrl.scrollToQuestionListing();
  }
  
  // brought over from ABED
  initAuditAutoFixes(){

    this.customAutoFixes['CLONES_IS_AUTOFIXING'] = async (auditSlug:string, patchSlug:string) => {
      const cloneLinkParam = this.frameworkCtrl.identifySpecialParams(PARAM_SPECIAL_FLAGS.CLONE_LINKER)[0];
      this.runQuestionAutoFixes(
        'CLONES_IS_AUTOFIXING',
        [
          'Q_CLONES_MISSING_PARAM',
          'Q_CLONES_INVALID_PARAM',
        ],
        (questionFixing: IQuestionConfig) => {
          const question = questionFixing;
          const getCloneLabelCore = (label: string) => label.split('/')[0];
          const labelCore = getCloneLabelCore(question.label);
          question.meta[cloneLinkParam.code] = labelCore;
          return Promise.resolve();
        }
      )
      .then(() => this.afterQuestionAutoFixes() )
    }

    this.customAutoFixes['EXP_ANS_IS_AUTOFIXING'] = async (auditSlug:string, patchSlug:string) => {
      const expAnsParam = this.frameworkCtrl.identifySingleEntryParams()[0];
      this.runQuestionAutoFixes(
        'EXP_ANS_IS_AUTOFIXING',
        [
          'Q_EXP_ANS_MISSING',
          'Q_EXP_ANS_MISMATCH',
        ],
        (questionFixing: IQuestionConfig) => {
          const question = questionFixing;
          let referenceVal;
          if (this.itemBankCtrl.isLangEnabled('en')){
            referenceVal = getQuestionConfiguredEA(question.content, expAnsParam, {question});
          }
          else{
            referenceVal = getQuestionConfiguredEA(question.langLink.content, expAnsParam, {question});
          }
          question.meta[expAnsParam.code] = referenceVal;
          return Promise.resolve();
        }
      )
      .then(() => this.afterQuestionAutoFixes() )
    }

    this.customAutoFixes['VOICEOVER_IS_AUTOFIXING'] = async (auditSlug:string, patchSlug:string) => {
      // const auditResultsMap = this.voiceoverAuditResults;
      enum LangId { EN = 'en', FR = 'fr' }
      enum CheckId { 'MISSING_OVERALL_VOICE', 'MISSING_OPTION_VOICE', 'CONTAIN_DUPLICATED_OPTION_VOICE' };
      const langs = [LangId.EN, LangId.FR];
      const renderAuditId = (checkId:CheckId, langId:LangId) => CheckId[checkId]+'/'+langId;
      const busyFlag = patchSlug;
      Promise.all( 
        langs.map( langId => Promise.all([
          this.runQuestionAutoFixes( busyFlag, [renderAuditId(CheckId.MISSING_OVERALL_VOICE, langId)], (questionFixing:IQuestionConfig) => {
            let question = questionFixing;
            if (langId === 'fr'){
              question = question.langLink;
            }
            let script = this.scriptGen.autoGenQuestionVoiceover(question, langId, true);
            return this.scriptGen.uploadNewVoice(script, question.voiceover, langId)
          }),
          this.runQuestionAutoFixes( busyFlag, [renderAuditId(CheckId.MISSING_OPTION_VOICE, langId), renderAuditId(CheckId.CONTAIN_DUPLICATED_OPTION_VOICE, langId)], (questionFixing:IQuestionConfig) => {
            let question = questionFixing;
            if (langId === 'fr'){
              question = question.langLink;
            }
            console.log('fixing options', langId, questionFixing.label, question.voiceover);
            const entries = identifyQuestionResponseEntries(question.content, []);
            return Promise.all(
              entries.map(entry => {
                if (entry.elementType === ElementType.MCQ){
                  const mcqEntry:IContentElementMcq = <any> entry;
                  if (mcqEntry.options){
                    return Promise.all(
                      mcqEntry.options.map((option, option_index) => {
                        const scriptMeta:any = {
                          optionScripts: [],
                          useOldScripts: true,
                          useOldScriptsDecision: true,
                        }
                        return this.scriptGen.extractScriptFromMcqNodeAsync(mcqEntry, langId, scriptMeta, []);
                      })
                    )
                  }
                }
              })
              )
            }),
          ]))
        )
      .then(() => this.afterQuestionAutoFixes() )
    }

    this.customAutoFixes['POSSIBLE_EXPECTED_ANSWER_AUDIT_IS_AUTOFIXING'] = async() => {
      const ranCode = 'POSSIBLE_EXPECTED_ANSWER_AUDIT';
      const singleMcqMissingSimulatedSub = ranCode + '_' + 'SINGLE_MCQ_MISSING_POSSIBLE_EXPECTED_ANSWER';

      const lang = this.lang.c();
      const questions: IQuestionConfig[] = this.auditQuestionMem[singleMcqMissingSimulatedSub];
      const MCQ_OPTION_MAP = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');

      const failedAddingSimulatedSub = [];
      const successAddingSimulatedSubQs = new Set();

      const getMcqEntryState = (): IEntryStateMcq => {
        return {
          type: 'mcq',
          isCorrect: false,
          isStarted: false,
          isFilled: false,
          isResponded: false,
          selections: [],
          alreadyScrambled: undefined,
          score:  0,
          weight: 0,
          scoring_type: ScoringTypes.AUTO, 
        }
      };

      const promises: Promise<any>[] = []

      for (const qConfig of questions) {
        const contents = this.getQuestionContent(qConfig);
        const scorableContent = this.getQuestionContentEntryElements(contents, true);
        const el = (<IContentElementMcq>scorableContent[0]);

        const expectedAnswers = (await this.itemEditCtrl.findAllExpectedAnswer(+qConfig.id))?.filter(ea => ea.lang === lang) ;
        
        const expectedAnswersSet = new Set();
        expectedAnswers.forEach(ans => { expectedAnswersSet.add(ans.formatted_response.trim()) });

        const mcqOptions: IContentElementMcqOption[] = el.options;
        const weight = getElementWeight(el);

        mcqOptions?.forEach((option, i) => {
          const currentOptionAsAns = MCQ_OPTION_MAP[i];

          const isCorrect = option.isCorrect
          if(!expectedAnswersSet.has(currentOptionAsAns)){
            // add to formatted resp
            const entryState = getMcqEntryState();
            entryState.isFilled = true;
            entryState.isResponded = true;
            entryState.isStarted = true;
            entryState.isCorrect = isCorrect;
            entryState.score = isCorrect ? weight : 0;
            entryState.weight = weight
            entryState.selections = [{
                i,
                id: option.optionId,
                elementType: option.elementType,
                content: option.elementType === ElementType.IMAGE ?  option.url : option.content                 
            }]

            const response = { [el.entryId] : entryState }                
            const p = this.addFormattedAnswerToSimulatedSubmission(qConfig, JSON.stringify(response))
            .then((res) => successAddingSimulatedSubQs.add(+qConfig.id))                 
            .catch((e) => failedAddingSimulatedSub.push({label: qConfig.label , id: qConfig.id, option}))
            promises.push(p);
          }
        });
        
        
      }
      
      await Promise.all(promises);

      this.auditQuestionMem[singleMcqMissingSimulatedSub] = questions?.filter((q) => !successAddingSimulatedSubQs.has(+q.id))

      if(failedAddingSimulatedSub.length){
        alert("some of questions failed to add simulated submissions. Please run the fix again.");
        console.log("FAILED_ADDING_SIMULATED_SUB",failedAddingSimulatedSub);
      } else {
        alert('fixed Single MCQ question adding all possible simulated submissions');
      }
    }

  }

  private getQuestionContentEntryElements(elements: IContentElement[], isAutoScoreable:boolean) {
    return <IContentElement[]>identifyQuestionResponseEntries(elements, [], isAutoScoreable);
  }
  
  async addFormattedAnswerToSimulatedSubmission(qConfig: IQuestionConfig, response_raw:string) {
      
    const data = {
      response_raw,
      test_question_id: +qConfig.id,
      item_set_id: this.itemBankCtrl.customTaskSetId,
      lang: this.lang.c(),
      create_expected_response: true
    }

    return this.auth.apiCreate(this.routes.SIMULATE_EXTRACT_RESPONSE, data);      
  }

  trackQuestionIssues = (key: string, isFixable: boolean, questionList: IQuestionConfig[], description: string, issueCategories: any[], totalIssues: number) => {
    this.auditQuestionMem[key] = questionList;
    const numIssues = questionList?.length;
    this.auditQuestionMem['NUM_' + key] = numIssues;
    issueCategories.push({
      key,
      isFixable,
      description
    });
    totalIssues += numIssues;
  };

  private getCurrentContext(lang?):IContext{
    return {
      itemBankCtrl: this.itemBankCtrl,
      frameworkCtrl: this.frameworkCtrl,
      lang: this.lang.c()
    }
  }

  initAudits(){
    // 1
    this.customIterators['QUESTION_UTIL'] = async (auditResultsModel:AuditResultCore) => {
        
      const {checkIds, auditResults, auditResultsMap} = auditResultsModel;

      enum CheckId {
        UTIL_TESTLET = 'UTIL_TESTLET',
        UTIL_QUAD = 'UTIL_QUAD'
      };

      const quadrantQuestionRef = new Map();
      const testletQuestionRef = new Map();
      this.quadrantCtrl.refreshQuadrantItems();
      this.frameworkCtrl.asmtFmrk.quadrantItems.forEach(quadrantItemSet => {
        quadrantItemSet.questions.forEach(question => {
          quadrantQuestionRef.set(question.label, true);
        });
      });
      this.frameworkCtrl.asmtFmrk.testlets.forEach(testlet => {
        testlet.questions.forEach(question => {
          testletQuestionRef.set(question.label, true);
        });
      });
      this.itemBankCtrl.getItems().forEach(question => {
        if (!quadrantQuestionRef.get(question.label)) {
          auditResultsMap.get(CheckId.UTIL_QUAD).items.push(question)
          // questionNoQuadrant.push(question);
        }
        if (!testletQuestionRef.get(question.label)) {
          auditResultsMap.get(CheckId.UTIL_TESTLET).items.push(question)
          // questionNoTestlet.push(question);
        }
      });
      this.auditQuestionMem['NUM_Q_NO_QUAD'] = auditResultsMap.get(CheckId.UTIL_QUAD).items.length;
      this.auditQuestionMem['NUM_Q_NO_TESTLET'] = auditResultsMap.get(CheckId.UTIL_TESTLET).items.length;

      return {}
    }

    // 2
    this.customIterators['QUESTION_LABEL'] = async (auditResultsModel:AuditResultCore) => {
      
      const {questions, checkIds, auditResults, auditResultsMap} = auditResultsModel;

      enum CheckId {
        Q_LABEL_DUPE = 'Q_LABEL_DUPE'
      };

      const duplicates = [];
      const questionsSeen = new Map();
      const questionsFirst = new Map();
      for (const question of questions){
        const label = question.label;
        if (questionsSeen.has(label)) {
          duplicates.push(question);
          const previousQuestion = questionsFirst.get(label);
          if (previousQuestion) {
            duplicates.push(question);
            questionsFirst.set(label, false);
          }
        } else {
          questionsSeen.set(label, true);
          questionsFirst.set(label, question);
        }
      }
      auditResultsMap.get(CheckId.Q_LABEL_DUPE).items.push(...duplicates);
      this.auditQuestionMem['NUM_Q_LABEL_DUPE'] = duplicates.length;

      return {}
    }

    // 3
    this.customIterators['EXP_ANS'] = async (auditResultsModel:AuditResultCore) => {
      
      const {auditSlug, questions, checkIds, auditResults, auditResultsMap} = auditResultsModel;

      const isTestletsOnly = false;

      const questionsMissingParam = [];
      const questionsMismatchParam = [];
      const questionsMismatchLang = [];
      const questionsNoEntry = [];
      const questionsMultiEntry = [];
      const questionsNotMcq = [];
      const questionsOutRange = [];
      const questionsNoAccepted = [];
      const questionsMultiAccepted = [];
      const trackingParams = this.frameworkCtrl.identifySingleEntryParams();
      if (trackingParams.length === 0) {
        alert('This audit can only run if there is exactly one parameter that is indicated as the Response Entry Value');
        throw new Error();
        // return this.markAuditAsRan(auditSlug, isTestletsOnly, false);
      }
      if (trackingParams.length > 1) {
        alert('This audit is not compatible with frameworks which contain multiple Response Entry Value parameters');
        throw new Error();
        // return this.markAuditAsRan(auditSlug, isTestletsOnly, false);
      }
      const expAnsParam = trackingParams[0];
      questions.forEach(question => {
        const context = {
          question,
          questionsMissingParam,
          questionsNotMcq,
          questionsOutRange,
          questionsNoEntry,
          questionsMultiEntry,
          questionsNoAccepted,
          questionsMultiAccepted,
        };
        let referenceVal;
        referenceVal = getQuestionConfiguredEA(question.content, expAnsParam, context);

        let valParam = question.meta[expAnsParam.code];
        if (valParam || valParam === 0) { valParam = '' + valParam; }
        if(context.questionsNotMcq.indexOf(question) === -1 && context.questionsNoEntry.indexOf(question) === -1 ){          
          if (!valParam) {
            questionsMissingParam.push(question);
          } 
          else if (valParam !== referenceVal) {
            questionsMismatchParam.push(question);
          }
        }
      });
      let totalIssues = 0;
      let issueCategories = [];
      this.trackQuestionIssues('Q_EXP_ANS_MISSING',       true,  questionsMissingParam, `MCQ items missing \`EA\` Param`, issueCategories, totalIssues);
      this.trackQuestionIssues('Q_EXP_ANS_MISMATCH',      true,  questionsMismatchParam, `MCQ items configuration doesn\'t match \`EA\` param`, issueCategories, totalIssues);
      if (this.itemBankCtrl.isMultiLingual()){
        this.trackQuestionIssues('Q_EXP_ANS_LANG_MISMATCH', false, questionsMismatchLang, `contain(s) different expected answers between languages`, issueCategories, totalIssues);
      }
      this.trackQuestionIssues('Q_EXP_ANS_MULTI_ENTRY',   false, questionsMultiEntry, `contain(s) more than one response entry `, issueCategories, totalIssues);
      this.trackQuestionIssues('Q_EXP_ANS_NO_ENTRY',      false, questionsNoEntry, `contain(s) no response entry `, issueCategories, totalIssues);
      this.trackQuestionIssues('Q_EXP_ANS_NOT_MCQ',       false, questionsNotMcq, `contain(s) non-MCQ responses`, issueCategories, totalIssues);
      this.trackQuestionIssues('Q_EXP_ANS_OUT_RANGE',     false, questionsOutRange, `contain(s) response entries that are out of the range that is specified in the framework parameter's list`, issueCategories, totalIssues);
      this.trackQuestionIssues('Q_EXP_ANS_NO_ACC',        false, questionsNoAccepted, `contain(s) no specified correct answer `, issueCategories, totalIssues);
      this.trackQuestionIssues('Q_EXP_ANS_MULTI_ACC',     false, questionsMultiAccepted, `contain(s) multiple specified correct responses `, issueCategories, totalIssues);
      
      this.auditQuestionMem['NUM_EXP_ANS_ISSUES'] = totalIssues;
      this.auditQuestionMem['EXP_ANS_CATEGORIES'] = issueCategories;

      const results = issueCategories.map(category => {
        const items = this.auditQuestionMem[category.key]
        return {
          ...category,
          items
        }
      });
    
      this.markAuditAsRan(auditSlug, isTestletsOnly, true, results);

      return {isCustomResults: true}
    }

    // 4
    this.customIterators['CLONES'] = async (auditResultsModel:AuditResultCore) => {
      
      const {auditSlug, questions, checkIds, auditResults, auditResultsMap} = auditResultsModel;
      
      const trackingParams = this.frameworkCtrl.identifySpecialParams(PARAM_SPECIAL_FLAGS.CLONE_LINKER);
      if (trackingParams.length === 0) {
        alert('This audit can only run if there is exactly one parameter that is indicated as the Clone Linker');
        // return this.auditsRan['CLONES'] = false;
        throw new Error();
      }
      if (trackingParams.length > 1) {
        alert('This audit is not compatible with frameworks which contain multiple Clone Linker parameters');
        // return this.auditsRan['CLONES'] = false;
        throw new Error();
      }
      const cloneIndicParam = trackingParams[0];
      const questionsNoCloneIndic = [];
      const questionsInvalidCloneIndic = [];
      const clonedQuestionsRef = new Map();
      const seenQuestionsRef = new Map();
      const getCloneLabelCore = (label: string) => label.split('/')[0];
      // this.itemBankCtrl.getItems()
      questions.forEach(question => {
        const labelCore = getCloneLabelCore(question.label);
        if (seenQuestionsRef.get(labelCore)) {
          clonedQuestionsRef.set(labelCore, true);
        }
        seenQuestionsRef.set(labelCore, true);
      });
      this.itemBankCtrl.getItems().forEach(question => {
        const labelCore = getCloneLabelCore(question.label);
        if (clonedQuestionsRef.get(labelCore)) {
          if (!question.meta[cloneIndicParam.code]) {
            questionsNoCloneIndic.push(question);
          } 
          else if (question.meta[cloneIndicParam.code] !== labelCore) {
            questionsInvalidCloneIndic.push(question);
          }
        }
      });
      let totalIssues = 0;
      let issueCategories = [];
      this.trackQuestionIssues('Q_CLONES_MISSING_PARAM', true,  questionsNoCloneIndic, `belong to a clone group but do not have the indicated parameter`, issueCategories, totalIssues);
      this.trackQuestionIssues('Q_CLONES_INVALID_PARAM', true,  questionsInvalidCloneIndic, `belong to a clone group but may not be properly identified/categorized`, issueCategories, totalIssues);
      this.auditQuestionMem['CLONES_CATEGORIES'] = issueCategories;
      this.auditQuestionMem['N_CLONES_ISSUES'] = totalIssues;
      const results = issueCategories.map(category => {
        const items = this.auditQuestionMem[category.key]
        return {
          ...category,
          items
        }
      });
      this.markAuditAsRan(auditSlug, false, true, results);
    
      return {isCustomResults: true}
    }

    // 5
    this.customIterators['ENTRY_ID'] = async (auditResultsModel:AuditResultCore) => {

      const {questions, auditSlug, checkIds, auditResults, auditResultsMap} = auditResultsModel;

      const questionsWithUntrackedEntries = [];
      questions.forEach(question => {
        const content = this.getQuestionContent(question)
        const tracker = collectAndEnsureEntryIds(content);
        if (tracker.nonIndicatedEntries.length > 0) {
          questionsWithUntrackedEntries.push(question);
        }
      });
      let totalIssues = 0;
      let issueCategories = [];
      this.trackQuestionIssues('Q_EXP_ANS_MULTI_ACC',     false, questionsWithUntrackedEntries, this.lang.tra('audit_untracked_entries'), issueCategories, totalIssues);
      this.auditQuestionMem['ENTRY_ID_CATEGORIES'] = issueCategories;
      const results = issueCategories.map(category => {
        const items = this.auditQuestionMem[category.key]
        return {
          ...category,
          items
        }
      });

      const isTestletsOnly = false
      this.markAuditAsRan(auditSlug, isTestletsOnly, true, results);
    
      return {isCustomResults: true}
    }

    // 6
    this.customIterators['VOICEOVER'] = async (auditResultsModel:AuditResultCore) => {

      const {auditSlug, questions, checkIds, auditResultsMap} = auditResultsModel;
      let {auditResults} = auditResultsModel
      enum CheckId { 
        MISSING_OVERALL_VOICE = 'MISSING_OVERALL_VOICE', 
        MISSING_OPTION_VOICE = 'MISSING_OPTION_VOICE', 
        CONTAIN_DUPLICATED_OPTION_VOICE = 'CONTAIN_DUPLICATED_OPTION_VOICE' 
      };

      const langs = [ this.lang.c() ];
      
      const renderAuditId = (checkId:string, langId:string) => CheckId[checkId]+'/'+langId;
      const getAuditResultList = (checkId:CheckId, langId:string) => {
        const id = renderAuditId(checkId, langId);
        return auditResultsMap.get(id);
      }
      const addAuditResult = (checkId:CheckId, langId:string, question:IQuestionConfig) => {
        getAuditResultList(checkId, langId).list.push(question);
      }
      langs.forEach(langId => {
        checkIds.forEach(checkId => {
          const id = renderAuditId(checkId, langId);
          const list:IAuditResult = {
            id, 
            caption: `${CheckId[checkId].split('_').join(' ').toLowerCase()} (${langId})`,
            list:[]
          };
          auditResults.push(list);
          auditResults = auditResults.filter(result => result.id !== checkId);
          auditResultsMap.set(id, list);
          auditResultsMap.delete(checkId);
        });
      });
      this.voiceoverAuditResults = auditResultsMap;
      
      questions.forEach(question => {
        langs.forEach(langId => {
          // console.log(question.label, 'audit in ', langId)
          let questionTarget = question;
          if (langId === LangId.FR){
            questionTarget = question.langLink;
          }
          if (!questionTarget.voiceover || !questionTarget.voiceover.url){
            addAuditResult(CheckId.MISSING_OVERALL_VOICE, langId, question);
          }
          const entries = identifyQuestionResponseEntries(questionTarget.content, []);
          const voiceUploadMap:Map<string, boolean> = new Map();
          let containsDuplicateVoiceover = false;
          let containsMissingVoiceover = false;
          entries.forEach(entry => {
            if (entry.elementType === ElementType.MCQ){
              const mcqEntry:IContentElementMcq = <any> entry;
              if (mcqEntry.options){
                mcqEntry.options.forEach((option, option_index) => {
                  if (option.voiceover && option.voiceover.url){
                    const url = option.voiceover.url;
                    if (voiceUploadMap.has(url)){
                      containsDuplicateVoiceover = true;
                      console.warn(question.label, 'MC Entry has duplicate option voice', option_index, `(${langId})`)
                    }
                    voiceUploadMap.set(url, true)
                  }
                  else{
                    containsMissingVoiceover = true;
                  }
                })
              }
              else{
                console.warn(question.label, 'MC Entry has no options', entry)
              }
            }
          });
          if (containsDuplicateVoiceover){
            addAuditResult(CheckId.CONTAIN_DUPLICATED_OPTION_VOICE, langId, question);
          }
          if (containsMissingVoiceover){
            addAuditResult(CheckId.MISSING_OPTION_VOICE, langId, question);
          }
        });
      });
      let totalIssues = 0;
      let issueCategories = [];
      auditResults.forEach(auditResult => {
        this.trackQuestionIssues(auditResult.id, true, auditResult.list, auditResult.caption, issueCategories, totalIssues);
      })
      this.auditQuestionMem[auditSlug+'_CATEGORIES'] = issueCategories;
      const results = issueCategories.map(category => {
        const items = this.auditQuestionMem[category.key]
        return {
          ...category,
          items
        }
      });
      const isTestletsOnly = false;
      this.markAuditAsRan(auditSlug, isTestletsOnly, true, results);
    
      return {isCustomResults: true}
    }

    // 7
    this.customIterators['IP_RIGHTS'] = async (auditResultsModel:AuditResultCore) => {

      const {questions, checkIds, auditResults, auditResultsMap} = auditResultsModel;

      enum CheckId {
        NOT_COMPLETED = 'NOT_COMPLETED', 
        NOT_INCLUDED_EN = 'NOT_INCLUDED_EN',
        NOT_INCLUDED_FR = 'NOT_INCLUDED_FR',
        ALLOWED_IMPRESSIONS_NUM = 'ALLOWED_IMPRESSIONS_NUM' 
      };

      let assetIdSet = new Set<number>();
      let thisAssessmentAssetSet = new Set<number>();
      const elementsWithoutAssetIdMapEn = new Map<IQuestionConfig, IContentElement[]>();
      const elementsWithoutAssetIdMapFr = new Map<IQuestionConfig, IContentElement[]>();
      let elementsWithoutAssetIdEn: IQuestionConfig[] = [];
      let elementsWithoutAssetIdFr: IQuestionConfig[] = [];
      const getAssetIds = (
          elements: IContentElement[], 
          root: IQuestionConfig, 
          assetSet: Set<number>, 
          noAssetIdMap: Map<IQuestionConfig, IContentElement[]>
      ) => {
        if (root.assetId) {
          assetSet.add(root.assetId);
        }
        if (elements) {
          for(const element of elements) {
            if(element.assetId) {
              assetSet.add(element.assetId);
            } else if (element.url && ['image','video','audio'].indexOf(element.elementType) !== -1) {
              let list = noAssetIdMap.get(root);
              if (!list) { list = []; noAssetIdMap.set(root, list)}
              //console.log(`no assetId for elementType "${element.elementType}" with URL: ${element.url} in question ${root.id}`);
              list.push(element);
            }
            getAssetIds(getElementChildren(element), root, assetSet, noAssetIdMap);
          }
        }
      }
      // limit to the assessment scope
      const questionStructs = questions.map(q => {return {
        root: q, 
        contentEn: this.getQuestionContent(q, 'en'), 
        contentFr: this.getQuestionContent(q, 'fr')
      }});
      for(const entry of questionStructs) {
        getAssetIds(entry.contentEn, entry.root, assetIdSet, elementsWithoutAssetIdMapEn);
        getAssetIds(entry.contentFr, entry.root, assetIdSet, elementsWithoutAssetIdMapFr);
      }
      elementsWithoutAssetIdEn = Array.from(elementsWithoutAssetIdMapEn.keys());
      elementsWithoutAssetIdFr = Array.from(elementsWithoutAssetIdMapFr.keys());
      auditResultsMap.get(CheckId.NOT_INCLUDED_EN).items = elementsWithoutAssetIdEn;
      auditResultsMap.get(CheckId.NOT_INCLUDED_FR).items = elementsWithoutAssetIdFr;
      const data = await this.auth.apiFind(this.routes.TEST_AUTH_ASSET, {})
      const assets: ILibraryAsset[] = data.data.filter(d => Array.from(assetIdSet).includes(d.asset_id));
      // CheckId.NOT_COMPLETED
      assets.forEach(asset => {
        if (asset.status !== EStatus.COMPLETED) {
          auditResultsMap.get(CheckId.NOT_COMPLETED).items.push(asset.asset_id)
        }
        if (asset.num_print_impressions && parseInt(asset.num_print_impressions) < asset.totalTestTakers && thisAssessmentAssetSet.has(asset.asset_id)) {
          auditResultsMap.get(CheckId.ALLOWED_IMPRESSIONS_NUM).items.push(asset.asset_id)
        }
      });

      return {}
    }

    // 8
    this.customIterators['ACCESSIBILITY'] = async (auditResultsModel:AuditResultCore) => {

      const {questions, checkIds, auditResults, auditResultsMap} = auditResultsModel;

      enum CheckId {
        IMAGES_MISSING_ALT_TEXT = 'IMAGES_MISSING_ALT_TEXT', 
        VIDEOS_MISSING_SUBTITLES = 'VIDEOS_MISSING_SUBTITLES', 
        AUDIO_FILES_MISSING_TRANSCRIPTS = 'AUDIO_FILES_MISSING_TRANSCRIPTS',
        ITEMS_USING_KEYBOARD_INACCESSIBLE_BLOCKS = 'ITEMS_USING_KEYBOARD_INACCESSIBLE_BLOCKS',
      };
      
      let inAccessibleQuestionSet = new Set<IQuestionConfig>();
      const isElementAccessible = (element: IContentElement): boolean => {
        let isAccessible = false;
        if (element.elementType) {
          const typeDef = ElementTypeDefs[element.elementType.toUpperCase()];
          if (typeDef) {
              isAccessible = (typeof typeDef.isKeyboardAccessible !== 'undefined') ? typeDef.isKeyboardAccessible : true;  
          } else {
            const accessibleExceptions: ElementType[] = [ElementType.DYNAMIC_IMAGE];
            if (accessibleExceptions.indexOf(element.elementType as ElementType) !== -1) {
              isAccessible = true;
            }
          }
        }
        return isAccessible;
      }
      const checkInaccessibleElements = (
          question: IQuestionConfig, 
          elements: IContentElement[], 
          questionSet: Set<IQuestionConfig>) => {
        if (elements) {
          for(const element of elements) {
            if (!isElementAccessible(element)) {
              questionSet.add(question)
            }
            checkInaccessibleElements(question, getElementChildren(element), questionSet);
          }
        }
      }

      for (const qConfig of questions){
        const qContent = this.getQuestionContent(qConfig);
        let isMissingAltText = false;
        let isMissingVideoSubTitles = false;
        let isMissingTranscript = false
        qContent.forEach(content => {
          switch(content.elementType){
            case ElementType.IMAGE:
              const imageContent =  (<IContentElementImage>content);
              if(!imageContent.altText) isMissingAltText = true;
              if(imageContent.hiContrastImg && !imageContent.hiContrastImg.altText) isMissingAltText = true;
              break;
            case ElementType.VIDEO:
              const videoContent = (<IContentElementVideo>content);
              if(!videoContent.subtitlesUrl) isMissingVideoSubTitles = true;
              break;
            case ElementType.AUDIO:
              const audioContent = (<IContentElementAudio>content);
              if(!audioContent.transcriptUrl) isMissingTranscript = true;
              break
          }
        });
        checkInaccessibleElements(qConfig, qContent, inAccessibleQuestionSet);
        if(isMissingAltText) auditResultsMap.get(CheckId.IMAGES_MISSING_ALT_TEXT).items.push(qConfig);
        if(isMissingVideoSubTitles) auditResultsMap.get(CheckId.VIDEOS_MISSING_SUBTITLES).items.push(qConfig);
        if(isMissingTranscript) auditResultsMap.get(CheckId.AUDIO_FILES_MISSING_TRANSCRIPTS).items.push(qConfig);
      }
      auditResultsMap.get(CheckId.ITEMS_USING_KEYBOARD_INACCESSIBLE_BLOCKS).items = Array.from(inAccessibleQuestionSet);
        
      return {}
    }

    // 9
    this.customIterators['PRINT_VERSION'] = async (auditResultsModel:AuditResultCore) => {

      const {questions, checkIds, auditResults, auditResultsMap} = auditResultsModel;

      enum CheckId {
        PRINT_FRIENDLY = 'PRINT_FRIENDLY',
      };

      let questionSet = new Set<IQuestionConfig>();
      const isElementPrintFriendly = (element: IContentElement): boolean => {
        let isPrintFriendly = true;
        if (element.elementType) {
          const typeDef = ElementTypeDefs[element.elementType.toUpperCase()];
          if (typeDef) {
            if (typeof typeDef.isPrintFriendly !== 'undefined') {
              isPrintFriendly = typeDef.isPrintFriendly;  
            } else { // special cases
                if (
                  (element.elementType === ElementType.INPUT && element.format === InputFormat.TEXT) ||
                  (element.elementType === ElementType.MCQ && element.displayStyle === McqDisplay.DROPDOWN) ||
                  (element.elementType === ElementType.TEXT && element.paragraphStyle === TextParagraphStyle.ANNOTATION)  
                ) {
                  isPrintFriendly = false;
                }
            }
          }
        }
        return isPrintFriendly;
      }
      const checkPrintFriendlyElements = (question: IQuestionConfig, elements: IContentElement[]) => {
        if (elements) {
          for(const element of elements) {
            if (!isElementPrintFriendly(element)) {
              questionSet.add(question)
            }
            checkPrintFriendlyElements(question, getElementChildren(element));
          }
        }
      }
      questions.forEach(question => {
        checkPrintFriendlyElements(question, this.getQuestionContent(question));
      })
      auditResultsMap.get(CheckId.PRINT_FRIENDLY).items = Array.from(questionSet);

      return {}
    }

    // 10
    // this.customIterators['MOBILE'] 

    // 11
    // this.customIterators['BROWSERLOCK']

    // 12
    this.customIterators['COMMENTS'] = async (auditResultsModel:AuditResultCore) => {

      const {questions, checkIds, auditResults, auditResultsMap} = auditResultsModel;

      enum CheckId {
        QS_W_OUTSTANDING_CMTS = 'QS_W_OUTSTANDING_CMTS',
        ASSETS_W_OUTSTANDING_CMTS = 'ASSETS_W_OUTSTANDING_CMTS',
      };
      
      const getAssetIds = (elements: IContentElement[], assetIdSet: Set<number>) => {
        if(!elements) {
          return;
        }
        for(const element of elements) {
          if(element.assetId) {
            assetIdSet.add(element.assetId);
          }
          getAssetIds(getElementChildren(element), assetIdSet);
        }
      }
      let assetIdSet = new Set<number>();
      const allContent = questions.map(q => {
        if (q.assetId) {
          assetIdSet.add(q.assetId)
        }
        return q.content
      });
      for(const content of allContent ) {
        getAssetIds(content, assetIdSet);
      }
      const assetIdArr = Array.from(assetIdSet.values());
      const res = await this.auth.apiGet(this.routes.TEST_AUTH_NOTES_AUDIT, this.itemBankCtrl.customTaskSetId, {query: {
        assetIds: assetIdArr
      }})
      const qIds = (!res || !res.unresolvedQIds) ? [] : res.unresolvedQIds.map( r => r.id);
      const aIds = (!res || !res.unresolvedAssetIds) ? [] : res.unresolvedAssetIds.map( a => a.id );  
      const assessmentQs = questions.filter( q => qIds.includes(q.id)) //this.itemBankCtrl.questions
      this.auditQuestionMem['NUM_QS_W_OUTSTANDING_CMTS'] = assessmentQs.length;
      this.auditQuestionMem['QS_W_OUTSTANDING_CMTS'] =  assessmentQs
      this.auditQuestionMem['NUM_ASSETS_W_OUTSTANDING_CMTS'] = aIds.length;
      this.auditQuestionMem['ASSETS_W_OUTSTANDING_CMTS'] = aIds;
      auditResultsMap.get(CheckId.ASSETS_W_OUTSTANDING_CMTS).items.push(...aIds)
      auditResultsMap.get(CheckId.QS_W_OUTSTANDING_CMTS).items.push(...assessmentQs)

      return {}
    }

    // 13
    // this.customIterators['ASSESSMENT_KEYS']

    // 14
    this.customIterators['COMMON_ITEMS'] = async (auditResultsModel:AuditResultCore) => {

      const {auditSlug, questions, checkIds, auditResults, auditResultsMap} = auditResultsModel;

      enum CheckId {
        COMMON_ITEMS = 'COMMON_ITEMS',
      }

      // phasing this out, but want to have a warning in case items are being skipped
      const questionsOld = this.itemBankCtrl.frameworkCtrl.getLinearFormQuestions()
      const questionIds = new Set(questions.map(q => q.id));
      const questionsMissed = questionsOld.filter(q => !questionIds.has(q.id));
      if (questionsMissed.length){
        console.warn('COMMON_ITEMS audit might be skipping some items. This may require developer investigation. Item discrepancy:', questionsMissed)
      }
      
      const groupid = this.itemBankCtrl.saveLoadCtrl.getGroupID().toString()
      //console.log(thisAssessment)
      if (!groupid) return;
      const res = await this.auth.apiGet(this.routes.TEST_AUTH_FRAMEWORKS_AUDIT, parseInt(groupid))
      const seenItemIds = new Map()
      const alreadyDuped = new Map()
      for (let i = 0;res[i];i++) {
        const obj = JSON.parse(res[i]["framework"])
        if (!obj || obj["testFormType"]!="LINEAR") {
          continue;
        }
        const sections = obj["sectionItems"]
        //console.log(sections)
        for (let s = 1;sections[s];s++) {
          const questions = sections[s]["questions"]
          for (let q = 0;questions[q];q++) {
            const id = questions[q].id
            if (id && seenItemIds.get(id) && !alreadyDuped.get(id)) {
              alreadyDuped.set(id, true)
            }
            seenItemIds.set(id, true)
          }
        }
      }
      const totalQuestions = []
      for (let i = 0;i<questions.length;i++) {
        const item = questions[i]
        if (alreadyDuped.has(item.id)) {
          totalQuestions.push(item)
        }
      }
      this.auditQuestionMem[auditSlug] = totalQuestions
      auditResultsMap.get(CheckId.COMMON_ITEMS).items.push(...totalQuestions)

      return {}
    }

    // 15
    this.customIterators['TRAX_PARAM'] = async (auditResultsModel:AuditResultCore) => {
      
      const {questions, checkIds, auditResults, auditResultsMap} = auditResultsModel;

      enum CheckId {
        ASMT_SESS_NUM = 'ASMT_SESS_NUM' ,
        ASMT_CODE = 'ASMT_CODE' ,
        ASMT_MARK = 'ASMT_MARK' ,
        ASMT_SCALE = 'ASMT_SCALE' ,
        ASMT_SESS_CHAR = 'ASMT_SESS_CHAR' 
      }
  
      questions.forEach((item:IQuestionConfig)=>{
        let asmt_session_numbers = false
        let assmt_code = false
        let mark_value = false
        let scale_factor = false
        let asmt_session_char = false;
        function isDigit(c) {
          return c>='0' && c<='9'
        }
        function isChar(c) {
          return (c>='a' && c<='z') || (c>='A' && c<='Z')
        }
        if (item.meta) {
          const assmt_sess = (item.meta["ASSMT_SESSION"])
          const code = (item.meta["ASSMT_CODE"])
          const mark = (item.meta["MARK_VALUE"])
          const scale = (item.meta["SCALE_FACTOR"])
          const section = (item.meta["ASSMT_SECTION"])
          if (assmt_sess) {
            if (assmt_sess.length==6) {
              asmt_session_numbers = true
              for (let i = 0;i<6;i++) {
                if (!isDigit(assmt_sess.charAt(i))) {
                  asmt_session_numbers = false
                }
              }
            }
          }
          if (code) {
            if (code.length==5) {
              assmt_code = true
              for (let i = 0;i<3;i++) {
                const c = code.charAt(i)
                if (!isChar(c)) {
                  assmt_code = false
                }
              }
              for (let i = 3;i<5;i++) {
                const c = code.charAt(i)
                if (!isDigit(c)) {
                  assmt_code = false
                }
              }
            }
          } 
          if (mark) {
            if (mark.length>0 && mark.length<3) {
              mark_value = true;
              for (let i = 0;i<mark.length;i++) {
                if (!isDigit(mark.charAt(i))) {
                  mark_value = false
                }
              }
            }
          }
          if (scale) {
            if (scale.length>0 && scale.length<9) {
              scale_factor = true;
              for (let i = 0;i<scale.length;i++) {
                if (!isDigit(scale.charAt(i))) {
                  scale_factor = false
                }
              }
            }
          }
          if (section && section.length>0) {
            const c = section.charAt(0)
            if (c=='A' || c=='B') {
              asmt_session_char = true
            }
          }
        }
        if (!asmt_session_numbers) auditResultsMap.get(CheckId.ASMT_SESS_NUM).items.push(item)
        if (!assmt_code) auditResultsMap.get(CheckId.ASMT_CODE).items.push(item)
        if (!mark_value) auditResultsMap.get(CheckId.ASMT_MARK).items.push(item)
        if (!scale_factor) auditResultsMap.get(CheckId.ASMT_SCALE).items.push(item)
        if (!asmt_session_char) auditResultsMap.get(CheckId.ASMT_SESS_CHAR).items.push(item)
      })

      return {}
    }

    // 16
    this.customIterators['MSCATITEMDUP_PARAM'] = async (auditResultsModel:AuditResultCore) => {
      
      const {auditSlug, checkIds, auditResults, auditResultsMap} = auditResultsModel;

      const panels = this.frameworkCtrl.asmtFmrk.panels;
      const panelAssembly = this.frameworkCtrl.asmtFmrk.panelAssembly;
      // create list of possibly-preceding modules
      const modulesByStage = new Map();
      const stageByModule = new Map();
      const violations = [];
      for (let module of panelAssembly.allModules){
        const {stageNumber} = module;
        if (!modulesByStage.get(+stageNumber)){
          modulesByStage.set(+stageNumber, []);
        }
        modulesByStage.get(+stageNumber).push(module.id);
        stageByModule.set(+module.id, stageNumber)
      }
      // 
      for (let panel of panels){
        const panelItemsByModule = new Map();
        for (let module of panel.modules){
          const {moduleId, __cached_itemIds} = module;
          panelItemsByModule.set(+moduleId, __cached_itemIds)
        }
        // check for duplicates
        for (let module of panel.modules){
          const {moduleId, __cached_itemIds} = module;
          const itemIds = __cached_itemIds;
          const forbiddenPrevItemId = new Map();
          itemIds?.map(itemId => forbiddenPrevItemId.set(+itemId, true));
          const stageNumber = stageByModule.get(+moduleId);
          for (let prevStageNum = 1; prevStageNum < stageNumber; prevStageNum ++){
            const prevModuleIds = modulesByStage.get(+prevStageNum);
            for (let prevModuleId of prevModuleIds){
              const prevPanelItemIds = panelItemsByModule.get(+prevModuleId);
              console.log('compare', moduleId, 'prev', prevModuleId, prevPanelItemIds)
              prevPanelItemIds?.forEach(prevItemId => {
                if (forbiddenPrevItemId.get(+prevItemId)){
                  violations.push({
                    panelId: panel.id,
                    prevItemId,
                    prevModuleId,
                    prevStageNum,
                    stageNumber,
                    moduleId
                  })
                }
              })
            }
          }
        }
      }
      const results: IAuditFormattedResult[] = [{
        key: auditSlug,
        description: 'Duplicate items in panels(MSCAT)',
        isAutoFixable: false,
        items: violations,
        num_issues: violations?.length
      }]

      if (violations.length){
        console.log('violations', violations)
        alert(`Found ${violations.length} invalid cases of re-use (see console log)`)
      }
      else {
        alert('No invalid duplications found.')
      }

      this.markAuditAsRan(auditSlug, false, true, results)

      return {isCustomResults: true}
    }

    // 17
    this.customIterators['CONTENT_DIFF_AUDIT'] = async (auditResultsModel:AuditResultCore) => {

      const {checkIds, auditResults, auditResultsMap} = auditResultsModel;

      enum CheckId {
        CURRENT_CONTENT_DIFF_AUDIT = 'CURRENT_CONTENT_DIFF_AUDIT',
      }
  
      const test_design_id = parseInt(prompt("Enter the test design id :"));
      if(!test_design_id){
        alert('Please Enter valid test design id');
        throw new Error();
      }
      const data: any[] = await this.auth.apiFind(this.routes.TEST_DESIGN_QUESTION_VERSIONS, {query: {test_design_ids: [test_design_id]}})
      const parsedData = itemContentDiff(data, true, {itemBankCtrl: this.itemBankCtrl});
      const prefix = 'CONTENT_DIFF_AUDIT';
      this.auditQuestionMem[prefix] = parsedData;
      auditResultsMap.get(CheckId.CURRENT_CONTENT_DIFF_AUDIT).items.push(...parsedData)
      this.auditResultHeader = `${prefix} - Test design id: ${test_design_id} `;
  
      return {} 
    }

    // 18
    this.customIterators['PUBLISHED_CONTENT_DIFF_AUDIT'] = async (auditResultsModel:AuditResultCore) => {

      const {checkIds, auditResults, auditResultsMap} = auditResultsModel;

      const test_design_ids = prompt("Enter comma seperated test design ids :");
      const ids = test_design_ids.toString().split(',').map(id => id.trim()).filter(id => id != null);
      // console.log(ids)
      if(!ids.length || ids.length < 2){
        alert('Please Enter valid test design id');
        throw new Error();
      }
      const data: any[] = await this.auth.apiFind(this.routes.TEST_DESIGN_QUESTION_VERSIONS, {query: {test_design_ids: ids}})
      const parsedData = itemContentDiff(data, false, {itemBankCtrl: this.itemBankCtrl});
      const prefix = 'PUBLISHED_CONTENT_DIFF_AUDIT';
      this.auditQuestionMem[prefix] = parsedData;
      this.auditResultHeader = `${prefix} - Test design ids: ${ids} `;
      auditResultsMap.get(prefix).items.push(...parsedData)

      return {}
    }

    // 19
    this.customIterators['HUMAN_SCORED_AUDIT'] = async (auditResultsModel:AuditResultCore) => {
      const {questions, auditResultsMap} = auditResultsModel;
      enum checkID {
        MISSING_HUMAN_SCORED = 'MISSING_HUMAN_SCORED',
        MISSING_SCALES = 'MISSING_SCALES',
        SCALE_MISSING_PROFILE_ID = 'SCALE_MISSING_PROFILE_ID',
        MISSING_INPUT_BLOCK = 'MISSING_INPUT_BLOCK',
        MISCONFIGURED_SCORING_SCALE = 'MISCONFIGURED_SCORING_SCALE',
      }
      for(const item of questions){
        const scoringConfig = this.itemBankCtrl.getQuestionScoringInfo(+item.id);
        const qContent =  getQuestionContent(item, this.getCurrentContext());
        let containsTextBox = false; // todo: this is a sub-optimal approach, assumes text is not scored directly (sometimes it is)
        let isHumanScoredMissing = false;
        let missingScales = false;
        let missingScoreProfileID = false;
        let misconfiguredScale = false;
        qContent.forEach(content => {
          if(content.elementType == ElementType.INPUT && content.format == InputFormat.TEXT){
            containsTextBox = true;
          }
        });
        if(!scoringConfig.is_human_scored){
          isHumanScoredMissing = true;
        }
        if(scoringConfig.is_human_scored){
          let scales = JSON.parse(''+scoringConfig.scales);
          if(typeof scales !== 'string' && !scales?.length){
            missingScales = true;
          } else if(typeof scales === 'string'){
            misconfiguredScale = true;
          } else {
            for(let scale of scales){
              if(!scale.score_profile_id){
                missingScoreProfileID = true;
              }
            }
          }
        }
        if(isHumanScoredMissing){
          auditResultsMap.get(checkID.MISSING_HUMAN_SCORED).items.push(item);
        }
        if(missingScales){
          auditResultsMap.get(checkID.MISSING_SCALES).items.push(item);
        }
        if(missingScoreProfileID && !missingScales){
          auditResultsMap.get(checkID.SCALE_MISSING_PROFILE_ID).items.push(item);
        }
        if(!containsTextBox){
          auditResultsMap.get(checkID.MISSING_INPUT_BLOCK).items.push(item);
        }
        if(misconfiguredScale){
          auditResultsMap.get(checkID.MISCONFIGURED_SCORING_SCALE).items.push(item);
        }
      }
      return {}
    }

    // 20
    this.customIterators['SCORE_POINT_AUDIT'] = async (auditResultsModel:AuditResultCore) => {
      
      const {questions, auditResultsMap} = auditResultsModel;

      enum CheckId {
        ITEMS_WITH_SCORE_POINT_COUNT = 'ITEMS_WITH_SCORE_POINT_COUNT',
        MISSING_SCORE_POINT = 'MISSING_SCORE_POINT',
        INVALID_SCORE_POINT_ALIGNMENT = 'INVALID_SCORE_POINT_ALIGNMENT',
        INVALID_SCORE_POINT_EXCEEDING_TOTAL_WEIGHT = 'INVALID_SCORE_POINT_EXCEEDING_TOTAL_WEIGHT',
        INVALID_SCORE_POINT_BELOW_TOTAL_WEIGHT = 'INVALID_SCORE_POINT_BELOW_TOTAL_WEIGHT',
        ITEMS_SCORE_POINT_EXCEEDS_MAX_SCORE_EA_SUBMISSIONS = 'ITEMS_SCORE_POINT_EXCEEDS_MAX_SCORE_EA_SUBMISSIONS',  // simulated submission
        ITEMS_SCORE_POINT_BELOW_MIN_SCORE_EA_SUBMISSIONS = 'ITEMS_SCORE_POINT_BELOW_MIN_SCORE_EA_SUBMISSIONS',  // simulated submission
        ITEMS_SCORE_POINT_EXCEEDS_MIN_WEIGHT_EA_SUBMISSIONS = 'ITEMS_SCORE_POINT_EXCEEDS_MIN_WEIGHT_EA_SUBMISSIONS',  // simulated submission
        ITEMS_SCORE_POINT_BELOW_MAX_WEIGHT_EA_SUBMISSIONS = 'ITEMS_SCORE_POINT_BELOW_MAX_WEIGHT_EA_SUBMISSIONS',  // simulated submission
        SCORE_POINT_BELOW_MAXIMUM_CAPTURED_SCORE_SS = 'SCORE_POINT_BELOW_MAXIMUM_CAPTURED_SCORE_SS'      //simulated submission
      };

      let countItemsWithScorePoint = 0;
      for(const qConfig of questions){
        const qContent = this.getQuestionContent(qConfig);
        const totalWeight = getQuestionPoints(qContent);
          // check for possible answers through simulated submissions
          const expectedAnswers = await this.itemEditCtrl.findAllExpectedAnswer(+qConfig.id)
          let possibleScores = [];
          let possibleWeights = [];
          expectedAnswers?.forEach((ea: ExpectedAnswer) => { 
          possibleScores.push(+(ea.score));
          possibleWeights.push(+(ea.weight));
        });
        let maxPossibleScore = Math.max(...possibleScores);
        let minPossibleScore = Math.min(...possibleScores);
        let maxPossibleWeight = Math.max(...possibleWeights);
        let minPossibleWeight = Math.min(...possibleWeights);
        // console.log(qConfig.id, qConfig.label, qConfig.meta['SP'])
        if(qConfig.meta && qConfig.meta.hasOwnProperty('SP')){
          let val = <string>qConfig.meta['SP']
          if (val && val.trim() != null) {
            auditResultsMap.get(CheckId.ITEMS_WITH_SCORE_POINT_COUNT).items.push(qConfig);
            if(+val > totalWeight){
              auditResultsMap.get(CheckId.INVALID_SCORE_POINT_EXCEEDING_TOTAL_WEIGHT).items.push(qConfig);
              auditResultsMap.get(CheckId.INVALID_SCORE_POINT_ALIGNMENT).items.push(qConfig);
            } else if( +val < totalWeight) {
              auditResultsMap.get(CheckId.INVALID_SCORE_POINT_BELOW_TOTAL_WEIGHT).items.push(qConfig);
              auditResultsMap.get(CheckId.INVALID_SCORE_POINT_ALIGNMENT).items.push(qConfig);
            }
            // check with simulated sub
            if(+val > maxPossibleScore) auditResultsMap.get(CheckId.ITEMS_SCORE_POINT_EXCEEDS_MAX_SCORE_EA_SUBMISSIONS).items.push(qConfig);
            if(+val < maxPossibleScore) auditResultsMap.get(CheckId.SCORE_POINT_BELOW_MAXIMUM_CAPTURED_SCORE_SS).items.push(qConfig);
            if(+val < minPossibleScore) auditResultsMap.get(CheckId.ITEMS_SCORE_POINT_BELOW_MIN_SCORE_EA_SUBMISSIONS).items.push(qConfig);
            if(+val > minPossibleWeight) auditResultsMap.get(CheckId.ITEMS_SCORE_POINT_EXCEEDS_MIN_WEIGHT_EA_SUBMISSIONS).items.push(qConfig);
            if(+val < maxPossibleWeight) auditResultsMap.get(CheckId.ITEMS_SCORE_POINT_BELOW_MAX_WEIGHT_EA_SUBMISSIONS).items.push(qConfig);
            countItemsWithScorePoint++;
          }
        } else {
          auditResultsMap.get(CheckId.MISSING_SCORE_POINT).items.push(qConfig);
        }
      }

      return {}
    }

    // 17
    this.customIterators['CONTENT_DIFF_AUDIT'] = async (auditResultsModel:AuditResultCore) => {

      const {checkIds, auditResults, auditResultsMap} = auditResultsModel;

      enum CheckId {
        CURRENT_CONTENT_DIFF_AUDIT = 'CURRENT_CONTENT_DIFF_AUDIT',
      }
  
      const test_design_id = parseInt(prompt("Enter the test design id :"));
      if(!test_design_id){
        alert('Please Enter valid test design id');
        throw new Error();
      }
      const data: any[] = await this.auth.apiFind(this.routes.TEST_DESIGN_QUESTION_VERSIONS, {query: {test_design_ids: [test_design_id]}})
      const parsedData = itemContentDiff(data, true, {itemBankCtrl: this.itemBankCtrl});
      const prefix = 'CONTENT_DIFF_AUDIT';
      this.auditQuestionMem[prefix] = parsedData;
      auditResultsMap.get(CheckId.CURRENT_CONTENT_DIFF_AUDIT).items.push(...parsedData)
      this.auditResultHeader = `${prefix} - Test design id: ${test_design_id} `;
  
      return {} 
    }

    // 18
    this.customIterators['PUBLISHED_CONTENT_DIFF_AUDIT'] = async (auditResultsModel:AuditResultCore) => {

      const {checkIds, auditResults, auditResultsMap} = auditResultsModel;

      const test_design_ids = prompt("Enter comma seperated test design ids :");
      const ids = test_design_ids.toString().split(',').map(id => id.trim()).filter(id => id != null);
      // console.log(ids)
      if(!ids.length || ids.length < 2){
        alert('Please Enter valid test design id');
        throw new Error();
      }
      const data: any[] = await this.auth.apiFind(this.routes.TEST_DESIGN_QUESTION_VERSIONS, {query: {test_design_ids: ids}})
      const parsedData = itemContentDiff(data, false, {itemBankCtrl: this.itemBankCtrl});
      const prefix = 'PUBLISHED_CONTENT_DIFF_AUDIT';
      this.auditQuestionMem[prefix] = parsedData;
      this.auditResultHeader = `${prefix} - Test design ids: ${ids} `;
      auditResultsMap.get(prefix).items.push(...parsedData)

      return {}
    }

    // 19
    // this.customIterators['HUMAN_SCORED_AUDIT'] // isComplete

    // 20
    this.customIterators['SCORE_POINT_AUDIT'] = async (auditResultsModel:AuditResultCore) => {
      
      const {questions, checkIds, auditResults, auditResultsMap} = auditResultsModel;

      enum CheckId {
        ITEMS_WITH_SCORE_POINT_COUNT = 'ITEMS_WITH_SCORE_POINT_COUNT',
        MISSING_SCORE_POINT = 'MISSING_SCORE_POINT',
        INVALID_SCORE_POINT_ALIGNMENT = 'INVALID_SCORE_POINT_ALIGNMENT',
        INVALID_SCORE_POINT_EXCEEDING_TOTAL_WEIGHT = 'INVALID_SCORE_POINT_EXCEEDING_TOTAL_WEIGHT',
        INVALID_SCORE_POINT_BELOW_TOTAL_WEIGHT = 'INVALID_SCORE_POINT_BELOW_TOTAL_WEIGHT',
        ITEMS_SCORE_POINT_EXCEEDS_MAX_SCORE_EA_SUBMISSIONS = 'ITEMS_SCORE_POINT_EXCEEDS_MAX_SCORE_EA_SUBMISSIONS',  // simulated submission
        ITEMS_SCORE_POINT_BELOW_MIN_SCORE_EA_SUBMISSIONS = 'ITEMS_SCORE_POINT_BELOW_MIN_SCORE_EA_SUBMISSIONS',  // simulated submission
        ITEMS_SCORE_POINT_EXCEEDS_MIN_WEIGHT_EA_SUBMISSIONS = 'ITEMS_SCORE_POINT_EXCEEDS_MIN_WEIGHT_EA_SUBMISSIONS',  // simulated submission
        ITEMS_SCORE_POINT_BELOW_MAX_WEIGHT_EA_SUBMISSIONS = 'ITEMS_SCORE_POINT_BELOW_MAX_WEIGHT_EA_SUBMISSIONS',  // simulated submission
        SCORE_POINT_BELOW_MAXIMUM_CAPTURED_SCORE_SS = 'SCORE_POINT_BELOW_MAXIMUM_CAPTURED_SCORE_SS'      //simulated submission
      };

      let countItemsWithScorePoint = 0;
      for(const qConfig of questions){
        const qContent = this.getQuestionContent(qConfig);
        const totalWeight = getQuestionPoints(qContent);
          // check for possible answers through simulated submissions
          const expectedAnswers = await this.itemEditCtrl.findAllExpectedAnswer(+qConfig.id)
          let possibleScores = [];
          let possibleWeights = [];
          expectedAnswers?.forEach((ea: ExpectedAnswer) => { 
          possibleScores.push(+(ea.score));
          possibleWeights.push(+(ea.weight));
        });
        let maxPossibleScore = Math.max(...possibleScores);
        let minPossibleScore = Math.min(...possibleScores);
        let maxPossibleWeight = Math.max(...possibleWeights);
        let minPossibleWeight = Math.min(...possibleWeights);
        // console.log(qConfig.id, qConfig.label, qConfig.meta['SP'])
        if(qConfig.meta && qConfig.meta.hasOwnProperty('SP')){
          let val = <string>qConfig.meta['SP']
          if (val && val.trim() != null) {
            auditResultsMap.get(CheckId.ITEMS_WITH_SCORE_POINT_COUNT).items.push(qConfig);
            if(+val > totalWeight){
              auditResultsMap.get(CheckId.INVALID_SCORE_POINT_EXCEEDING_TOTAL_WEIGHT).items.push(qConfig);
              auditResultsMap.get(CheckId.INVALID_SCORE_POINT_ALIGNMENT).items.push(qConfig);
            } else if( +val < totalWeight) {
              auditResultsMap.get(CheckId.INVALID_SCORE_POINT_BELOW_TOTAL_WEIGHT).items.push(qConfig);
              auditResultsMap.get(CheckId.INVALID_SCORE_POINT_ALIGNMENT).items.push(qConfig);
            }
            // check with simulated sub
            if(+val > maxPossibleScore) auditResultsMap.get(CheckId.ITEMS_SCORE_POINT_EXCEEDS_MAX_SCORE_EA_SUBMISSIONS).items.push(qConfig);
            if(+val < maxPossibleScore) auditResultsMap.get(CheckId.SCORE_POINT_BELOW_MAXIMUM_CAPTURED_SCORE_SS).items.push(qConfig);
            if(+val < minPossibleScore) auditResultsMap.get(CheckId.ITEMS_SCORE_POINT_BELOW_MIN_SCORE_EA_SUBMISSIONS).items.push(qConfig);
            if(+val > minPossibleWeight) auditResultsMap.get(CheckId.ITEMS_SCORE_POINT_EXCEEDS_MIN_WEIGHT_EA_SUBMISSIONS).items.push(qConfig);
            if(+val < maxPossibleWeight) auditResultsMap.get(CheckId.ITEMS_SCORE_POINT_BELOW_MAX_WEIGHT_EA_SUBMISSIONS).items.push(qConfig);
            countItemsWithScorePoint++;
          }
        } else {
          auditResultsMap.get(CheckId.MISSING_SCORE_POINT).items.push(qConfig);
        }
      }

      return {}
    }

    // 21
    this.customIterators['TEI_EA_ANSWER_AUDIT'] = async (auditResultsModel:AuditResultCore) => {
        
      const {questions, auditResultsMap} = auditResultsModel;

      enum CheckId {
        MISSING_EXPECTED_ANSWER = 'MISSING_EXPECTED_ANSWER',
        INVALID_TEI_EA_FOR_MCQ_BLOCK = 'INVALID_TEI_EA_FOR_MCQ_BLOCK',
        MISS_MATCH_TEI_EA = 'MISS_MATCH_TEI_EA',
      };

      for (const qConfig of questions){
        const qContent = this.getQuestionContent(qConfig);
        const qEntries = identifyQuestionResponseEntries(qContent);
        let { meta } = qConfig;
        if(!meta) meta = {};
        let tei_ea = meta['TEI_EA'];
        // const ea = meta['EA'];
        if (qEntries.length && !tei_ea) {
          auditResultsMap.get(CheckId.MISSING_EXPECTED_ANSWER).items.push(qConfig);
        }
        else if (qEntries.length === 1 && qEntries[0].elementType === ElementType.MCQ){
          // we only need EA - make TEI_EA is blank if defined
          if (meta.hasOwnProperty('TEI_EA') && meta['TEI_EA'] != null) { // todo: we should not be managing these variables so tightly
            auditResultsMap.get(CheckId.INVALID_TEI_EA_FOR_MCQ_BLOCK).items.push(qConfig);
          }
        } 
        else {
          // check for all other Element blocks
          // check for possible answers through simulated submissions
          const expectedAnswers = await this.itemEditCtrl.findAllExpectedAnswer(+qConfig.id)
          const possibleTEI = new Set();
          if(_.isObject(tei_ea)){
            tei_ea = (tei_ea?.caption || '').trim();
          } 
          else {
            tei_ea = (tei_ea || '').trim()
          }
          expectedAnswers.forEach(ans => {
            if(ans.score && ans.score === ans.weight){
              possibleTEI.add(ans.formatted_response.trim());
            }
          });
          if(!possibleTEI.has(tei_ea)){
            auditResultsMap.get(CheckId.MISS_MATCH_TEI_EA).items.push(qConfig);
          }
        }
      }

      return {}
    }

    // 22 //covered by check

    // 23
    this.customIterators['ALTERNATIVE_LINEAR_TEST_AUDIT'] = async (auditResultsModel:AuditResultCore) => {
      if(!this.frameworkCtrl.asmtFmrk.isAlternativeLinearTest){
        alert("Please mark the assessment as alternative Linear test in the framework settings");
        throw new Error();
      }
      return {}
    }

    // 24
    // this.customIterators['REJECTED_ITEMS_AUDIT'] // covered by structured check

    // 25
    // this.customIterators['FIELD_TRIAL_ITEMS_AUDIT'] // isCompleteCheck

    // 26
    this.customIterators['POSSIBLE_EXPECTED_ANSWER_AUDIT'] = async (auditResultsModel:AuditResultCore) => {
      const lang =  this.lang.c()     
      const {auditResultsMap, questions} = auditResultsModel; 
      enum CheckId {
        MISSING_POSSIBLE_EXPECTED_ANSWER = "MISSING_POSSIBLE_EXPECTED_ANSWER",
        CONTAINS_ONE_POSSIBLE_EXPECTED_ANSWER = "CONTAINS_ONE_POSSIBLE_EXPECTED_ANSWER",
        COUNT_MULTI_SELECT_MCQ_WITH_POSSIBLE_EA_AS_MANY_AS_OPTIONS = "COUNT_MULTI_SELECT_MCQ_WITH_POSSIBLE_EA_AS_MANY_AS_OPTIONS",
        INVALID_DND_POSSIBLE_EXPECTED_ANSWER = "INVALID_DND_POSSIBLE_EXPECTED_ANSWER",
        ITEM_MISSING_CORRECT_ANSWER = "ITEM_MISSING_CORRECT_ANSWER",
        // SCORE_POINT_EXCEEDS_MAXIMUM_CAPTURED_SCORE_SS,   //through simulated submission
        SINGLE_MCQ_MISSING_POSSIBLE_EXPECTED_ANSWER = "SINGLE_MCQ_MISSING_POSSIBLE_EXPECTED_ANSWER"
        
      };

      for(const qConfig of questions){
        //skip all reading passages
        if(qConfig.isReadingSelectionPage){
          continue;
        }

        const groupingTypes = new Set([ElementType.MOVEABLE_DND, ElementType.GROUPING, ElementType.INSERTION, ElementType.ORDER])

        // check for possible answers through simulated submissions
        const expectedAnswers = (await this.itemEditCtrl.findAllExpectedAnswer(+qConfig.id))?.filter(ea => ea.lang === lang)

        const qContent = this.getQuestionContent(qConfig);
        const scorableContent = this.getQuestionContentEntryElements(qContent, false);

        scorableContent.forEach(content => {
          if(content.elementType === ElementType.MCQ && (<IContentElementMcq>content).isMultiSelect){
            const qC = (<IContentElementMcq>content);
            const numSelected = +qC.maxOptions | 1;
            const options = qC.options?.length | 0;
            const correct = qC.options?.filter(opt => opt.isCorrect).length | 0;
            if(possiblePartialCorrectMCQ(numSelected, options, correct) > expectedAnswers?.length){
              auditResultsMap.get(CheckId.COUNT_MULTI_SELECT_MCQ_WITH_POSSIBLE_EA_AS_MANY_AS_OPTIONS).items.push(qConfig);
            }
          } else if(groupingTypes.has(<ElementType>(content.elementType))) {
            
            let draggablesLength = 0;
            let targetsLength = 0;

            if(content.elementType === ElementType.MOVEABLE_DND || content.elementType === ElementType.GROUPING){
              let qC = (<IContentElementMoveableDragDrop | IContentElementGroup>content);
              draggablesLength = qC.draggables?.length;
              targetsLength = qC.targets?.length;

            } else if(content.elementType === ElementType.INSERTION){
              let qC = <IContentElementInsertion>content;
              draggablesLength = qC.draggables?.length
              targetsLength = qC.textBlocks?.filter(block => block.element.elementType === ElementType.BLANK || block.element.elementType === ElementType.BLANK_DEPRECIATED)?.length ; 
            }

            if(!draggablesLength) draggablesLength = 0
            if(!targetsLength) targetsLength = 0

            if(expectedAnswers.length < ( draggablesLength * targetsLength)){
              auditResultsMap.get(CheckId.INVALID_DND_POSSIBLE_EXPECTED_ANSWER).items.push(qConfig);
            }
          }
        });

        const { meta } = qConfig;
        const SP = meta['SP'] || 0;

        let maxPossibleScore = 0;
        
        const isSingleMcq = scorableContent.length === 1 && scorableContent[0].elementType === ElementType.MCQ && !(<IContentElementMcq>scorableContent[0]).isMultiSelect;

        if(isSingleMcq){
          if(expectedAnswers.length < (<IContentElementMcq>scorableContent[0]).options.length) {
            auditResultsMap.get(CheckId.SINGLE_MCQ_MISSING_POSSIBLE_EXPECTED_ANSWER).items.push(qConfig);
          }
        } 
        else {

          if(expectedAnswers.length === 0 && qConfig.isReadingSelectionPage != true){
            auditResultsMap.get(CheckId.MISSING_POSSIBLE_EXPECTED_ANSWER).items.push(qConfig);
          } else if(expectedAnswers.length === 1){
            auditResultsMap.get(CheckId.CONTAINS_ONE_POSSIBLE_EXPECTED_ANSWER).items.push(qConfig);
          }
        }

        let isCorrectAnswerPresent = false;
        expectedAnswers.forEach(ea => {
          if(ea.weight && ea.score === ea.weight) isCorrectAnswerPresent = true;
        })
        
        if(!isCorrectAnswerPresent && qConfig.isReadingSelectionPage != true) auditResultsMap.get(CheckId.ITEM_MISSING_CORRECT_ANSWER).items.push(qConfig);
      }
      return {}
    }
    // 27
    // this.customIterators['VIDEO_ITEM_AUDIT'] // isCompleteCheck

    // 28
    this.customIterators['BLANK_INVISIBLE_ENTRIES_ITEMS_AUDIT'] = async (auditResultsModel:AuditResultCore) => {
      const {auditResultsMap, questions} = auditResultsModel; 
      enum CheckIds {
        DRAG_DROP_MISSING_TARGETS_INSERTION = "DRAG_DROP_MISSING_TARGETS_INSERTION",
      }
      questions.forEach(question =>{
        let insertionMissingTarget = false;
        const blockElements = getQuestionDeepElements(question, this.getCurrentContext());
        for (let i = 0; i < blockElements.length; i++) {
          const element = blockElements[i];
        
          if (element.elementType === ElementType.INSERTION) {
            if (element?.['textBlocks']) {
              if (!element?.['textBlocks'].some(textblock => (textblock.element.elementType === ElementType.BLANK || textblock.element.elementType === ElementType.BLANK_DEPRECIATED))) {
                insertionMissingTarget = true;
                break;
              }
            } else {
              insertionMissingTarget = true;
              break;
            }
          }
        }
        if(insertionMissingTarget){
          auditResultsMap.get(CheckIds.DRAG_DROP_MISSING_TARGETS_INSERTION).items.push(question);
        }
      });
      return {}
    }

    // 29.a
    // this.customIterators['DRAG_DROP_AUDIT_MISSING_KEYS']
  

    // 29.b
    // this.customIterators['DRAG_DROP_AUDIT_MISSING_DIMENSIONS']
  
    // 30
    this.customIterators['ASSOCIATED_READING_PASSAGE_AUDIT'] = async (auditResultsModel:AuditResultCore) => {
      
      const {questions, auditResultsMap} = auditResultsModel;
      
      enum CheckId {
        MISSING_READING_PASSAGE = "MISSING_READING_PASSAGE",
        INVALID_READING_PASSAGE = 'INVALID_READING_PASSAGE',
        TRAILING_SPACE_IN_READING_PASSAGE = 'TRAILING_SPACE_IN_READING_PASSAGE',
        MISSING_BOOKMARK_ID = 'MISSING_BOOKMARK_ID',
        MISSING_TARGET_ITEM = 'MISSING_TARGET_ITEM',
        INVALID_TARGET_ITEM = 'INVALID_TARGET_ITEM',
        TRAILING_SPACE_IN_TARGET_ITEM = 'TRAILING_SPACE_IN_TARGET_ITEM',
        INVALID_BOOKMARK_ID = 'INVALID_BOOKMARK_ID',
        TRAILING_SPACE_IN_BOOKMARK_ID = 'TRAILING_SPACE_IN_BOOKMARK_ID',
      };
      const scoredQuestions =  questions.filter(q => !q.isReadingSelectionPage && !q.isQuestionnaire);
      const nonScoredQuestions = questions.filter(q => q.isReadingSelectionPage && !q.isQuestionnaire);
      const existingBookmarksMap = new Map<string, string[]>();
      const regexBookMarks = /<\s*bookmark\s+id\s*=\s*"(.*?)"\s*>/g;
      nonScoredQuestions.forEach(passage =>{
        const label = passage.label.trim();
        existingBookmarksMap.set(label, []);
        const elements = getQuestionDeepElements(passage, this.getCurrentContext());
        elements.forEach(el =>{
          if(el.elementType === ElementType.TEXT && el.paragraphStyle === TextParagraphStyle.PARAGRAPHS){
            let match;
            el.paragraphList.forEach(paragraph =>{
              while((match = regexBookMarks.exec(paragraph.caption)) !== null){
                existingBookmarksMap.get(label).push(match[1]);
              }
            })
          }
          if(el.elementType === ElementType.PASSAGE){
            let match;
            while((match = regexBookMarks.exec(el['text'])) !== null){
              existingBookmarksMap.get(label).push(match[1]);
            }
          }
        })
      });
      scoredQuestions.forEach(q => {
        const elementBasedFails = {}

        const readSelections = q.readSelections;
        if(!readSelections?.length){
          auditResultsMap.get(CheckId.MISSING_READING_PASSAGE).items.push(q);
        } else {
          readSelections.forEach(readSel => {
            if(!existingBookmarksMap.has(readSel)){
              if(existingBookmarksMap.has(readSel.trim())){
                elementBasedFails[CheckId.TRAILING_SPACE_IN_READING_PASSAGE] = true;
              } else {
                elementBasedFails[CheckId.INVALID_READING_PASSAGE] = true;
              }
            }
          })
        }
        const elements = getQuestionDeepElements(q, this.getCurrentContext());
        
        elements.forEach(e => {
          if(e.elementType === ElementType.TEXT && e.paragraphStyle === TextParagraphStyle.ADVANCED_INLINE){
            for(let textElement of e.advancedList) {
              if(textElement.elementType === ElementType.BOOKMARK_LINK){
                const targetItem = textElement.itemLabel;
                const bookmarkId = textElement.bookmarkId;
                const targetItemTrimmed = targetItem.trim();
                const bookmarkIdTrimmed = bookmarkId.trim();
                if(!bookmarkId){
                  elementBasedFails[CheckId.MISSING_BOOKMARK_ID] = true;
                } else if(!existingBookmarksMap.get(targetItemTrimmed)?.includes(bookmarkId)){
                  // If the trimmed version isn't included regular invalid
                  if(existingBookmarksMap.get(targetItemTrimmed)?.includes(bookmarkIdTrimmed)){
                    elementBasedFails[CheckId.TRAILING_SPACE_IN_BOOKMARK_ID] = true;
                  }
                  elementBasedFails[CheckId.INVALID_BOOKMARK_ID] = true;
                }
                if(!targetItem){
                  elementBasedFails[CheckId.MISSING_TARGET_ITEM] = true;
                } else if (!readSelections.includes(targetItem)){
                  // If the trimmed version isn't included regular invalid
                  if(readSelections.includes(targetItemTrimmed)){
                    elementBasedFails[CheckId.TRAILING_SPACE_IN_TARGET_ITEM] = true;
                  } else {
                    elementBasedFails[CheckId.INVALID_TARGET_ITEM] = true;
                  }
                }
                if(Object.values(elementBasedFails).reduce((acc, value) => acc && value, true)){
                  break;
                }
              }
            }
          }
        });
        for (let elementBasedFail in elementBasedFails){
          if(elementBasedFails[elementBasedFail]){
            auditResultsMap.get(elementBasedFail).items.push(q);
          }
        }
      });
      return {}
    }

    // 31
    this.customIterators['ITEMS_MISSING_COMPLETION_MARK_CONDITION_AUDIT'] = async (auditResultsModel: AuditResultCore) => {
      const {questions, auditResultsMap} = auditResultsModel;
      enum CheckId {
        MORE_DRAGGABLES_THAN_TARGETS_INSERTION = 'MORE_DRAGGABLES_THAN_TARGETS_INSERTION',
        MORE_TARGETS_THAN_FILLABLE_INSERTION = 'MORE_TARGETS_THAN_FILLABLE_INSERTION',
        LESS_DRAGGABLES_THAN_MARK_COMPLETED_DND = 'LESS_DRAGGABLES_THAN_MARK_COMPLETED_DND',
        MORE_DRAGGABLES_THAN_MARK_COMPLETED_DND = 'MORE_DRAGGABLES_THAN_MARK_COMPLETED_DND',
        LESS_DRAGGABLES_THAN_TARGETS_DND = 'LESS_DRAGGABLES_THAN_TARGETS_DND',
        MORE_DRAGGABLES_THAN_TARGETS_DND = 'MORE_DRAGGABLES_THAN_TARGETS_DND',
        LESS_DRAGGABLES_THAN_MARK_COMPLETED_GROUPING = 'LESS_DRAGGABLES_THAN_MARK_COMPLETED_GROUPING',
        MORE_DRAGGABLES_THAN_MARK_COMPLETED_GROUPING = 'MORE_DRAGGABLES_THAN_MARK_COMPLETED_GROUPING',
        LESS_DRAGGABLES_THAN_TARGETS_GROUPING = 'LESS_DRAGGABLES_THAN_TARGETS_GROUPING',
        MORE_DRAGGABLES_THAN_TARGETS_GROUPING = 'MORE_DRAGGABLES_THAN_TARGETS_GROUPING'
    };
    

      questions.forEach((question)=>{
        const failedMap = {}
        getQuestionDeepElements(question, this.getCurrentContext()).forEach((element)=>{
          // Grouping and DND use same structure so they can share the draggables and targets
          const draggables = element['draggables']?.length ?? 0;
          let targets = element['targets']?.length ?? 0;
          const isOptionsReusable = element['isOptionsReusable'];
          const isNotReusableOrMissingDraggables = (!isOptionsReusable || !draggables);
          switch(element.elementType){
            case ElementType.INSERTION:
              element['textBlocks']?.forEach(textblock =>{
                if(textblock.element.elementType === ElementType.BLANK || textblock.element.elementType === ElementType.BLANK_DEPRECIATED){
                  targets++;
                }
              });
              if(draggables > targets){
                failedMap[CheckId.MORE_DRAGGABLES_THAN_TARGETS_INSERTION] = true;
              }
              if((!element['isRepeatableOptions'] || !draggables) && targets > draggables){
                failedMap[CheckId.MORE_TARGETS_THAN_FILLABLE_INSERTION] = true;
              }
              break;
            case ElementType.GROUPING:
              const reqMinimumPlacedTarget = element['reqMinimumPlacedTarget'] ?? 0;
              if(draggables < reqMinimumPlacedTarget && isNotReusableOrMissingDraggables){
                failedMap[CheckId.LESS_DRAGGABLES_THAN_MARK_COMPLETED_GROUPING] = true;
              }
              if(draggables > reqMinimumPlacedTarget){
                failedMap[CheckId.MORE_DRAGGABLES_THAN_MARK_COMPLETED_GROUPING] = true;
              }
              if(draggables < targets && isNotReusableOrMissingDraggables){
                failedMap[CheckId.LESS_DRAGGABLES_THAN_TARGETS_GROUPING] = true;
              }
              if(draggables > targets){
                failedMap[CheckId.MORE_DRAGGABLES_THAN_TARGETS_GROUPING] = true;
              }
              break;
            case ElementType.MOVEABLE_DND:
              const howManyToFill = element['howManyToFill'] ?? 0;
              if(draggables < howManyToFill && isNotReusableOrMissingDraggables){
                failedMap[CheckId.LESS_DRAGGABLES_THAN_MARK_COMPLETED_DND] = true;
              }
              if(draggables > howManyToFill){
                failedMap[CheckId.MORE_DRAGGABLES_THAN_MARK_COMPLETED_DND] = true;
              }
              if(draggables < targets && isNotReusableOrMissingDraggables){
                failedMap[CheckId.LESS_DRAGGABLES_THAN_TARGETS_DND] = true;
              }
              if(draggables > targets){
                failedMap[CheckId.MORE_DRAGGABLES_THAN_TARGETS_DND] = true;
              }
              break;
          }
        });
        Object.keys(failedMap).forEach( key =>
          auditResultsMap.get(key).items.push(question)
        )

      });
      
      return {};
    };
    
    // 32
    // this.customIterators['FILE_EXTENTION_ITEM_AUDIT'] = async (auditResultsModel: AuditResultCore) => {
      
    //   console.log(auditResultsModel)
    //   return {};
    // }
    
    // 33
    this.customIterators['IS_RESPONDABLE_ITEM_AUDIT'] = async (auditResultsModel: AuditResultCore) => {
      enum CheckId {
        READING_SEL_AND_IS_RESPONDABLE = 'READING_SEL_AND_IS_RESPONDABLE'
      }
      const questions = await extractTestDesignScopedquestion({lang:this.lang.c(), itemBankCtrl: this.itemBankCtrl, frameworkCtrl:this.frameworkCtrl });
      const {auditSlug,checkIds, auditResultsMap} = auditResultsModel;
      const questionIDs = questions.questionsMap.forEach(question =>{
        if(question.meta && question.meta.hasOwnProperty('is_respondable')){
          let is_respondable_val = <string>question.meta['is_respondable'];
          if(is_respondable_val){
            const reading_sel_checked = question.isReadingSelectionPage;
            if(reading_sel_checked){
              auditResultsMap.get(CheckId.READING_SEL_AND_IS_RESPONDABLE).items.push(question);
            }
          }
          }
      });
      this.markAuditAsRan(auditSlug, false, true, auditResultsMap);
      return {}
    }

    //34 this.customIterators['OVERALL_EXPECTATION_AUDIT']
    

    // 35
    this.customIterators['MULTIMEDIA_ASSET_AUDIT'] = async (auditResultsModel: AuditResultCore) => {
      enum CheckId{
        GET_TOTAL_MULTI_MEDIA_COUNT_AND_FILESIZE = 'GET_TOTAL_MULTI_MEDIA_COUNT_AND_FILESIZE',
      }
      const size_limit = parseInt(prompt("Enter in kilobytes the total file size you want to flag items based on:"));
      if(!size_limit || size_limit < 1){
        alert('Please enter a value greater than 1 KB');
        throw new Error();
      }

      const {auditSlug,checkIds, auditResultsMap} = auditResultsModel;
      const {questions} = this.itemBankCtrl;
      const assetResults = await this.getAssetResults(questions)
      let remodeledResults;
      for(let checkId of checkIds){
        if(checkId === CheckId.GET_TOTAL_MULTI_MEDIA_COUNT_AND_FILESIZE){
          remodeledResults = this.remodeledData(assetResults,size_limit);
          this.auditQuestionMem[auditSlug] = remodeledResults;
          this.auditResultHeader = `${checkId} - Total Size Limit set to ${size_limit} KB`;
          auditResultsMap.get(checkId).items.push(...remodeledResults);
        } 
      }
      
      this.markAuditAsRan(auditSlug, false, true, auditResultsMap);
      return {isCustomResults: true};
    }

    // 36
    this.customIterators['PJ_SCAN_AUDIT'] = async (auditResultsModel: AuditResultCore, skipDisabled: boolean) => {

      const ranCode = 'PJ_SCAN_AUDIT';
      enum CheckId {
        MISSING_SCAN_SLUG = "MISSING_SCAN_SLUG",
        INCORRECTLY_MAPPED_SCAN_SLUG = "INCORRECTLY_MAPPED_SCAN_SLUG",
        MISSING_PAPER_TEXT = "MISSING_PAPER_TEXT",
        SCAN_ID_PRESERVED_PARAMS = "SCAN_ID_PRESERVED_PARAMS", // applicable to the whole test design, not a question
      };

      interface IAuditResult { id: CheckId,  caption: string, items: any[], type: EAuditElementType};

      const auditResults: IAuditResult[] = [
        {id: CheckId.MISSING_SCAN_SLUG, caption: 'Items that have scan slug missing', items: [], type: 'QUESTION'},
        {id: CheckId.INCORRECTLY_MAPPED_SCAN_SLUG, caption: 'Items that have scan slug incorrectly mapped', items: [], type: 'QUESTION'},
        {id: CheckId.MISSING_PAPER_TEXT, caption: 'Items that have paper text or voiceover missing', items: [], type: 'QUESTION'},
        {id: CheckId.SCAN_ID_PRESERVED_PARAMS, caption: 'Assessment framework has no configured SCAN_ID in preserved params', items: [], type: 'QUESTION'},
      ];
      const { auditResultsMap} = auditResultsModel;
      auditResults.forEach(result => auditResultsMap.set(result.id, result))

      // const questions = isScopedToTestDesign ? await this.getScreens() : this.frameworkCtrl.itemBankCtrl.getItems();
      // console.log('questions', questions)
      console.log('asmtFrmwrk', this.frameworkCtrl.asmtFmrk)
      console.log('testlest', this.frameworkCtrl.testletCtrl)

      for(const testlet of this.frameworkCtrl.asmtFmrk.testlets) {
        // 36.1.1 - Only Audit Enabled Forms
        if(testlet.isDisabled && skipDisabled) {
          continue;
        }

        const questions = testlet.questions.map(q => this.frameworkCtrl.itemBankCtrl.getQuestionById(q.id)).filter(q => q);
        console.log('questions', questions)

        for(const qConfig of questions){
          const qContent = this.getQuestionContent(qConfig);

          // 36.1.2 - Only Audit Input Long Text Dual
          const longTextDual = qContent.filter((content: IContentElementInput) => {
            return content.elementType === ElementType.INPUT && content.isDual
          });

          if(!longTextDual.length) {
            continue;
          }
        
          // 36.2.1. - Check for scan slug presence
          const scanSlug = qConfig.meta?.SCAN_ID;
          console.log('scanSlug', scanSlug);
          if(!scanSlug) {
            auditResultsMap.get(CheckId.MISSING_SCAN_SLUG).items.push(qConfig);
          }

          // 36.2.2a - Check for scan slug mapping
          const sectionToValidSlugMap = {
            0: ['SESSION_A'],
            1: ['SESSION_B'],
            2: ['SESSION_C'],
            3: ['SESSION_DR', 'SESSION_DW']
          }
          const section = +testlet.section - 1;
          const validSlugs = sectionToValidSlugMap[section] || [];
          console.log('slugCheck', section, validSlugs, scanSlug, validSlugs.includes(scanSlug));
          if(!validSlugs.includes(scanSlug)) {
            auditResultsMap.get(CheckId.INCORRECTLY_MAPPED_SCAN_SLUG).items.push(qConfig);
          }

          // 36.2.2b - Check for Session D scan slug mapping based on similarity slug
          const sectionToValidSSlugMap = {
            'SESSION_DR': ['IN', 'LN'],
            'SESSION_DW': ['SW']
          }
          const validSSlugs = sectionToValidSSlugMap[scanSlug] || []; 
          console.log('validSSlugs', testlet.similaritySlug, validSSlugs, validSSlugs.includes(testlet.similaritySlug))
          if(validSSlugs.length > 0 && (!testlet.similaritySlug || !validSSlugs.includes(testlet.similaritySlug))) {
            auditResultsMap.get(CheckId.INCORRECTLY_MAPPED_SCAN_SLUG).items.push(qConfig);
          }

          // 36.2.3a, 36.2.3b Check for paper text and voiceover presence
          longTextDual.forEach((content: IContentElementInput) => {
            const noVoiceoverUrl = !content?.paperVoiceOver?.url || !content?.paperVoiceOver?.url.trim().length;
            const noVoiceOverScript = !content.paperVoiceOver?.script || !content?.paperVoiceOver?.script.trim().length;
            const noVoiceover = noVoiceoverUrl && noVoiceOverScript;
            
            if(noVoiceover || !content?.paperText || !content?.paperText.trim().length) {
              auditResultsMap.get(CheckId.MISSING_PAPER_TEXT).items.push(qConfig);
            }
          });
        }
      }

      // 36.2.4. Validate SCAN_ID is present in Preserved Parameters section of the Assessment Framework
      const preservedMetaParams = this.frameworkCtrl.asmtFmrk.preservedMetaParams || [];
      if(!preservedMetaParams.includes('SCAN_ID')) {
        auditResultsMap.get(CheckId.SCAN_ID_PRESERVED_PARAMS).items.push(true);
      }
      
      const results = this.getFormattedResults(Object.values(CheckId), auditResultsMap);
      this.markAuditAsRan(ranCode, false, true, results);
    
      return {}
    }

    // 37 this.customIterators['SKILL']
    
    // 38 this.customIterators['SKPTILL']
    
    // 39 this.customIterators['PASSAGE']
    
    // 40 this.customIterators['STRAND']

    // 41 this.customIterators['REPORTING_SUBJECT']

    //42
    this.customIterators['ITEM_MATRIX_AUDIT'] = async (auditResultsModel: AuditResultCore) => {
      const {auditResultsMap, questions} = auditResultsModel;
      enum CheckId {
        UNCONFIRMED = "UNCONFIRMED",
        OUTDATED = "OUTDATED",
        // FLAGGED = "FLAGGED",
        REUSABLE_DRAGGABLES = "REUSABLE_DRAGGABLES",
        MULTI_COMBINATION = "MULTI_COMBINATION",
        MULTI_TARGET = "MULTI_TARGET",
        CUSTOM_VALIDATION = "CUSTOM_VALIDATION",
        NON_SCORING_MATRIX = "NON_SCORING_MATRIX",
        FLAGGED_ITEMS = "FLAGGED_ITEMS"
      };
      
      const lang = this.lang.c() 
      for (const qConfig of questions) {
        const qContent = getQuestionRespondableDeep(qConfig);
        let failedMap: { [key: string]: boolean } = {};
        let matrixCount = 0;
        const expectedAnswers = (await this.itemEditCtrl.findAllExpectedAnswer(+qConfig.id))?.filter(ea => ea.lang === lang);
        qContent.forEach(content => {
          if(scoreMatrixElementTypes.includes(content.elementType)){
            matrixCount++;
          }
          if (!failedMap[CheckId.NON_SCORING_MATRIX] && !scoreMatrixElementTypes.includes(content.elementType)) {
            failedMap[CheckId.NON_SCORING_MATRIX] = true;
          }
      
          if (scoreMatrixElementTypes.includes(content.elementType) && content.scoreMatrix) {
            if (!failedMap[CheckId.UNCONFIRMED] && content.scoreMatrix.isConfirmed == false) {
              failedMap[CheckId.UNCONFIRMED] = true;
            }
      
            if (!failedMap[CheckId.OUTDATED] && content.scoreMatrix.isUpdated == false) {
              failedMap[CheckId.OUTDATED] = true;
            }
      
            if (!failedMap[CheckId.REUSABLE_DRAGGABLES] && (content.elementType == ElementType.MOVEABLE_DND || content.elementType == ElementType.GROUPING) && (<IContentElementMoveableDragDrop>content).isOptionsReusable == true) {
              failedMap[CheckId.REUSABLE_DRAGGABLES] = true;
            }
      
            if (content.elementType == ElementType.MOVEABLE_DND) {
              if (!failedMap[CheckId.MULTI_COMBINATION] && (<IContentElementMoveableDragDrop>content).isAcceptMultipleCombinations == true) {
                failedMap[CheckId.MULTI_COMBINATION] = true;
              }
      
              if (!failedMap[CheckId.MULTI_TARGET] && (<IContentElementMoveableDragDrop>content).isMultipleOptionsRight == true) {
                failedMap[CheckId.MULTI_TARGET] = true;
              }
      
              if (!failedMap[CheckId.CUSTOM_VALIDATION] && (<IContentElementMoveableDragDrop>content).isCustomValidataion == true) {
                failedMap[CheckId.CUSTOM_VALIDATION] = true;
              }
            }
          }
        });
        if(!failedMap[CheckId.NON_SCORING_MATRIX] && matrixCount === 1){
          const flaggedAnswers = expectedAnswers.filter((ans: any) => {
            return !ans.is_score_matrix_validated;
          });
          if (flaggedAnswers.length > 0) {
            failedMap[CheckId.FLAGGED_ITEMS] = true;
          }
        }
        // Push all failed conditions at the end
        Object.keys(failedMap).forEach(key => {
          auditResultsMap.get(key).items.push(qConfig);
        });
      }
      
      
      return {}
    }

    // 43 
    this.customIterators['ITEM_TYPE_AUDIT'] = async (auditResultsModel: AuditResultCore) => {
      const {questions, auditResultsMap} = auditResultsModel;
      enum CheckId {
        ITEM_TYPE_MISSING = 'ITEM_TYPE_MISSING',
        CLK_NO_MCQS = 'CLK_NO_MCQS',
        DP_NO_MCQS = 'DP_NO_MCQS',
        DDP_NO_TWO_MCQS = 'DDP_NO_TWO_MCQS',
        DG_NO_VALID_BLOCKS = 'DG_NO_VALID_BLOCKS',
        CH_NO_SELECTION_TABLE = 'CH_NO_SELECTION_TABLE',
        SW_NO_SHORT_ANSWER = 'SW_NO_SHORT_ANSWER',
        LW_NO_SHORT_ANSWER = 'LW_NO_SHORT_ANSWER',
        OR_NO_SHORT_ANSWER = 'OR_NO_SHORT_ANSWER',
      }    
      questions.forEach(q =>{
        if(!q?.meta?.ItemType){
          auditResultsMap.get(CheckId.ITEM_TYPE_MISSING).items.push(q);
          return;
        }
        const itemType:ItemTypesParam = q?.meta?.ItemType;
        const blockCount = countKeyValues(getQuestionContent(q, this.getCurrentContext()), 'elementType');
        const mcqElements = this.getQuestionContentEntryElements(q.content, true).filter(c => c.elementType === ElementType.MCQ);
        const mcqDisplayStyleCountMap = countKeyValues(mcqElements, 'displayStyle');
        const totalDropdownCount = (typeof mcqDisplayStyleCountMap[McqDisplay.DROPDOWN] === 'number' ? mcqDisplayStyleCountMap[McqDisplay.DROPDOWN] : 0) + (typeof mcqDisplayStyleCountMap[McqDisplay.CUSTOM_DROPDOWN] === 'number' ? mcqDisplayStyleCountMap[McqDisplay.CUSTOM_DROPDOWN] : 0);
        switch(itemType){
          case 'DDP':
            if( totalDropdownCount < 2){
              auditResultsMap.get(CheckId.DDP_NO_TWO_MCQS).items.push(q);
            }
            break;
          case 'DP':
            if(!totalDropdownCount){
              auditResultsMap.get(CheckId.DP_NO_MCQS).items.push(q);
            }
            break
          case 'CLK':
            if(!blockCount[ElementType.MCQ] || blockCount[ElementType.MCQ] <= totalDropdownCount){
              auditResultsMap.get(CheckId.CLK_NO_MCQS).items.push(q);
            }
            break
          case 'DG':
            if(!blockCount[ElementType.MOVEABLE_DND] && !blockCount[ElementType.ORDER] && !blockCount[ElementType.GROUPING] && !blockCount[ElementType.INSERTION]){
              auditResultsMap.get(CheckId.DG_NO_VALID_BLOCKS).items.push(q);
            }
            break;
          case 'CH':
            if(!blockCount[ElementType.SELECT_TABLE]){
              auditResultsMap.get(CheckId.CH_NO_SELECTION_TABLE).items.push(q);
            }
            break;
          case 'OR':
            if(!blockCount[ElementType.INPUT]){
              auditResultsMap.get(CheckId.OR_NO_SHORT_ANSWER).items.push(q);
            }
            break;
          case 'LW':
            if(!blockCount[ElementType.INPUT]){
              auditResultsMap.get(CheckId.LW_NO_SHORT_ANSWER).items.push(q);
            }
            break;
          case 'SW':
            if(!blockCount[ElementType.INPUT]){
              auditResultsMap.get(CheckId.SW_NO_SHORT_ANSWER).items.push(q);
            }
            break;
        }
      })
      
      
      return {}
    }

    // 44 // TODO make the audit show individual checks for each preserved parameter
    this.customIterators['ITEM_MAP_MODULE_META_AUDIT'] = async (auditResultsModel: AuditResultCore) => {
      const {questions, auditResultsMap, } = auditResultsModel;
      const preservedMetaParams = this.frameworkCtrl.asmtFmrk.preservedMetaParams;
      if(!preservedMetaParams){
        alert("No Preserved Params to Check");
        throw new Error("NO_RESERVED_PARAMS");
      }
      enum CheckId {
        MISSING_META = 'MISSING_RESERVED_META'
      }
      ITEM_MAP_MODULE_META_AUDIT_CONST_LABELS.forEach(questionLabel =>{
        const question = this.itemBankCtrl.getQuestionByLabel(questionLabel);
        if(question){
          questions.push(question);
        }
      });
      questions.forEach(q =>{
        let isFailed = false;
        preservedMetaParams.forEach(param =>{
          if(isNullOrEmptyOrUndefined(q.meta?.[param])){
            isFailed = true;
          }
        });
        if(isFailed){
          auditResultsMap.get(CheckId.MISSING_META).items.push(q);
        }
      });
      return {}
    }

  }


  // interface methods
  private async getQuestionsByScope(questionScope: AuditQuestionScope, languageSensitive: boolean = true){
    return getQuestionsByScope(questionScope, {
      lang: this.lang.c(),
      itemBankCtrl: this.itemBankCtrl, 
      frameworkCtrl: this.frameworkCtrl, 
    },
    languageSensitive
  )
  }

  private getQuestionContent(question: IQuestionConfig, lang?: 'en' | 'fr') {
    return getQuestionContent(question, {itemBankCtrl: this.itemBankCtrl}, lang)
  }

  // private isRejectedItem(qConfig: IQuestionConfig) {
  //   if(qConfig?.meta){
  //     return !!qConfig.meta?.['REJ']
  //   }
  //   return false;
  // }

  private async processDraggableElements(element: IContentElementDndDraggable, results: IAssetResult[]) {
    let totalSize = 0;
    let totalImageSize = 0;
    let totalAudioSize = 0;
    const { element: contentElement } = element
    if (contentElement.elementType === ElementType.IMAGE) {
      const { image }: { image: IContentElementImage } = contentElement.images.default;
      const assetSize = await getAssetSize(image?.url);
      results.push({ url: image.url, size: Math.round(assetSize * 100) / 100, entryId: image.entryId, type: ElementType.IMAGE });
      totalSize += assetSize;
      totalImageSize += assetSize;
    }
    if (contentElement.voiceover?.url) {
      const assetSize = await getAssetSize(contentElement.voiceover.url);
      results.push({ url: contentElement.voiceover.url, size: Math.round(assetSize * 100) / 100, entryId: contentElement.voiceover.entryId, type: ElementType.AUDIO });
      totalSize += assetSize;
      totalAudioSize += assetSize;
    }
    if (element.voiceover?.url) {
      const assetSize = await getAssetSize(element.voiceover.url);
      results.push({ url: element.voiceover.url, size: Math.round(assetSize * 100) / 100, entryId: element.voiceover.entryId, type: ElementType.AUDIO });
      totalSize += assetSize;
      totalAudioSize += assetSize;
    }
    return { totalSize, totalImageSize, totalAudioSize };
  }


  private async processMcQOptions(option: IContentElementMcqOption, results: IAssetResult[]) {
    let totalSize = 0;
    let totalImageSize = 0;
    let totalAudioSize = 0;
    if (option.elementType === ElementType.IMAGE) {
      const assetSize = await getAssetSize(option?.url);
      results.push({ url: option.url, size: Math.round(assetSize * 100) / 100, entryId: option.entryId, type: ElementType.IMAGE });
      totalSize += assetSize;
      totalImageSize += assetSize;
    }
    if (option.voiceover?.url) {
      const assetSize = await getAssetSize(option.voiceover.url);
      results.push({ url: option.voiceover.url, size: Math.round(assetSize * 100) / 100, entryId: option.voiceover.entryId, type: ElementType.AUDIO });
      totalSize += assetSize;
      totalAudioSize += assetSize;
    }
    return { totalSize, totalImageSize, totalAudioSize };
  }


  private async processContentElement(content: IContentElementTemplate | IContentElementMcq | IContentElement, results: IAssetResult[]) {
    let totalSize = 0;
    let totalImageSize = 0;
    let totalAudioSize = 0;

    if (content.elementType === ElementType.IMAGE) {
      const { image }: { image: IContentElementImage } = content.images.default;
      const assetSize = await getAssetSize(image.url);
      results.push({ url: image.url, size: Math.round(assetSize * 100) / 100, entryId: image.entryId, type: ElementType.IMAGE });
      totalSize += assetSize;
      totalImageSize += assetSize;
    }

    if (content.voiceover?.url) {
      const assetSize = await getAssetSize(content.voiceover.url);
      results.push({ url: content.voiceover.url, size: Math.round(assetSize * 100) / 100, entryId: content.voiceover.entryId, type: ElementType.AUDIO });
      totalSize += assetSize;
      totalAudioSize += assetSize;
    }

    if (content.elementType === ElementType.MCQ) {
      if (Array.isArray((content as IContentElementMcq).options)) {
        for (let option of (content as IContentElementMcq).options) {
          const { totalSize: optionTotalSize, totalImageSize: optionTotalImageSize, totalAudioSize: optionTotalAudioSize } = await this.processMcQOptions(option, results);
          totalSize += optionTotalSize;
          totalImageSize += optionTotalImageSize;
          totalAudioSize += optionTotalAudioSize;
        }
      }
    }
    if (content.elementType === ElementType.GROUPING) {
      if (Array.isArray((content as IContentElementGroup).draggables)) {
        for (let element of (content as IContentElementGroup).draggables) {
          const { totalSize: draggableTotalSize, totalImageSize: draggableTotalImageSize, totalAudioSize: draggableTotalAudioSize } = await this.processDraggableElements(element, results);
          totalSize += draggableTotalSize;
          totalImageSize += draggableTotalImageSize;
          totalAudioSize += draggableTotalAudioSize;

        }
      }
    }
    if (content.elementType === ElementType.TEMPLATE || content.elementType === ElementType.FRAME) {
      if (Array.isArray(content["content"])) {
        for (let element of content["content"]) {
          const { totalSize: nestedElTotalSize, totalImageSize: nestedElTotalImageSize, totalAudioSize: nestedElTotalAudioSize } = await this.processContentElement(element, results);
          totalSize += nestedElTotalSize;
          totalImageSize += nestedElTotalImageSize;
          totalAudioSize += nestedElTotalAudioSize;

        }
      }
    }
    return { totalSize, totalImageSize, totalAudioSize };
  }

  private async getAssetResults(questions: IQuestionConfig[]): Promise<Map<string, IQuestionResult>> {
    const assetResult = new Map<string, IQuestionResult>();
    await Promise.all(questions.map(async q => {
      const qContent = await this.getQuestionContent(q);
      const results: IAssetResult[] = [];
      let totalSize = 0;
      let totalImageSize = 0;
      let totalAudioSize = 0;
      for (let content of qContent) {
        const { totalSize: contentTotalSize, totalImageSize: contentTotalImageSize, totalAudioSize: contentTotalAudioSize } = await this.processContentElement(content, results);
        totalSize += contentTotalSize;
        totalImageSize += contentTotalImageSize;
        totalAudioSize += contentTotalAudioSize;
      }
      if (q.voiceover?.url) {
        const assetSize = await getAssetSize(q.voiceover.url);
        results.push({ url: q.voiceover.url, size: Math.round(assetSize * 100) / 100, entryId: q.voiceover.entryId, type: "Question Voice-Over" });
        totalSize += assetSize;
        totalAudioSize += assetSize;
      }
      if (results.length > 0) {
        assetResult.set(`${q.id} - ${q.label}`, { qid: q.id, results, imageSize: Math.round(totalImageSize * 100) / 100, audioSize: Math.round(totalAudioSize * 100) / 100, totalSize: Math.round(totalSize * 100) / 100 });
      }
    }));

    return assetResult;
  }


  remodeledData(imageResults: Map<string, IQuestionResult>, sizeLimit: number){
    const remodeledResults = Array.from(imageResults.entries()).map(([label,value]) => {
      const content: IExpansionPanelContent[] = [
        { type: ExpansionPanelContentType.JSON_VIEW,label: `Total Size = ${value.totalSize} KB; Total Image Size = ${value.imageSize} KB; Total Audio Size = ${value.audioSize} KB`,
         data: value.results.map((result) => { return { type:`${result.type}`,id: `${result.entryId}`, size:`${result.size} KB`,url:`${result.url}`  } }) }]
      const greaterThanSize = (value.totalSize > sizeLimit) ? true : false

      return {
        title: `${label}`,
        subTitle: `Total Files = ${value.results.length}`,
        content,
        greaterThanSize,
        style: {'border':`solid 0.1em ${greaterThanSize ? 'orange' : 'green'}`}
      }
    })
    return remodeledResults;
  }

  getAuditLogs() {
    this.auditLogs = [];
    this.auth.apiGet(this.routes.TEST_AUTH_ITEM_SET_AUDITS, this.itemBankCtrl.customTaskSetId, 
      { query: {
          lang: this.lang.getCurrentLanguage()
      }}).then((res) => {
        const latestDesignDate = this.frameworkCtrl.publishingCtrl.testDesignReleaseHistory?.[0] ? 
          new Date(this.frameworkCtrl.publishingCtrl.testDesignReleaseHistory?.[0]?.created_on) :
          null;
        res.forEach((log) => {
          const logDate = new Date(log.audited_on)
          if (!latestDesignDate) {
            log.is_new = true;
          } else {
            if(logDate > latestDesignDate) {
              log.is_new = true;
            } else {
              log.is_new = false;
            }
          }

          this.auditLogs.push(log);
        })
    })
  }

  getAuditLog(auditSlug: string) {
    if(this.auditLogs) {
      return this.auditLogs.find(log => log.audit_slug == auditSlug)
    } 

    return null;
  }  
  removeDuplicatesFromQuestions(questions: IQuestionConfig[]){
    return questions.filter((v,i,a)=>a.findIndex(v2=>(v2.id===v.id))===i)
  }
  private markAuditRunning(ranCode: string, isRunning: boolean){
    this.auditsRunning[ranCode] = isRunning
  }  
}

function factorial(x:number){
  return (x > 1) ? x * factorial(x-1) : 1;
}

/**
 * Calculates all number of all combinations that contain at least one correct answer
 * @param numSelected 
 * @param options 
 * @param correct 
 * @returns 
 */
function possiblePartialCorrectMCQ(numSelected:number, options:number, correct:number){
  return posibleCombinations(options, numSelected) - posibleCombinations(options, (numSelected - correct));
}

/**
 * Calculates the number of possible combinations that can be made by choosing a specific number of items
 * from a larger set, where the order of selection does not matter.
 *
 * @param optionsAvailable The total number of distinct options available to choose from.
 * @param slots The number of items to be selected from the available options.
 * @returns The number of possible combinations=.
 */
function posibleCombinations(optionsAvailable: number, slots: number) {
  return factorial(optionsAvailable) / (factorial(slots) * factorial(optionsAvailable - slots));
}

