import * as moment from 'moment-timezone';
import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';

export const dateStrToDateInputStr = (dateStr: string) => {
  const m = moment.tz(dateStr, moment.tz.guess());
  return m.format('YYYY-MM-DD');
}

const defaultType = 'date';

@Component({
  selector: 'input-date',
  templateUrl: './input-date.component.html',
  styleUrls: ['./input-date.component.scss']
})
export class InputDateComponent implements OnInit, OnChanges {

  @Input() fc: FormControl;
  @Input() type: string;
  @Input() isDisabled: boolean;
  @Output() dateSelected = new EventEmitter();
  @Output() isDateValid = new EventEmitter<boolean>();
  @Input() isRequired: boolean = true;

  constructor() { }

  datePicker = new FormControl();
  isValid: boolean = true;

  ngOnInit(): void {
    this.type = this.type || defaultType;
    this.datePicker.valueChanges.subscribe(v => this.onDatePickerChange());
    this.fc.valueChanges.subscribe(v => this.injectNewDate());
    this.injectNewDate();
    this.updateDisabledStatus();
    this.getMinDateTime()
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.isDisabled) { this.updateDisabledStatus() }
  }

  updateDisabledStatus() {
    if (this.isDisabled) {
      this.datePicker.disable();
    }
    else {
      this.datePicker.enable();
    }
  }

  injectNewDate() {
    const date: string = this.fc.value;
    if (date) {
      this.datePicker.setValue(date);
    }
  }
  getMinDateTime() {
    return new Date(new Date().toString().split('GMT')[0] + ' UTC').toISOString().split('.')[0].split(':', 2).join(':');
  }
  onDatePickerChange() {
    const dateStr: string = this.datePicker.value;
    if (dateStr) {
        this.isValid = true;
        if (this.type === 'date') {
          this.sanitizeDateStr(dateStr);
          // TODO: ADD SANITIZE FOR TIME PART  
        } else if (this.type === 'datetime-local') {
          this.fc.setValue(dateStr);
        }
        this.dateSelected.emit(dateStr);
    } else if(this.isRequired === false) {
      this.fc.setValue(null);
      this.dateSelected.emit(null);
    }
  }

  onDateChange($event){
    const dateStr: string = $event.target.value;
    if (dateStr || this.isRequired === true) {
      this.sanitizeDateStr($event.target.value)
    } else if(this.isRequired === false && dateStr) {
      $event.target.value = null;
      this.isDateValid.emit(true);
    }
    this.dateSelected.emit($event.target.value);
  }

  sanitizeDateStr(dateStr){
   // const dateStrSanitized = dateStr.substr(0, 10);
    const datePieces = dateStr.split('-');  
    if(datePieces.length < 3 ){this.isValid = false;} 
    else{
      if(datePieces[2] === ''){this.isValid = false}
    }   
    if (datePieces[0] && datePieces[0].length !== 4) { this.isValid = false; }
    if (datePieces[1] && datePieces[1].length !== 2) { this.isValid = false; }
    if (datePieces[2] && datePieces[2].length !== 2) { this.isValid = false; }
    if (datePieces[1] && +datePieces[1] > 12) { this.isValid = false; }
    if (datePieces[2] && +datePieces[2] > 32) { this.isValid = false; }
    if (this.isValid) {
      this.isDateValid.emit(true);
      if (dateStr !== this.fc.value) {
        this.fc.setValue(dateStr);
      }
    }
    else{
      this.isDateValid.emit(false)
    }
  }
}
